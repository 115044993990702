import { Link, Icon } from '@mui/material';
import React from 'react';

export const renderAsFileSize = (size: number) => {
  if (size < 1024) return <>{size} B</>;
  if (size < 1024 * 1024) return <>{Math.round((size / 1024) * 10) / 10} KB</>;
  if (size < 1024 * 1024 * 1024) return <>{Math.round((size / 1024 / 1024) * 10) / 10} MB</>;
  if (size < 1024 * 1024 * 1024 * 1024)
    return <>{Math.round((size / 1024 / 1024 / 1024) * 10) / 10} GB</>;
};

export const renderAsFileLink = (path: string, onlyPath?: boolean) => {
  path = path.replace('.pdf.pdf', '.pdf');
  path = path.replace('.pdf.docx', '.docx');
  path = path.replace('.docx.pdf', '.docx');

  let display =
    onlyPath && path.lastIndexOf('/') >= 0 && path.lastIndexOf('/') <= path.length - 1
      ? path.substring(path.lastIndexOf('/') + 1)
      : path;
  display = display.length > 33 ? display.substring(0, 30) + '...' : display;

  return (
    <>
      <div style={{ whiteSpace: 'nowrap' }}>
        {/* <OpenInNewIcon style={{ paddingRight: '3px', fontSize: '16px', position: 'relative', top: '3px' }} /> */}
        <Icon style={{ paddingRight: '3px', fontSize: '16px', position: 'relative', top: '3px' }}>
          open_in_new
        </Icon>
        <Link target="_blank" underline="none" href={`/files/${path}`}>
          {display}
        </Link>
      </div>
    </>
  );
};
