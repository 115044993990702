import { IconButton, Theme, Icon } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Opportunity, OpportunityArticle } from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';

/**
 *
 */
interface Props {
  article: OpportunityArticle;
  indexRow: number;
  selectMatchDialogHelper: any;
  selectPlayerDialogHelper: any;
}

export const tableStyle = makeStyles((theme: Theme) => {
  return createStyles({
    iconButtonNoPadding: {
      padding: '0px',
      '&& :hover': {
        color: theme.palette.primary.main,
      },
      '&& :active': {
        color: theme.palette.primary.dark,
      },
    },
  });
});

/**
 *
 * @param props
 */
export const OArticleIcon: React.FC<Props> = (props) => {
  const { translate } = useContext(LanguageContext);
  const { values, setFieldValue } = useFormikContext<Opportunity>();
  const classes = tableStyle();
  const [article, setArticle] = useState(props.article);
  const [indexRow, setIndexRow] = useState(props.indexRow);

  // Effects
  useEffect(() => {
    if (props.article !== article) setArticle(article);
    if (props.indexRow !== indexRow) setIndexRow(indexRow);

    return function cleanup() {
      //console.log({ cleanup: '' });
    };
  }, [article, props.article, indexRow, props.indexRow]);

  return (
    <>
      <>
        {props.article.text !== undefined && props.article.text !== null ? (
          <IconButton
            title={translate('Remove custom text')}
            className={classes.iconButtonNoPadding}
            disabled={values?.status === '100%' || values?.status === '0%'}
            style={{
              color: values?.status === '100%' || values?.status === '0%' ? '#666' : '#fff',
              paddingRight: '5px',
            }}
            onClick={() => setFieldValue(`articles[${indexRow}]text` as any, undefined)}
            size="large"
          >
            <Icon>remove_circle</Icon>
          </IconButton>
        ) : (
          <>
            <IconButton
              title={translate('Add custom text')}
              className={classes.iconButtonNoPadding}
              style={{ paddingRight: '5px' }}
              onClick={() => setFieldValue(`articles[${indexRow}]text` as any, '')}
              size="large"
            >
              <Icon>add_circle</Icon>
            </IconButton>
          </>
        )}
      </>
      {props.article.article?.isConnectedToMatch && !props.article.match && (
        <IconButton
          title={translate('Select match')}
          className={classes.iconButtonNoPadding}
          onClick={() => {
            //dispatch(fetchMatches({}));
            props.selectMatchDialogHelper.setData({
              indexRow: props.indexRow,
              article: props.article,
            });
            props.selectMatchDialogHelper.setOpen(true);
          }}
          size="large"
        >
          <Icon>link</Icon>
        </IconButton>
      )}
      {props.article.article?.isConnectedToMatch && props.article.match && (
        <IconButton
          title={translate('Unselect match')}
          className={classes.iconButtonNoPadding}
          onClick={() => setFieldValue(`articles[${indexRow}]match` as any, undefined)}
          size="large"
        >
          <Icon>link_off</Icon>
        </IconButton>
      )}
      {props.article.article?.isConnectedToPlayer && !props.article.player && (
        <IconButton
          title={translate('Select player')}
          className={classes.iconButtonNoPadding}
          onClick={() => {
            //dispatch(fetchMatches({}));
            props.selectPlayerDialogHelper.setData({
              indexRow: props.indexRow,
              article: props.article,
            });
            props.selectPlayerDialogHelper.setOpen(true);
          }}
          size="large"
        >
          <Icon>link</Icon>
        </IconButton>
      )}
      {props.article.article?.isConnectedToPlayer && props.article.player && (
        <IconButton
          title={translate('Unselect player')}
          className={classes.iconButtonNoPadding}
          onClick={() => setFieldValue(`articles[${indexRow}]player` as any, undefined)}
          size="large"
        >
          <Icon>link_off</Icon>
        </IconButton>
      )}
    </>
  );
};

export default OArticleIcon;
