import { User } from '@earnenterprise/asc-models';
import { Link } from '@mui/material';
import React from 'react';

/**
 *
 * @param user
 * @param history
 */
export const renderAsOwnerHome = (user?: User | null, history?: any) => {
  if (!user) return null;
  return (
    <Link
      underline="none"
      href={`/home/${user.id}`}
      onClick={(e: any) => {
        if (history) {
          e.preventDefault();
          history(`/home/${user.id}`);
        }
      }}
    >
      {user.firstname} {user.lastname}
    </Link>
  );
};
