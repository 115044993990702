import { useState } from 'react';
import { useQuery } from 'react-query';
import { Group, emptyGroup, emptyAccount, Account } from '@earnenterprise/asc-models';
import { Message } from '@earnenterprise/asc-models';
import { Setting, emptySetting } from '@earnenterprise/asc-models';
import { Theme } from '@earnenterprise/asc-theme';
import { emptyUser, User } from '@earnenterprise/asc-models';
import { getFromDate, getToDate } from 'routes/report/report.filter';
import { GQLReturnType } from 'services/gql.service';

export const useSetting = () => {
  const { online } = useUser();

  const querySetting = useQuery<GQLReturnType<Setting>>(['settings'], {
    refetchOnWindowFocus: false,
    enabled: !!online,
  });
  const setting =
    querySetting.data && querySetting.data.data ? querySetting.data.data : emptySetting();
  return { querySetting, setting };
};

export const useUser = () => {
  const queryUser = useQuery<GQLReturnType>(['user']);
  const user = queryUser.data ? (queryUser.data as any as User) : emptyUser();
  const online =
    user !== null && user !== undefined && user.id !== null && user.id !== 0 && user.id !== '0';

  return { queryUser, user, online };
};

export const useAccount = (id: number) => {
  const queryAccount = useQuery<GQLReturnType>(['account', { id }]);
  const account = queryAccount.data ? (queryAccount.data as any as Account) : emptyAccount();
  return { queryAccount, account };
};

export const useMessages = () => {
  const queryMessages = useQuery<GQLReturnType>(['messages-user'], { refetchOnWindowFocus: false });
  const messages = queryMessages.data ? (queryMessages.data?.data as any as Message[]) : undefined;

  return { queryMessages, messages };
};

export const useHints = () => {
  //const queryRights = useQuery<any>(['rights']);
  //const rights = queryRights.data ? ((queryRights.data?.group as any) as Group) : emptyGroup();
  const [hints, setHints] = useState<boolean>(
    localStorage.getItem('hints') === 'true' ? true : false
  );

  return {
    hints,
    setHints: (value: boolean) => {
      localStorage.setItem('hints', value.toString());
      setHints(value);
    },
  };
};

export const useRights = () => {
  const { online } = useUser();

  const queryRights = useQuery<any>(['rights'], { enabled: !!online });
  const rights = queryRights.data ? (queryRights.data?.group as any as Group) : emptyGroup();

  return { queryRights, rights };
};

export const useThemes = () => {
  const queryThemes = useQuery<any>(['themes']);
  const themes = queryThemes.data ? (queryThemes.data as any as Theme[]) : [];

  return { queryThemes, themes };
};

export const useTable = (lsName: string, orderBy: string, options?: { tags?: string[] }) => {
  const lsItem = localStorage.getItem(lsName);
  const lsData = lsItem ? JSON.parse(lsItem) : null;

  const [searchPage, setSearchPage] = useState<number>(lsData ? lsData.searchPage : 0);
  const [searchOrder, setSearchOrder] = useState<string>(lsData ? lsData.searchOrder : orderBy);
  const [searchSize, setSearchSize] = useState<number>(lsData ? lsData.searchSize : 50);
  const [searchText, setSearchText] = useState<string | undefined>(
    lsData ? lsData.searchText : undefined
  );
  const [searchTags, setSearchTags] = useState<string[] | undefined>(
    lsData
      ? lsData.searchTags
        ? lsData.searchTags
        : options
        ? options.tags
        : undefined
      : options
      ? options.tags
      : undefined
  );
  const [searchMatching, setSearchMatching] = useState<'Any' | 'All'>(
    lsData ? lsData.searchMatching : 'All'
  );
  const [tableSize, setTableSize] = useState<any | undefined>(lsData ? lsData.tableSize : 'medium');

  if (searchTags && searchTags.length === 0 && options && options.tags) {
    setSearchTags(options.tags);
  }

  return {
    searchPage,
    setSearchPage,
    searchOrder,
    setSearchOrder,
    searchSize,
    setSearchSize,
    searchText,
    setSearchText,
    searchTags,
    setSearchTags,
    searchMatching,
    setSearchMatching,
    tableSize,
    setTableSize,
  };
};

interface Category {
  category: string;
  articles: { name: string; id: string | number | null; amount: number; priceInCents: number }[];
  amount: number;
  priceInCents: number;
  showDetails: boolean;
  counted: boolean;
}

export const useReportTable = (lsName: string, orderBy: string, linktype: string) => {
  const lsItem = localStorage.getItem(lsName);
  const lsData = lsItem ? JSON.parse(lsItem) : null;

  const [linkType, setLinkType] = useState<string>(linktype);
  const [tags, setTags] = useState<string[] | undefined>(lsData ? lsData.tags : null);
  const [categories, setCategories] = useState<Category[]>(lsData ? lsData.categories : []);

  return {
    tags,
    setTags,
    linkType,
    setLinkType,
    categories,
    setCategories,
  };
};

export const useReportGeneralTable = (lsName: string, orderBy: string, linktype: string) => {
  const lsItem = localStorage.getItem(lsName);
  const lsData = lsItem ? JSON.parse(lsItem) : null;

  const [from, setFrom] = useState<Date | null>(
    lsData && lsData.from ? new Date(Date.parse(lsData.from)) : getFromDate(0)
  );
  const [to, setTo] = useState<Date | null>(
    lsData && lsData.to ? new Date(Date.parse(lsData.to)) : getToDate(0)
  );

  return {
    from,
    setFrom,
    to,
    setTo,
  };
};
