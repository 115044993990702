/**
 *
 * @param user
 */
function setupAddress(item) {
    if (item.description === null)
        item.description = '';
    if (item.street === null)
        item.street = '';
    if (item.suite === null)
        item.suite = '';
    if (item.zipcode === null)
        item.zipcode = '';
    if (item.city === null)
        item.city = '';
    if (item.email == null)
        item.email = '';
}
/**
 *
 */
function emptyAddress() {
    return {
        description: '',
        street: '',
        suite: '',
        zipcode: '',
        city: '',
        email: ''
    };
}

/**
 *
 * @param user
 */
function setupCustomField(item) {
    if (item.name === null)
        item.name = '';
    if (item.value === null)
        item.value = '';
}
/**
 *
 */
function emptyCustomField() {
    return {
        name: '',
        value: ''
    };
}

/**
 *
 * @param item
 */
function setupPerson(item) {
    if (item.title === null)
        item.title = '';
    if (item.firstname === null)
        item.firstname = '';
    if (item.lastname === null)
        item.lastname = '';
    if (item.phone === null)
        item.phone = '';
    if (item.email === null)
        item.email = '';
}
/**
 *
 */
function emptyPerson() {
    return {
        id: 0,
        title: '',
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    };
}

/**
 * Valid exports for account
 */
const AccountOutput = () => {
    return {
        id: '?int',
        name: 'string',
        organizationNo: 'string',
        inactive: 'bool',
        email: 'string',
        phone: 'string',
        priceInCents: 'int',
        owner: { id: 'int', firstname: 'string', lastname: 'string' },
        addresses: { description: 'string', street: 'string', suite: 'string', zipcode: 'string', city: 'string', email: 'string' },
        customFields: { name: 'string', value: 'string' },
        contacts: { id: 'int', title: 'string', firstname: 'string', lastname: 'string', email: 'string', phone: 'string' },
        tags: ['string'],
        logs: { id: 'int', date: 'time', info: 'string' },
    };
};
/**
 *
 */
function emptyAccount(owner) {
    return {
        id: null,
        name: '',
        inactive: false,
        owner: owner ? owner : null,
        addresses: null,
        priceInCents: 0,
        status: 0,
        customFields: null,
        agreements: null,
        contacts: null,
        organizationNo: '',
        phone: null,
        email: null,
    };
}
/**
 *
 * @param user
 */
function setupAccount(item, withBL, withFortnox) {
    if (!item.organizationNo)
        item.organizationNo = '';
    if (!item.phone)
        item.phone = '';
    if (!item.email)
        item.email = '';
    if (item.addresses) {
        Object.keys(item.addresses).forEach((key) => {
            if (item.addresses[key])
                setupAddress(item.addresses[key]);
        });
    }
    else {
        item.addresses = [];
    }
    if (item.contacts) {
        Object.keys(item.contacts).forEach((key) => {
            if (item.contacts[key])
                setupPerson(item.contacts[key]);
        });
    }
    else {
        item.contacts = [];
    }
    if (item.customFields) {
        Object.keys(item.customFields).forEach((key) => {
            if (item.customFields[key])
                setupCustomField(item.customFields[key]);
        });
    }
    else {
        if (withBL)
            item.customFields = [{ name: 'BLID', value: '' }];
        if (withFortnox)
            item.customFields = [{ name: 'Fortnox ID', value: '' }];
    }
}

/**
 * Valid exports for agreement
 */
const AgreementOutput = () => {
    return {
        id: '?int',
        name: 'string',
        owner: { id: 'int', firstname: 'string', lastname: 'string' },
        account: { id: 'int', name: 'string' },
        files: {
            id: '?int',
            name: 'string',
            path: 'string',
            size: 'int',
        },
        tags: ['string'],
        from: 'time',
        to: 'time',
        reminderAt: '?time',
        reminderStatus: 'int',
    };
};
/**
 *
 */
function emptyAgreement(owner, account) {
    return {
        id: null,
        name: '',
        from: new Date(),
        to: new Date(),
        owner: owner ? owner : null,
        account: account ? account : null,
        reminderStatus: 0,
    };
}
/**
 *
 * @param user
 */
function setupAgreement(agreement) {
    console.debug(agreement);
}

/**
 * Valid exports for Article
 */
const ArticleOutput = () => {
    return {
        id: '?int',
        name: 'string',
        category: 'string',
        number: 'string',
        priceInCents: 'int',
        tags: ['string'],
        inStock: 'int',
        account: 'int',
        costCenter: 'int',
        vatCode: 'int',
        company: 'string',
        textSeller: 'string',
        textBuyer: 'string',
        textOther: 'string',
        textInvoice: 'string',
        buyPriceInCents: 'int',
        isAutoIncluded: 'bool',
        isInvoicedAfterEvent: 'bool',
        isFixedPrice: 'bool',
        isSendMessage: 'bool',
        isConnectedToMatch: 'bool',
        isConnectedToPlayer: 'bool',
        isExposure: 'bool',
        isExchangeable: 'bool',
        isPercentage: 'bool',
        isBarter: 'bool',
        excludeOnQuote: 'bool',
        inactive: 'bool',
        split: 'bool',
        sendMessageTo: { id: 'int', firstname: 'string', lastname: 'string' },
        image: 'string',
        imageExtra: 'string',
        imageName: 'string',
        imageNext: 'string',
        imagePrevious: 'string',
        imageDisplayed: 'bool',
        imageCoordsType: 'string',
        imageCoords: { 0: 'int', 1: 'int' },
    };
};
/**
 *
 */
function emptyArticle() {
    return {
        id: null,
        name: '',
        category: '',
        number: '',
        priceInCents: 0,
        buyPriceInCents: 0,
        costCenter: 0,
        account: 0,
        inStock: 0,
        sold: 0,
        vatCode: 0,
        imageDisplayed: false,
    };
}
/**
 *
 * @param user
 */
function setupArticle(article) {
    if (article.inStock === null || article.inStock === undefined)
        article.inStock = 0;
    if (article.buyPriceInCents === null || article.buyPriceInCents === undefined)
        article.buyPriceInCents = 0;
    if (article.priceInCents === null || article.priceInCents === undefined)
        article.priceInCents = 0;
    if (article.sold === null || article.sold === undefined)
        article.sold = 0;
    if (article.vatCode === null || article.vatCode === undefined)
        article.vatCode = 0;
    if (article.isAutoIncluded === null || article.isAutoIncluded === undefined)
        article.isAutoIncluded = false;
    if (article.textSeller === null || article.textSeller === undefined)
        article.textSeller = '';
    if (article.textBuyer === null || article.textBuyer === undefined)
        article.textBuyer = '';
    if (article.textOther === null || article.textOther === undefined)
        article.textOther = '';
    if (article.textInvoice === null || article.textInvoice === undefined)
        article.textInvoice = '';
    if (article.isFixedPrice === null || article.isFixedPrice === undefined)
        article.isFixedPrice = false;
    if (article.isExposure === null || article.isExposure === undefined)
        article.isExposure = false;
    if (article.split === null || article.split === undefined)
        article.split = false;
    if (article.isExchangeable === null || article.isExchangeable === undefined)
        article.isExchangeable = false;
    if (article.isInvoicedAfterEvent === null || article.isInvoicedAfterEvent === undefined)
        article.isInvoicedAfterEvent = false;
    if (article.isSendMessage === null || article.isSendMessage === undefined)
        article.isSendMessage = false;
    if (article.isConnectedToMatch === null || article.isConnectedToMatch === undefined)
        article.isConnectedToMatch = false;
    if (article.isConnectedToPlayer === null || article.isConnectedToPlayer === undefined)
        article.isConnectedToPlayer = false;
    if (article.isPercentage === null || article.isPercentage === undefined)
        article.isPercentage = false;
    if (article.isBarter === null || article.isBarter === undefined)
        article.isBarter = false;
    if (article.excludeOnQuote === null || article.excludeOnQuote === undefined)
        article.excludeOnQuote = false;
    if (article.imageDisplayed === null || article.imageDisplayed === undefined)
        article.imageDisplayed = false;
    if (article.imageCoords === null || article.imageCoords === undefined)
        article.imageCoords = null;
    if (article.imageCoordsType === null || article.imageCoordsType === undefined)
        article.imageCoordsType = 'rect';
}
/**
 *
 */
const getSoldForDates = (article, from, to) => {
    var _a, _b, _c, _d, _e, _f;
    let sold = 0;
    let booked = 0;
    let sales = [];
    let bookings = [];
    // No dates specified
    if (!from && !to) {
        const currentDate = new Date().getTime();
        (_a = article.sales) === null || _a === void 0 ? void 0 : _a.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            const between = currentDate >= fromDate && currentDate <= toDate;
            if (between) {
                sales.push(sale);
                sold += sale.amount;
            }
        });
        (_b = article.bookings) === null || _b === void 0 ? void 0 : _b.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            const between = currentDate >= fromDate && currentDate <= toDate;
            if (between) {
                bookings.push(sale);
                booked += sale.amount;
            }
        });
    }
    // Only from specified
    else if (!to && from) {
        const currentDate = Date.parse(from.toString());
        (_c = article.sales) === null || _c === void 0 ? void 0 : _c.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            const between = currentDate >= fromDate && currentDate <= toDate;
            if (between) {
                sales.push(sale);
                sold += sale.amount;
            }
        });
        (_d = article.bookings) === null || _d === void 0 ? void 0 : _d.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            const between = currentDate >= fromDate && currentDate <= toDate;
            if (between) {
                bookings.push(sale);
                booked += sale.amount;
            }
        });
    }
    // From and to specified
    else if (from && to) {
        const startDate = Date.parse(from.toString());
        const endDate = Date.parse(to.toString());
        (_e = article.sales) === null || _e === void 0 ? void 0 : _e.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            let between = startDate >= fromDate && endDate <= toDate; // Between, ie 2020-05-2020-07 in between 2020-01 and 2020-12
            if (!between)
                between = startDate < fromDate && endDate >= fromDate && endDate <= toDate; // Before but ending inside, ie 2019-06-2020-06 before 2020-01 but ending before 2020-12
            if (!between)
                between = endDate > toDate && startDate >= fromDate && startDate <= toDate; // After but starting inside, ie 2020-06-2021-06 starting 2020-01 but ending after 2020-12
            if (between) {
                sales.push(sale);
                sold += sale.amount;
            }
        });
        (_f = article.bookings) === null || _f === void 0 ? void 0 : _f.forEach((sale) => {
            const fromDate = sale.from ? Date.parse(sale.from) : 0;
            const toDate = sale.to ? Date.parse(sale.to) : 9999999999999;
            let between = startDate >= fromDate && endDate <= toDate; // Between, ie 2020-05-2020-07 in between 2020-01 and 2020-12
            if (!between)
                between = startDate < fromDate && endDate >= fromDate && endDate <= toDate; // Before but ending inside, ie 2019-06-2020-06 before 2020-01 but ending before 2020-12
            if (!between)
                between = endDate > toDate && startDate >= fromDate && startDate <= toDate; // After but starting inside, ie 2020-06-2021-06 starting 2020-01 but ending after 2020-12
            if (between) {
                bookings.push(sale);
                booked += sale.amount;
            }
        });
    }
    return { sold, sales, booked, bookings };
};

/**
 * Valid exports for user
 */
const GroupOutput = () => {
    return {
        id: '?int',
        name: 'string',
        users: { id: 'int', firstname: 'string', lastname: 'string' },
        opportunityRead: 'bool',
        opportunityWrite: 'bool',
        opportunityAll: 'bool',
        quoteRead: 'bool',
        quoteWrite: 'bool',
        quoteAll: 'bool',
        messageRead: 'bool',
        messageWrite: 'bool',
        messageAll: 'bool',
        accountRead: 'bool',
        accountWrite: 'bool',
        accountAll: 'bool',
        agreementRead: 'bool',
        agreementWrite: 'bool',
        agreementAll: 'bool',
        articleRead: 'bool',
        articleWrite: 'bool',
        reportAll: 'bool',
        adminAll: 'bool',
    };
};
/**
 * ! All fields who are displayed on formik fields needs a default value
 */
function emptyGroup() {
    return {
        id: null,
        name: '',
        users: null,
        opportunityRead: false,
        opportunityWrite: false,
        opportunityAll: false,
        quoteRead: false,
        quoteWrite: false,
        quoteAll: false,
        messageRead: false,
        messageWrite: false,
        messageAll: false,
        accountRead: false,
        accountWrite: false,
        accountAll: false,
        agreementRead: false,
        agreementWrite: false,
        agreementAll: false,
        articleRead: false,
        articleWrite: false,
        reportAll: false,
        adminAll: false,
    };
}
/**
 *
 * @param user
 */
function setupGroup(group) {
    if (group.name === null)
        group.name = '';
}

/**
 * Valid exports for user
 */
const ImageOutput = () => {
    return {
        id: '?int',
        name: 'string',
        type: 'string',
        path: 'string',
        displayName: 'string',
        next: 'string',
        previous: 'string',
        size: 'int',
        displayed: 'bool'
    };
};
/**
 * ! All fields who are displayed on formik fields needs a default value
 */
function emptyImage() {
    return {
        id: null,
        name: '',
        type: 'Article',
        path: '',
        size: 0
    };
}
/**
 *
 * @param user
 */
function setupImage(image) {
    if (image.displayName === null)
        image.displayName = '';
    if (image.previous === null)
        image.previous = '';
    if (image.next === null)
        image.next = '';
}

/**
 * Valid exports for match
 */
const MatchOutput = () => {
    return {
        id: '?int',
        date: 'time',
        homeTeam: 'string',
        awayTeam: 'string',
        time: 'string',
    };
};
/**
 *
 */
function emptyMatch() {
    return {
        id: null,
        date: new Date(),
        homeTeam: '',
        awayTeam: '',
        time: '00:00',
    };
}
/**
 *
 * @param user
 */
function setupMatch(item) { }

/**
 * Valid exports for account
 */
const MessageOutput = () => {
    return {
        id: '?int',
        subject: 'string',
        text: 'string',
        from: { id: 'int', firstname: 'string', lastname: 'string' },
        to: { id: 'int', firstname: 'string', lastname: 'string' },
        account: { id: '?int', name: 'string' },
        opportunity: { id: '?int', name: 'string' },
    };
};
/**
 *
 */
function emptyMessage(to, account) {
    return {
        id: null,
        subject: '',
        text: '',
        read: false,
        to: to ? to : null,
        account: account ? account : null,
        opportunity: null,
    };
}
/**
 *
 * @param user
 */
function setupMessage(item) {
    if (!item.text)
        item.text = '';
}

/**
 * Valid exports for match
 */
const PlayerOutput = () => {
    return {
        id: '?int',
        season: 'string',
        name: 'string',
        number: 'int',
    };
};
/**
 *
 */
function emptyPlayer() {
    return {
        id: null,
        season: '',
        name: '',
    };
}
/**
 *
 * @param user
 */
function setupPlayer(item) { }

const OpportunityOutput = () => {
    return {
        id: '?int',
        //name: 'string',
        number: 'int',
        tags: true,
        status: 'string',
        ignoreSeason: 'string',
        text: 'string',
        from: 'time',
        to: '?time',
        completedAt: '?time',
        contact: {
            title: 'string',
            firstname: 'string',
            lastname: 'string',
            email: 'string',
            phone: 'string',
        },
        owner: { id: 'int', firstname: 'string', lastname: 'string' },
        account: { id: 'int', name: 'string' },
        articles: {
            id: 'int',
            order: 'int',
            article: ArticleOutput(),
            pricePerItemInCents: 'int',
            priceInCents: 'int',
            rebateInCents: 'int',
            amount: 'int',
            sumInCents: 'int',
            match: MatchOutput(),
            player: PlayerOutput(),
            text: 'string',
        },
        payments: PaymentOutput(),
        priceInCents: 'int',
    };
};
const PaymentOutput = () => {
    return {
        id: 'int',
        date: 'time',
        text: 'string',
        status: 'string',
        season: 'string',
        label: 'string',
        calculated: 'string',
        priceInCents: 'int',
        invoiceNumber: 'int',
        orderNumber: 'int',
        paymentTerms: 'int',
        hideArticles: 'bool',
        hideArticlesText: 'string',
        useFortnoxSecond: 'bool',
        isInvoicedAfterEvent: 'bool',
        articles: {
            id: 'int',
            article: ArticleOutput(),
            pricePerItemInCents: 'int',
            priceInCents: 'int',
            costCenter: 'int',
            rebateInCents: 'int',
            amount: 'int',
            vat: 'float',
            sumInCents: 'int',
            text: 'string',
        },
    };
};
const OpportunityPaymentOutput = () => {
    return {
        id: '?int',
        opportunityId: '?string',
        date: 'time',
        text: 'string',
        status: 'string',
        season: 'string',
        label: 'string',
        calculated: 'string',
        priceInCents: 'int',
        invoiceNumber: 'int',
        orderNumber: 'int',
        paymentTerms: 'int',
        hideArticles: 'bool',
        hideArticlesText: 'string',
        useFortnoxSecond: 'bool',
        isInvoicedAfterEvent: 'bool',
        articles: {
            id: '?int',
            article: ArticleOutput(),
            pricePerItemInCents: 'int',
            priceInCents: 'int',
            costCenter: 'int',
            rebateInCents: 'int',
            amount: 'int',
            vat: 'float',
            sumInCents: 'int',
            text: 'string',
        },
    };
};
const getDefaultStartDate = (offset) => {
    const date = new Date();
    if (date.getMonth() < 3)
        return new Date(Date.parse((date.getFullYear() - 1 + offset).toString() + '-05-01T12:00:00Z'));
    return new Date(Date.parse((date.getFullYear() + offset).toString() + '-05-01T12:00:00Z'));
};
const getDefaultEndDate = (offset) => {
    const date = new Date();
    if (date.getMonth() < 3)
        return new Date(Date.parse((date.getFullYear() + offset).toString() + '-04-30T12:00:00Z'));
    return new Date(Date.parse((date.getFullYear() + 1 + offset).toString() + '-04-30T12:00:00Z'));
};
function emptyOpportunity(owner, account) {
    return {
        id: null,
        number: 0,
        owner: owner ? owner : null,
        account: account ? account : null,
        priceInCents: 0,
        ignoreSeason: '',
        status: '1%',
        contact: emptyPerson(),
        from: getDefaultStartDate(0),
        to: getDefaultEndDate(0),
    };
}
function setupOpportunity(opportunity) {
    if (opportunity.articles) {
        opportunity.articles.forEach((_, index) => setupOpportunityArticle(opportunity.articles[index]));
    }
    if (opportunity.payments) {
        opportunity.payments.forEach((_, index) => setupPayment(opportunity.payments[index]));
    }
    if (opportunity.from === null)
        opportunity.from = new Date();
    if (opportunity.contact === null)
        opportunity.contact = emptyPerson();
}
function setupOpportunityArticle(opportunityArticle) {
    if (opportunityArticle.amount === null)
        opportunityArticle.amount = 0;
    if (opportunityArticle.pricePerItemInCents === null)
        opportunityArticle.pricePerItemInCents = 0;
    if (opportunityArticle.priceInCents === null)
        opportunityArticle.priceInCents = 0;
    if (opportunityArticle.costCenter === null)
        opportunityArticle.costCenter = 0;
    if (opportunityArticle.rebateInCents === null)
        opportunityArticle.rebateInCents = 0;
    if (opportunityArticle.sumInCents === null)
        opportunityArticle.sumInCents = 0;
    if (opportunityArticle.text === '')
        opportunityArticle.text = undefined;
    //if (opportunityArticle.text === null) opportunityArticle.text = '';
}
function emptyOpportunityArticle(article) {
    return {
        id: null,
        priceInCents: 0,
        article,
        amount: 0,
        order: 0,
        pricePerItemInCents: 0,
        rebateInCents: 0,
        sumInCents: 0,
        costCenter: article.costCenter,
        vat: 0,
    };
}
function setupPayment(payment) {
    if (payment.articles) {
        payment.articles.forEach((_, index) => setupOpportunityArticle(payment.articles[index]));
    }
    if (payment.date === null)
        payment.date = new Date();
    try {
        payment.date = new Date(payment.date).toISOString().substring(0, 10);
    }
    catch (_a) { }
}
function getOpportunitySeason(opportunity) {
    let start = '';
    let end = '';
    if (opportunity.from) {
        let from = new Date(opportunity.from);
        if (from.getMonth() < 4) {
            start = (from.getFullYear() - 1).toString();
        }
        else {
            start = from.getFullYear().toString();
        }
        if (opportunity.to) {
            let to = new Date(opportunity.to);
            if (to.getMonth() < 4) {
                end = to.getFullYear().toString();
            }
            else {
                end = (to.getFullYear() + 1).toString();
            }
        }
    }
    // Running agreement
    if (end === '') {
        const date = new Date();
        let startNum = parseInt(start, 10);
        const currentSeason = date.getMonth() < 4 ? date.getFullYear() - 1 : date.getFullYear();
        let seasons = '';
        for (let i = startNum; i <= currentSeason; i++) {
            if (seasons !== '')
                seasons += ', ';
            seasons += i + '/' + (i + 1);
        }
        //return start + '/' + (startNum + 1);
        return seasons;
    }
    let startNum = parseInt(start, 10);
    let endNum = parseInt(end, 10);
    let seasons = '';
    for (let i = 0; i < endNum - startNum; i++) {
        if (seasons !== '')
            seasons += ', ';
        seasons += startNum + i + '/' + (startNum + i + 1);
    }
    return seasons;
}

/**
 * Valid exports for account
 */
const PackageOutput = () => {
    return {
        id: '?int',
        name: 'string',
    };
};
/**
 *
 */
function emptyPackage() {
    return {
        id: null,
        name: '',
    };
}
/**
 *
 * @param user
 */
function setupPackage(item) {
    if (!item.name)
        item.name = '';
}

/**
 * Valid exports for match
 */
const PresentationOutput = () => {
    return {
        id: '?int',
        name: 'string',
        image: 'string',
        text: 'string'
    };
};
/**
 *
 */
function emptyPresentation() {
    return {
        id: null,
        name: '',
        image: '',
        text: ''
    };
}
/**
 *
 * @param user
 */
function setupPresentation(item) { }

const QuoteOutput = () => {
    return {
        id: 'int',
        status: 'string',
        template: 'string',
        filename: 'string',
        tags: true,
        forSigning: 'bool',
        getAcceptSubject: 'string',
        getAcceptMessage: 'string',
        oneflowMessage: 'string',
        comment: 'string',
        signers: { firstname: 'string', lastname: 'string', email: 'string' },
        opportunity: { id: 'int', number: 'int' },
        contact: {
            id: 'int',
            title: 'string',
            firstname: 'string',
            lastname: 'string',
            email: 'string',
            phone: 'string',
        },
        owner: { id: 'int', firstname: 'string', lastname: 'string' },
        account: { id: 'int', name: 'string' },
        textSeller: { text: 'string', type: 'string' },
        textBuyer: { text: 'string', type: 'string' },
        textOther: { text: 'string', type: 'string' },
    };
};
function emptyQuote(owner, account, opportunity) {
    return {
        id: null,
        owner: owner ? owner : null,
        account: account ? account : null,
        opportunity: opportunity ? opportunity : null,
        status: 'Created',
        forSigning: true,
        signers: [],
        template: '',
        comment: '',
        textSeller: [],
        textBuyer: [],
        textOther: [],
    };
}
function setupQuote(quote) { }

/**
 * Valid exports for match
 */
const ReminderOutput = () => {
    return {
        id: '?int',
        text: 'string',
        status: 'string',
        date: 'time',
        owner: {
            id: '?int',
        },
        account: {
            id: '?int',
        },
        opportunity: {
            id: '?int',
        },
        agreement: {
            id: '?int',
            name: 'string',
        },
    };
};
/**
 *
 */
function emptyReminder(user) {
    return {
        id: null,
        text: '',
        status: '',
        date: new Date(),
        owner: user,
    };
}
/**
 *p
 * @param user
 */
function setupReminder(item) { }

const SettingOutput = () => {
    return {
        id: 'int',
        paymentTerms: 'int',
        defaultVAT: 'float',
        hasSecondaryCompany: 'bool',
        blHiddenArticleNumber: 'string',
        fortnoxCreateOrder: 'bool',
        alwaysShowPaymentsOnQuote: 'bool',
        mandatoryOrgNo: 'bool',
        companyName: 'string',
        secondaryCompanyName: 'string',
        getAcceptUsername: 'string',
        getAcceptPassword: 'string',
        invoiceTextAppend: 'string',
        statusLevels: 'string',
        menuString: 'string',
    };
};
function emptySetting() {
    return {
        id: null,
        paymentTerms: 30,
        defaultVAT: 0,
        getAcceptEnabled: false,
        fortnoxEnabled: false,
        briljantEnabled: false,
        blEnabled: false,
        signrEnabled: false,
        oneflowEnabled: false,
        oneflowSignOrderEnabled: false,
        mandatoryOrgNo: true,
        testEnvironment: false,
        exportAgreementFiles: false,
        fortnoxCreateOrder: false,
        hasSecondaryCompany: false,
        alwaysShowPaymentsOnQuote: false,
        companyName: '',
        secondaryCompanyName: '',
        invoiceTextAppend: '',
        blHiddenArticleNumber: '',
        statusLevels: '1%|10%|25%|50%|75%',
        menuString: '0;Message;|1;Info;|1;Customer;|2;Opportunity;|3;Quote;|4;Agreement;|5;Order;',
    };
}
function setupSetting(item) {
    if (item.statusLevels === '') {
        item.statusLevels = '1%|10%|25%|50%|75%';
    }
    if (item.menuString === '') {
        item.menuString =
            '0;Message;|1;Info;|1;Customer;|2;Opportunity;|3;Quote;|4;Agreement;|5;Order;';
    }
}

/**
 * Valid exports for match
 */
const TagOutput = () => {
    return {
        id: '?int',
        name: 'string',
        type: 'string'
    };
};
/**
 *
 */
function emptyTag() {
    return {
        id: null,
        name: '',
        type: ''
    };
}
/**
 *p
 * @param user
 */
function setupTag(item) { }

/**
 * Valid exports for user
 */
const UserOutput = () => {
    return {
        id: '?int',
        username: 'string',
        firstname: 'string',
        lastname: 'string',
        email: 'string',
        password: 'string',
        signrPassword: 'string',
        avatar: 'string',
        isOffice365: 'boolean',
    };
};
/**
 * ! All fields who are displayed on formik fields needs a default value
 */
function emptyUser() {
    return {
        id: null,
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        avatar: '',
        passwordConfirm: '',
        signrPassword: '',
        email: '',
        version: 0,
        isOffice365: false,
    };
}
/**
 *
 * @param user
 */
function setupUser(user) {
    if (user.lastname === null)
        user.lastname = '';
    if (user.password === null)
        user.password = '';
    if (user.passwordConfirm === null)
        user.passwordConfirm = '';
    if (user.signrPassword === null)
        user.signrPassword = '';
    // Make sure these values exists
    if (Object.keys(user).findIndex((key) => key === 'password') === -1)
        user.password = '';
    if (Object.keys(user).findIndex((key) => key === 'passwordConfirm') === -1)
        user.passwordConfirm = '';
    if (Object.keys(user).findIndex((key) => key === 'signrPassword') === -1)
        user.signrPassword = '';
}

export { AccountOutput, AgreementOutput, ArticleOutput, GroupOutput, ImageOutput, MatchOutput, MessageOutput, OpportunityOutput, OpportunityPaymentOutput, PackageOutput, PaymentOutput, PlayerOutput, PresentationOutput, QuoteOutput, ReminderOutput, SettingOutput, TagOutput, UserOutput, emptyAccount, emptyAddress, emptyAgreement, emptyArticle, emptyCustomField, emptyGroup, emptyImage, emptyMatch, emptyMessage, emptyOpportunity, emptyOpportunityArticle, emptyPackage, emptyPerson, emptyPlayer, emptyPresentation, emptyQuote, emptyReminder, emptySetting, emptyTag, emptyUser, getDefaultEndDate, getDefaultStartDate, getOpportunitySeason, getSoldForDates, setupAccount, setupAddress, setupAgreement, setupArticle, setupCustomField, setupGroup, setupImage, setupMatch, setupMessage, setupOpportunity, setupOpportunityArticle, setupPackage, setupPayment, setupPerson, setupPlayer, setupPresentation, setupQuote, setupReminder, setupSetting, setupTag, setupUser };
