import {
  AppBar,
  Button,
  Container,
  Dialog,
  Divider,
  IconButton,
  List,
  Slide,
  Theme,
  Toolbar,
  Typography,
  Icon,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionProps } from '@mui/material/transitions';
import React, { useContext } from 'react';
import { AccordionComponent } from 'components/expansion.panel.component';
import { LanguageContext } from 'translation/languageContext';
import { ErrorContext } from 'error/error.context';

interface Props {
  errors?: any[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme && theme.spacing ? theme.spacing(2) : 0,
      flex: 1,
    },
  })
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref as any} {...(props as any)} />;
});

export const ErrorDisplayComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { errors, clearErrors } = useContext(ErrorContext);
  const { translate } = useContext(LanguageContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        style={{ position: 'fixed', bottom: '0', right: '0' }}
        onClick={handleClickOpen}
        size="large"
      >
        {/* <ErrorOutlineIcon color={!errors ? 'disabled' : errors.length === 0 ? 'disabled' : 'error'} fontSize={!errors ? 'small' : 'large'} /> */}
        <Icon
          color={!errors ? 'disabled' : errors.length === 0 ? 'disabled' : 'error'}
          fontSize={!errors ? 'small' : 'large'}
        >
          error_outline
        </Icon>
      </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              {/* <CloseIcon /> */}
              <Icon>close</Icon>
            </IconButton>
            <Typography variant="h6" className={classes.title} color="primary">
              {translate('Error', true)}
            </Typography>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                clearErrors();
                handleClose();
              }}
            >
              Clear
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {errors &&
            errors.map((error, index) => (
              <Container key={index}>
                <AccordionComponent
                  defaultExpanded={false}
                  header={error.message ? error.message : '.message not set'}
                >
                  <Typography variant="caption" color="error">
                    <pre style={{ paddingLeft: '20px' }}>
                      {JSON.stringify(error.extensions, null, 2)}
                    </pre>
                  </Typography>
                </AccordionComponent>

                <Divider />
              </Container>
            ))}
        </List>
      </Dialog>
    </>
  );
};

export default ErrorDisplayComponent;
