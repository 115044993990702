import { User } from '@earnenterprise/asc-models';
import { Action } from 'redux/actions/auth.actions';
import authQLService from 'services/authQL.service';

export const userReducer = (state: User | null = null, action: Action): User | null => {
  switch (action.type) {
    case 'LOGIN':
      return authQLService.getUser();
    case 'LOGOUT':
      authQLService.logout();
      return null;
    case 'STATUS':
      return authQLService.getUser();
    default:
      return state;
  }
};

export default userReducer;
