import { Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';

/**
 *
 */
interface Props {
  error?: string;
  warning?: string;
  info?: string;
}

/**
 *
 * @param props
 */
export const BaseContainerAlerts: React.FC<Props> = ({ error, warning, info, ...props }) => {
  return (
    <>
      {(error || warning || info) && (
        <Grid item xs={12}>
          {error && (
            <Alert severity="error">
              {error.split('\n').map((text, index) => {
                return (
                  <Typography variant="body2" key={index}>
                    {text}
                  </Typography>
                );
              })}
            </Alert>
          )}
          {warning && (
            <>
              <Alert severity="warning">
                {warning.split('\n').map((text, index) => {
                  return (
                    <Typography variant="body2" key={index}>
                      {text}
                    </Typography>
                  );
                })}
              </Alert>
              <br />
            </>
          )}
          {info && (
            <>
              <Alert severity="info">
                {info.split('\n').map((text, index) => {
                  return (
                    <Typography variant="body2" key={index}>
                      {text}
                    </Typography>
                  );
                })}
              </Alert>
              <br />
            </>
          )}
        </Grid>
      )}
    </>
  );
};
