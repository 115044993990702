import { Typography } from '@mui/material';
import { Field, FieldAttributes, useField, useFormikContext } from 'formik';
import React from 'react';
import { useHints } from 'components/hooks/useQueryHooks';
import useReduxState from 'components/hooks/useReduxState';
import { FieldProps } from 'fields/field.props';
import HelperTextField from 'fields/text.field';
import { TextField } from '@mui/material';

/**
 *
 * @param param0
 */
export const HelperAutoCompleteField: React.FC<FieldAttributes<FieldProps>> = ({
  className,
  label,
  hint,
  options,
  readOnly,
  renderAs,
  onKeyUp,
  onValueChange,
  ...props
}) => {
  const [fields] = useField<FieldProps>(props);
  const { theme } = useReduxState({ fetched: ['theme'] });
  const { setFieldValue } = useFormikContext();
  const { hints } = useHints();
  if (readOnly) {
    props.name = props.name + '.label';
    return (
      <HelperTextField
        {...props}
        label={label}
        readOnly={true}
        value={(fields.value as any).value}
      ></HelperTextField>
    );
  }
  return (
    <>
      <Field
        {...fields}
        className={className}
        disabled={readOnly}
        onChange={(e: any) => {
          // FIXME: this is a hack to get the value from the autocomplete
          options?.forEach((item) => {
            if ((item as any).label === e.target.textContent) {
              fields.value = item;
            }
          });
          if (onValueChange) onValueChange(fields.value as any);
          setFieldValue(fields.name, fields.value, false);
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.value === value.value;
        }}
        label={label}
        onKeyUp={onKeyUp}
        default={fields.value}
        value={fields.value}
        options={options}
        style={{ marginTop: '0px', paddingTop: '0px' }}
        component={renderAs}
        props={{
          disabled: true,
        }}
        renderInput={(params: any) => (
          <TextField label={label} variant="standard" value={'Test'} {...params} />
        )}
      />
      {hints ? (
        <Typography variant={'caption'} style={{ color: theme ? theme.theme.hint : 'primary' }}>
          {hint}
        </Typography>
      ) : null}
    </>
  );
};

export default HelperAutoCompleteField;
