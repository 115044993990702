import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { Fragment, useContext } from 'react';

import {
  Account,
  Agreement,
  emptyUser,
  Opportunity,
  Reminder,
  ReminderOutput,
  User,
} from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import globalStyles from 'styling/global.styles';
import useSave from 'components/hooks/useSave';
import { gqlReminderService } from 'services/gql.services';
import { useQuery, useQueryClient } from 'react-query';
import { renderAsAccount, renderAsAgreement, renderAsOpportunity } from 'components/renderers';
import { generateRequestData } from 'components/table.component';
import { fancyTime } from 'models/helper.tableData';
import useDelete from 'components/hooks/useDelete';
import { DialogHelper } from 'components/confirm.dialog.component';

interface Props {
  dialogHelper: DialogHelper;
  account?: Account | null;
  opportunity?: Opportunity | null;
  agreement?: Agreement | null;
  user?: User | null;
  onAccept: (newReminder: Reminder) => void;
  onCancel: () => void;
}

export const ReminderAddDialogComponent: React.FC<Props> = ({
  account,
  user,
  opportunity,
  agreement,
  ...props
}: Props) => {
  const { translate } = useContext(LanguageContext);
  const [reminder, setReminder] = React.useState<Reminder>({
    id: 0,
    text: '',
    description: '',
    date: new Date(),
    status: '',
    owner: user,
    account,
    opportunity,
    agreement,
  } as Reminder);
  const classes = globalStyles();
  const { saveItem } = useSave({
    func: gqlReminderService.save,
    sanitizeObject: ReminderOutput(),
    type: 'reminder',
    disableRedirect: true,
  });
  const { removeItem } = useDelete({
    func: gqlReminderService.remove,
    type: 'reminder',
    skipRedirect: true,
  });
  const search = ['ownerId'];
  const lsName = 'reminderListDialog';
  const queryClient = useQueryClient();

  const queryReminders = useQuery(
    ['reminders', { user, account, opportunity, agreement }],
    async () => {
      if (props.dialogHelper.getOpen()) {
        const requestData = generateRequestData(
          lsName,
          search,
          'date',
          user ? user.id : undefined,
          undefined,
          undefined,
          undefined
        );
        return await gqlReminderService.list(requestData, false);
      }
    },
    { enabled: !!user && props.dialogHelper.getOpen(), refetchOnMount: 'always' }
  );

  const reminders = queryReminders.data
    ? (queryReminders.data?.data as unknown as Reminder[])
    : null;

  const onChange = (
    field: string,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setReminder((reminder) => {
      return { ...reminder, [field]: e.target.value };
    });
  };

  const toggleDone = async (reminder: Reminder) => {
    await saveItem({
      ...reminder,
      status: reminder.status === 'done' ? 'active' : 'done',
      owner: !user ? emptyUser() : user,
    });
    //await queryReminders.refetch();
    queryClient.invalidateQueries('reminders');
  };

  const deleteReminder = async (reminder: Reminder) => {
    await removeItem({
      ...reminder,
      status: reminder.status === 'done' ? 'active' : 'done',
      owner: !user ? emptyUser() : user,
    });
    //await queryReminders.refetch();
    queryClient.invalidateQueries('reminders');
  };

  // Only return the reminder if it matches the account, opportunity, or agreement when any of those is specified.
  const filterReminder = (reminder: Reminder) => {
    if (account) {
      if (!reminder.account) return null;
      if (reminder.account.id !== account.id) return null;
    }

    if (opportunity) {
      if (!reminder.opportunity) return null;
      if (reminder.opportunity.id !== opportunity.id) return null;
    }

    if (agreement) {
      if (!reminder.agreement) return null;
      if (reminder.agreement.id !== agreement.id) return null;
    }
    return reminder;
  };

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      keepMounted
      fullWidth
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{translate('Add reminder')}</DialogTitle>
      <DialogContent style={{ height: 'auto', overflow: 'hidden' }}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant={'body2'}>{translate('Text')}</Typography>
            <Input
              className={'MuiInputBase-input MuiInput-input MuiInputBase-root' + classes.textField}
              type="text"
              style={{ marginTop: '-4px' }}
              name="text"
              fullWidth
              onChange={(e) => onChange('text', e)}
              placeholder={translate('Text')}
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={translate('Date')}
                value={reminder.date}
                mask="____-__-__"
                onChange={(date: unknown) => {
                  if (date === null) {
                    setReminder((reminder: Reminder) => ({ ...reminder, date: undefined }));
                  } else {
                    try {
                      (date as Date).setHours(12, 0, 0);
                      setReminder((reminder: Reminder) => ({ ...reminder, date: date as Date }));
                    } catch {
                      console.log({ warning: 'Not a valid date' });
                    }
                  }
                }}
                inputFormat="yyyy-MM-dd"
                renderInput={(props: TextFieldProps) => (
                  <TextField fullWidth variant="standard" {...props} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {false && (
            <>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{translate('Account')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>
                  {translate('Opportunity')} / {translate('Order')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{translate('Agreement')}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant={'body2'}>{translate('Description')}</Typography>
            <Input
              className={'MuiInputBase-input MuiInput-input MuiInputBase-root' + classes.textField}
              type="text"
              multiline
              style={{ marginTop: '-4px' }}
              name="description"
              fullWidth
              onChange={(e) => onChange('description', e)}
              placeholder={translate('Description')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            props.dialogHelper.setData({});
            await saveItem(reminder);
            queryClient.invalidateQueries('reminders');
          }}
        >
          {translate('Save')}
        </Button>
        <Button
          onClick={() => {
            props.dialogHelper.setData(undefined);
            props.dialogHelper.setOpen(false);
            props.onCancel();
          }}
        >
          {translate('Close')}
        </Button>
      </DialogActions>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '1px' }}></TableCell>
              <TableCell>{translate('Date')}</TableCell>
              <TableCell>{translate('Text')}</TableCell>
              {!account && <TableCell>{translate('Account')}</TableCell>}
              {!opportunity && (
                <TableCell>{translate('Opportunity') + '/' + translate('Order')}</TableCell>
              )}
              <TableCell>{translate('Agreement')}</TableCell>
              <TableCell style={{ width: '1px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reminders
              ?.filter((reminder: Reminder) => filterReminder(reminder))
              .map((reminder: Reminder, index: number) => {
                if (!reminder.date) return 'Unknown';
                let time = fancyTime(new Date(Date.parse(reminder.date.toString())));

                time = time.replace('In', translate('In'));
                time = time.replace('ago', translate('ago'));
                time = time.replace('seconds', translate('second', true));
                time = time.replace('minutes', translate('minute', true));
                time = time.replace('hours', translate('hour', true));
                time = time.replace('days', translate('day', true));
                time = time.replace('day', translate('day'));
                let pastTense = false;
                if (time.indexOf(translate('ago')) >= 0) {
                  pastTense = true;
                }

                return (
                  <Fragment key={index}>
                    <TableRow
                      style={{ backgroundColor: pastTense ? 'rgba(250, 0, 0, 0.2)' : 'inherit' }}
                    >
                      <TableCell>
                        <IconButton onClick={() => toggleDone(reminder)}>
                          <Icon>
                            {reminder.status === 'done' ? 'check_box' : 'check_box_outline_blank'}
                          </Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell>{time}</TableCell>
                      <TableCell>{reminder.text}</TableCell>
                      {!account && <TableCell>{renderAsAccount(reminder.account)}</TableCell>}
                      {!opportunity && (
                        <TableCell>{renderAsOpportunity(reminder.opportunity)}</TableCell>
                      )}
                      <TableCell>{renderAsAgreement(reminder.agreement)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteReminder(reminder)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ReminderAddDialogComponent;
