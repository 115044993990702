import {
  Button,
  Grid,
  IconButton,
  Input,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
} from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Local imports: Models
import { Opportunity, Person } from '@earnenterprise/asc-models';
import { Account } from '@earnenterprise/asc-models';

// Local imports: Hooks, Components
import { useUser } from 'components/hooks/useQueryHooks';

// Local imports: Services
import { gqlSearchService } from 'services/gql.services';
import { LanguageContext } from 'translation/languageContext';

/**
 * Navigation class for logged in users.
 */
export const NavSearch = () => {
  const { user: me } = useUser();
  const { translate } = useContext(LanguageContext);
  const history = useNavigate();

  const [openSearch, setOpenSearch] = useState<boolean | null>(null);
  const [searchString, setSearchString] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  //const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);
  const inputRef = useRef();

  // Quotes
  const querySearch = useQuery(
    ['search', { search: searchString }],
    async () => {
      return gqlSearchService.search(searchString);
    },
    {
      enabled: false,
      refetchOnMount: 'always',
      refetchIntervalInBackground: false,
      staleTime: 10 * 60 * 1000, // Stale timer of 10 minutes.
    }
  );

  // Toggle the search input
  const handleMenu = (event: any) => {
    setOpenSearch(true);
    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  };

  // Close the search result and search input
  const handleCloseResults = (event: any) => {
    setOpenSearch(false);
  };

  // Search input changed
  const onSearch = (event: any) => {
    if (event.target.value === '') {
      // Close the search window if the search string is empty, with a 2 second delay
      //if (closeTimeout) clearTimeout(closeTimeout);
    }

    // Set the search string
    setSearchString(event.target.value);

    // Clear the search timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set the search timeout
    setSearchTimeout(
      setTimeout(async () => {
        setSearchTimeout(null);
        const data = await querySearch.refetch();
        const result = (data?.data as any)?.search;
        console.log({ result });
      }, 1500)
    );
  };

  // Empty menu when user is null
  if (me === null || me === undefined) {
    return <></>;
  }

  const searchResult = querySearch.data ? (querySearch.data as any).search : null;

  return (
    <>
      <IconButton aria-controls="menu-appbar" onClick={(e) => handleMenu(e)} size="large">
        <Icon>search</Icon>
        &nbsp;
        {(searchString !== '' || openSearch) && (
          <Input
            autoFocus={true}
            ref={inputRef}
            defaultValue={searchString}
            onChange={(e) => onSearch(e)}
          />
        )}
      </IconButton>
      {searchString !== '' && openSearch && (
        <Paper
          style={{
            position: 'fixed',
            top: '80px',
            right: '15px',
            width: '800px',
            maxHeight: 'calc(100% - 100px)',
            overflow: 'auto',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <Grid container spacing={2}>
            {searchTimeout && (
              <Grid item xs={12}>
                Loading...
              </Grid>
            )}
            {searchResult && searchResult.accounts.length > 0 && (
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('Account')}</TableCell>
                      <TableCell>{translate('Organization No')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResult.accounts.map((account: Account, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          onClick={() => {
                            history('/account/' + account.id);
                            setOpenSearch(false);
                          }}
                        >
                          <Link
                            color="primary"
                            onClick={(e: any) => e.preventDefault()}
                            href={'/account/' + account.id}
                            title={account.name}
                          >
                            {account.name.length > 33
                              ? account.name.substr(0, 30) + '...'
                              : account.name}
                          </Link>
                        </TableCell>
                        <TableCell style={{ fontFamily: 'Consolas' }}>
                          {account.organizationNo}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}

            {searchResult && searchResult.opportunities.length > 0 && (
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('Opportunity')}</TableCell>
                      <TableCell>{translate('Status')}</TableCell>
                      <TableCell>{translate('Account')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResult.opportunities.map((opportunity: Opportunity, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontFamily: 'Consolas' }}
                          onClick={() => {
                            history('/opportunity/' + opportunity.id);
                            setOpenSearch(false);
                          }}
                        >
                          <Link
                            color="primary"
                            onClick={(e: any) => e.preventDefault()}
                            href={'/opportunity/' + opportunity.id}
                          >
                            {opportunity.number}
                          </Link>
                        </TableCell>
                        <TableCell>{opportunity.status}</TableCell>
                        {opportunity.account && (
                          <TableCell
                            onClick={() => {
                              history(
                                '/account/' + (opportunity.account ? opportunity.account.id : '0')
                              );
                              setOpenSearch(false);
                            }}
                          >
                            <Link
                              color="primary"
                              onClick={(e: any) => e.preventDefault()}
                              href={'/account/' + opportunity.account.id}
                              title={opportunity.account.name}
                            >
                              {opportunity?.account.name.length > 23
                                ? opportunity?.account.name.substr(0, 20) + '...'
                                : opportunity.account.name}
                            </Link>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}

            {searchResult && searchResult.persons.length > 0 && (
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('Firstname')}</TableCell>
                      <TableCell>{translate('Lastname')}</TableCell>
                      <TableCell>{translate('E-mail')}</TableCell>
                      <TableCell>{translate('Phone')}</TableCell>
                      <TableCell>{translate('Account')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResult.persons.map((person: Person, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{person.firstname}</TableCell>
                        <TableCell>{person.lastname}</TableCell>
                        <TableCell>
                          {me.isOffice365 && (
                            <Link
                              href={
                                'https://outlook.office.com/mail/deeplink/compose?to=' +
                                person.email
                              }
                              target="_blank"
                            >
                              {person.email}
                            </Link>
                          )}
                          {!me.isOffice365 && (
                            <Link href={'mailto:' + person.email} target="_blank">
                              {person.email}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          <Link href={'tel:' + person.phone} target="_blank">
                            {person.phone}
                          </Link>
                        </TableCell>
                        {(person as any).account && (
                          <TableCell
                            onClick={() => {
                              history('/account/' + (person as any).account.id);
                              setOpenSearch(false);
                            }}
                          >
                            <Link
                              color="primary"
                              onClick={(e: any) => e.preventDefault()}
                              href={'/account/' + (person as any).account.id}
                              title={(person as any).account.name}
                            >
                              {(person as any).account.name.length > 23
                                ? (person as any).account.name.substr(0, 20) + '...'
                                : (person as any).account.name}
                            </Link>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button onClick={handleCloseResults}>{translate('Close')}</Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default NavSearch;
