import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { Fragment, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useReduxState from 'components/hooks/useReduxState';
import { renderDate } from 'models/helper.tableData';
import { Match } from '@earnenterprise/asc-models';
import { Opportunity } from '@earnenterprise/asc-models';
import { fetchMatches } from 'redux/actions/items.actions';
import { LanguageContext } from 'translation/languageContext';

interface Props {
  dialogHelper: any;
  opportunity: Opportunity;
  onAccept: (match: Match) => void;
  onCancel: () => void;
}

export const MatchSelectDialogComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { translate } = useContext(LanguageContext);
  const { matches: items, workStrings } = useReduxState({
    fetched: ['matches'],
  });

  useEffect(() => {
    let from: Date | undefined;
    if (props.opportunity.from) {
      if (Object.prototype.toString.call(props.opportunity.from) !== '[object Date]')
        from = new Date(Date.parse(props.opportunity.from as string));
      else from = props.opportunity.from as Date;
    }
    let to: Date | undefined;
    if (props.opportunity.to) {
      if (Object.prototype.toString.call(props.opportunity.to) !== '[object Date]')
        to = new Date(Date.parse(props.opportunity.to as string));
      else to = props.opportunity.to as Date;
    }
    dispatch(fetchMatches({ workStrings, from, to, limit: 500 }));
  }, [props.opportunity.from, props.opportunity.to, dispatch, workStrings]);

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      scroll={'paper'}
      keepMounted
      fullWidth
      maxWidth={'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{translate('Select match')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px', height: 'auto' }}>
        <Grid container spacing={3}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('Date')}</TableCell>
                <TableCell>{translate('Time')}</TableCell>
                <TableCell>{translate('Home Team')}</TableCell>
                <TableCell>{translate('Away Team')}</TableCell>
                <TableCell>
                  {translate('Booked')}/{translate('Sold', true)}/{translate('In stock')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((match, index) => (
                <Fragment key={index}>
                  {match.articles?.map((article, key) => {
                    let style = { backgroundColor: '' };
                    if (article.inStock > 0) {
                      if (article.sales && article.sales?.length >= article.inStock)
                        style = { backgroundColor: 'rgba(200, 100, 100, 0.6)' };
                      else if (
                        article.bookings &&
                        article.bookings?.length + (article.sales ? article.sales?.length : 0) >=
                          article.inStock
                      )
                        style = { backgroundColor: 'rgba(200, 200, 0, 0.6)' };
                    }

                    return (
                      <Fragment key={key}>
                        {props.dialogHelper.getData() &&
                          article.id === props.dialogHelper.getData()['article'].article.id && (
                            <>
                              <TableRow>
                                <TableCell style={style}>{renderDate(match, 'date')}</TableCell>
                                <TableCell style={style}>{match.time}</TableCell>
                                <TableCell style={style}>{match.homeTeam}</TableCell>
                                <TableCell style={style}>{match.awayTeam}</TableCell>
                                <TableCell style={style}>
                                  {article.bookings?.length} / {article.sales?.length} /{' '}
                                  {article.inStock}
                                </TableCell>
                                <TableCell>
                                  {style.backgroundColor !== 'rgba(200, 100, 100, 0.6)' && (
                                    <Button
                                      style={{ padding: '0px' }}
                                      color="primary"
                                      onClick={() => {
                                        props.onAccept(match);
                                      }}
                                    >
                                      {translate('Select')}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          color="primary"
        >
          {translate('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchSelectDialogComponent;
