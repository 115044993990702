import { Action, Cart } from 'redux/actions/cart.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ItemState extends Cart {}

const getCart = () => {
  const cart = localStorage.getItem('cart');
  return cart ? JSON.parse(cart) : [];
};

export const cartReducer = (
  state: ItemState = {
    cart: getCart(),
  },
  action: Action
) => {
  switch (action.type) {
    case 'CART':
      return { ...state, cart: action.cart };
    default:
      return state;
  }
};

export default cartReducer;
