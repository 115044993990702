import { unboxAxios } from '@earnenterprise/react-network';
import axios from 'axios';
import decode from 'jwt-decode';
import Cookies from 'universal-cookie';

export const authEnvironment: { backend?: string } = {
  backend: process.env.REACT_APP_GRAPHQL_API_URL,
};

/**
 *
 */
export const authQLService = {
  login,
  logout,
  isOnline,
  refreshToken,
  getUser,
  header,
  expireInMinutes,
  oauthLogin,
};

/**
 *
 * @param id
 * @param type
 */
const postLogin = async (user: any) => {
  const data = {
    operationName: null,

    query: user.password
      ? '{ login(username: "' + user.username + '", password: "' + user.password + '") }'
      : '{ login(username: "' + user.username + '", token: "' + user.idToken + '") }',
    variables: {},
  };
  return await axios.post(authEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(),
  });
};

/**
 *
 * @param id
 * @param type
 */
const postRefresh = async (user: any) => {
  const data = {
    operationName: null,
    query: '{ token }',
    variables: {},
  };
  return await axios.post(authEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(),
  });
};

/**
 *
 * @param username
 * @param password
 * @returns {(any|Error)} returns object
 */
async function login(username: string, password: string) {
  const { response, error } = await unboxAxios(postLogin({ username, password }));

  if (error) {
    return { error };
  }

  if (!response) {
    return { error: new Error('Empty response') };
  }

  if (!response.data) {
    return { error: new Error('Unable to login') };
  }

  if (!response.data.data) {
    if (response.data.errors) {
      console.error(response.data.errors);
      return { error: new Error('Unable to login') };
    }
    return { error: new Error('Unable to login') };
  }

  const jwtToken: any = decode(response.data.data.login);
  if (jwtToken) {
    localStorage.setItem('refreshToken', response.data.data.login);
    return { refreshToken: response.data.data.login, error };
  } else {
    return { error: new Error('Unable to decode JWTToken') };
  }
}

async function oauthLogin(idToken: string) {
  let jwtToken: any = decode(idToken);
  console.log({ jwtToken });
  if (jwtToken) {
    let username = jwtToken['unique_name'];
    if (!username) username = jwtToken['email'];
    if (!username) username = jwtToken['preferred_username'];

    const { response, error } = await unboxAxios(postLogin({ username, idToken }));

    if (error) {
      return { error };
    }

    if (!response) {
      return { error: new Error('Empty response') };
    }

    if (!response.data) {
      return { error: new Error('Unable to login') };
    }

    if (!response.data.data) {
      if (response.data.errors) {
        console.error(response.data.errors);
        return { error: new Error('Unable to login') };
      }
      return { error: new Error('Unable to login') };
    }

    jwtToken = decode(response.data.data.login);
    if (jwtToken) {
      localStorage.setItem('refreshToken', response.data.data.login);
      return { refreshToken: response.data.data.login, error };
    } else {
      return { error: new Error('Unable to decode JWTToken') };
    }
  } else {
    return { error: new Error('Unable to decode JWTToken') };
  }
}

/**
 *
 * @param username
 * @param password
 * @returns {(any|Error)} returns object
 */
async function refresh(refreshToken: string) {
  const { response, error } = await unboxAxios(postRefresh(refreshToken));
  if (error) {
    return { error };
  }
  if (!response) {
    return { error: new Error('Empty response') };
  }

  if (response.data) {
    if (response.data.errors) {
      return { error: response.data.errors[0] };
    }
    const jwtToken: any = decode(response.data.data.token);
    if (jwtToken) {
      localStorage.setItem('refreshToken', response.data.data.token);
      return { refreshToken: response.data.data.token, error };
    } else {
      return { error: new Error('Unable to decode JWTToken') };
    }
  }

  return { refreshToken: 'keep', error: null };
}

/**
 *
 */
async function logout() {
  const cookies = new Cookies();
  cookies.remove('accessToken', { path: '/' });
  cookies.remove('X-Token', { path: '/' });
  localStorage.removeItem('refreshToken');
  sessionStorage.clear();

  //if (document.location.href !== '/login') document.location.href = '/login';
}

/**
 *
 */
function getUser(): any {
  const token = getToken();
  if (token) {
    if (token.user) {
      return token.user;
    }
  }
  return null;
}

/**
 *
 */
function getToken(): any {
  const token = localStorage.getItem('refreshToken');
  return token ? decode(token) : '';
}

/**
 *
 */
function expireInMinutes() {
  const token = getToken();
  if (!token) {
    return -1;
  }
  if (!token.exp) {
    return -1;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(token.exp);

  const exp = (date.valueOf() - new Date().valueOf()) / 1000 / 60;
  console.log('Refresh token expires in ' + exp + ' minutes.');

  return exp;
}

/**
 *
 */
function isTokenValid() {
  const token = getToken();
  if (!token) {
    return false;
  }
  if (!token.exp) {
    return true;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(token.exp);

  if (date === null) {
    return true;
  }
  const valid = date.valueOf() > new Date().valueOf();

  if (!valid) {
    localStorage.removeItem('refreshToken');
  }

  return valid;
}

/**
 *
 */
async function isOnline() {
  if (isTokenValid()) {
    return true;
  }
  return false;
}

/**
 *
 */
async function refreshToken(): Promise<{
  refreshToken?: string;
  error?: Error;
}> {
  const token = localStorage.getItem('refreshToken');
  if (!token) {
    return { error: new Error('Refresh token missing') };
  }

  const { refreshToken, error } = await refresh(token);
  if (error) {
    return { error };
  }
  if (!refreshToken) {
    return { error: new Error('Unable to decode JWTToken') };
  }

  // Actually login, keep is a status when no new refresh token is needed.
  if (refreshToken !== 'keep') localStorage.setItem('refreshToken', refreshToken);
  return { refreshToken };
}

/**
 * Checks if a HttpOnly cookie exists.
 * @param name
 */
function doesHttpOnlyCookieExist(name: string): boolean {
  const d = new Date();
  d.setTime(d.getTime() + 1000);
  const expires = 'expires=' + d.toUTCString();

  document.cookie = name + '=invalid;path=/;' + expires;
  if (document.cookie.indexOf(name + '=') === -1) {
    return true;
  } else {
    return false;
  }
}

/**
 * Returns X-Token-Refresh header if X-Token cookie is not set as HttpOnlyCookie
 */
function header(useRefreshToken?: boolean) {
  const refreshToken = localStorage.getItem('refreshToken');
  if ((refreshToken && !doesHttpOnlyCookieExist('X-Token')) || (refreshToken && useRefreshToken)) {
    //console.log({ refreshToken, useRefreshToken, cookie: doesHttpOnlyCookieExist('X-Token') });
    return { 'X-Token-Refresh': refreshToken };
  } else {
    return {};
  }
}

export default authQLService;
