/* eslint-disable prefer-const */
import { useEffect, useState, RefObject } from 'react';
import { Article, getSoldForDates } from '@earnenterprise/asc-models';

export type Coordinate = [number, number];

export interface Props {
  canvas?: HTMLCanvasElement;
}

/**
 * Use Save hook
 * @param param0
 */
export const useImageHelper = () => {
  //const ctx = canvas.getContext('2d');
  let [canvas, setCanvasInner] = useState<HTMLCanvasElement | null>(null);
  let [imageRef, setImageRef] = useState<React.RefObject<HTMLImageElement> | null>(null);
  let [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

  /**
   *
   * @param sales
   */
  const getExtraTooltipText = (sales: any[] | undefined, bookings: any[] | undefined) => {
    let valueSale = '';
    let valueBooking = '';
    if (sales)
      sales.forEach((sale, index) => {
        //if (value !== '') value += '<br />';
        valueSale =
          sale.account?.name +
          ' (' +
          sale.from.substring(0, 10) +
          ' -- ' +
          sale.to?.substring(0, 10) +
          ')';
      });
    if (bookings)
      bookings.forEach((sale) => {
        if (valueBooking !== '') valueBooking += '<br />';
        valueBooking +=
          '<i>' +
          sale.status +
          ': ' +
          sale.account?.name +
          ' (' +
          sale.from.substring(0, 10) +
          ' -- ' +
          sale.to?.substring(0, 10) +
          ')</i>';
      });
    return valueSale + (valueBooking !== '' ? '<br />' + valueBooking : '');
  };

  /**
   *
   * @param opos
   * @param from
   * @param to
   */
  const getStatus = (
    opos: {
      id?: string | number;
      name?: string;
      imageCoordsType?: string;
      imageCoords?: Coordinate[] | null;
      needScale?: boolean;
      sales?: any[];
      bookings?: any[];
    },
    from?: Date | string,
    to?: Date | string | null
  ) => {
    const sold = getSoldForDates(
      { sales: opos.sales, bookings: opos.bookings } as Article,
      from,
      to
    );
    if (sold.sold > 0) {
      return 'sold';
    }
    if (sold.booked > 0) {
      return 'booked';
    }

    return 'free';
  };

  const setCanvas = (
    element: HTMLCanvasElement | null,
    image: RefObject<HTMLImageElement> | null
  ) => {
    canvas = element;

    if (canvas) context = canvas.getContext('2d');
    if (image) {
      imageRef = image;
      setImageRef(image);
    }
    setCanvasInner(canvas);
    setContext(context);
  };

  const getContext = () => {
    return context;
  };

  const getCanvas = () => {
    return canvas;
  };

  const getImageRef = () => {
    return imageRef;
  };

  /**
   * Scales up the coordinates in the area by the given scale factor.
   *
   * @param area the area's coordinates to scale up.
   * @param scale the [x, y] scale factor to scale the area's coordinate's x and y value with.
   * @returns the scaled area.
   */
  const scaleUp = (area: Coordinate[], scale: Coordinate) => {
    // For some reason, '*=' is needed instead of just '=', otherwise the active article position
    // is not rerendered with the new position.
    return area.map<Coordinate>((p) => [(p[0] *= scale[0]), (p[1] *= scale[1])]);
  };

  /**
   * Scales down the coordinates in the area array by the given scale factor.
   *
   * @param area the coordinates to scale down.
   * @param scale the [x, y] scale factor to scale the area's coordinates' x and y value with.
   * @returns the scaled area.
   */
  const scaleDown = (area: Coordinate[], scale: Coordinate) => {
    return area.map<Coordinate>((p) => [p[0] / scale[0], p[1] / scale[1]]);
  };

  /**
   *
   * @param point
   * @param area
   */
  const isPointInArea = (point: Coordinate, area: Coordinate[]) => {
    if (!canvas || !context) return false;
    if (!area || area.length < 2) return false;

    // Begin Path
    context.beginPath();

    // Move to first point, draw lines to other points, and dra a last line to the first point.
    context.moveTo(area[0][0], area[0][1]);
    for (let index = 1; index < area.length; index++)
      context.lineTo(area[index][0], area[index][1]);
    context.lineTo(area[0][0], area[0][1]);

    // Check if point is in path and then close path.
    const inPath = context.isPointInPath(point[0], point[1]);
    context.closePath();

    return inPath;
  };

  /**
   *
   * @param point
   * @param area
   */
  const isPointInCircle = (point: Coordinate, area: Coordinate[]) => {
    if (!canvas || !context) return false;
    if (!area || area.length < 2) return false;

    // Begin Path
    context.beginPath();

    // Draw circle
    context.arc(area[0][0], area[0][1], area[1][0], 0, 2 * Math.PI);

    // Check if point is in path and then close path.
    const inPath = context.isPointInPath(point[0], point[1]);
    context.closePath();

    return inPath;
  };

  /**
   *
   * @param area
   */
  const drawArea = (area: Coordinate[]) => {
    if (!canvas || !context) return false;
    if (!area || area.length < 2) return false;

    // Begin Path
    context.beginPath();

    // Move to first point, draw lines to other points, and dra a last line to the first point.
    context.moveTo(area[0][0], area[0][1]);
    for (let index = 1; index < area.length; index++)
      context.lineTo(area[index][0], area[index][1]);
    context.lineTo(area[0][0], area[0][1]);

    // Stroke and fill
    context.stroke();
    context.fill();
  };

  /**
   *
   * @param point
   * @param area
   */
  const drawCircle = (area: Coordinate[]) => {
    if (!canvas || !context) return false;
    if (!area || area.length < 2) return false;

    // Begin Path
    context.beginPath();

    // Draw circle
    if (area[0] && area[1]) context.arc(area[0][0], area[0][1], area[1][0], 0, 2 * Math.PI);

    // Stroke an fill
    context.stroke();
    context.fill();
  };

  /**
   *
   */
  useEffect(() => {
    if (canvas) {
      setContext(canvas.getContext('2d'));
      setCanvasInner(canvas);
    }
  }, [canvas, context]);

  return {
    isPointInArea,
    isPointInCircle,
    setCanvas,
    drawArea,
    drawCircle,
    getContext,
    getCanvas,
    scaleDown,
    scaleUp,
    getExtraTooltipText,
    getStatus,
    getImageRef,
  };
};

export default useImageHelper;
