export const English = [
  // A
  { text: 'Account', translation: { default: 'Account', multiple: 'Accounts' } },
  { text: 'Agreement', translation: { default: 'Agreement', multiple: 'Agreements' } },
  // B
  // C
  { text: 'Contact', translation: { default: 'Contact', multiple: 'Contacts' } },
  // D
  // E
  // F
  // G
  // H
  // I
  // J
  // K
  // L
  // M
  { text: 'My Accounts', translation: { default: 'My Accounts' } },
  { text: 'My Agreements', translation: { default: 'My Agreements' } },
  { text: 'Match', translation: { default: 'Match', multiple: 'Matches' } },
  // N
  // O
  { text: 'Order', translation: { default: 'Order', multiple: 'Orders' } },
  { text: 'Opportunity', translation: { default: 'Opportunity', multiple: 'Opportunities' } },
  // P
  // Q
  { text: 'Quote', translation: { default: 'Quote', multiple: 'Quotes' } },
  // R
  // S
  // T
  // U
  { text: 'User', translation: { default: 'User', multiple: 'Users' } },
  // V
  // W
  // X
  // Y
  // Z
];

export default English;
