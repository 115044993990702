import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { themeOptions } from 'styling/themeContext';
//import { DEFAULT_FONT_SIZE } from 'styling/createTheme';

/**
 *
 * @param themeName
 */
export function updateThemeForStyle(themeName: string) {
  const selectedTheme =
    themeOptions.find((t: any) => t.theme.name.toLowerCase() === themeName.toLowerCase()) || {};
  const html = document.getElementsByTagName('html')[0];
  Object.keys(selectedTheme).forEach((property: any, i) => {
    if (property === 'name') {
      return;
    }
    html.style.setProperty(property, (selectedTheme as any)[property]);
  });
}

// Styles
export const globalStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      opacity: 0,
      animation: 'fadeIn 1s forwards',
      paddingTop: '90px',
    },
    cardAutoCentered: {
      margin: '0px auto',
    },
    gridMaxWidthSpacing3: {
      width:
        theme && theme.spacing ? 'calc(100% + ' + theme.spacing(3) + 'px)' : 'calc(100% + 3px)',
    },
    textField: {
      width: '100%',
      paddingTop: '3px', // To match AutoCompleteField
    },
    popup: {
      top: '50px',
      marginTop: '40px',
    },

    appBar: {
      zIndex: theme && theme.zIndex && theme.zIndex.drawer ? theme.zIndex.drawer + 1 : 999,
    },
    drawer: {
      whiteSpace: 'nowrap',
      flexShrink: 0,
      overflow: 'hidden',
    },
    drawerOpen: {
      width: '240px',
      transition: theme.transitions.create('all', {
        easing: 'ease-in-out',
        duration: '500ms',
      }),
      '&& .MuiTypography-root': {
        display: 'block',
      },
      overflow: 'hidden',
    },
    drawerClose: {
      width: '56px',
      transition: theme.transitions.create('all', {
        easing: 'ease-in-out',
        duration: '500ms',
      }),
      '&& .MuiTypography-root': {
        display: 'none',
      },
      overflow: 'hidden',
    },
    /*
    drawer: {
      flexShrink: 0,
      minWidth: '56px',
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    */
    /*
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme && theme.spacing ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        width: theme && theme.spacing ? theme.spacing(9) + 1 : 0,
      },
    },
    */
    toolbar: theme && theme.mixins && theme.mixins.toolbar ? theme.mixins.toolbar : {},
    autoCompleteField: {
      padding: '0px',
      margin: '0px',
      '&& div [class*=-IndicatorsContainer]': {
        marginBottom: '-4px',
        position: 'relative',
        top: '-5px',
      },
      '&& svg': {
        position: 'relative',
        top: '-2px !important',
      },
    },
    chip: {
      marginRight: '10px',
      border: '1px solid #fff',
    },
    chipAdd: {
      marginRight: '10px',
      border: '1px solid #fff',
      '&& :hover': {
        color: '#acf',
        cursor: 'pointer',
      },
    },
    content: {
      flexGrow: 1,
      flexShrink: 1,
      padding: theme.spacing(3),
    },
    icon: {
      marginRight: '0px !important',
      marginTop: '0px',
      top: '3px',
      position: 'relative',
      width: 18,
      height: 18,
    },
    iconInfo: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#aaf' : '#669') : '#669',
    },
    iconAccount: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#fff' : '#999') : '#999',
    },
    iconPerson: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#faa' : '#966') : '#966',
    },
    iconAgreement: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#afa' : '#696') : '#696',
    },
    iconQuote: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#ffa' : '#996') : '#996',
    },
    iconOrder: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#faf' : '#969') : '#969',
    },
    iconOpportunity: {
      color: theme.palette ? (theme.palette.mode === 'dark' ? '#aff' : '#699') : '#699',
    },
    iconButtonNoPadding: {
      padding: '0px',
      '&& :hover': {
        color: theme.palette ? theme.palette.primary.main : '#000',
      },
      '&& :active': {
        color: theme.palette ? theme.palette.primary.dark : '#000',
      },
    },
    breadcrumblink: {
      textDecoration: 'none',
    },
    summaryRow: {
      fontSize: '1rem',
      fontWeight: 'bold',
      background: 'rgba(0, 0, 0, 0.1)',
    },
    summaryRowHint: {
      fontSize: '1rem',
      fontWeight: 'bold',
      background: 'rgba(0, 0, 0, 0.05)',
    },
    dropzone: {
      position: 'relative',
      border: '1px dashed #cccccc',
      textAlign: 'center',
      borderRadius: '5px',
      background: 'rgba(0, 0, 0, 0.1)',
      display: 'grid',
      height: '100px',
      '&& p': {
        position: 'absolute',
        //top: '40px',
        //display: 'block',
        width: '100%',
        textAlign: 'center',
        margin: '0',
        height: '100px',
        display: 'inline-grid',
        placeItems: 'center',
      },
      '&&:hover': {
        background: 'rgba(0, 0, 0, 0.2)',
      },
    },
    existingFile: {
      position: 'relative',
      border: '1px solid #cccccc',
      padding: '0px',
      lineHeight: '50px',
      borderRadius: '5px',
      background: 'rgba(255, 200, 0, 0.2)',
      height: '100px',
      '&&:hover': {
        background: 'rgba(255, 200, 0, 0.5)',
      },
    },
  });
});

export default globalStyles;
