import { Button, Grid, Icon } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { LanguageContext } from 'translation/languageContext';

/**
 *
 */
interface Props {
  submitButton?: boolean;
  submitText?: string;
  deleteText?: string;
  resetText?: string;
  error?: string;
  warning?: string;
  info?: string;
  onReset?: any;
  onDelete?: any;
  isSubmitting?: boolean;
  dirty?: boolean;
  status?: { opportunity: boolean; quote: boolean; order: boolean; agreement: boolean };
  buttons?: { name: string; fn: any; icon?: any; disabled?: boolean; neverDisable?: boolean }[];
  functions?: { name: string; fn: any; icon?: any }[];
}

/**
 *
 * @param props
 */
export const BaseContainerButtons: React.FC<Props> = ({
  isSubmitting,
  onDelete,
  deleteText,
  submitButton,
  submitText,
  buttons,
  dirty,
  onReset,
  resetText,
  ...props
}) => {
  let values: any;
  const { translate } = useContext(LanguageContext);

  const formik = useFormikContext();
  if (formik) values = formik.values;

  if (!submitText) submitText = translate('Save');
  if (!deleteText) deleteText = translate('Delete');
  if (!resetText) resetText = translate('Reset');

  const hasButtons = submitButton || onReset || onDelete;

  return (
    <>
      {hasButtons ? (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {onDelete ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  disabled={isSubmitting}
                  onClick={onDelete}
                >
                  <Icon>delete</Icon>
                  &nbsp; {deleteText}
                </Button>
              </Grid>
            ) : null}
            {submitButton ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    console.log({ values, onSubmit: (formik as any).handleSubmit });
                    //e.preventDefault();
                  }}
                  disabled={isSubmitting}
                  type="submit"
                >
                  <Icon>save</Icon>
                  &nbsp; {submitText}
                </Button>
              </Grid>
            ) : null}
            {buttons
              ? buttons.map((button, index) => (
                  <Grid item key={index}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        console.log({ values });

                        e.preventDefault();
                        button.fn(values, formik);
                      }}
                      disabled={(isSubmitting || dirty) && !button.neverDisable}
                      type="submit"
                    >
                      {button.icon ? button.icon : null}
                      &nbsp; {button.name}
                    </Button>
                  </Grid>
                ))
              : null}
            <Grid item xs></Grid>
            {onReset ? (
              <Grid item>
                <Button
                  style={{ marginRight: '0px' }}
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting || !dirty}
                  onClick={onReset}
                >
                  <Icon>rotate_left</Icon>
                  &nbsp; {resetText}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
