import { Grid, IconButton, Menu, MenuItem, Icon } from '@mui/material';
import { FormikContextType } from 'formik';
import React, { Fragment } from 'react';

interface Props {
  functions?: { name: string; fn: any; icon?: any }[];
  removeGrid?: boolean;
  formikContext?: FormikContextType<unknown>;
}

export const BaseContainerFunctions = ({ functions, removeGrid, formikContext }: Props) => {
  const values = formikContext?.values;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (functions) {
    functions.forEach((value) => {
      if (value.icon === 'add') value.icon = <Icon>add</Icon>;
      if (value.icon === 'delete') value.icon = <Icon>delete</Icon>;
      if (value.icon === 'reset') value.icon = <Icon>rotate_left</Icon>;
      if (value.icon === 'copy') value.icon = <Icon>file_copy</Icon>;
      if (value.icon === 'download') value.icon = <Icon>download</Icon>;
      if (value.icon === 'unlock') value.icon = <Icon>lock_open</Icon>;
    });
  }

  const Container = removeGrid ? Fragment : Grid;

  return functions ? (
    <Container {...(removeGrid ? {} : { item: true, sm: true, style: { textAlign: 'end' } })}>
      <IconButton size="medium" onClick={handleClick}>
        <Icon>menu</Icon>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {functions.map((value, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              value.fn(values, formikContext);
            }}
          >
            {value.icon ? value.icon : null}
            &nbsp;{value.name}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  ) : null;
};
