import { IServiceError } from '@earnenterprise/react-network';
import { Action, ModelItem, ModelItems, ResetItem } from 'redux/actions/items.actions';

interface ItemState extends ModelItem, ModelItems, ResetItem {
  working: boolean;
  workCount: number;
  workStrings: string[];
  error?: IServiceError | IServiceError[] | null;
}

export const itemReducer = (
  state: ItemState = { working: false, workCount: 0, workStrings: [] },
  action: Action
): ItemState => {
  if (action.type === 'ITEM_STATUS' || action.type === 'ITEMS_STATUS') {
    if (action.error && state.error) {
      if (Array.isArray(state.error) && !Array.isArray(action.error)) {
        action.error = [...state.error, action.error];
      } else if (Array.isArray(state.error) && Array.isArray(action.error)) {
        action.error = [...state.error, ...action.error];
      } else if (!Array.isArray(state.error) && !Array.isArray(action.error)) {
        action.error = [state.error, action.error];
      } else if (!Array.isArray(state.error) && Array.isArray(action.error)) {
        action.error = [state.error, ...action.error];
      }
    }
  }

  if (action.type === 'ITEM_RESET') {
    if (action.reset.userReset) return { ...state, user: undefined };
    if (action.reset.groupReset) return { ...state, group: undefined };
    if (action.reset.agreementReset) return { ...state, agreement: undefined };
    if (action.reset.accountReset) return { ...state, account: undefined };
    if (action.reset.articleReset) return { ...state, article: undefined };
    if (action.reset.opportunityReset) return { ...state, opportunity: undefined };
    if (action.reset.quoteReset) return { ...state, quote: undefined };
    if (action.reset.messageReset) return { ...state, message: undefined };
    if (action.reset.matchReset) return { ...state, match: undefined };
    if (action.reset.tagReset) return { ...state, tag: undefined };
    if (action.reset.presentationReset) return { ...state, presentation: undefined };
    if (action.reset.themeReset) return { ...state, theme: undefined };
    if (action.reset.imageReset) return { ...state, image: undefined };
  }

  // Dont chache stuff more than one minute.
  const now = new Date();
  const timeout = new Date();
  timeout.setMinutes(new Date().getMinutes() + 10);
  //timeout.setMinutes(new Date().getSeconds() + 30);
  switch (action.type) {
    case 'ITEMS_STATUS':
      if (!action.users && state.usersTimeout && state.usersTimeout < now.getTime())
        state.users = state.usersTimeout = undefined;
      if (!action.groups && state.groupsTimeout && state.groupsTimeout < now.getTime())
        state.groups = state.groupsTimeout = undefined;
      if (!action.agreements && state.agreementsTimeout && state.agreementsTimeout < now.getTime())
        state.agreements = state.agreementsTimeout = undefined;
      if (!action.accounts && state.accountsTimeout && state.accountsTimeout < now.getTime())
        state.accounts = state.accountsTimeout = undefined;
      if (!action.articles && state.articlesTimeout && state.articlesTimeout < now.getTime())
        state.articles = state.articlesTimeout = undefined;
      if (
        !action.opportunities &&
        state.opportunitiesTimeout &&
        state.opportunitiesTimeout < now.getTime()
      )
        state.opportunities = state.opportunitiesTimeout = undefined;
      if (!action.quotes && state.quotesTimeout && state.quotesTimeout < now.getTime())
        state.quotes = state.quotesTimeout = undefined;
      if (!action.messages && state.messagesTimeout && state.messagesTimeout < now.getTime())
        state.messages = state.messagesTimeout = undefined;
      if (!action.matches && state.matchesTimeout && state.matchesTimeout < now.getTime())
        state.matches = state.matchesTimeout = undefined;
      if (
        !action.externalMatches &&
        state.externalMatchesTimeout &&
        state.externalMatchesTimeout < now.getTime()
      )
        state.externalMatches = state.externalMatchesTimeout = undefined;
      if (!action.tags && state.tagsTimeout && state.tagsTimeout < now.getTime())
        state.tags = state.tagsTimeout = undefined;
      if (
        !action.presentations &&
        state.presentationsTimeout &&
        state.presentationsTimeout < now.getTime()
      )
        state.presentations = state.presentationsTimeout = undefined;
      if (!action.themes && state.themesTimeout && state.themesTimeout < now.getTime())
        state.themes = state.themesTimeout = undefined;
      if (!action.images && state.imagesTimeout && state.imagesTimeout < now.getTime())
        state.images = state.imagesTimeout = undefined;
  }

  // Single item request
  switch (action.type) {
    case 'ITEM_STATUS':
      if (action.error) {
        if (action.user) return { ...state, user: action.user, error: action.error };
        if (action.group) return { ...state, group: action.group, error: action.error };
        if (action.agreement) return { ...state, agreement: action.agreement, error: action.error };
        if (action.account)
          return {
            ...state,
            account: action.account,
            quotes: undefined,
            opportunities: undefined,
            error: action.error,
          };
        if (action.article) return { ...state, article: action.article, error: action.error };
        if (action.opportunity)
          return {
            ...state,
            opportunity: action.opportunity,
            quotes: undefined,
            error: action.error,
          };
        if (action.quote) return { ...state, quote: action.quote, error: action.error };
        if (action.message) return { ...state, message: action.message, error: action.error };
        if (action.match) return { ...state, match: action.match, error: action.error };
        if (action.tag) return { ...state, tag: action.tag, error: action.error };
        if (action.presentation)
          return { ...state, presentation: action.presentation, error: action.error };
        if (action.theme) return { ...state, theme: action.theme, error: action.error };
        if (action.image) return { ...state, image: action.image, error: action.error };
        return { ...state, error: action.error };
      } else {
        if (action.user) return { ...state, user: action.user };
        if (action.group) return { ...state, group: action.group };
        if (action.agreement) return { ...state, agreement: action.agreement };
        if (action.account)
          return { ...state, account: action.account, quotes: undefined, opportunities: undefined };
        if (action.article) return { ...state, article: action.article };
        if (action.opportunity)
          return { ...state, opportunity: action.opportunity, quotes: undefined };
        if (action.quote) return { ...state, quote: action.quote };
        if (action.message) return { ...state, message: action.message };
        if (action.match) return { ...state, match: action.match };
        if (action.tag) return { ...state, tag: action.tag };
        if (action.presentation) return { ...state, presentation: action.presentation };
        if (action.theme) return { ...state, theme: action.theme };
        if (action.image) return { ...state, image: action.image };
      }
      return state;

    case 'ITEM_ERROR':
      for (const key in action) {
        if (key === 'user')
          return {
            ...state,
            user: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'group')
          return {
            ...state,
            group: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'agreement')
          return {
            ...state,
            agreement: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'account')
          return {
            ...state,
            account: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'article')
          return {
            ...state,
            article: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'opportunity')
          return {
            ...state,
            opportunity: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'quote')
          return {
            ...state,
            quote: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'message')
          return {
            ...state,
            message: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'match')
          return {
            ...state,
            match: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'tag')
          return {
            ...state,
            tag: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'presentation')
          return {
            ...state,
            presentation: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'theme')
          return {
            ...state,
            theme: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
        if (key === 'image')
          return {
            ...state,
            image: {
              id: state.workStrings.find((v) => v.startsWith(key + ':'))?.split(':')[1],
            } as any,
            error: action.error,
          };
      }
      return { ...state, error: action.error };

    // Multiple item request
    case 'ITEMS_STATUS':
      if (action.error) {
        if (
          (action.error as any).message === 'Unauthorized' &&
          (action.error as any).extra === 'Unathorized'
        ) {
          console.log('Unauthorized user');
          return { ...state };
        }
        if (action.users)
          return {
            ...state,
            users: action.users,
            usersTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.groups)
          return {
            ...state,
            groups: action.groups,
            groupsTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.agreement)
          return {
            ...state,
            agreement: action.agreement,
            agreementsTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.accounts)
          return {
            ...state,
            accounts: action.accounts,
            accountsTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.articles)
          return {
            ...state,
            articles: action.articles,
            articlesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.opportunities)
          return {
            ...state,
            opportunities: action.opportunities,
            opportunitiesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.quotes)
          return {
            ...state,
            quotes: action.quotes,
            quotesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.messages)
          return {
            ...state,
            messages: action.messages,
            messagesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.matches)
          return {
            ...state,
            matches: action.matches,
            matchesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.externalMatches)
          return {
            ...state,
            externalMatches: action.externalMatches,
            externalMatchesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.tags)
          return {
            ...state,
            tags: action.tags,
            tagsTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.presentations)
          return {
            ...state,
            presentations: action.presentations,
            presentationsTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.themes)
          return {
            ...state,
            themes: action.themes,
            themesTimeout: timeout.getTime(),
            error: action.error,
          };
        if (action.images)
          return {
            ...state,
            images: action.images,
            imagesTimeout: timeout.getTime(),
            error: action.error,
          };
        return { ...state, error: action.error };
      } else {
        if (action.users) return { ...state, users: action.users, usersTimeout: timeout.getTime() };
        if (action.groups)
          return { ...state, groups: action.groups, groupsTimeout: timeout.getTime() };
        if (action.agreements)
          return { ...state, agreements: action.agreements, agreementsTimeout: timeout.getTime() };
        if (action.accounts)
          return { ...state, accounts: action.accounts, accountsTimeout: timeout.getTime() };
        if (action.articles)
          return { ...state, articles: action.articles, articlesTimeout: timeout.getTime() };
        if (action.opportunities)
          return {
            ...state,
            opportunities: action.opportunities,
            opportunitiesTimeout: timeout.getTime(),
          };
        if (action.quotes)
          return { ...state, quotes: action.quotes, quotesTimeout: timeout.getTime() };
        if (action.messages)
          return { ...state, messages: action.messages, messagesTimeout: timeout.getTime() };
        if (action.matches)
          return { ...state, matches: action.matches, matchesTimeout: timeout.getTime() };
        if (action.externalMatches)
          return {
            ...state,
            externalMatches: action.externalMatches,
            matchesTimeout: timeout.getTime(),
          };
        if (action.tags) return { ...state, tags: action.tags, tagsTimeout: timeout.getTime() };
        if (action.presentations)
          return {
            ...state,
            presentations: action.presentations,
            presentationsTimeout: timeout.getTime(),
          };
        if (action.themes)
          return { ...state, themes: action.themes, themesTimeout: timeout.getTime() };
        if (action.images)
          return { ...state, images: action.images, imagesTimeout: timeout.getTime() };
      }
      return state;

    case 'FETCH_WORKING':
      if (action.isWorking) {
        const workStrings = state.workStrings;
        workStrings[workStrings.length] = action.workString;
        return { ...state, working: true, workCount: state.workCount + 1, workStrings };
      } else {
        console.groupCollapsed(`- Done ${action.workString}`);
        console.log(state);
        console.groupEnd();
        const index = state.workStrings.findIndex((v) => v === action.workString);
        state.workStrings.splice(index, 1);
        return { ...state, working: state.workCount > 1, workCount: state.workCount - 1 };
      }

    case 'CLEAR_ERRORS':
      return { ...state, error: undefined };

    case 'TOGGLE_HINTS':
      return { ...state, hints: action.hints };

    default:
      return state;
  }
};

export default itemReducer;
