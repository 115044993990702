export const Swedish = [
  { text: '0% - Lost', translation: { default: '0% - Förlorad' } },
  { text: '100% - Won', translation: { default: '100% - Vunnen' } },
  { text: '100%', translation: { default: '100%' } },

  // A
  { text: 'Administration', translation: { default: 'Administration' } },
  { text: 'Auto included?', translation: { default: 'Lägg till automatiskt?' } },
  { text: 'Agree', translation: { default: 'OK' } },
  { text: 'All Accounts', translation: { default: 'Alla Kunder' } },
  { text: 'All Agreements', translation: { default: 'Alla Avtal' } },
  { text: 'All Opportunities', translation: { default: 'Alla Affärsmöjligheter' } },
  { text: 'All Orders', translation: { default: 'Alla Ordrar' } },
  { text: 'All Users', translation: { default: 'Alla Användare' } },
  { text: 'All Quotes', translation: { default: 'Alla Offerter' } },
  { text: 'All Messages', translation: { default: 'Alla Meddelanden' } },
  { text: 'All Customers', translation: { default: 'Alla Kunder' } },
  { text: 'All Items', translation: { default: 'Visa alla' } },
  { text: 'All Invoices', translation: { default: 'Alla fakturor' } },
  { text: 'Article OK', translation: { default: 'Artikel OK' } },
  { text: 'Accept payment', translation: { default: 'Markera fakturaunderlaget som skickat' } },
  { text: 'Actions', translation: { default: 'Åtgärder' } },
  { text: 'Account', translation: { default: 'Kund', multiple: 'Kunder' } },
  { text: 'Amount', translation: { default: 'Antal' } },
  { text: 'Article', translation: { default: 'Artikel', multiple: 'Artiklar' } },
  {
    text: 'Add article',
    translation: { default: 'Lägg till artikel', multiple: 'Lägg till artiklar' },
  },
  { text: 'Add reminder', translation: { default: 'Lägg till påminnelse' } },
  { text: 'Address', translation: { default: 'Adress' } },
  { text: 'Any', translation: { default: 'Någon' } },
  { text: 'Article Number', translation: { default: 'Artikelnummer' } },
  { text: 'Article text on invoice', translation: { default: 'Artikeltext på faktura' } },
  { text: 'Agreement', translation: { default: 'Avtal', multiple: 'Avtal' } },
  { text: 'Add Address', translation: { default: 'Lägg till adress' } },
  { text: 'Add Contact', translation: { default: 'Lägg till kontaktperson' } },
  { text: 'Add Log', translation: { default: 'Lägg till kundlogg' } },
  { text: 'Add invoice', translation: { default: 'Lägg till fakturering' } },
  { text: 'Account log', translation: { default: 'Kundlogg' } },
  {
    text: 'Are you sure you want to delete this quote?',
    translation: { default: 'Är du säker på att du vill ta bort offerten?' },
  },
  {
    text: 'Are you sure you want to delete this player?',
    translation: { default: 'Är du säker på att du vill ta bort spelaren?' },
  },
  {
    text: 'Are you sure you want to delete this package?',
    translation: { default: 'Är du säker på att du vill ta bort paketeringen?' },
  },
  {
    text: 'Are you sure you want to send this quote?',
    translation: { default: 'Är du säker på att du vill skicka offerten?' },
  },
  {
    text: 'Are you sure you want to mark this payment as sent?',
    translation: { default: 'Är du säker på att du vill markera fakturaunderlaget som skickat?' },
  },
  {
    text: 'Added article to opportunity',
    translation: { default: 'La till artikel i affärsmöjlighet' },
  },
  { text: 'All', translation: { default: 'Alla' } },
  { text: 'Add custom text', translation: { default: 'Lägg till extra text' } },
  { text: 'All Articles', translation: { default: 'Alla Artiklar' } },
  {
    text: 'Are you sure you want to delete this agreement?',
    translation: { default: 'Är du säker på att du vill ta bort avtalet?' },
  },
  {
    text: 'Are you sure you want to delete this file?',
    translation: { default: 'Är du säker på att du vill ta bort filen?' },
  },
  {
    text: 'Are you sure you want to delete this message?',
    translation: { default: 'Är du säker på att du vill ta bort meddelandet?' },
  },
  {
    text: 'Are you sure you want to delete this customer?',
    translation: { default: 'Är du säker på att du vill ta bort kunden?' },
  },
  {
    text: 'Are you sure you want to restore backup from file?',
    translation: { default: 'Är du säker på att du vill återställa en backup från filen?' },
  },
  {
    text: 'Are you sure you want to import from file?',
    translation: { default: 'Är du säker på att du vill importera data från fil?' },
  },
  {
    text: 'Are you sure you want to delete this payment?',
    translation: { default: 'Är du säker på att du vill ta bort faktureringen?' },
  },
  {
    text: 'Are you sure you want to ignore this order for this season?',
    translation: { default: 'Är du säker på att du vill dölja denna order för denna säsong?' },
  },
  {
    text: 'Are you sure you want to resize the file?',
    translation: { default: 'Är du säker på att du vill ändra storlek på denna bild?' },
  },
  { text: 'Articles text on invoice', translation: { default: 'Artikeltext på faktura' } },
  {
    text: 'Are you sure you want to open another article? All unsaved changes on this article will be lost.',
    translation: {
      default:
        'Är du säker på att du vill öppna en annan artikel? Ändringar som inte är sparade kommer att förloras.',
    },
  },
  {
    text: 'Are you sure you want to delete this opportunity?',
    translation: { default: 'Är du säker på att du vill ta bort affärsmöjligheten?' },
  },
  {
    text: 'Are you sure you want to delete this order?',
    translation: { default: 'Är du säker på att du vill ta bort ordern?' },
  },
  {
    text: 'Are you sure you want to delete this account?',
    translation: { default: 'Är du säker på att du vill ta bort kunden?' },
  },
  {
    text: 'Are you sure you want to convert this to an order?',
    translation: { default: 'Är du säker på att du vill skapa en order?' },
  },
  {
    text: 'Are you sure you want to delete this match?',
    translation: { default: 'Är du säker på att du vill ta bort matchen?' },
  },
  {
    text: 'Are you sure you want to delete this tag?',
    translation: { default: 'Är du säker på att du vill ta bort taggen?' },
  },
  { text: 'Accept quote', translation: { default: 'Accepterad offert' } },
  { text: 'Addon cost', translation: { default: 'Kostnadspåslag' } },
  {
    text: 'Always show payments on quote',
    translation: { default: 'Visa alltid betalningstillfällen på offert/avtal' },
  },
  {
    text: 'Are you sure you want to reset the database? All current information will be lost!',
    translation: {
      default:
        'Är du säker på att du vill återställa databasen? All nuvarande information kommer att tas bort!',
    },
  },
  {
    text: 'Are you sure you want to mark this quote as accepted?',
    translation: { default: 'Är du säker på att du vill markera offerten som accepterad?' },
  },
  {
    text: 'Removes all information from the database!',
    translation: { default: 'Tar bort all information från databasen!' },
  },
  { text: 'Added article to cart', translation: { default: 'La till artikel i varukorg' } },
  { text: 'Away Team', translation: { default: 'Bortalag' } },
  { text: 'Add tag', translation: { default: 'Lägg till tagg' } },
  { text: 'Active', translation: { default: 'Aktiv' } },
  { text: 'Amount of invoices', translation: { default: 'Antal fakturatillfällen' } },
  { text: 'Accepted', translation: { default: 'Accepterad' } },
  { text: 'Article Image', translation: { default: 'Artikelbilder' } },
  { text: 'Aborted', translation: { default: 'Avbruten' } },
  { text: 'Invoices created', translation: { default: 'Fakturor skapade' } },
  {
    text: 'Active or Inactive. Inactive does not show up when creating new opportunities.',
    translation: {
      default:
        'Aktiv eller Inaktiverad. Inaktiverade kunder visas ej när man skapar affärsmöjligheter.',
    },
  },
  {
    text: 'Auto tag customer when this template is used?',
    translation: { default: 'Tagga automatiskt kunden när denna mall används?' },
  },
  { text: 'Auto tag', translation: { default: 'Automatiskt tagg' } },
  {
    text: 'Article number used in BL when hiding article lines.',
    translation: {
      default: 'Artikelnummer i BLInfo som skall användas när artikelrader är dolda.',
    },
  },
  { text: 'Ad Tax', translation: { default: 'Reklamskatt' } },
  { text: 'ago', translation: { default: 'sedan' } },

  // B
  { text: 'Barter article?', translation: { default: 'Barterartikel?' } },
  { text: 'Buy price', translation: { default: 'Inköpspris' } },
  { text: 'Buyer text', translation: { default: 'Köparens text' } },
  {
    text: 'Background css of "boxes"',
    translation: { default: 'CSS-kod för bakgrunden av "boxar"' },
  },
  {
    text: 'Background css when no image is selected',
    translation: { default: 'CSS-kod för bakgrund när ingen bakgrundsbild är vald' },
  },
  {
    text: 'Background css of the menu to the left',
    translation: { default: 'CSS-kod för bakgrunden av menyn till vänster' },
  },
  {
    text: 'Background css of the top menu',
    translation: { default: 'CSS-kod för bakgrunden av menyn längst upp' },
  },
  { text: 'Background Navigation', translation: { default: 'Bakgrund Topmeny' } },
  { text: 'Background Drawer Left', translation: { default: 'Bakgrund Vänstermeny' } },
  { text: 'Background Default', translation: { default: 'Bakground' } },
  { text: 'Background Paper', translation: { default: 'Bakgrund "boxar"' } },
  {
    text: 'Background Image',
    translation: { default: 'Bakgrundsbild', multiple: 'Bakgrundsbilder' },
  },
  { text: 'Booked', translation: { default: 'Bokade' } },
  { text: 'Backup file', translation: { default: 'Backupfil', multiple: 'Backupfiler' } },
  { text: 'Backup database', translation: { default: 'Ta databasbackup' } },
  { text: 'Backed up successfully', translation: { default: 'Backup tagen' } },
  {
    text: 'BLInfo integration disabled.',
    translation: { default: 'Integreringen med BLInfo är ej aktiverad.' },
  },
  {
    text: 'BLInfo hiddden article number',
    translation: { default: 'BLInfo artikelnummer för dolda artiklar' },
  },
  { text: 'BLInfo test failed', translation: { default: 'Test av BLInfo misslyckades' } },

  // C
  {
    text: 'Corners will automatically snap to other close corners, hold ctrl if you want to avoid snapping.',
    translation: {
      default:
        'Hörnen kommer automatiskt att kopplas ihop med andra hörn som är nära, håll nere ctrl om du inte vill att hörn kopplas ihop.',
    },
  },
  { text: 'Consumer', translation: { default: 'Konsument', multiple: 'Konsumenter' } },
  { text: 'Changelog', translation: { default: 'Ändringslogg' } },
  { text: 'Change table size', translation: { default: 'Ändra storlek på tabell' } },
  { text: 'Category', translation: { default: 'Kategori' } },
  { text: 'Credit', translation: { default: 'Kredit' } },
  { text: 'Credit payment', translation: { default: 'Kreditera faktura' } },
  { text: 'City', translation: { default: 'Stad' } },
  { text: 'Contact', translation: { default: 'Kontakt', multiple: 'Kontakter' } },
  { text: 'Created', translation: { default: 'Skapad' } },
  { text: 'Creating', translation: { default: 'Skapar...' } },
  { text: 'Check article', translation: { default: 'Kontrollera artikel' } },
  { text: 'Create quote', translation: { default: 'Skapa offert' } },
  { text: 'Create order', translation: { default: 'Skapa order' } },
  { text: 'Create invoices', translation: { default: 'Skapa fakturor' } },
  { text: 'Create & send invoices', translation: { default: 'Skapa & skicka fakturor' } },
  { text: 'Created successfully', translation: { default: 'Skapad utan problem' } },
  { text: 'Cost center', translation: { default: 'Kostnadsställe' } },
  { text: 'Calculate price', translation: { default: 'Prisuträkning' } },
  { text: 'Company name', translation: { default: 'Bolagsnamn' } },
  { text: 'Cancel', translation: { default: 'Avbryt' } },
  { text: 'Customer', translation: { default: 'Kund', multiple: 'Kunder' } },
  { text: 'Customer text', translation: { default: 'Kundtext' } },
  { text: 'Choice', translation: { default: 'Tillval', multiple: 'Tillval' } },
  { text: 'Cart', translation: { default: 'Kundkorg' } },
  { text: 'Close', translation: { default: 'Stäng' } },
  { text: 'Cost account', translation: { default: 'Konto' } },
  { text: 'Contact person at customer', translation: { default: 'Kundens kontaktperson' } },
  { text: 'Connected to match?', translation: { default: 'Ihopkopplad till match?' } },
  { text: 'Connected to players?', translation: { default: 'Ihopkopplad till spelare?' } },
  {
    text: 'Create manual database backup.',
    translation: { default: 'Skapa en manuell databasbackup.' },
  },
  { text: 'Copy article', translation: { default: 'Kopiera artikel' } },
  { text: 'Copy opportunity/order', translation: { default: 'Kopiera affärsmöjlighet/order' } },
  {
    text: 'Create order instead of invoice',
    translation: { default: 'Skapa som order istället för faktura' },
  },
  { text: 'Company', translation: { default: 'Bolag' } },
  { text: 'Configure GetAccept', translation: { default: 'Konfigurera GetAccept' } },
  {
    text: 'Configures GetAccept connection.',
    translation: { default: 'Ställer in inställningar för GetAccept-anslutningen.' },
  },
  { text: 'Create invoices', translation: { default: 'Skapa fakturor' } },
  {
    text: 'Custom subject and message',
    translation: { default: 'Specificera rubrik och meddelande' },
  },
  {
    text: 'Custom message',
    translation: { default: 'Specificera meddelande' },
  },
  { text: 'Comment', translation: { default: 'Kommentar' } },
  {
    text: "Customer is missing organization number. Invoices can't be created or imported.",
    translation: {
      default: 'Kunden saknar organisationsnummer. Fakturor går ej att skapa eller importera.',
    },
  },
  { text: 'Create Report', translation: { default: 'Skapa Rapport' } },
  {
    text: 'Creating report, please wait...',
    translation: { default: 'Skapar rapport, var god vänta...' },
  },
  { text: 'Creating...', translation: { default: 'Skapar...' } },
  { text: 'Create new version of quote', translation: { default: 'Skapa ny version av offert' } },

  // D
  {
    text: "Drag 'n' drop some files here, or click to select files",
    translation: { default: 'Dra och släpp filer här, eller klicka för att välja filer' },
  },
  {
    text: "Drag 'n' drop a file here, or click to select file",
    translation: { default: 'Dra och släpp en fil här, eller klicka för att välja en fil' },
  },
  { text: 'Description', translation: { default: 'Beskrivning' } },
  { text: 'Delete', translation: { default: 'Ta bort' } },
  { text: 'Done', translation: { default: 'Klar' } },
  { text: 'Day', translation: { default: 'Dag', multiple: 'Dagar' } },
  { text: 'Date', translation: { default: 'Datum' } },
  { text: 'Distribute', translation: { default: 'Fördela' } },
  { text: 'Delete account', translation: { default: 'Ta bort kund' } },
  { text: 'Delete user', translation: { default: 'Ta bort användare' } },
  { text: 'Delete group', translation: { default: 'Ta bort grupp' } },
  { text: 'Delete quote', translation: { default: 'Ta bort offert' } },
  { text: 'Delete agreement', translation: { default: 'Ta bort avtal' } },
  { text: 'Delete customer', translation: { default: 'Ta bort kund' } },
  { text: 'Delete article', translation: { default: 'Ta bort artikel' } },
  { text: 'Default Address', translation: { default: 'Adress' } },
  { text: 'Delivery Address', translation: { default: 'Leveransadress' } },
  { text: 'Delete opportunity', translation: { default: 'Ta bort affärsmöjlighet' } },
  { text: 'Delete order', translation: { default: 'Ta bort order' } },
  { text: 'Delete log', translation: { default: 'Ta bort kundlogg' } },
  { text: 'Delete message', translation: { default: 'Ta bort meddelande' } },
  { text: 'Delete contact', translation: { default: 'Ta bort kontakt' } },
  {
    text: 'Disabled text color, for disabled inputs',
    translation: { default: 'Ej skrivbara textfärg, för ej skrivbara fält' },
  },
  { text: 'Delete payment', translation: { default: 'Ta bort fakturering' } },
  { text: 'Delete package', translation: { default: 'Ta bort paketering' } },
  { text: 'Delete file', translation: { default: 'Ta bort fil' } },
  { text: 'Delete match', translation: { default: 'Ta bort match' } },
  { text: 'Delete presentation', translation: { default: 'Ta bort presentation' } },
  { text: 'Default payment terms', translation: { default: 'Standard betalningsvillkor' } },
  { text: "Don't calculate price", translation: { default: 'Räkna inte ut pris automatiskt' } },
  {
    text: 'Default payment terms in days, can be overwritten in invoices',
    translation: {
      default: 'Standard betalningsvillkor i antal dagar. Kan ändras individuellt på varje faktura',
    },
  },
  { text: 'Displayed', translation: { default: 'Visas' } },
  { text: 'Delete tag', translation: { default: 'Ta bort tagg' } },
  { text: 'Debug Mode', translation: { default: 'Debuggläge' } },
  { text: 'Default VAT', translation: { default: 'Standard Moms' } },
  { text: 'Delete status level', translation: { default: 'Ta bort statusnivå' } },
  { text: 'Delete menu', translation: { default: 'Ta bort meny' } },
  { text: 'Delete player', translation: { default: 'Ta bort spelare' } },
  { text: 'day', translation: { default: 'dag', multiple: 'dagar' } },

  {
    text: 'Default VAT, can be overwritten in invoices',
    translation: { default: 'Standard Moms. Kan ändras på varje fakturarad.' },
  },
  // E
  { text: 'E-mail', translation: { default: 'E-post' } },
  { text: 'Exposure?', translation: { default: 'Exponering?' } },
  { text: 'Expansionpanel Header Color', translation: { default: 'Expansionspanel rubrikfärg' } },
  {
    text: 'Either "dark" or "light", affects icons etc',
    translation: { default: 'Antingen "dark" eller "light", påverkar ikoner osv' },
  },
  { text: 'Error', translation: { default: 'Fel', multiple: 'Fel' } },
  { text: 'Empty cart', translation: { default: 'Töm kundvagn' } },
  { text: 'Exchangeable?', translation: { default: 'Utbytbar' } },
  {
    text: 'Exchangeable to other articles?',
    translation: { default: 'Utbytbar mot annan artikel' },
  },
  { text: 'Exchange article', translation: { default: 'Byt ut artikel' } },
  { text: 'Export to Excel', translation: { default: 'Exportera till Excel' } },
  { text: 'Economy', translation: { default: 'Ekonomi', multiple: 'Ekonomi' } },
  { text: 'Edit presentation', translation: { default: 'Ändra presentationen' } },
  { text: 'Expected sales', translation: { default: 'Förväntad försäljning' } },
  { text: 'Exclude on Quote?', translation: { default: 'Exkludera på offert/avtal?' } },
  {
    text: 'Exclude the article in quotes?',
    translation: { default: 'Ta ej med artikeln på offerter/avtal?' },
  },
  { text: 'Expand/Collapse all', translation: { default: 'Visa/Dölj alla' } },
  { text: 'External link', translation: { default: 'Länk för externa användare' } },
  { text: 'Edit contact', translation: { default: 'Uppdatera kontakt' } },
  { text: 'Edit status level', translation: { default: 'Uppdatera statusnivå' } },
  { text: 'Edit menu', translation: { default: 'Ändra menyn' } },
  { text: 'Edit customer', translation: { default: 'Ändra kundinformation' } },
  // F
  { text: 'First name', translation: { default: 'Förnamn' } },
  { text: 'From', translation: { default: 'Från' } },
  { text: 'File', translation: { default: 'Fil', multiple: 'Filer' } },
  { text: 'Failed to remove', translation: { default: 'Misslyckades med att ta bort' } },
  { text: 'Failed to save', translation: { default: 'Misslyckades med att spara' } },
  {
    text: 'Failed to create backup',
    translation: { default: 'Misslyckades med att skapa backup' },
  },
  {
    text: 'Failed to import excel',
    translation: { default: 'Misslyckades med att importera från excel' },
  },
  {
    text: 'Failed to copy article',
    translation: { default: 'Misslyckades med att kopiera artikel' },
  },
  {
    text: 'Failed to send payment',
    translation: { default: 'Misslyckades med att skicka fakturaunderlag' },
  },
  {
    text: 'Failed to tag customers',
    translation: { default: 'Misslyckades med att tagga kunder' },
  },
  { text: 'Fixed price', translation: { default: 'Fast pris' } },
  {
    text: 'Font size of menu, ie 14px',
    translation: { default: 'Fontstorlek i menyer, tex 14px' },
  },
  { text: 'Firstname', translation: { default: 'Förnamn' } },
  {
    text: 'Failed to update article',
    translation: { default: 'Misslyckades med att uppdatera artikel' },
  },
  { text: 'Failed to create quote', translation: { default: 'Misslyckades med att skapa offert' } },
  { text: 'For signing?', translation: { default: 'För signering?' } },
  {
    text: 'Failed to update template',
    translation: { default: 'Misslyckades med att uppdatera mallen' },
  },
  {
    text: 'Failed to restore backup',
    translation: { default: 'Misslyckades med att återställa backup' },
  },
  {
    text: 'Failed to import excel',
    translation: { default: 'Misslyckades med att importera excelfilen' },
  },
  {
    text: 'Fortnox integration disabled.',
    translation: { default: 'Integreringen med fortnox är ej aktiverad.' },
  },
  {
    text: 'Fortnox test successfull',
    translation: { default: 'Fortnox-test slutfördes utan problem' },
  },
  { text: 'Fortnox test failed', translation: { default: 'Fortnox-test misslyckades' } },
  {
    text: 'For currently active deals, a red background means sold (100%) and yellow means not yet sold (<100%)',
    translation: {
      default:
        'För pågående affärer får raden en röd bakgrund om affärden är såld (100%) och en gul om den inte är såld ännu (<100%)',
    },
  },

  // G
  {
    text: 'GetAccept test successfull',
    translation: { default: 'GetAccept-test slutfördes utan problem' },
  },
  { text: 'GetAccept test failed', translation: { default: 'GetAccept-test misslyckades' } },
  { text: 'GetAccept Login', translation: { default: 'GetAccept logga in' } },
  { text: 'GetAccept Username', translation: { default: 'GetAccept användarnamn' } },
  { text: 'GetAccept Password', translation: { default: 'GetAccept lösenord' } },
  { text: 'Group', translation: { default: 'Grupp', multiple: 'Grupper' } },
  { text: 'Get all agreement files', translation: { default: 'Hämta alla avtal' } },
  { text: 'Get my agreement files', translation: { default: 'Hämta mina avtal' } },
  // H
  { text: 'Hint text color (this!)', translation: { default: 'Hjälptextfärg (den här!)' } },
  {
    text: 'Header text color of expandable containers',
    translation: { default: 'Rubrikfärg på texten i expanderbara paneler' },
  },
  { text: 'Home Team', translation: { default: 'Hemmalag' } },
  { text: 'Home', translation: { default: 'Hem' } },
  { text: 'HTML', translation: { default: 'HTML' } },
  { text: 'Has Secondary Company', translation: { default: 'Har sekundärt bolag' } },
  { text: 'Hide articles', translation: { default: 'Dölj artiklar' } },
  { text: 'Hide articles on invoice', translation: { default: 'Dölj artiklar på faktura' } },
  {
    text: 'How many are available per year? Use -1 for unlimited.',
    translation: {
      default: 'Hur många av denna artikeln är tillgängliga per år? Använd -1 för oändligt antal.',
    },
  },
  { text: 'Hide empty categories', translation: { default: 'Dölj tomma kategorier' } },
  { text: 'hour', translation: { default: 'timme', multiple: 'timmar' } },

  // I
  { text: 'INFO', translation: { default: 'INFO' } },
  { text: 'In stock', translation: { default: 'Lager' } },
  { text: 'Image', translation: { default: 'Bild' } },
  { text: 'Invoice Address', translation: { default: 'Faktureringsadress' } },
  { text: 'Invoiced after event?', translation: { default: 'Faktureras efter händelse?' } },
  {
    text: 'Include this article in all new opportunities?',
    translation: { default: 'Lägg till denna artikel i alla nya affärsmöjligheter?' },
  },
  {
    text: 'Is this an exposure article?',
    translation: { default: 'Är denna artikel kopplad till exponering?' },
  },
  {
    text: 'Invoiced separately after specific event?',
    translation: { default: 'Faktureras separat efter en specifik händelse?' },
  },
  { text: 'Invoice text', translation: { default: 'Fakturatext' } },
  { text: 'Invoice date', translation: { default: 'Fakturadatum' } },
  { text: 'Info', translation: { default: 'Info' } },
  { text: 'Information', translation: { default: 'Information' } },
  {
    text: 'Is the article connected to matches played?',
    translation: { default: 'Är denna artikel kopplad till en matchhändelse?' },
  },
  {
    text: 'Is the article connected to players?',
    translation: { default: 'Är denna artikel kopplad till en valbar spelare?' },
  },
  { text: 'Is this a barter article?', translation: { default: 'Är det här en barterartikel?' } },
  { text: 'Import file', translation: { default: 'Importfil', multiple: 'Importfiler' } },
  {
    text: 'Is this quote being sent for singning or only viewing?',
    translation: { default: 'Skickas offerten för signering eller bara för visning?' },
  },
  { text: 'Inactive', translation: { default: 'Inaktiverad' } },
  { text: 'Invoiced', translation: { default: 'Fakturerat' } },
  { text: 'Invoice', translation: { default: 'Faktura', multiple: 'Fakturor' } },
  { text: 'Invoicing', translation: { default: 'Faktuering' } },
  { text: 'Invoice Afterwards', translation: { default: 'Efterfakturering' } },
  {
    text: 'Invoiced sum does not match order sum.',
    translation: { default: 'Fakturerad summa matchar inte ordersumman.' },
  },
  {
    text: 'Include comment?',
    translation: { default: 'Inkludera kommentar?' },
  },
  {
    text: 'Ignore this order for this season',
    translation: { default: 'Dölj order för denna säsong' },
  },
  { text: 'Ignore season', translation: { default: 'Dölj för säsong' } },
  { text: 'In', translation: { default: 'Om' } },
  {
    text: 'If no active deal is found, the last one is shown',
    translation: { default: 'Om ingen pågående affär är hittad så visas den sista' },
  },
  // J
  // K
  // L
  { text: 'Last name', translation: { default: 'Efternamn' } },
  { text: 'Language', translation: { default: 'Språk', multiple: 'Språk' } },
  { text: 'Login', translation: { default: 'Logga in' } },
  { text: 'Login with Microsoft', translation: { default: 'Logga in med Microsoft' } },
  { text: 'Logout', translation: { default: 'Logga ut' } },
  { text: 'Label', translation: { default: 'Märke' } },
  { text: 'Lastname', translation: { default: 'Efternamn' } },
  { text: 'Login failed', translation: { default: 'Inloggningen misslyckades' } },
  { text: 'Level', translation: { default: 'Nivå' } },
  // M
  { text: 'My Accounts', translation: { default: 'Mina Kunder' } },
  { text: 'My Agreements', translation: { default: 'Mina Avtal' } },
  { text: 'My Opportunities', translation: { default: 'Mina Affärsmöjligheter' } },
  { text: 'My Orders', translation: { default: 'Mina Ordrar' } },
  { text: 'My Quotes', translation: { default: 'Mina Offerter' } },
  { text: 'My Messages', translation: { default: 'Mina Meddelanden' } },
  { text: 'My Customers', translation: { default: 'Mina Kunder' } },
  { text: 'My Items', translation: { default: 'Visa mina' } },
  { text: 'My Invoices', translation: { default: 'Mina Fakturor' } },
  { text: 'Message', translation: { default: 'Meddelande', multiple: 'Meddelanden' } },
  { text: 'Match', translation: { default: 'Match', multiple: 'Matcher' } },
  { text: 'Modified', translation: { default: 'Ändrad' } },
  { text: 'Month', translation: { default: 'Månad' } },
  { text: 'Menu', translation: { default: 'Meny' } },
  { text: 'Merged', translation: { default: 'Ihoplagen' } },
  { text: 'Measurement', translation: { default: 'Mått' } },
  {
    text: 'Mandatory Organization number',
    translation: { default: 'Tvingande organisationsnummer' },
  },
  {
    text: 'Mandatory or not can be changed in settings/economy',
    translation: { default: 'Tvingande eller inte kan ändras i inställningar/ekonomi' },
  },
  {
    text: 'Multiple accounts exists with this organization number.',
    translation: { default: 'Fler än en kund är upplagd med detta organisationsnummer.' },
  },
  {
    text: 'Multipe companies for articles',
    translation: { default: 'Artiklar skall importeras på olika bolag' },
  },
  { text: 'Mark quote as accepted', translation: { default: 'Markera offert som accepterad' } },
  {
    text: 'Missing results for user, result for all users is shown',
    translation: {
      default: 'Sökresultat saknas för användaren, resultat för alla användare visas',
    },
  },
  { text: 'minute', translation: { default: 'minut', multiple: 'minuter' } },

  // N
  { text: 'Name', translation: { default: 'Namn' } },
  { text: 'Naviation Text Color', translation: { default: 'Textfärg för menyer' } },
  { text: 'Navigation Text Font Size', translation: { default: 'Fontstorlek i menyer' } },
  { text: 'Name of the theme', translation: { default: 'Namn på temat' } },
  { text: 'Name is a required field.', translation: { default: 'Namn måste fyllas i.' } },
  { text: 'Number', translation: { default: 'Nummer' } },
  { text: 'New', translation: { default: 'Ny' } },
  { text: 'No payments.', translation: { default: 'Saknar fakturering.' } },
  { text: 'New tag', translation: { default: 'Ny tagg' } },
  { text: 'New account', translation: { default: 'Ny kund' } },
  { text: 'New user', translation: { default: 'Ny användare' } },
  { text: 'New group', translation: { default: 'Ny grupp' } },
  { text: 'New agreement', translation: { default: 'Nytt avtal' } },
  { text: 'New customer', translation: { default: 'Ny kund' } },
  { text: 'New message', translation: { default: 'Nytt meddelande' } },
  { text: 'Next Article ID', translation: { default: 'Nästa Artikel-ID' } },
  { text: 'New article', translation: { default: 'Ny artikel' } },
  { text: 'New package', translation: { default: 'Ny paketering' } },
  { text: 'New player', translation: { default: 'Ny spelare' } },
  { text: 'New opportunity', translation: { default: 'Ny affärsmöjlighet' } },
  { text: 'New status level', translation: { default: 'Ny statusnivå' } },
  { text: 'New match', translation: { default: 'Ny match' } },
  { text: 'New presentation', translation: { default: 'Ny presentation' } },
  { text: 'Not a valid date', translation: { default: 'Ogiltigt datum' } },
  { text: 'Navigation Text Color', translation: { default: 'Textfärg menyer' } },
  { text: 'Next season', translation: { default: 'Nästa säsong' } },
  {
    text: 'No invoices added for this order',
    translation: { default: 'Det finns inga fakturor skapade för denna order' },
  },
  { text: 'No', translation: { default: 'Nej' } },
  { text: 'Not yet invoiced orders', translation: { default: 'Ej fakturerade ordrar' } },
  { text: 'No split', translation: { default: 'Ingen uppdelning' } },
  // O
  { text: 'of', translation: { default: 'av' } },
  { text: 'Owner', translation: { default: 'Ägare' } },
  { text: 'Open article', translation: { default: 'Öppna artikel' } },
  { text: 'Organization Number', translation: { default: 'Organisationsnummer' } },
  { text: 'Order', translation: { default: 'Order', multiple: 'Ordrar' } },
  { text: 'Ordering', translation: { default: 'Ordning' } },
  { text: 'Order date', translation: { default: 'Orderdatum' } },
  {
    text: 'Opportunity',
    translation: { default: 'Affärsmöjlighet', multiple: 'Affärsmöjligheter' },
  },
  { text: 'Other text', translation: { default: 'Övrig text' } },
  { text: 'Other', translation: { default: 'Övrigt' } },
  { text: 'owner is a required field', translation: { default: 'ägare måste fyllas i' } },
  { text: 'Open invoice', translation: { default: 'Öppna fakturaunderlag' } },
  { text: 'Opportunity value', translation: { default: 'Värde på affärsmöjlighet' } },
  { text: 'Owner needs to be set', translation: { default: 'Ägare måste fyllas i' } },
  {
    text: 'OneFlow integration disabled.',
    translation: { default: 'Integreringen med OneFlow är ej aktiverad.' },
  },
  { text: 'Original', translation: { default: 'Original' } },
  // P
  { text: 'Phone', translation: { default: 'Telefon' } },
  { text: 'Previous season', translation: { default: 'Föregående säsong' } },
  { text: 'Path', translation: { default: 'Sökväg' } },
  { text: 'Password', translation: { default: 'Lösenord' } },
  { text: 'Price', translation: { default: 'Pris' } },
  { text: 'Price/Item', translation: { default: "A'pris" } },
  { text: 'Primary text color', translation: { default: 'Primär textfärg' } },
  { text: 'Profile', translation: { default: 'Profil' } },
  { text: 'Primary Dark', translation: { default: 'Primär Mörk' } },
  { text: 'Primary Light', translation: { default: 'Primär Ljus' } },
  { text: 'Primary Main', translation: { default: 'Primär Huvud' } },
  {
    text: 'Please enter your login information below',
    translation: { default: 'Skriv in din inloggningsinformation nedan' },
  },
  { text: 'Payment', translation: { default: 'Fakturering' } },
  { text: 'Payment terms', translation: { default: 'Betalningsvillkor' } },
  { text: 'Part invoice', translation: { default: 'Delfaktura' } },
  { text: 'Previous Article ID', translation: { default: 'Föregående Artikel-ID' } },
  { text: 'PDF', translation: { default: 'PDF' } },
  { text: 'Player', translation: { default: 'Spelare', multiple: 'Spelare' } },
  { text: 'Price in percentage?', translation: { default: 'Pris i procent?' } },
  { text: 'Presentation', translation: { default: 'Presentation', multiple: 'Presentationer' } },
  { text: 'Prepend invoice text', translation: { default: 'Start av fakturatexter' } },
  {
    text: 'Prepend any invoice text with this text.',
    translation: { default: 'Starta alltid all fakturatext med denna text.' },
  },
  {
    text: 'Presentation Image',
    translation: { default: 'Presentationsbild', multiple: 'Presentationsbilder' },
  },
  { text: 'Package', translation: { default: 'Paketering', multiple: 'Paketeringar' } },
  // Q
  { text: 'Quote', translation: { default: 'Offert', multiple: 'Offerter' } },
  { text: 'Queued', translation: { default: 'Köad' } },
  { text: 'Quote sent to recipients', translation: { default: 'Offert skickad till mottagare' } },
  // R
  { text: 'Reset', translation: { default: 'Ångra Ändringar' } },
  { text: 'Reset to default', translation: { default: 'Återställ' } },
  { text: 'Reset successfully', translation: { default: 'Återställd utan problem' } },
  { text: 'Rebate', translation: { default: 'Rabatt' } },
  { text: 'Refresh articles', translation: { default: 'Uppdatera artiklar' } },
  { text: 'Removed successfully', translation: { default: 'Bortagen utan problem' } },
  { text: 'Removed payment', translation: { default: 'Fakturering borttagen' } },
  {
    text: 'Removed article from opportunity',
    translation: { default: 'Tog bort artikel från affärsmöjlighet' },
  },
  { text: 'Report', translation: { default: 'Rapport', multiple: 'Rapporter' } },
  { text: 'Reminder', translation: { default: 'Påminnelse' } },
  { text: 'Reminders has been set', translation: { default: 'Påminnelse inställd' } },
  { text: 'Restore backup from file', translation: { default: 'Återställ backup från fil' } },
  { text: 'Remove custom text', translation: { default: 'Ta bort extra text' } },
  { text: 'Reset database', translation: { default: 'Återställ databas' } },
  { text: 'Reviewed', translation: { default: 'Genomläst' } },
  { text: 'Rejected', translation: { default: 'Avvisad' } },
  { text: 'Restored successfully', translation: { default: 'Databas återställd' } },
  { text: 'Reset opportunity/order', translation: { default: 'Återställ affärsmöjlighet/order' } },
  { text: 'Released', translation: { default: 'Uppdaterades' } },
  { text: 'Right', translation: { default: 'Rättighet', multiple: 'Rättigheter' } },
  { text: 'Read', translation: { default: 'Läsa' } },
  { text: 'Row', translation: { default: 'Rad' } },
  { text: 'Reason', translation: { default: 'Orsak' } },
  { text: 'Resize file', translation: { default: 'Ändra storlek på bild' } },
  // S
  { text: 'Sum', translation: { default: 'Summa' } },
  {
    text: 'Sum of invoices does not match total price',
    translation: { default: 'Summan av faktureringarna stämmer inte överrens med totalpriset' },
  },
  { text: 'Season', translation: { default: 'Säsong' } },
  { text: 'Sold', translation: { default: 'Såld', multiple: 'Sålda' } },
  { text: 'Street', translation: { default: 'Gatuadress' } },
  { text: 'Size', translation: { default: 'Storlek' } },
  { text: 'Show Details', translation: { default: 'Visa Detaljer' } },
  { text: 'Saved successfully', translation: { default: 'Sparat utan problem' } },
  { text: 'Saved account contact', translation: { default: 'Sparade kontakten' } },
  { text: 'Save', translation: { default: 'Spara' } },
  { text: 'Save & create', translation: { default: 'Spara & skapa' } },
  { text: 'Save invoice', translation: { default: 'Spara fakturaunderlag' } },
  { text: 'Secondary Company name', translation: { default: 'Sekundärt bolagsnamn' } },
  { text: 'Sent', translation: { default: 'Skickad' } },
  { text: 'Save account', translation: { default: 'Spara kund' } },
  { text: 'Save user', translation: { default: 'Spara användare' } },
  { text: 'Save group', translation: { default: 'Spara grupp' } },
  { text: 'Save agreement', translation: { default: 'Spara avtal' } },
  { text: 'Save article', translation: { default: 'Spara artikel' } },
  { text: 'Save message', translation: { default: 'Spara meddelande' } },
  { text: 'Save match', translation: { default: 'Spara match' } },
  { text: 'Status level', translation: { default: 'Statusnivå', multiple: 'Statusnivåer' } },
  { text: 'Save presentation', translation: { default: 'Spara presentation' } },
  { text: 'Split by seasons', translation: { default: 'Dela upp betalning över säsonger' } },
  {
    text: 'Split by all invoices',
    translation: { default: 'Dela upp betalning över hela affären' },
  },
  { text: 'Seller', translation: { default: 'Säljare' } },
  { text: 'Select', translation: { default: 'Välj' } },
  { text: 'Save opportunity', translation: { default: 'Spara affärsmöjlighet' } },
  { text: "Sums don't match", translation: { default: 'Summorna matchar inte' } },
  { text: 'Seller text', translation: { default: 'Säljarens text' } },
  { text: 'Setting', translation: { default: 'Inställning', multiple: 'Inställningar' } },
  { text: 'Subject', translation: { default: 'Rubrik' } },
  { text: 'Select match', translation: { default: 'Välj match' } },
  { text: 'Select player', translation: { default: 'Välj spelare' } },
  { text: 'Send payment', translation: { default: 'Skicka fakturaunderlag' } },
  { text: 'Send message to', translation: { default: 'Skicka meddelande till' } },
  {
    text: 'Send message when sold?',
    translation: { default: 'Skicka meddelande vid försäljning?' },
  },
  {
    text: 'Send message when this article is sold?',
    translation: { default: 'Skicka ett meddelande när denna artikel säljs?' },
  },
  { text: 'Send quote', translation: { default: 'Skicka offert' } },
  { text: 'Split by order', translation: { default: 'Uppdelad per order' } },
  { text: 'Summarized by account', translation: { default: 'Sammanslagen per kund' } },
  { text: 'System', translation: { default: 'System' } },
  { text: 'System administration', translation: { default: 'Systemadministration' } },
  { text: 'Sending', translation: { default: 'Skickar' } },
  {
    text: 'Started import successfully',
    translation: { default: 'Startade importen utan problem' },
  },
  { text: 'Select all not invoiced', translation: { default: 'Markera alla ej fakturerade' } },
  {
    text: 'Select a tag or enter a new one below',
    translation: { default: 'Välj en tagg eller skriv in en ny nedan' },
  },
  {
    text: 'Should the article be priced with a percentage of the total opportunity price?',
    translation: {
      default:
        'Skall artikeln prissättas motsvarande en procentsats på totala värdet av affärsmöjligheten?',
    },
  },
  { text: 'Split to single amount?', translation: { default: 'Dela upp med en på varje rad?' } },
  {
    text: 'Split each one of this article to 1 per row?',
    translation: {
      default: 'Om antalet artiklar är fler än en, dela upp varje artikel på en egen rad?',
    },
  },
  {
    text: 'Should organization number be mandatory when saving accounts? They are always required when creating invoices.',
    translation: {
      default:
        'Skall organisationsnummer vara tvingande när man sparar en kund? Organisationsnummer behövs alltid vid skapande av fakturor.',
    },
  },
  { text: 'Select already uploaded file', translation: { default: 'Välj redan uppladdade filer' } },
  { text: 'Search', translation: { default: 'Sök' } },
  { text: 'Select image', translation: { default: 'Välj bild' } },
  {
    text: 'Select the image area for the article on the image below. You can continue to drag and resize until you are happy with the result.',
    translation: {
      default:
        'Välj område för artikeln i bilden nedanför. Du kan flytta och ändra storlek tills du är nöjd med resultatet.',
    },
  },
  {
    text: 'Secondary text color, used for labels etc',
    translation: { default: 'Sekundär textfärg, används till rubriker osv' },
  },
  { text: 'Save as new', translation: { default: 'Spara som ny' } },
  { text: 'Status', translation: { default: 'Status' } },
  { text: 'Split by', translation: { default: 'Dela upp över' } },
  { text: 'Split by amount', translation: { default: 'Uppdelad varje (dag/månad)' } },
  { text: 'Show empty categories', translation: { default: 'Visa tomma kategorier' } },
  { text: 'Send quote to recipients', translation: { default: 'Skicka offert till mottagare' } },
  { text: 'second', translation: { default: 'sekund', multiple: 'sekunder' } },

  // T
  { text: 'Theme', translation: { default: 'Tema', multiple: 'Teman' } },
  { text: 'Template', translation: { default: 'Mall', multiple: 'Mallar' } },
  { text: 'Text Disabled', translation: { default: 'Textfärg inaktiverad' } },
  { text: 'Text Hint', translation: { default: 'Textfärg hjälp' } },
  { text: 'Text Secondary', translation: { default: 'Sekundär textfärg' } },
  { text: 'Text Primary', translation: { default: 'Primär textfärg' } },
  { text: 'Total price', translation: { default: 'Totalpris' } },
  { text: 'To', translation: { default: 'Till' } },
  { text: 'Tag all my customers', translation: { default: 'Tagga alla mina kunder' } },
  { text: 'Test BLInfo', translation: { default: 'Testa BLInfo' } },
  { text: 'Test BLInfo connection.', translation: { default: 'Testa anslutningen till BLInfo.' } },
  { text: 'Test GetAccept', translation: { default: 'Testa GetAccept' } },
  {
    text: 'Tests GetAccept connection.',
    translation: { default: 'Testar anslutningen till GetAccept.' },
  },
  { text: 'Test OneFlow', translation: { default: 'Testa OneFlow' } },
  {
    text: 'Tests OneFlow connection.',
    translation: { default: 'Testar anslutningen till OneFlow.' },
  },
  { text: 'Test Fortnox', translation: { default: 'Testa Fortnox' } },
  {
    text: 'Tests Fortnox connection.',
    translation: { default: 'Testar anslutningen till Fortnox.' },
  },
  { text: 'Type', translation: { default: 'Typ' } },
  { text: 'Title', translation: { default: 'Titel' } },
  { text: 'Time', translation: { default: 'Tid' } },
  {
    text: 'The article has a fixed unchangeable price?',
    translation: { default: 'Har artikeln ett fast pris som ej går att ändra?' },
  },
  { text: 'Total', translation: { default: 'Totalt' } },
  { text: 'Text', translation: { default: 'Text', multiple: 'Texter' } },
  {
    text: 'Template updated successfully',
    translation: { default: 'Mallen uppdaterades utan problem' },
  },
  {
    text: 'The message will be added as a comment to the contract. The comment will be added after the document has been sent to the first contact in the signing order.',
    translation: {
      default:
        'Detta meddelande skapas som en kommentar till dokumentet. Kommentaren läggs in efter att dokumentet är skicka till den första kontakten i signeringsordningen.',
    },
  },
  {
    text: 'The text visible on the invoice. If no text is specified the seller text is used.',
    translation: {
      default: 'Texten som visas på fakturan. Om ingen text angetts så används säljarens text.',
    },
  },
  {
    text: 'The text appearing in the sellers section on the quote.',
    translation: { default: 'Text som visas i säljarens del i offerten.' },
  },
  {
    text: 'The text appearing in the buyer section on the quote.',
    translation: { default: 'Text som visas i köparens del i offerten.' },
  },
  {
    text: 'The text appearing in the other section on the quote.',
    translation: { default: 'Text som visas i övrigt-delen i offerten.' },
  },
  { text: 'Text color for top and left menu', translation: { default: 'Färg på text i menyerna' } },
  { text: 'This season', translation: { default: 'Nuvarande säsong' } },
  { text: 'Tag', translation: { default: 'Tagg', multiple: 'Taggar' } },
  { text: 'Template settings', translation: { default: 'Mallinställning' } },
  {
    text: 'This is a new version of ASC since your last visit. Click here if you want to see whats changed.',
    translation: {
      default:
        'Det här är en nyare version av ASC än vid ditt senaste besök. Klicka här för att se vad som förändrats.',
    },
  },
  { text: 'To article report', translation: { default: 'Till artikelrapport' } },
  {
    text: 'The "customer" column corresponds to the customer that have an deal with the given player linked to one of the articles',
    translation: {
      default:
        'Kundkolumnen visar kunden som har en affär med en artikel som är kopplad till spelaren',
    },
  },
  {
    text: 'The first currently active deal found is shown ("Currently active" means that today is within the start and end date of the deal)',
    translation: {
      default:
        'Den första pågående affären som hittades visas ("pågående" menas att dagens datum är inom affärens start- och slutdatum)',
    },
  },

  // U
  { text: 'User', translation: { default: 'Användare', multiple: 'Användare' } },
  { text: 'Username', translation: { default: 'Användarnamn' } },
  { text: 'Uploaded', translation: { default: 'Uppladdad' } },
  { text: 'Urgently', translation: { default: 'Omgående' } },
  { text: 'Unknown', translation: { default: 'Okänd' } },
  { text: 'Update account', translation: { default: 'Uppdatera kund' } },
  { text: 'Update user', translation: { default: 'Uppdatera användare' } },
  { text: 'Update group', translation: { default: 'Uppdatera grupp' } },
  { text: 'Update article', translation: { default: 'Uppdatera artikel' } },
  { text: 'Update agreement', translation: { default: 'Uppdatera avtal' } },
  { text: 'Update message', translation: { default: 'Uppdatera meddelande' } },
  { text: 'Update customer', translation: { default: 'Uppdatera kund' } },
  { text: 'Update match', translation: { default: 'Uppdatera match' } },
  { text: 'Update player', translation: { default: 'Uppdatera spelare' } },
  { text: 'Updated payment', translation: { default: 'Uppdaterade faktura' } },
  { text: 'Unsent payments.', translation: { default: 'Ej skickade fakturor.' } },
  { text: 'Update presentation', translation: { default: 'Uppdatera presentation' } },
  {
    text: 'Updated article information.',
    translation: { default: 'Uppdaterade artikelinformation.' },
  },
  { text: 'Unselect match', translation: { default: 'Ta bort vald match' } },
  { text: 'Unselect player', translation: { default: 'Ta bort vald spelare' } },
  { text: 'Unlock order', translation: { default: 'Lås upp order' } },
  { text: 'URL to background image', translation: { default: 'URL till bakgrundsbild' } },
  {
    text: 'Unbooked articles for matches and players',
    translation: { default: 'Ej inbokade artiklar på matcher och spelare' },
  },
  {
    text: 'Use this template when opportunity exceeds this value?',
    translation: {
      default: 'Använd denna mall automatiskt när affärsmöjligheten överstiger detta värde?',
    },
  },
  // V
  { text: 'View as list', translation: { default: 'Visa som lista' } },
  { text: 'View as image grid', translation: { default: 'Visa som bild-rutnät' } },
  { text: 'Visiting Address', translation: { default: 'Besöksadress' } },
  { text: 'Weighed value', translation: { default: 'Viktat värde' } },
  { text: 'Welcome', translation: { default: 'Välkommen' } },
  { text: 'Word', translation: { default: 'Word' } },
  { text: 'Viewed', translation: { default: 'Visad' } },
  { text: 'VAT (%)', translation: { default: 'Moms (%)' } },
  { text: 'VAT Code', translation: { default: 'Momskod' } },
  {
    text: 'When pressing next in the grid view of articles, which article should we open?',
    translation: {
      default: 'När vi väljer nästa i artikel-bild-vyn, vilken artikel skall vi öppna?',
    },
  },
  {
    text: 'When pressing previous in the grid view of articles, which article should we open?',
    translation: {
      default: 'När vi väljer föregående i artikel-bild-vyn, vilken artikel skall vi öppna?',
    },
  },
  {
    text: 'When creating an invoice in Fortnox, should we instead create an order?',
    translation: {
      default: 'När det skapas en faktura i Fortnox, skall det istället skapas en order?',
    },
  },
  {
    text: 'Wrong username and/or password. Please try again or contact system administrators.',
    translation: {
      default:
        'Felaktigt användarnamn och/eller lösenord. Var vänlig försök igen eller kontakta en systemadministratör.',
    },
  },
  { text: 'Write', translation: { default: 'Skriva' } },
  // W
  // X
  // Y
  { text: 'Yes', translation: { default: 'Ja' } },
  {
    text: 'You can select existing files to the left',
    translation: { default: 'Du kan välja filer som redan finns till vänster' },
  },
  {
    text: 'Your report is created and is available below.',
    translation: { default: 'Din rapport är skapad och är tillgänglig nedan.' },
  },
  { text: 'Your report was not created.', translation: { default: 'Din rapport skapades inte.' } },
  // Z
  { text: 'Zip Code', translation: { default: 'Postnummer' } },
  { text: '', translation: { default: '' } },

  // Time
  { text: 'seconds ago', translation: { default: 'sekunder sedan' } },
  { text: 'minutes ago', translation: { default: 'minuter sedan' } },
  { text: 'hours ago', translation: { default: 'timmar sedan' } },
  { text: 'days ago', translation: { default: 'dagar sedan' } },
  { text: 'minute,', translation: { default: 'minut,' } },
  { text: 'hour,', translation: { default: 'timme,' } },
  { text: 'day,', translation: { default: 'dag,' } },
];

export default Swedish;
