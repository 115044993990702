import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import update from 'immutability-helper';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { DndProvider, DropTargetMonitor, XYCoord, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';

import { Article, Match, Opportunity, Player } from '@earnenterprise/asc-models';
import { useDialogHelper } from 'components/confirm.dialog.component';
import { renderAsArticle } from 'components/renderers';
import { ErrorContext } from 'error/error.context';
import { HelperCurrencyFormat, HelperTextField } from 'fields/text.field';
import { fetchArticles } from 'redux/actions/items.actions';
import { MatchSelectDialogComponent } from 'routes/match/match.select.dialog';
import { ArticleExchangeDialogComponent } from 'routes/opportunity/articles/article.exchange.dialog';
import { OArticleIcon } from 'routes/opportunity/articles/article.icon';
import { OpportunityArticleAddComponent } from 'routes/opportunity/opportunity.article.add';
import PlayerSelectDialogComponent from 'routes/player/player.select.dialog';
import { gqlArticleService } from 'services/gql.services';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';

const DEFAULT_ARTICLE_FONT_SIZE = '14px';

interface Props {
  isNew?: boolean;
  unlocked?: boolean;
}

export const ItemTypes = {
  BOX: 'box',
};

export const renderAsCurrency = (
  value: number,
  excludeCurrency?: boolean,
  negativeZero?: boolean
) => {
  let prefix = '';
  if (!value) {
    value = 0;
    prefix = negativeZero ? '-' : '';
  }
  if (value < 0) prefix = '-';

  if (excludeCurrency)
    return prefix + value.toLocaleString('sv-SE', { style: 'decimal', currency: 'SEK' });
  return prefix + value.toLocaleString('sv-SE', { style: 'currency', currency: 'SEK' });
};

export const tableStyle = makeStyles((theme: Theme) => {
  return createStyles({
    tableCell: {
      padding: '2px 0px 2px 10px',
      textAlign: 'right',
      fontSize: DEFAULT_ARTICLE_FONT_SIZE,
    },
    tableCellInput: {
      padding: '0px 0px 0px px',
      minWidth: '100px',
      width: '100%',
      '&& .Mui-disabled': {
        backgroundColor: 'transparent',
      },
      '&& .MuiTableCell-root': {
        '&& .MuiTableCell-sizeSmall': {
          padding: '0px 8px 0px 8px',
        },
        '&& .Mui-disabled': {
          color: theme.palette.text.disabled,
          '-webkit-text-fill-color': theme.palette.text.disabled,
        },
      },
      '&& .MuiInputBase-input': {
        backgroundColor: 'rgba(1,1,1,0.2)',
        color: theme.palette.primary.main,
        fontSize: DEFAULT_ARTICLE_FONT_SIZE,
        padding: '5px 7px 5px 2px',
      },
      '&& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.text.primary,
        '-webkit-text-fill-color': theme.palette.text.primary,
        backgroundColor: 'rgba(0,0,0,0)',
        borderBottom: '0px',
      },
    },
    tableCellInputFooter: {
      padding: '0px 0px 0px px',
      minWidth: '50px',
      width: '100%',
      '&& .Mui-disabled': {
        backgroundColor: 'rgba(1,1,1,0.9)',
      },
      '&& .MuiTableCell-root': {
        '&& .MuiTableCell-sizeSmall': {
          padding: '0px 8px 0px 8px',
        },
        '&& .Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },

      '&& .MuiInputBase-input': {
        backgroundColor: 'rgba(1,1,1,0.2)',
        textAlign: 'right',
        color: theme.palette.primary.main,
        fontSize: DEFAULT_ARTICLE_FONT_SIZE,
        padding: '5px 13px 5px 8px',
      },
      '&& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.text.primary,
        '-webkit-text-fill-color': theme.palette.text.primary,
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
    tableCellInputDate: {
      textAlign: 'right',
      padding: '0px 0px 0px px',
      minWidth: '50px',
      width: '100%',
      color: 'green',

      '&& .Mui-disabled': {
        backgroundColor: 'rgba(1,1,1,0.9)',
      },
      '&& .MuiTableCell-root': {
        '&& .MuiTableCell-sizeSmall': {
          padding: '0px 8px 0px 8px',
        },
        '&& .Mui-disabled': {
          color: theme.palette.text.disabled,
          '-webkit-text-fill-color': theme.palette.text.disabled,
        },
      },
      '&& .MuiInputBase-root': {
        boxSizing: 'inherit',
        backgroundColor: 'rgba(1,1,1,0.2)',
        textAlign: 'right',
        color: theme.palette.primary.main,
        fontSize: DEFAULT_ARTICLE_FONT_SIZE,
        padding: '5px 13px 5px 8px',
      },
      '&& .MuiInputBase-input': {
        boxSizing: 'inherit',
        backgroundColor: 'rgba(1,1,1,0.2)',
        textAlign: 'right',
        color: theme.palette.primary.main,
        fontSize: DEFAULT_ARTICLE_FONT_SIZE,
        padding: '5px 13px 5px 8px',
      },
      '&& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.text.primary,
        '-webkit-text-fill-color': theme.palette.text.primary,
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    tableRowArticleWithText: {
      '&& .MuiTableCell-root': {
        borderBottom: '0px',
      },
    },
    tableRowText: {
      '&& .MuiTableCell-root': {},
      '&& .MuiTableCell-sizeSmall': {
        padding: '0px 16px 5px 5px',
        textAlign: 'left',
        fontSize: '0.5rem',
      },
    },
    tableCellIcon: {
      padding: '0px 10px 0px 5px',
      fontSize: (theme.components?.MuiIcon?.styleOverrides?.root as any).fontSize,
      '&& .MuiSvgIcon-root': {
        padding: '0px',
        fontSize: (theme.components?.MuiIcon?.styleOverrides?.root as any).fontSize,
      },
    },
    tableCellHeader: {
      fontSize: theme.typography.body2.fontSize,
      '&& .MuiTableCell-head': {
        fontSize: theme.typography.body2.fontSize,
        textAlign: 'left',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
  });
});

interface DraggableProps {
  id: string | number;
  className: string;
  index: number;
  children: React.ReactNode;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const DraggableTableRow = ({ id, className, index, moveRow, children }: DraggableProps) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.order;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // We're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.order = hoverIndex;
    },
  });

  //const [{ isDragging }, drag] = useDrag({
  /*
  FIXME: this needs fixing.
  const [, drag] = useDrag({
    item: { type: ItemTypes.BOX, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  */
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, type: ItemTypes.BOX, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );

  drag(drop(ref));

  return (
    <TableRow ref={ref} className={className}>
      {children}
    </TableRow>
  );
};

export const OpportunityArticleTable = ({ isNew, unlocked }: Props) => {
  const { translate } = useContext(LanguageContext);
  const { snackbar } = useContext(ErrorContext);
  const { values, setFieldValue } = useFormikContext<Opportunity>();
  const [openAddArticle, setOpenAddArticle] = useState(false);
  const [exchangeRow, setExchangeRow] = useState<number | null>(null);
  const [displayArticle, setDisplayArticle] = useState<Article | undefined>(undefined);
  const dispatch = useDispatch();
  const selectMatchDialogHelper = useDialogHelper();
  const selectPlayerDialogHelper = useDialogHelper();
  const articleExchangeDialogHelper = useDialogHelper();

  const classes = tableStyle();
  const classesGlobal = globalStyles();

  let sumAmount = 0;
  let sumPrice = 0;
  let sumRebate = 0;

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    if (values.articles) {
      const dragArticleIndex = values.articles.findIndex((a) => a.order === dragIndex);
      const hoverArticleIndex = values.articles.findIndex((a) => a.order === hoverIndex);

      if (dragArticleIndex !== -1 && hoverArticleIndex !== -1) {
        const dragArticle = values.articles[dragArticleIndex];
        if (values.articles[dragArticleIndex] && values.articles[hoverArticleIndex]) {
          const tmpArticles = update(values.articles, {
            $splice: [
              [dragArticleIndex, 1],
              [hoverArticleIndex, 0, dragArticle],
            ],
          });

          for (let index = 0; index < tmpArticles.length; index++) {
            tmpArticles[index].order = index + 1;
          }
          console.log({ tmpArticles });
          setFieldValue(`articles`, tmpArticles, false);
        }
      }
    }
  };

  if (!values) return <></>;

  const refreshArticles = async () => {
    if (values.articles) {
      for (let index = 0; index < values.articles.length; index++) {
        const { data, error } = await gqlArticleService.get(values.articles[index].article.id);
        if (error) {
          snackbar(
            translate(`Failed to update article`) + ` "${values.articles[index].article.name}".`,
            'error',
            error
          );
        } else if (data) {
          setFieldValue(`articles[${index}].pricePerItemInCents`, data.priceInCents, false);
          setFieldValue(
            `articles[${index}].priceInCents`,
            data.priceInCents * values.articles[index].amount,
            false
          );
          setFieldValue(
            `articles[${index}].rebateInCents`,
            data.priceInCents * values.articles[index].amount - values.articles[index].sumInCents,
            false
          );
          setFieldValue(`articles[${index}].article`, data, false);
          setFieldValue(`articles[${index}].costCenter`, data.costCenter, false);
        }
      }
      snackbar(translate('Updated article information.'), 'info');
    }
  };

  const addArticles = async () => {
    dispatch(fetchArticles({}));
    setOpenAddArticle(true);
  };

  const changeAmount = (index: number, e: React.ChangeEvent<any>) => {
    console.log('changeAmount()');
    if (!values || !values.articles || e.target.value === null) return;
    const article = values.articles[index];
    article.priceInCents = toNumber(e.target.value) * article.pricePerItemInCents;
    article.sumInCents =
      toNumber(e.target.value) * article.pricePerItemInCents - article.rebateInCents;
    setFieldValue(`articles[${index}].priceInCents`, article.priceInCents, false);
    setFieldValue(`articles[${index}].sumInCents`, article.sumInCents, false);
    updateTotalSum();
  };

  const changePrice = (index: number, e: React.ChangeEvent<any>) => {
    //console.log('changePrice()');
    if (!values || !values.articles || e.target.value === null) return;
    const article = values.articles[index];
    article.sumInCents = toNumber(e.target.value) - article.rebateInCents;
    setFieldValue(`articles[${index}].sumInCents`, article.sumInCents, false);
    updateTotalSum();
  };

  const changeRebate = (index: number, e: React.ChangeEvent<any>) => {
    //console.log('changeRebate()');
    if (!values || !values.articles || e.target.value === null) return;
    const article = values.articles[index];
    article.sumInCents = article.priceInCents - toNumber(e.target.value);
    setFieldValue(`articles[${index}].sumInCents`, article.sumInCents, false);
    updateTotalSum();
  };

  const changeSum = (index: number, e: React.ChangeEvent<any>) => {
    //console.log('changeSum()');
    if (!values || !values.articles || e.target.value === null) return;
    const article = values.articles[index];
    article.rebateInCents = article.priceInCents - toNumber(e.target.value);
    article.sumInCents = toNumber(e.target.value);
    setFieldValue(`articles[${index}].rebateInCents`, article.rebateInCents, false);
    updateTotalSum();
  };

  const changeTotalSum = (e: React.ChangeEvent<any>) => {
    //console.log('changeTotalSum()');
    if (!values || !values.articles || e.target.value === null) return;

    //console.log({ totalSumNew: e.target.value, old: values.priceInCents, sum: sumPrice });
    const totalSumNew = toNumber(e.target.value);

    if (e.target.value === sumPrice - sumRebate) return;

    const percents: number[] = [];
    values.articles.forEach(
      (article, index) =>
        (percents[index] = article.article.isFixedPrice ? 0 : article.priceInCents / sumPrice)
    );

    // Calculate sums by percentage
    let totSum = 0;
    let lastArticleIndex = -1;
    values.articles.forEach((article, index) => {
      if (!article.article.isFixedPrice) {
        article.sumInCents = Math.round(totalSumNew * percents[index]);
        article.rebateInCents = Math.round(article.priceInCents - article.sumInCents);
        if (!article.article.isInvoicedAfterEvent) totSum += article.sumInCents;
        if (values.articles) {
          values.articles[index].sumInCents = article.sumInCents;
          values.articles[index].rebateInCents = article.rebateInCents;
        }
        setFieldValue(`articles[${index}].sumInCents`, article.sumInCents, false);
        setFieldValue(`articles[${index}].rebateInCents`, article.rebateInCents, false);
        lastArticleIndex = index;
      }
    });

    // Does it diff?
    const diff = totSum - toNumber(totalSumNew);
    if (diff !== 0) {
      const article = values.articles[lastArticleIndex];
      article.sumInCents = Math.round(totalSumNew * percents[lastArticleIndex]) - diff;
      article.rebateInCents = article.priceInCents - article.sumInCents;
      setFieldValue(`articles[${lastArticleIndex}].sumInCents`, article.sumInCents, false);
      setFieldValue(`articles[${lastArticleIndex}].rebateInCents`, article.rebateInCents, false);
    }
  };

  const updateTotalSum = () => {
    if (!values || !values.articles) return;
    let sumSum = 0;
    values.articles.forEach(
      (item) => (sumSum += item.article.isInvoicedAfterEvent ? 0 : toNumber(item.sumInCents))
    );
    if (values.priceInCents !== sumSum) {
      setFieldValue('priceInCents', sumSum, false);
    }
  };

  const toNumber = (value: any) => {
    const output = parseFloat(value);
    if (output) return output;
    return 0;
  };

  const onSelectMatch = (index: number, match: Match) => {
    console.log({ index, match });
    if (values.articles) {
      values.articles[index].match = match;
    }

    //removeItem(opportunity, undefined).then(() => {});
  };

  const onSelectPlayer = (index: number, player: Player) => {
    console.log({ index, player });
    if (values.articles) {
      values.articles[index].player = player;
    }

    //removeItem(opportunity, undefined).then(() => {});
  };

  const onExchange = (article: Article | null | undefined, index: number) => {
    if (!article) return;
    articleExchangeDialogHelper.setData(article);
    articleExchangeDialogHelper.setOpen(true);
    setExchangeRow(index);
  };

  if (values.articles) {
    values.articles.forEach((item) => {
      sumAmount += toNumber(item.amount);
      if (item && item.article) {
        if (!item.article.isInvoicedAfterEvent) {
          sumPrice += toNumber(item.priceInCents);
          sumRebate += toNumber(item.rebateInCents);
        }
      }
    });
  }

  // Percentage prices
  if (values.articles) {
    values.articles.forEach((item) => {
      if (item && item.article) {
        if (item.article.isPercentage) {
          item.sumInCents =
            (((item.article.priceInCents / 100) * (sumPrice - sumRebate)) / 100) * item.amount;
        }
      }
    });
  }

  const won = values.status === '100%';
  const lost = values.status === '0%';
  const locked = !unlocked && (won || lost);

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableCellHeader}>
            <TableCell style={{}}></TableCell>
            <TableCell>{translate('Category')}</TableCell>
            <TableCell>{translate('Name')}</TableCell>
            <TableCell>{translate('Number')}</TableCell>
            <TableCell style={{ textAlign: 'right', maxWidth: '60px' }}>
              {translate('Price/Item')}
            </TableCell>
            <TableCell style={{ textAlign: 'right', maxWidth: '60px' }}>
              {translate('Amount')}
            </TableCell>
            <TableCell style={{ textAlign: 'right', minWidth: '100px' }}>
              {translate('Price')}
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>{translate('Rebate')}</TableCell>
            <TableCell style={{ textAlign: 'right' }}>{translate('Sum')}</TableCell>
            {values?.status === '100%' ? null : <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <DndProvider backend={HTML5Backend}>
            {values.articles?.map((article, indexRow) => {
              return (
                <Fragment key={indexRow}>
                  <DraggableTableRow
                    key={indexRow}
                    id={article.id ?? 0}
                    moveRow={moveRow}
                    index={article.order}
                    className={article.text ? classes.tableRowArticleWithText : ''}
                  >
                    <TableCell
                      className={classes.tableCellIcon}
                      style={{ whiteSpace: 'nowrap', paddingRight: '0px' }}
                    >
                      <OArticleIcon
                        article={article}
                        indexRow={indexRow}
                        selectMatchDialogHelper={selectMatchDialogHelper}
                        selectPlayerDialogHelper={selectPlayerDialogHelper}
                      />
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', textAlign: 'left' }}
                      className={classes.tableCell}
                    >
                      {article.article ? article.article.category : ''}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', textAlign: 'left' }}
                      className={classes.tableCell}
                    >
                      {renderAsArticle({
                        article: article.article,
                        oarticle: article,
                        translate,
                        onExchange: (article) => onExchange(article, indexRow),
                        maxSize: 30,
                      })}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                      {article.article ? article.article.number : ''}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }} className={classes.tableCell}>
                      <HelperTextField
                        type="currency_short"
                        style={{ minWidth: '80px' }}
                        readOnly={true}
                        className={classes.tableCellInput}
                        name={'articles[' + indexRow + '].pricePerItemInCents'}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }} className={classes.tableCell}>
                      <HelperTextField
                        type="number"
                        style={{ minWidth: '40px' }}
                        readOnly={locked}
                        value={article.amount}
                        onValueChange={(e) => changeAmount(indexRow, e)}
                        className={classes.tableCellInput}
                        name={'articles[' + indexRow + '].amount'}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }} className={classes.tableCell}>
                      <HelperTextField
                        type="currency_short"
                        readOnly={true}
                        style={{ minWidth: '80px' }}
                        onValueChange={(e) => changePrice(indexRow, e)}
                        className={classes.tableCellInput}
                        name={'articles[' + indexRow + '].priceInCents'}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }} className={classes.tableCell}>
                      <HelperTextField
                        type="currency_short"
                        readOnly={
                          locked || article.article?.isFixedPrice || article.article?.isPercentage
                        }
                        onValueChange={(e) => changeRebate(indexRow, e)}
                        className={classes.tableCellInput}
                        style={{ minWidth: '70px' }}
                        name={'articles[' + indexRow + '].rebateInCents'}
                        value={article.rebateInCents}
                      />
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'right', paddingRight: locked ? '0px' : '8px' }}
                      className={classes.tableCell}
                    >
                      <HelperTextField
                        type="currency_short"
                        readOnly={
                          locked || article.article?.isFixedPrice || article.article?.isPercentage
                        }
                        onValueChange={(e) => changeSum(indexRow, e)}
                        className={classes.tableCellInput}
                        name={'articles[' + indexRow + '].sumInCents'}
                        value={article.sumInCents}
                      />
                    </TableCell>
                    {locked ? null : (
                      <TableCell
                        className={classes.tableCellIcon}
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '35px',
                          paddingLeft: '5px',
                          paddingRight: '0px',
                        }}
                      >
                        <IconButton
                          className={classesGlobal.iconButtonNoPadding}
                          title={translate('Delete article')}
                          onClick={(e) => {
                            values.articles?.splice(indexRow, 1);
                            setFieldValue('articles', values.articles);
                            snackbar(translate(`Removed article from opportunity`) + '.', 'info');
                            updateTotalSum();
                          }}
                          size="large"
                        >
                          <Icon>delete_outline</Icon>
                        </IconButton>
                      </TableCell>
                    )}
                  </DraggableTableRow>
                  {article.text !== undefined && article.text !== null ? (
                    <TableRow className={classes.tableRowText}>
                      <TableCell></TableCell>
                      <TableCell
                        colSpan={8}
                        className={classes.tableCell}
                        style={{
                          paddingRight: locked ? '0px' : '8px',
                          paddingLeft: '13px',
                          paddingTop: '5px',
                        }}
                      >
                        <HelperTextField
                          className={classes.tableCellInput}
                          name={'articles[' + indexRow + '].text'}
                        />
                      </TableCell>
                      {locked ? null : <TableCell></TableCell>}
                    </TableRow>
                  ) : null}
                </Fragment>
              );
            })}
          </DndProvider>
        </TableBody>
        {!isNew && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} className={classes.tableCellIcon}>
                {!locked && (
                  <IconButton
                    className={classesGlobal.iconButtonNoPadding}
                    onClick={() => addArticles()}
                    size="large"
                  >
                    <Icon>add_circle</Icon>{' '}
                    <span
                      className={classes.tableCellInput}
                      style={{ fontSize: DEFAULT_ARTICLE_FONT_SIZE, paddingLeft: '25px' }}
                    >
                      {translate('Add article')}
                    </span>
                  </IconButton>
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.tableCell}>{sumAmount} &nbsp;&nbsp;&nbsp;</TableCell>
              <TableCell className={classes.tableCell}>
                <HelperCurrencyFormat
                  name="sumPrice"
                  type="currency_short"
                  value={sumPrice}
                  readOnly
                  className={classes.tableCellInput}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <HelperCurrencyFormat
                  name="sumRebate"
                  type="currency_short"
                  value={sumRebate}
                  readOnly
                  className={classes.tableCellInput}
                />
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ paddingRight: locked ? '0px' : '8px' }}
              >
                <HelperTextField
                  type="currency_short"
                  readOnly={locked}
                  onValueChange={(e) => changeTotalSum(e)}
                  className={classes.tableCellInput}
                  name={'priceInCents'}
                  value={values ? values.priceInCents : 0}
                />
              </TableCell>
              {locked ? null : (
                <TableCell
                  className={classes.tableCell}
                  style={{ whiteSpace: 'nowrap', maxWidth: '35px', paddingLeft: '5px' }}
                >
                  <IconButton
                    className={classesGlobal.iconButtonNoPadding}
                    title={translate('Refresh articles')}
                    onClick={() => refreshArticles()}
                    size="large"
                  >
                    <Icon>cached</Icon>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableFooter>
        )}
      </Table>
      <MatchSelectDialogComponent
        opportunity={values}
        dialogHelper={selectMatchDialogHelper}
        onAccept={(match: Match) => {
          onSelectMatch(selectMatchDialogHelper.getData()['indexRow'], match);
          selectMatchDialogHelper.setOpen(false);
        }}
        onCancel={() => {
          selectMatchDialogHelper.setOpen(false);
        }}
      />
      <PlayerSelectDialogComponent
        opportunity={values}
        dialogHelper={selectPlayerDialogHelper}
        onAccept={(player: Player) => {
          onSelectPlayer(selectPlayerDialogHelper.getData()['indexRow'], player);
          selectPlayerDialogHelper.setOpen(false);
        }}
        onCancel={() => {
          selectPlayerDialogHelper.setOpen(false);
        }}
      />
      <ArticleExchangeDialogComponent
        opportunity={values}
        dialogHelper={articleExchangeDialogHelper}
        onAccept={(article: Article) => {
          setFieldValue(`articles[${exchangeRow}].article`, article);
        }}
        onCancel={() => {
          setExchangeRow(null);
        }}
      />
      <OpportunityArticleAddComponent
        opportunity={values}
        open={openAddArticle || displayArticle ? true : false}
        displayArticle={displayArticle}
        onClose={() => {
          setDisplayArticle(undefined);
          setOpenAddArticle(false);
          updateTotalSum();
        }}
        onAddArticle={(article) => {
          if (!values.articles) values.articles = [];
          values.articles?.push({
            id: null,
            article,
            amount: 1,
            order: 0,
            //costCenter: article.costCenter,
            pricePerItemInCents: article.priceInCents,
            priceInCents: article.priceInCents,
            rebateInCents: 0,
            sumInCents: article.priceInCents,
          });
          snackbar(translate(`Added article to opportunity`) + '.', 'info');
        }}
        onDisplayArticleChanged={(article) => {
          setDisplayArticle(article);
        }}
      ></OpportunityArticleAddComponent>
    </>
  );
};

export default OpportunityArticleTable;
