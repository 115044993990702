/* External lib imports. */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
//import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
/* My Imports */
import { AppFC } from 'App';
import { checkStatus } from 'redux/actions/auth.actions';
import { authReducer } from 'redux/reducers/auth.reducer';
import { itemReducer } from 'redux/reducers/items.reducer';
import { cartReducer } from 'redux/reducers/cart.reducer';
import { themeReducer } from 'styling/redux/reducers';
import { reportReducer } from 'redux/reducers/report.reducer';
import 'themes/index.scss';

/* React Store */
/** Combined Reducers */
export const allReducers = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  items: itemReducer,
  reports: reportReducer,
  //settings: settingsReducer,
  cart: cartReducer,
});

//const store = createStore(allReducers, composeWithDevTools(applyMiddleware(thunk)));
const store = createStore(allReducers, applyMiddleware(thunk));
store.dispatch(checkStatus() as unknown as AnyAction);
//store.dispatch(fetchThemes({}) as unknown as AnyAction);

//updateThemeForStyle(localStorage.getItem('theme') ? (localStorage.getItem('theme') as string) : 'Default');

/**
 * Render to dom
 */
ReactDOM.render(
  <Provider store={store}>
    <AppFC />
  </Provider>,
  document.getElementById('root')
);
