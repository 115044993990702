import { Tag } from '@earnenterprise/asc-models';
import { Alert, Chip, Grid, Switch, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import useReduxState from 'components/hooks/useReduxState';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTags } from 'redux/actions/items.actions';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';

interface Props {
  type?: 'Agreement' | 'Account' | 'Opportunity' | 'Article' | 'Package' | 'Player';
  /** A set of tags to use instead of those fetched from DB */
  customTags?: (Tag | Tag[])[];
  defaultSearch?: string;
  defaultTags?: string[];
  defaultMatch?: 'Any' | 'All';
  disableSearch?: boolean;
  disableTagMatch?: boolean;
  message?: string;
  onSearch?: (search: any) => void;
  onChangeTags: (tags: string[], match: 'Any' | 'All') => void;
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const isTagArrayArray = (tags: (Tag | Tag[])[]): tags is Tag[][] => Array.isArray(tags[0]);

/**
 * Displays a list of selectable tags.
 * This function ensures the tags are of correct type.
 */
const SearchTags = (props: {
  /** For Two-dimensional arrays, a Tags component is returned for each subarray. */
  tags: (Tag | Tag[])[];
  selectedTags: string[];
  onToggle: (tag: string) => void;
  type: Props['type'];
  /** If true, displays all selected thats tha's missing from the tags array. */
  listDeleted?: boolean;
}) => {
  const { tags, selectedTags, type, listDeleted = false } = props;
  // Recurively create a Tags component for earch Tag-array.
  if (!listDeleted && isTagArrayArray(tags)) {
    return (
      <>
        {tags.map((value: Tag[], index: number) => (
          <SearchTags {...props} tags={value} key={index} />
        ))}
      </>
    );
  }

  const classes = globalStyles();
  // Flat and filter to only use tags of correct type.
  const flatTags = tags.flat().filter((tag) => tag.type === type);
  // Filter the tags to only use missing ones from selected.
  const filteredTags = listDeleted
    ? selectedTags.filter((tag) => !flatTags.some((value) => value.name === tag))
    : flatTags;

  return (
    <Grid item xs={12}>
      {filteredTags.map((value: Tag | string, index: number) => {
        const text = typeof value === 'string' ? value : value.name;

        return (
          <Chip
            key={index}
            className={classes.chip}
            style={{ cursor: 'pointer', marginBottom: '5px' }}
            onClick={() => props.onToggle(text)}
            label={text}
            color={listDeleted ? 'secondary' : 'primary'}
            variant={selectedTags.includes(text) ? 'filled' : 'outlined'}
          />
        );
      })}
    </Grid>
  );
};

export const SearchBar = ({
  onChangeTags,
  onSearch,
  type,
  disableSearch = false,
  disableTagMatch = false,
  defaultSearch,
  defaultTags,
  defaultMatch,
  message,
  customTags,
}: Props) => {
  const { translate } = useContext(LanguageContext);
  const [timeout, setCachedTimeout] = useState<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState<string[]>(defaultTags ?? []);
  const [match, setMatch] = useState<'Any' | 'All'>(defaultMatch ?? 'All');
  const [tags, setTags] = useState<(Tag | Tag[])[]>(customTags ?? []);
  const { tags: fetchedTags, workStrings } = useReduxState({ fetched: ['tags'] });

  useEffect(() => {
    if (onChangeTags) onChangeTags(selectedTags, match);
  }, [onChangeTags, selectedTags, match]);

  useEffect(() => {
    if (fetchedTags !== undefined) {
      // Prioritize using custom tags.
      setTags(customTags ?? fetchedTags);
    } else if (customTags === undefined) {
      dispatch(fetchTags({ workStrings }));
    }
  }, [fetchedTags, customTags, workStrings, dispatch]);

  const onKeyDown = (e: any) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setCachedTimeout(
      setTimeout(() => {
        setCachedTimeout(undefined);
        if (onSearch) onSearch((e.target as any).value);
      }, 500)
    );
  };

  const toggleSelectedTag = (tag: string) => {
    const index = selectedTags.findIndex((a) => a === tag);
    if (index >= 0) {
      selectedTags.splice(index, 1);
    } else {
      selectedTags.push(tag);
    }
    setSelectedTags(Object.assign([], selectedTags));
  };

  const toggleMatch = () => {
    match === 'All' ? setMatch('Any') : setMatch('All');
    if (onChangeTags) onChangeTags(selectedTags, match);
  };

  return (
    <>
      {type && (
        <Grid item xs={12}>
          <Grid container spacing={1} style={{}}>
            {!disableTagMatch && tags && selectedTags.length > 1 && (
              <>
                <Grid item style={{ paddingTop: '0.5rem' }}>
                  {translate('Any')}
                </Grid>
                <Grid item style={{ paddingTop: '0.6rem' }}>
                  <AntSwitch
                    checked={match === 'All'}
                    onChange={() => toggleMatch()}
                    name="Match"
                  />
                </Grid>
                <Grid item style={{ paddingTop: '0.5rem', paddingRight: '1.5rem' }}>
                  {translate('All')}
                </Grid>
              </>
            )}
            {tags && (
              <SearchTags
                tags={tags}
                selectedTags={selectedTags}
                onToggle={toggleSelectedTag}
                type={type}
              />
            )}
            {tags && (
              <SearchTags
                tags={tags}
                selectedTags={selectedTags}
                onToggle={toggleSelectedTag}
                type={type}
                listDeleted={true}
              />
            )}
          </Grid>
        </Grid>
      )}
      {!disableSearch && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '10px' }}
        >
          <TextField
            fullWidth
            variant="standard"
            autoFocus={true}
            id="standard-error-helper-text"
            label={translate('Search')}
            defaultValue={defaultSearch}
            onKeyUp={onKeyDown}
          />
        </Grid>
      )}
      {message && (
        <Grid item xs={12}>
          <Alert severity="warning" style={{ marginBottom: '10px' }}>
            <Typography variant="body2">{message}</Typography>
          </Alert>
        </Grid>
      )}
    </>
  );
};

export default SearchBar;
