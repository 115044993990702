import React from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Icon,
} from '@mui/material';

interface Props {
  header: string;
  subheader?: string;
  defaultExpanded: boolean;
  children?: React.ReactNode;
}

/**
 *
 * @param param0 Props
 */
export const AccordionComponent: React.FC<Props> = ({
  defaultExpanded = false,
  header,
  subheader,
  children,
  ...props
}: Props) => {
  return (
    <Grid item xs={12}>
      <Accordion
        defaultExpanded={defaultExpanded}
        aria-controls={`panel-content-${header}`}
        id={`panel-header-${header}`}
      >
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography>{header}</Typography>
          {subheader && <Typography variant="caption">&nbsp;&nbsp;({subheader})</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            {children}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
