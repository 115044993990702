/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */

// TODO: Fix the eslint-disable statements above

import React, { createRef, useState, useEffect } from 'react';
import { Coordinate, useImageHelper } from 'components/hooks/useImageHelper';

interface Props {
  src: string;
  type?: string;
  sales?: any[];
  bookings?: any[];
  from?: Date | string;
  to?: Date | string | null;
  draggable?: boolean;
  isSale?: boolean;
  id?: string | number;
  name?: string;
  imageCoords: Coordinate[] | null;
  otherImageCoords?:
    | {
        id?: string | number;
        name: string;
        imageCoordsType: string;
        imageCoords: Coordinate[];
        needScale?: boolean;
      }[]
    | null;
  onChange?: (positions: Coordinate[] | null) => void;
  onClick?: (event?: {
    id?: string | number;
    name: string;
    imageCoordsType: string;
    imageCoords: Coordinate[] | null;
  }) => void;
}

export const ImageComponent: React.FC<Props> = (props) => {
  const src = props.src;
  const imageRef = createRef<HTMLImageElement>();
  const canvasRef = createRef<HTMLCanvasElement>();
  const [imageData, setImageData] = useState<ImageData>();
  const [offset, setOffset] = useState([0, 0]);
  const [tooltip, setTooltip] = useState<{
    id?: string | number;
    text: string;
    position: Coordinate;
  } | null>();
  const imageHelper = useImageHelper();
  const cornerRadius: [number, number] = [10, 0];
  const cornerRadiusSmall: [number, number] = [3, 0];
  const cornerRadiusMedium: [number, number] = [4, 0];

  const [pos, setPos] = useState<Coordinate[] | null>(Object.assign([], props.imageCoords));
  const [otherPos, setOtherPos] = useState<
    | {
        id?: string | number;
        name: string;
        imageCoordsType: string;
        imageCoords: Coordinate[] | null;
        needScale?: boolean;
        sales?: any[];
        bookings?: any[];
      }[]
    | null
    | undefined
  >(props.otherImageCoords);
  let [imageScale, setImageScale] = useState<[number, number]>([0, 0]);
  const [overPoint, setOverPoint] = useState(-1);
  const [selectedPoint, setSelectedPoint] = useState(-1);
  const [selectedPointSnap, setSelectedPointSnap] = useState<boolean>(false);
  let [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    if (props.isSale) setPos(props.imageCoords);
  }, [props.imageCoords, props.isSale]);

  if (pos && otherPos) {
    for (let i = 0; i < otherPos.length; i++) {
      if (otherPos[i].imageCoords) {
        if (otherPos[i].imageCoords!.length === pos.length) {
          let allSame = true;
          for (let j = 0; j < otherPos[i].imageCoords!.length; j++) {
            if (
              otherPos[i].imageCoords![j][0] !== pos[j][0] ||
              otherPos[i].imageCoords![j][1] !== pos[j][1]
            ) {
              allSame = false;
              break;
            }
          }
          if (allSame) {
            otherPos.splice(i, 1);
            setOtherPos(otherPos);
            break;
          }
        }
      }
    }
  }

  /**
   * Moving mouse
   * @param e
   */
  const onMouseMove = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
    if (!canvasRef || !canvasRef.current) return;
    const xReal = (e.nativeEvent as MouseEvent).offsetX;
    const yReal = (e.nativeEvent as MouseEvent).offsetY;
    const mMove = (e.nativeEvent as MouseEvent).movementX;
    const mPos: [number, number] = [xReal, yReal];
    const tmpPos = pos;

    // No point selected, start checking
    if (selectedPoint === -1 && selectedAll === false) {
      if (canvasRef && canvasRef.current) {
        // Context and postitions, without these exit.
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx || !tmpPos) return;

        // Over the four corners? Use mouse position and a cirecle with 15 radius
        if (props.draggable) {
          for (let index = 0; index < tmpPos.length; index++) {
            const p = tmpPos[index];
            if (props.type === 'rect' || index === 0) {
              if (imageHelper.isPointInCircle(mPos, [p, cornerRadius])) {
                document.body.style.cursor = 'nesw-resize';
                setOverPoint(index);
                //setPos(tmpPos);
                //updateImage();
                return;
              }
            }
          }
        }

        // No corner found, check if we're over the area.
        if (overPoint === -1) {
          let mouseOver = false;
          if (props.type === 'circle') mouseOver = imageHelper.isPointInCircle(mPos, tmpPos);
          else if (props.type === 'rect') mouseOver = imageHelper.isPointInArea(mPos, tmpPos);

          // If mouse is over set offset of top left corner to the mouse position.
          if (mouseOver) {
            setOffset([xReal - tmpPos[0][0], yReal - tmpPos[0][1]]);
            if (props.draggable) {
              document.body.style.cursor = 'move';
              setTooltip(null);
              //updateImage();
            } else {
              if (props.name) {
                let tmpTooltip = props.name;
                if (
                  (props.sales && props.sales.length > 0) ||
                  (props.bookings && props.bookings.length > 0)
                )
                  tmpTooltip +=
                    '<br>' + imageHelper.getExtraTooltipText(props.sales, props.bookings);
                setTooltip({ id: props.id, text: tmpTooltip, position: [xReal - 10, yReal + 10] });
              }
              document.body.style.cursor = 'auto';
              setOverPoint(-1);
              return;
            }
            setPos(tmpPos);
            //updateImage();
            return;
          }

          // Check mouse over other points?
          if (otherPos) {
            let showTooltip = false;
            for (let index = 0; index < otherPos.length; index++) {
              let opos = otherPos[index];
              if (opos.imageCoordsType === 'circle' && opos.imageCoords) {
                if (imageHelper.isPointInCircle(mPos, opos.imageCoords)) {
                  showTooltip = true;
                  let tmpTooltip = opos.name;
                  if (
                    (opos.sales && opos.sales.length > 0) ||
                    (opos.bookings && opos.bookings.length > 0)
                  )
                    tmpTooltip +=
                      '<br>' + imageHelper.getExtraTooltipText(opos.sales, opos.bookings);
                  setTooltip({ id: opos.id, text: tmpTooltip, position: [xReal - 10, yReal + 10] });
                  break;
                }
              } else if (opos.imageCoordsType === 'rect' && opos.imageCoords) {
                if (imageHelper.isPointInArea(mPos, opos.imageCoords)) {
                  showTooltip = true;
                  let tmpTooltip = opos.name;
                  if (
                    (opos.sales && opos.sales.length > 0) ||
                    (opos.bookings && opos.bookings.length > 0)
                  )
                    tmpTooltip +=
                      '<br>' + imageHelper.getExtraTooltipText(opos.sales, opos.bookings);
                  setTooltip({ id: opos.id, text: tmpTooltip, position: [xReal - 10, yReal + 10] });
                  break;
                }
              }
            }

            if (showTooltip) {
              document.body.style.cursor = 'auto';
              setOverPoint(-1);
              return;
            }
          }
        }

        // Not over area or corners, reset cursor and point
        document.body.style.cursor = 'auto';
        setTooltip(null);
        setOverPoint(-1);
      }
    } else if (selectedPoint >= 0) {
      if (props.type === 'circle') {
        if (tmpPos) {
          tmpPos[1][0] += mMove;
          if (tmpPos[1][0] < 30) tmpPos[1][0] = 30;
        }
      } else if (props.type === 'rect') {
        // Moving single point
        if (tmpPos) tmpPos[selectedPoint] = [xReal, yReal];
      }

      // Update state and redraw
      setPos(tmpPos);
      updateImage(selectedPoint, selectedAll);
    } else if (selectedAll) {
      // We're moving everything

      if (tmpPos) {
        if (props.type === 'circle') {
          tmpPos[0] = [xReal - offset[0], yReal - offset[1]];
        } else if (props.type === 'rect') {
          // Calculate offset of all points relative to TL corner.
          let offsets: Coordinate[] = [];
          for (let index = 1; index < tmpPos.length; index++)
            offsets[index - 1] = [tmpPos[0][0] - tmpPos[index][0], tmpPos[0][1] - tmpPos[index][1]];

          //pos.positions[0] = [x * imageScale[0] - offset[0] / imageScale[0], y * imageScale[1] - offset[1] / imageScale[1]];
          tmpPos[0] = [xReal - offset[0], yReal - offset[1]];

          // Move other points with TL offset.
          for (let index = 1; index < tmpPos.length; index++)
            tmpPos[index] = [
              tmpPos[0][0] - offsets[index - 1][0],
              tmpPos[0][1] - offsets[index - 1][1],
            ];
        }
      }

      // Update state and redraw
      setPos(tmpPos);
      updateImage(selectedPoint, selectedAll);
    }
  };

  /**
   * Grab stuff when we're over a corner or the area.
   * @param e
   */
  const onMouseDown = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
    console.log({ ctrlKey: (e as any).ctrlKey });

    if (overPoint >= 0) {
      setSelectedPoint(overPoint);
      setSelectedPointSnap(!(e as any).ctrlKey);
    } else if (document.body.style.cursor === 'move') setSelectedAll(true);
  };

  /**
   * Release stuff and make sure to redraw.
   * @param e
   */
  const onMouseUp = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
    if (selectedPointSnap && selectedPoint >= 0) {
      // We should snap?
      console.log('Snapping');
      const tmpPos = pos;

      // Only snap rectangle
      if (props.type === 'rect' && tmpPos) {
        // Find nearest point
        let nearX = tmpPos[selectedPoint][0];
        let nearY = tmpPos[selectedPoint][1];

        props.otherImageCoords?.forEach((otherImageCoord) => {
          if (otherImageCoord.imageCoordsType === 'rect' && otherImageCoord.imageCoords) {
            otherImageCoord.imageCoords.forEach((coord) => {
              // Snap if we're close enough (20px)
              if (
                nearX > coord[0] - 10 &&
                nearX < coord[0] + 10 &&
                nearY > coord[1] - 10 &&
                nearY < coord[1] + 10
              ) {
                nearX = coord[0];
                nearY = coord[1];
              }
            });
          }
        });

        // Moving single point
        tmpPos[selectedPoint] = [nearX, nearY];
      }

      // Update state and redraw
      setPos(tmpPos);
      updateImage(selectedPoint, selectedAll);
    }
    //selectedPoint = -1;
    selectedAll = false;
    //setSelectedPoint(selectedPoint);
    setSelectedPoint(-1);
    //setSelectedAll(selectedAll);
    setSelectedAll(false);
    updateImage(-1, false);
  };

  /**
   * Canvas clicked, we should update points
   * @param e
   */
  const onClickCanvas = (e: React.SyntheticEvent<HTMLCanvasElement>) => {
    // We're over a corner or we're over the area, return.
    if (overPoint !== -1 || selectedPoint !== -1) return;
    if (document.body.style.cursor === 'move') return;

    if (tooltip && props.onClick) {
      if (otherPos) {
        props.onClick(
          otherPos.find((item) => item.name === tooltip.text || item.id === tooltip.id)
        );
      }
    }
  };

  /**
   * Update image with new positions
   * @param sp selectedPoint
   * @param sa selectedAll
   * @param runCallback [default = true] should we run the callback?
   * @param canvas [optional] the canvas to update, if not provided, we'll use the current canvas
   * @returns
   */
  const updateImage = (sp: number, sa: boolean, runCallback = true, canvas?: any) => {
    if ((!canvasRef || !canvasRef.current) && !canvas) return;
    if (canvas) imageHelper.setCanvas(canvas, null);

    // Get context and set background image.
    const context = imageHelper.getContext();
    if (!context) return;
    if (imageData) context.putImageData(imageData, 0, 0);
    if (!pos) return;

    // Default settings.
    context.lineWidth = 1;
    context.strokeStyle = 'rgba(0, 0, 0, 1)';
    if (!props.isSale) context.fillStyle = 'rgba(200, 255, 255, 0.6)';
    else {
      const status = imageHelper.getStatus(
        {
          name: props.name,
          id: props.id,
          imageCoords: props.imageCoords,
          imageCoordsType: props.type,
          sales: props.sales,
          bookings: props.bookings,
        },
        props.from,
        props.to
      );
      if (status === 'free') context.fillStyle = 'rgba(100, 200, 100, 0.6)';
      if (status === 'booked') context.fillStyle = 'rgba(200, 200, 000, 0.6)';
      if (status === 'sold') context.fillStyle = 'rgba(200, 100, 100, 0.6)';
    }

    // Draw corners
    if (props.type === 'circle') {
      imageHelper.drawCircle(pos);
      context.strokeStyle = 'rgba(0, 0, 0, 1)';
      context.fillStyle = 'rgba(0, 0, 0, 1)';
      imageHelper.drawCircle([pos[0], [5, 0]]);
    } else if (props.type === 'rect') {
      imageHelper.drawArea(pos);

      // Drag handles
      context.strokeStyle = 'rgba(255, 255, 0, 1)';
      context.fillStyle = 'rgba(255, 0, 0, 1)';
      if (props.isSale) pos.forEach((p) => imageHelper.drawCircle([p, cornerRadiusMedium]));
      else pos.forEach((p) => imageHelper.drawCircle([p, cornerRadiusSmall]));
    }

    // Draw other
    if (otherPos) {
      otherPos.forEach((opos) => {
        context.strokeStyle = 'rgba(0, 0, 0, 1)';
        if (!props.isSale) {
          context.fillStyle = 'rgba(200, 100, 255, 0.6)';
        } else {
          const status = imageHelper.getStatus(opos, props.from, props.to);
          if (status === 'free') context.fillStyle = 'rgba(100, 200, 100, 0.6)';
          if (status === 'booked') context.fillStyle = 'rgba(200, 200, 000, 0.6)';
          if (status === 'sold') context.fillStyle = 'rgba(200, 100, 100, 0.6)';
        }
        if (opos.imageCoordsType === 'circle' && opos.imageCoords)
          imageHelper.drawCircle(opos.imageCoords);
        else if (opos.imageCoordsType === 'rect' && opos.imageCoords)
          imageHelper.drawArea(opos.imageCoords);
      });
    }

    // Run callbacks if we're not moving
    if (sp === -1 && !sa && runCallback && props.onChange) {
      props.onChange(imageHelper.scaleDown(pos, imageScale));
    }
  };

  /**
   * After img loaded image.
   * @param e
   */
  const onLoadImage = (e: React.SyntheticEvent<any>) => {
    if (!canvasRef || !canvasRef.current) return;
    if (!imageRef || !imageRef.current) return;
    imageRef.current.style.display = 'inline';

    imageHelper.setCanvas(canvasRef.current, imageRef);
    const canvas = imageHelper.getCanvas();

    // Make the canvas Ref the size of the image.
    if (!canvas) return;
    canvas.width = imageRef.current.width;
    canvas.height = imageRef.current.height;

    // Get Context
    const context = imageHelper.getContext();
    if (!context) return;

    // Set the image size
    imageScale = [
      canvas.width / imageRef.current.naturalWidth,
      canvas.height / imageRef.current.naturalWidth,
    ];
    setImageScale(imageScale);
    imageRef.current.style.display = 'none';

    // Scale all other first.
    if (otherPos) {
      otherPos.forEach((opos) => {
        if (opos.needScale === undefined || opos.needScale === true) {
          if (opos.imageCoords) {
            opos.imageCoords = imageHelper.scaleUp(opos.imageCoords, imageScale);
            opos.needScale = false;
          }
        }
      });
      setOtherPos(otherPos);
    }

    // No positions? Make or own with 10px to spare to the sides.
    let tmpPos = pos;
    if (!tmpPos || (tmpPos.length === 0 && props.type !== '')) {
      if (!props.isSale && props.type !== '') {
        tmpPos = [
          [10, 10],
          [10, 40],
          [40, 40],
          [40, 10],
          //[10, canvas.height / 10],
          //[canvas.width / 10, canvas.height / 10],
          //[canvas.width / 10, 10]
        ];
      } else {
        tmpPos = [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ];
      }
    } else {
      // Scale the cordinates to the scaled image.
      tmpPos = imageHelper.scaleUp(tmpPos, imageScale);
    }
    setPos(tmpPos);

    // Draw the image, and save the image in state. Then update with pos.
    context.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
    setImageData(context.getImageData(0, 0, canvas.width, canvas.height));
    updateImage(selectedPoint, selectedAll);

    //if (canvasRef.current.parentElement) canvasRef.current.parentElement.addEventListener('onresize', onResize);
    //if (canvasRef.current.parentElement) window.addEventListener('beforeunload', onBeforeUnload);
  };

  return (
    <>
      <div
        style={{
          width: '99%',
          textAlign: 'center',
          justifyContent: 'center',
          left: '3px',
          top: '3px',
          position: 'relative',
        }}
        data-tip={tooltip}
      >
        <canvas
          ref={canvasRef}
          style={{ border: '1px solid #fff', transition: 'all 0.5s' }}
          onClick={onClickCanvas}
          onMouseUp={(e) => {
            if (props.draggable) onMouseUp(e);
          }}
          onMouseDown={(e) => {
            if (props.draggable) onMouseDown(e);
          }}
          onMouseMove={(e) => {
            onMouseMove(e);
          }}
        ></canvas>
        <img
          ref={imageRef}
          alt=""
          crossOrigin={'anonymous'}
          src={src}
          style={{ width: '100%' }}
          onLoad={onLoadImage}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          display: tooltip ? 'inline' : 'none',
          top: tooltip ? tooltip.position[1] + 300 + 'px' : '0px',
          left: tooltip ? tooltip.position[0] + 200 + 'px' : '0px',
          zIndex: 999,
          border: '1px solid #000',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#000',
          textAlign: 'left',
          color: '#fff',
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: tooltip ? tooltip.text : '' }} />
      </div>
    </>
  );
};

export default ImageComponent;
