import { FormikHelpers } from 'formik';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { save } from 'models/helper.useItem';
import { updateItem } from 'redux/actions/items.actions';
import { GQLReturnType } from 'services/gql.service';
import { LanguageContext } from 'translation/languageContext';
import { ErrorContext } from 'error/error.context';
import { Models } from '@earnenterprise/asc-models';
import { Theme } from '@earnenterprise/asc-theme';

export interface Props {
  func: (
    id: string | number | null,
    //data: any,
    sanitizeObject: Record<string, unknown>,
    useRefreshToken?: boolean | undefined
  ) => Promise<GQLReturnType>;
  sanitizeObject: Record<string, unknown>;
  type:
    | 'account'
    | 'article'
    | 'agreement'
    | 'opportunity'
    | 'user'
    | 'group'
    | 'quote'
    | 'theme'
    | 'message'
    | 'match'
    | 'presentation'
    | 'setting'
    | 'package'
    | 'player'
    | 'reminder';
  disableRedirect?: boolean;
}

/**
 * Use Save hook
 * @param param0
 */
export const useSave = ({ func, sanitizeObject, type, disableRedirect }: Props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useContext(LanguageContext);
  const { snackbar } = useContext(ErrorContext);

  const saveItem = async (item: Models | Theme, helpers?: FormikHelpers<any>) => {
    const saveItem = Object.assign({}, item) as any;
    if (!saveItem.id) delete saveItem.id;

    const { data, isNew, error } = await save(saveItem, func, sanitizeObject, helpers);
    if (error) {
      snackbar(translate('Failed to save') + '!', 'error', error);
      return;
    }

    dispatch(updateItem({ [type]: data }, error));
    if (!error && isNew && disableRedirect !== true) {
      setTimeout(() => {
        history(`/${type}/` + data.id);
      }, 100);
    }
    snackbar(translate('Saved successfully') + '!', 'success');
  };

  return { saveItem };
};

export default useSave;
