import { ReduxState } from 'redux/state';

// Auth
export const meSelector = (s: ReduxState) => (s.auth ? s.auth.user : undefined);
export const onlineSelector = (s: ReduxState) => (s.auth ? s.auth.online : undefined);
export const rightSelector = (s: ReduxState) => (s.auth ? s.auth.rights : undefined);

// Single
export const workingSelector = (s: ReduxState) => (s.items ? s.items.working : undefined);
export const workCountSelector = (s: ReduxState) => (s.items ? s.items.workCount : undefined);
export const accountSelector = (s: ReduxState) => (s.items ? s.items.account : undefined);
export const articleSelector = (s: ReduxState) => (s.items ? s.items.article : undefined);
export const agreementSelector = (s: ReduxState) => (s.items ? s.items.agreement : undefined);
export const userSelector = (s: ReduxState) => (s.items ? s.items.user : undefined);
export const groupSelector = (s: ReduxState) => (s.items ? s.items.group : undefined);

export const opportunitySelector = (s: ReduxState) => (s.items ? s.items.opportunity : undefined);
export const quoteSelector = (s: ReduxState) => (s.items ? s.items.quote : undefined);
export const messageSelector = (s: ReduxState) => (s.items ? s.items.message : undefined);
export const matchSelector = (s: ReduxState) => (s.items ? s.items.match : undefined);
export const presentationSelector = (s: ReduxState) => (s.items ? s.items.presentation : undefined);
export const tagSelector = (s: ReduxState) => (s.items ? s.items.tag : undefined);
export const imageSelector = (s: ReduxState) => (s.items ? s.items.image : undefined);

// Multiple
export const accountsSelector = (s: ReduxState) =>
  s.items
    ? s.items.accountsTimeout && s.items.accountsTimeout < new Date().getTime()
      ? undefined
      : s.items.accounts
    : undefined;
export const themesSelector = (s: ReduxState) =>
  s.items
    ? s.items.themesTimeout && s.items.themesTimeout < new Date().getTime()
      ? undefined
      : s.items.themes
    : undefined;
export const matchesSelector = (s: ReduxState) =>
  s.items
    ? s.items.matchesTimeout && s.items.matchesTimeout < new Date().getTime()
      ? undefined
      : s.items.matches
    : undefined;
export const externalMatchesSelector = (s: ReduxState) =>
  s.items
    ? s.items.externalMatchesTimeout && s.items.externalMatchesTimeout < new Date().getTime()
      ? undefined
      : s.items.externalMatches
    : undefined;
export const quotesSelector = (s: ReduxState) =>
  s.items
    ? s.items.quotesTimeout && s.items.quotesTimeout < new Date().getTime()
      ? undefined
      : s.items.quotes
    : undefined;
export const opportunitiesSelector = (s: ReduxState) =>
  s.items
    ? s.items.opportunitiesTimeout && s.items.opportunitiesTimeout < new Date().getTime()
      ? undefined
      : s.items.opportunities
    : undefined;
export const usersSelector = (s: ReduxState) =>
  s.items
    ? s.items.usersTimeout && s.items.usersTimeout < new Date().getTime()
      ? undefined
      : s.items.users
    : undefined;
export const groupsSelector = (s: ReduxState) =>
  s.items
    ? s.items.groupsTimeout && s.items.groupsTimeout < new Date().getTime()
      ? undefined
      : s.items.groups
    : undefined;
export const agreementsSelector = (s: ReduxState) =>
  s.items
    ? s.items.agreementsTimeout && s.items.agreementsTimeout < new Date().getTime()
      ? undefined
      : s.items.agreements
    : undefined;
export const articlesSelector = (s: ReduxState) =>
  s.items
    ? s.items.articlesTimeout && s.items.articlesTimeout < new Date().getTime()
      ? undefined
      : s.items.articles
    : undefined;
export const messagesSelector = (s: ReduxState) =>
  s.items
    ? s.items.messagesTimeout && s.items.messagesTimeout < new Date().getTime()
      ? undefined
      : s.items.messages
    : undefined;
export const presentationsSelector = (s: ReduxState) =>
  s.items
    ? s.items.presentationsTimeout && s.items.presentationsTimeout < new Date().getTime()
      ? undefined
      : s.items.presentations
    : undefined;
export const tagsSelector = (s: ReduxState) =>
  s.items
    ? s.items.tagsTimeout && s.items.tagsTimeout < new Date().getTime()
      ? undefined
      : s.items.tags
    : undefined;
export const imagesSelector = (s: ReduxState) =>
  s.items
    ? s.items.imagesTimeout && s.items.imagesTimeout < new Date().getTime()
      ? undefined
      : s.items.images
    : undefined;

// Reports
export const reportOpportunitiesSelector = (s: ReduxState) =>
  s.reports ? s.reports.opportunities : undefined;
export const reportAccountsSelector = (s: ReduxState) =>
  s.reports ? s.reports.accounts : undefined;
export const reportArticlesSelector = (s: ReduxState) =>
  s.reports ? s.reports.articles : undefined;
export const reportInvoicesSelector = (s: ReduxState) =>
  s.reports ? s.reports.invoices : undefined;

// Cart
export const cartSelector = (s: ReduxState) => (s.cart ? s.cart.cart : undefined);
