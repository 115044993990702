import React, { Fragment } from 'react';
import { useState } from 'react';

const socketUrl = 'wss://' + document.location.host + '/asc-wss';
let ws: WebSocket | undefined = undefined;
let wsInterval: NodeJS.Timeout;
let wsEnabled: false; // SETTING: Enable this to enable webSockets

export const useWebsocket = () => {
  const [status, setStatus] = useState<'OPEN' | 'CLOSED'>('CLOSED');

  if (!wsEnabled) return { socket: undefined, status, undefined };

  if (ws === undefined) ws = new WebSocket(socketUrl);

  /**
   *
   * @param event
   */
  ws.onopen = (event: Event) => {
    setStatus('OPEN');
  };

  /**
   *
   * @param event
   */
  ws.onmessage = (event: MessageEvent) => {
    console.groupCollapsed('WebSocket: Incoming message');
    console.log({ event });
    console.log({ message: JSON.parse(event.data) });
    console.groupEnd();
  };

  /**
   *
   * @param event
   */
  ws.onclose = (event: CloseEvent) => {
    setStatus('CLOSED');
    wsInterval = setInterval(() => {
      ws = new WebSocket(socketUrl);
      ws.onopen = () => {
        setStatus('OPEN');
        clearTimeout(wsInterval);
      };
    }, 10000);
  };

  /**
   *
   * @param event
   */
  ws.onerror = (event: Event) => {
    console.error({ event });
  };

  /**
   *
   * @param message
   * @param severity
   */
  const sendMessage = (message: string, severity = 'info') => {
    if (ws) ws.send(JSON.stringify({ severity, message }));
  };

  return { socket: ws, status, sendMessage };
};

export const WebSocketService: React.FC = ({ children }) => {
  const { socket, status, sendMessage } = useWebsocket();

  // If socket is disabled only return children;
  if (!socket || !sendMessage) return <Fragment>{children}</Fragment>;

  socket.onmessage = (event: MessageEvent) => {
    console.groupCollapsed('WebSocket - Not Hook: Incoming message');
    console.log({ event });
    console.log({ message: JSON.parse(event.data) });
    console.groupEnd();
  };

  return (
    <Fragment>
      {children}
      <div
        style={{ position: 'absolute', bottom: '20px', right: '50px' }}
        onClick={() => sendMessage('TEST', 'debug')}
      >
        {status}
      </div>
    </Fragment>
  );
};
