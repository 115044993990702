import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Account } from '@earnenterprise/asc-models';
import { Agreement } from '@earnenterprise/asc-models';
import { Article } from '@earnenterprise/asc-models';
import { Opportunity } from '@earnenterprise/asc-models';
import { User } from '@earnenterprise/asc-models';
import { GQLReturnType } from 'services/gql.service';
import {
  gqlAccountService,
  gqlAgreementService,
  gqlArticleService,
  gqlOpportunityService,
  gqlUserService,
  gqlQuoteService,
  gqlThemeService,
  gqlMessageService,
  gqlMatchService,
  gqlPresentationService,
  gqlTagService,
  gqlImageService,
  gqlGroupService,
} from 'services/gql.services';
import { Quote } from '@earnenterprise/asc-models';
import { IServiceError } from '@earnenterprise/react-network';
import { Theme } from '@earnenterprise/asc-theme';
import { Message } from '@earnenterprise/asc-models';
import { Match } from '@earnenterprise/asc-models';
import { Presentation } from '@earnenterprise/asc-models';
import { Tag } from '@earnenterprise/asc-models';
import { Image } from '@earnenterprise/asc-models';
import { logoutAction } from 'redux/actions/auth.actions';
import { Group } from '@earnenterprise/asc-models';

export type ErrorItem = { id: string | number | null; message: string };
export type ModelItems = {
  users?: User[];
  usersTimeout?: number;
  groups?: Group[];
  groupsTimeout?: number;
  agreements?: Agreement[];
  agreementsTimeout?: number;
  accounts?: Account[];
  accountsTimeout?: number;
  articles?: Article[];
  articlesTimeout?: number;
  opportunities?: Opportunity[];
  opportunitiesTimeout?: number;
  quotes?: Quote[];
  quotesTimeout?: number;
  messages?: Message[];
  messagesTimeout?: number;
  matches?: Match[];
  matchesTimeout?: number;
  externalMatches?: Match[];
  externalMatchesTimeout?: number;
  themes?: Theme[];
  themesTimeout?: number;
  images?: Image[];
  imagesTimeout?: number;
  tags?: Tag[];
  tagsTimeout?: number;
  presentations?: Presentation[];
  presentationsTimeout?: number;
};
export type ResetItem = {
  userReset?: boolean;
  groupReset?: boolean;
  agreementReset?: boolean;
  accountReset?: boolean;
  articleReset?: boolean;
  opportunityReset?: boolean;
  quoteReset?: boolean;
  messageReset?: boolean;
  matchReset?: boolean;
  themeReset?: boolean;
  imageReset?: boolean;
  tagReset?: boolean;
  presentationReset?: boolean;
};

export type ModelItem = {
  user?: User;
  group?: Group;
  agreement?: Agreement;
  account?: Account;
  article?: Article;
  opportunity?: Opportunity;
  quote?: Quote;
  message?: Message;
  match?: Match;
  theme?: Theme;
  image?: Image;
  tag?: Tag;
  presentation?: Presentation;
  hints?: boolean;
};

export interface ItemStatusAction extends ModelItems, ModelItem {
  type: 'ITEM_STATUS' | 'ITEMS_STATUS' | 'TOGGLE_HINTS' | 'ITEM_ERROR' | 'ITEMS_ERROR';
  error: IServiceError | IServiceError[] | null;
}

export interface ItemStatusWorking {
  type: 'FETCH_WORKING';
  isWorking: boolean;
  count: number;
  workString: string;
}

export interface ItemStatusReset {
  type: 'ITEM_RESET';
  reset: ResetItem;
}

export interface ItemStatusError {
  type: 'CLEAR_ERRORS';
}

export type Action = ItemStatusAction | ItemStatusWorking | ItemStatusError | ItemStatusReset;

/**
 *
 * @param param0
 */
export const updateItem = (
  item: ModelItem,
  error: IServiceError | IServiceError[] | null
): ItemStatusAction => {
  return { type: 'ITEM_STATUS', ...item, error };
};

export const resetItem = (
  reset: ResetItem,
  error: IServiceError | IServiceError[] | null
): ItemStatusReset => {
  return { type: 'ITEM_RESET', reset };
};

/**
 *
 * @param param0
 */
export const errorItem = (
  item: ModelItem,
  error: IServiceError | IServiceError[] | null
): ItemStatusAction => {
  return { type: 'ITEM_ERROR', ...item, error };
};

/**
 *
 * @param param0
 */
export const updateItems = (
  items: ModelItems,
  error: IServiceError | IServiceError[] | null
): ItemStatusAction => {
  return { type: 'ITEMS_STATUS', ...items, error };
};

/**
 *
 * @param isWorking
 */
export const statusWorking = (isWorking: boolean, workString: string): ItemStatusWorking => {
  return { type: 'FETCH_WORKING', isWorking, count: isWorking ? 1 : -1, workString };
};

/**
 *
 * @param isWorking
 */
export const toggleHints = (
  hints: boolean,
  error: IServiceError | IServiceError[] | null
): ItemStatusAction => {
  return { type: 'TOGGLE_HINTS', hints, error };
};

/**
 *
 * @param isWorking
 */
export const clearErrors = (): ItemStatusError => {
  return { type: 'CLEAR_ERRORS' };
};

/**
 *
 * @param param0
 */
export const fetchUsers = ({
  order = 'firstname',
  limit = 50,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'users') === -1)) {
      dispatch(statusWorking(true, 'users'));

      const data = await gqlUserService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ users: data.data }, data.error));

      dispatch(statusWorking(false, 'users'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchGroups = ({
  order = 'name',
  limit = 50,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'groups') === -1)) {
      dispatch(statusWorking(true, 'groups'));

      const data = await gqlGroupService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ groups: data.data }, data.error));

      dispatch(statusWorking(false, 'groups'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchImages = ({
  order = 'display_name, name',
  limit,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'images') === -1)) {
      dispatch(statusWorking(true, 'images'));

      const data = await gqlImageService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ images: data.data }, data.error));

      dispatch(statusWorking(false, 'images'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchMessages = ({
  order = 'created',
  limit = 50,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'messages') === -1)) {
      dispatch(statusWorking(true, 'messages'));

      const data = await gqlMessageService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ messages: data.data }, data.error));

      dispatch(statusWorking(false, 'messages'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchMatches = ({
  order = 'date',
  limit = 50,
  offset,
  search,
  fields,
  from,
  to,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  from?: Date;
  to?: Date;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'matches') === -1)) {
      dispatch(statusWorking(true, 'matches'));

      const data = await gqlMatchService.list({ order, limit, offset, search, fields, from, to });
      dispatch(updateItems({ matches: data.data }, data.error));

      dispatch(statusWorking(false, 'matches'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchExternalMatches = ({
  order = 'date',
  limit = 50,
  offset,
  search,
  fields,
  from,
  to,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  from?: Date;
  to?: Date;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (
      !workStrings ||
      (workStrings && workStrings.findIndex((v) => v === 'externalMatches') === -1)
    ) {
      dispatch(statusWorking(true, 'externalMatches'));

      const data = await (gqlMatchService as any).externalList({ order, from, to });
      dispatch(updateItems({ externalMatches: data.data }, data.error));

      dispatch(statusWorking(false, 'externalMatches'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchPresentations = ({
  order = 'name',
  limit = 50,
  offset,
  search,
  fields,
  from,
  to,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  from?: Date;
  to?: Date;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (
      !workStrings ||
      (workStrings && workStrings.findIndex((v) => v === 'presentations') === -1)
    ) {
      dispatch(statusWorking(true, 'presentations'));

      const data = await gqlPresentationService.list({
        order,
        limit,
        offset,
        search,
        fields,
        from,
        to,
      });
      dispatch(updateItems({ presentations: data.data }, data.error));

      dispatch(statusWorking(false, 'presentations'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchAccounts = ({
  order = 'name',
  limit = 50,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'accounts') === -1)) {
      dispatch(statusWorking(true, 'accounts'));

      const data = await gqlAccountService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ accounts: data.data }, data.error));

      dispatch(statusWorking(false, 'accounts'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchTags = ({
  order = 'name',
  limit,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'tags') === -1)) {
      dispatch(statusWorking(true, 'tags'));

      const data = await gqlTagService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ tags: data.data }, data.error));

      dispatch(statusWorking(false, 'tags'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchArticles = ({
  order = 'category, name',
  limit = 500,
  offset,
  search = '0',
  fields = ['inactive'],
  tags,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  tags?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'articles') === -1)) {
      dispatch(statusWorking(true, 'articles'));

      const data = await gqlArticleService.list({ order, limit, offset, search, fields, tags });
      dispatch(updateItems({ articles: data.data }, data.error));

      dispatch(statusWorking(false, 'articles'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchQuotes = ({
  order = '-created',
  limit = 500,
  offset,
  search,
  fields,
  opportunityId,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  opportunityId?: string | number;
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'quotes') === -1)) {
      dispatch(statusWorking(true, 'quotes'));

      const data = await gqlQuoteService.list({
        order,
        limit,
        offset,
        search,
        opportunityId,
        fields,
      });
      dispatch(updateItems({ quotes: data.data }, data.error));

      dispatch(statusWorking(false, 'quotes'));
    }
  };
};

/**
 *
 * @param param0
 */
export const fetchThemes = ({
  order = '-created',
  limit = 500,
  offset,
  search,
  fields,
  workStrings,
}: {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
  fields?: string[];
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === 'themes') === -1)) {
      dispatch(statusWorking(true, 'themes'));

      const data = await gqlThemeService.list({ order, limit, offset, search, fields });
      dispatch(updateItems({ themes: data.data }, data.error));

      dispatch(statusWorking(false, 'themes'));
    }
  };
};

/**
 *
 */
export const fetchItem = ({
  agreementId,
  userId,
  accountId,
  articleId,
  opportunityId,
  quoteId,
  groupId,
  messageId,
  matchId,
  tagId,
  presentationId,
  imageId,
  themeName,
  workStrings,
}: {
  agreementId?: string | number;
  userId?: string | number;
  accountId?: string | number;
  articleId?: string | number;
  opportunityId?: string | number;
  quoteId?: string | number;
  groupId?: string | number;
  messageId?: string | number;
  matchId?: string | number;
  presentationId?: string | number;
  tagId?: string | number;
  imageId?: string | number;
  themeName?: string | number;
  workStrings?: string[];
}): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    //const workStrings = useSelector((s: ReduxState) => (s.items && s.items.workStrings ? s.items.workStrings : []));

    // Dispatch working
    let workString = '';
    if (agreementId) workString = 'agreement:' + agreementId;
    if (userId) workString = 'user:' + userId;
    if (groupId) workString = 'group:' + groupId;
    if (accountId) workString = 'account:' + accountId;
    if (articleId) workString = 'article:' + articleId;
    if (opportunityId) workString = 'opportunity:' + opportunityId;
    if (quoteId) workString = 'quote:' + quoteId;
    if (messageId) workString = 'message:' + messageId;
    if (matchId) workString = 'match:' + matchId;
    if (tagId) workString = 'tag:' + tagId;
    if (imageId) workString = 'image:' + imageId;
    if (presentationId) workString = 'presentation:' + presentationId;
    if (themeName) workString = 'theme:' + themeName;

    if (!workStrings || (workStrings && workStrings.findIndex((v) => v === workString) === -1)) {
      dispatch(statusWorking(true, workString));

      // Generate op
      let op: Promise<GQLReturnType> | null = null;
      if (agreementId) op = gqlAgreementService.get(agreementId);
      if (userId) op = gqlUserService.get(userId);
      if (groupId) op = gqlGroupService.get(groupId);
      if (accountId) op = gqlAccountService.get(accountId);
      if (articleId) op = gqlArticleService.get(articleId);
      if (opportunityId) op = gqlOpportunityService.get(opportunityId);
      if (quoteId) op = gqlQuoteService.get(quoteId);
      if (messageId) op = gqlMessageService.get(messageId);
      if (matchId) op = gqlMatchService.get(matchId);
      if (tagId) op = gqlTagService.get(tagId);
      if (imageId) op = gqlImageService.get(imageId);
      if (presentationId) op = gqlPresentationService.get(presentationId);
      if (themeName) op = gqlThemeService.get(themeName);

      if (!op) return;

      // Get op result
      const data = await op;
      if (data) {
        if (data.error) {
          console.groupCollapsed(`= Working Error ${workString}`);
          console.log(data.error);
          console.groupEnd();
          let error: IServiceError;
          if (Array.isArray(data.error)) error = data.error[0];
          else error = data.error;

          if (error.message === 'Unauthorized' && (error as any).extra === 'Token is expired') {
            logoutAction();
            dispatch(logoutAction());
            return;
          }
          if (workString) {
            if (error.message === 'Unauthorized' && workString.indexOf('user:' + userId) >= 0) {
              logoutAction();
              dispatch(logoutAction());
              return;
            }
          }
        }

        if (data.error) {
          if (agreementId) dispatch(errorItem({ agreement: data.data }, data.error));
          if (userId) dispatch(errorItem({ user: data.data }, data.error));
          if (groupId) dispatch(errorItem({ group: data.data }, data.error));
          if (accountId) dispatch(errorItem({ account: data.data }, data.error));
          if (articleId) dispatch(errorItem({ article: data.data }, data.error));
          if (opportunityId) dispatch(errorItem({ opportunity: data.data }, data.error));
          if (quoteId) dispatch(errorItem({ quote: data.data }, data.error));
          if (messageId) dispatch(errorItem({ message: data.data }, data.error));
          if (matchId) dispatch(errorItem({ match: data.data }, data.error));
          if (tagId) dispatch(errorItem({ tag: data.data }, data.error));
          if (imageId) dispatch(errorItem({ image: data.data }, data.error));
          if (presentationId) dispatch(errorItem({ presentation: data.data }, data.error));
          if (themeName) dispatch(errorItem({ theme: data.data }, data.error));
        } else {
          if (agreementId) dispatch(updateItem({ agreement: data.data }, data.error));
          if (userId) dispatch(updateItem({ user: data.data }, data.error));
          if (groupId) dispatch(updateItem({ group: data.data }, data.error));
          if (accountId) dispatch(updateItem({ account: data.data }, data.error));
          if (articleId) dispatch(updateItem({ article: data.data }, data.error));
          if (opportunityId) dispatch(updateItem({ opportunity: data.data }, data.error));
          if (quoteId) dispatch(updateItem({ quote: data.data }, data.error));
          if (messageId) dispatch(updateItem({ message: data.data }, data.error));
          if (matchId) dispatch(updateItem({ match: data.data }, data.error));
          if (tagId) dispatch(updateItem({ tag: data.data }, data.error));
          if (imageId) dispatch(updateItem({ image: data.data }, data.error));
          if (presentationId) dispatch(updateItem({ presentation: data.data }, data.error));
          if (themeName) dispatch(updateItem({ theme: data.data }, data.error));
        }
      }

      // Dispatched stopped working
      dispatch(statusWorking(false, workString));
    }
  };
};

export default fetchItem;
