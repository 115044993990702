import React from 'react';
import { Link } from '@mui/material';
import { Opportunity } from '@earnenterprise/asc-models';

export const renderAsOpportunity = (opportunity?: Opportunity | null, history?: any) => {
  if (!opportunity) return null;
  return (
    <Link
      underline="none"
      href={`/opportunity/${opportunity.id}`}
      onClick={(e: any) => {
        if (history) {
          e.preventDefault();
          history(`/opportunity/${opportunity.id}`);
        }
      }}
    >
      {opportunity.number}
    </Link>
  );
};
