import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  Icon,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentProps, Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BACKEND } from 'App';
import SearchBar from 'components/search.component';
import { ErrorContext } from 'error/error.context';
import { Article, getSoldForDates } from '@earnenterprise/asc-models';
import { renderAsArticle } from 'components/renderers';
import { Image } from '@earnenterprise/asc-models';
import { gqlArticleService, gqlFileService, gqlImageService } from 'services/gql.services';
import { LanguageContext } from 'translation/languageContext';
import LoadingComponent from 'routes/loading';
import { renderAsCurrency } from 'routes/opportunity/opportunity.article.table';
import { isVideo } from 'routes/article/article.info';
import { useRights, useTable } from 'components/hooks/useQueryHooks';

import { User } from '@earnenterprise/asc-models';
import { searchTagsUpdated, setDefaultTableValues } from 'components/table.component';
import { GQLReturnType } from 'services/gql.service';
import {
  addArticleToCart,
  getFirstArticleWithImage,
  toggleCategory,
  toggleCategoryAll,
} from 'utils/articleUtils';

type DisplayType = 'list' | 'grid';

interface TableProps {
  isInline?: boolean;
  accountId?: string | number | null;
  userId?: string | number | null;
  from?: string | Date | null;
  to?: string | Date | null;
  displayType: DisplayType;
  onAddArticle?: (article: Article) => void;
  onDisplayArticleChanged?: (article: Article) => void;
  onDisplayImageChanged?: (image: Image) => void;
  tagsChanged?: (tags?: string[], matching?: 'Any' | 'All') => void;
  containerProps?: ComponentProps<typeof Grid>;
}

export const tableStyle = makeStyles((theme: Theme) => {
  return createStyles({
    card: {},
    header: {
      backgroundColor: theme.palette.background.default,
      '&& .MuiTypography-h5': {
        fontSize: '1rem',
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    iconButtonNoPadding: {
      padding: '0px',
      '&& :hover': {
        color: theme.palette.primary.main,
      },
      '&& :active': {
        color: theme.palette.primary.dark,
      },
    },
  });
});

const lsExpanded = localStorage.getItem('articleDefaultExpanded');
let lsDisplayType = localStorage.getItem('articleDisplayType') as DisplayType;

export const ArticleListTable = (props: TableProps) => {
  const lsName = props.isInline ? 'articleListInline' : 'articleList';
  const lsNameImages = 'articleImagesList';
  const dispatch = useDispatch();
  const classes = tableStyle();
  const history = useNavigate();
  const { translate } = useContext(LanguageContext);
  const { snackbar } = useContext(ErrorContext);
  const [displayType, setDisplayType] = useState<DisplayType>('list');
  const [shown, setShown] = useState<string[]>(lsExpanded ? JSON.parse(lsExpanded) : []);
  const {
    setSearchSize,
    searchText,
    searchTags,
    setSearchTags,
    searchMatching,
    setSearchMatching,
  } = useTable(lsName, 'category, name');
  const { data: qUser } = useQuery<User>(['user']);
  const { rights } = useRights();

  // Articles
  const articlesQuery = useQuery<GQLReturnType | null>(
    props.isInline
      ? ['articles']
      : searchTags && searchTags.length > 0
      ? ['articles', { tags: searchTags, tagsMatching: searchMatching }]
      : ['articles'],
    async () => {
      return await gqlArticleService.list({
        order: 'category, name',
        limit: 500,
        offset: 0,
        tags: searchTags,
        tagsMatching: searchMatching,
      });
    },
    { enabled: !!qUser && !!rights, refetchOnMount: true }
  );

  // Images
  const imagesQuery = useQuery<GQLReturnType | null>(
    ['images'],
    async () => {
      return await gqlImageService.list({ order: 'display_name, name', limit: 500, offset: 0 });
    },
    { enabled: !!qUser && !!rights, refetchOnMount: true }
  );

  // * This is needed to make sure we're displaying the grid or list when using the back button.
  useEffect(() => {
    if (!props.isInline && props.displayType !== displayType) {
      setDisplayType(props.displayType);
    }
  }, [displayType, shown, props.displayType, props.isInline, dispatch]);

  // Default values
  if (setDefaultTableValues(lsName, setSearchSize)) {
    articlesQuery.refetch();
  }

  if (setDefaultTableValues(lsNameImages, setSearchSize)) {
    imagesQuery.refetch();
  }

  // Fetch data if missing
  if (articlesQuery.data === undefined) {
    if (!articlesQuery.isFetching) {
      console.warn("No articlesQuery data and isn't fetching! Investigate cause!");
      articlesQuery.refetch();
    }
    return <LoadingComponent />;
  }

  if (imagesQuery.data === undefined) {
    if (!imagesQuery.isFetching) {
      console.warn("No imageQuery data and isn't fetching! Investigate cause!");
      imagesQuery.refetch();
    }
    return <LoadingComponent />;
  }

  const articles = articlesQuery.data?.data as Article[];
  const images = imagesQuery.data?.data as Image[];

  const categories = [...new Set(articles.map((article) => article.category))].sort();

  return (
    <Grid container spacing={3} {...props.containerProps}>
      {props.isInline && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Paper>
            <IconButton
              onClick={() => {
                lsDisplayType = 'list';
                setDisplayType('list');
                localStorage.setItem('articleDisplayType', 'list');
              }}
              size="large"
            >
              <Icon>list</Icon>
            </IconButton>
            <IconButton
              onClick={() => {
                lsDisplayType = 'grid';
                setDisplayType('grid');
                localStorage.setItem('articleDisplayType', 'grid');
              }}
              size="large"
            >
              <Icon>apps</Icon>
            </IconButton>
          </Paper>
        </Grid>
      )}
      {displayType === 'grid'
        ? images.map(
            (image, index) =>
              image.displayed && (
                <Grid key={index} item sm={12} md={6} lg={4}>
                  <Card
                    className={classes.card}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!props.isInline) {
                        history(
                          '/article/area/' + getFirstArticleWithImage(articles, image.path).id
                        );
                      } else if (props.onDisplayArticleChanged) {
                        props.onDisplayArticleChanged(
                          getFirstArticleWithImage(articles, image.path)
                        );
                      }
                    }}
                  >
                    <CardHeader
                      title={image.displayName ? image.displayName : image.name}
                      className={classes.header}
                    />
                    {isVideo(image.path) ? (
                      <CardContent style={{ margin: '0px', padding: '0px' }}>
                        <video style={{ width: '100%', padding: '0px', margin: '0px' }}>
                          <source src={BACKEND + image.path} type="video/mp4" />
                        </video>
                      </CardContent>
                    ) : (
                      <CardMedia
                        className={classes.media}
                        image={BACKEND + image.path}
                        title={image.displayName}
                      />
                    )}
                  </Card>
                </Grid>
              )
          )
        : displayType === 'list' && (
            <Grid item xs={12}>
              <Paper style={{ padding: '16px' }}>
                {!props.isInline && (
                  <SearchBar
                    type="Article"
                    disableSearch={true}
                    onSearch={(search) => {
                      // TODO: Handle article search?
                    }}
                    onChangeTags={searchTagsUpdated(
                      lsName,
                      articlesQuery,
                      searchTags,
                      searchMatching,
                      setSearchTags,
                      setSearchMatching,
                      props.tagsChanged
                    )}
                    defaultSearch={searchText}
                    defaultTags={searchTags}
                    defaultMatch={searchMatching}
                  />
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              title={translate('Expand/Collapse all')}
                              className={classes.iconButtonNoPadding}
                              onClick={() => setShown(toggleCategoryAll(categories, shown))}
                              size="large"
                            >
                              {categories && shown.length === categories.length ? (
                                <Icon>arrow_right</Icon>
                              ) : (
                                <Icon>arrow_drop_down</Icon>
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell colSpan={2}>{translate('Name')}</TableCell>
                          <TableCell style={{ textAlign: 'right' }}>
                            {translate('Number')}
                          </TableCell>
                          <TableCell style={{ textAlign: 'right' }}>
                            {translate('Booked')}/{translate('Sold', true)}/{translate('In stock')}
                          </TableCell>
                          <TableCell style={{ textAlign: 'right' }}>
                            {translate('Price/Item')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {categories.map((category: string, indexCategory: number) => (
                          <Fragment key={indexCategory}>
                            <TableRow>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '40px',
                                  width: '40px',
                                }}
                              >
                                <IconButton
                                  className={classes.iconButtonNoPadding}
                                  onClick={() => setShown(toggleCategory(category, shown))}
                                  size="large"
                                >
                                  {shown.includes(category) ? (
                                    <Icon>arrow_right</Icon>
                                  ) : (
                                    <Icon>arrow_drop_down</Icon>
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                colSpan={5}
                                onClick={() => setShown(toggleCategory(category, shown))}
                              >
                                {category}
                              </TableCell>
                            </TableRow>
                            {shown.includes(category) &&
                              articles.map((article: Article, indexRow: number) => {
                                const sold = getSoldForDates(article, props.from, props.to);
                                let style = {};
                                if (article.inStock > 0) {
                                  if (sold.sold >= article.inStock)
                                    style = { backgroundColor: 'rgba(200, 100, 100, 0.6)' };
                                  else if (sold.booked + sold.sold >= article.inStock)
                                    style = { backgroundColor: 'rgba(200, 200, 0, 0.6)' };
                                }
                                return article.category === category ? (
                                  <TableRow key={indexRow} style={style}>
                                    <TableCell></TableCell>
                                    <TableCell
                                      style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: '40px',
                                        width: '40px',
                                      }}
                                    >
                                      {rights.opportunityWrite && (
                                        <IconButton
                                          className={classes.iconButtonNoPadding}
                                          onClick={() => {
                                            if (props.onAddArticle) props.onAddArticle(article);
                                            else {
                                              addArticleToCart(article, dispatch);
                                              snackbar(
                                                translate(`Added article to cart`) + '.',
                                                'info'
                                              );
                                            }
                                          }}
                                          size="large"
                                        >
                                          <Icon>add_circle</Icon>
                                        </IconButton>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {renderAsArticle({
                                        article,
                                        skipNewWindow: !props.isInline,
                                        history,
                                        skipExchangeable: !props.isInline,
                                      })}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                      {article.number}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                      {sold.booked} / {sold.sold}
                                      {article.inStock > 0 && ' / ' + article.inStock}
                                      {article.inStock <= 0 && ' / -'}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                      {renderAsCurrency(article.priceInCents / 100)}
                                    </TableCell>
                                  </TableRow>
                                ) : null;
                              })}
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                  {!props.isInline && (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => history('/article/0')}
                      >
                        <Icon>add_box</Icon>
                        &nbsp; {translate('New article')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
    </Grid>
  );
};

export const ArticleList = () => {
  const { translate } = useContext(LanguageContext);
  const [displayType, setDisplayType] = useState<DisplayType>(lsDisplayType ?? 'list');

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px' }}>
            <Grid container direction="row">
              <Grid item xs={'auto'}>
                <Typography variant="h6">{translate('Article', true)}</Typography>
              </Grid>
              <Grid item xs style={{ textAlign: 'end' }}>
                <IconButton
                  title={translate('View as list')}
                  style={{ padding: '0px' }}
                  onClick={() => {
                    //lsDisplayType = 'list';
                    setDisplayType('list');
                    localStorage.setItem('articleDisplayType', 'list');
                  }}
                  size="large"
                >
                  <Icon style={{ width: '1.4em', height: '1.4em' }}>list</Icon>
                </IconButton>
                <IconButton
                  title={translate('View as image grid')}
                  style={{ padding: '0px', marginLeft: '20px' }}
                  onClick={() => {
                    //lsDisplayType = 'grid';
                    setDisplayType('grid');
                    localStorage.setItem('articleDisplayType', 'grid');
                  }}
                  size="large"
                >
                  <Icon style={{ width: '1.4em', height: '1.4em' }}>apps</Icon>
                </IconButton>
                <IconButton
                  title={translate('Export to Excel')}
                  style={{ padding: '0px', marginLeft: '20px' }}
                  onClick={async () => {
                    const data = await (gqlFileService as any).export('articles', null, false);
                    if (data.error) {
                      return;
                    }
                    if (data.data) {
                      window.open(data.data.path);
                    }
                  }}
                  size="large"
                >
                  <Icon>file_copy</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ArticleListTable displayType={displayType} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArticleList;
