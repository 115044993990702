import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Icon,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Autocomplete } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';

import { BACKEND } from 'App';
import { ConfirmDialogComponent, useDialogHelper } from 'components/confirm.dialog.component';
import useDelete from 'components/hooks/useDelete';
import useOptions from 'components/hooks/useOptions';
import useReduxState from 'components/hooks/useReduxState';
import useSave from 'components/hooks/useSave';
import { ImageComponent } from 'components/image.component';
import Tags from 'components/tags.component';
import { ErrorContext } from 'error/error.context';
import HelperAutoCompleteField from 'fields/autocomplete.field';
import BaseContainerFormik from 'fields/basecontainer_formik';
import { FileUploadContainer } from 'fields/file.upload.container';
import { HelperTextField } from 'fields/text.field';
import {
  Article,
  ArticleOutput,
  emptyArticle,
  getSoldForDates,
  setupArticle,
} from '@earnenterprise/asc-models';
import { Image } from '@earnenterprise/asc-models';
import {
  fetchItem,
  fetchUsers,
  resetItem,
  updateItem,
  updateItems,
} from 'redux/actions/items.actions';
import { gqlArticleService } from 'services/gql.services';
import { globalStyles } from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { LoadingComponent } from 'routes/loading';
import { Coordinate } from 'components/hooks/useImageHelper';
import { useRights, useSetting } from 'components/hooks/useQueryHooks';

interface Props {
  id: number | string;
}

/**
 * Validation
 */
const validationSchema = yup.object({
  name: yup.string().required().max(100),
  category: yup.string().required(),
});

/**
 *
 * @param filename
 */
export const isVideo = (filename: string) => {
  return (
    filename.endsWith('.avi') ||
    filename.endsWith('.mpg') ||
    filename.endsWith('.mpeg') ||
    filename.endsWith('.mp4') ||
    filename.endsWith('.ogg') ||
    filename.endsWith('.webm')
  );
};

/**
 * Userinfo
 * @param props
 */
export const ArticleInfo = (props: Props) => {
  const { translate } = useContext(LanguageContext);
  const { saveItem } = useSave({
    func: gqlArticleService.save,
    sanitizeObject: ArticleOutput(),
    type: 'article',
  });
  const { removeItem } = useDelete({ func: gqlArticleService.remove, type: 'article' });
  const { createUserOptions } = useOptions({});
  const dialogHelper = useDialogHelper();
  const { addError, snackbar } = useContext(ErrorContext);

  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = globalStyles(props);
  let { article: item } = useReduxState({ fetched: ['article'] });
  const { workStrings, theme } = useReduxState({ fetched: ['theme'] });
  const id = useParams<{ id: string }>().id;
  const idNumeric = parseInt(id ? id.toString() : '0');
  const { rights } = useRights();
  const { setting } = useSetting();
  const queryClient = useQueryClient();

  // For deciding which exp panels to show default
  const lsExpText = localStorage.getItem('lsexptext') ? localStorage.getItem('lsexptext') : 'false';
  const lsExpChoice = localStorage.getItem('lsexpchoice')
    ? localStorage.getItem('lsexpchoice')
    : 'false';
  const lsExpImage = localStorage.getItem('lsexpimage')
    ? localStorage.getItem('lsexpimage')
    : 'false';

  // Check for changes of id
  /*
  useEffect(() => {
    if (id === '0') dispatch(updateItem({ article: emptyArticle() }, null));
    if (id !== '0') {
      if ((item && item.id !== id) || !item) {
        dispatch(updateItem({ article: emptyArticle() }, null));
        dispatch(fetchItem({ articleId: id, workStrings }));
      }
    }
    return function cleanup() {
      if (item) {
        dispatch(resetItem({ articleReset: true }, null));
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, workStrings]);
  */

  useEffect(() => {
    if ((!item && id !== '0') || (item && item.id !== id && item.id !== idNumeric && id !== '0')) {
      dispatch(fetchItem({ articleId: id, workStrings }));
    }

    return function cleanup() {
      if (item) {
        dispatch(resetItem({ articleReset: true }, null));
      }
    };
  }, [id, idNumeric, item, workStrings, dispatch]);

  // Make sure we have an opportunity when creating a new one.
  if (id === '0') {
    item = emptyArticle();
  }

  // Dont render without and item
  if (item === null || item === undefined) {
    return <LoadingComponent />;
  }

  if (id !== '0' && !item.created) {
    return <LoadingComponent />;
  }
  const userOptions = createUserOptions(item, 'sendMessageTo');
  setupArticle(item);

  /**
   *
   * @param values
   * @param formik
   */
  const checkArticle = (values: Article, formik: any) => {
    (async () => {
      const data = await gqlArticleService.checkArticle(values);
      if (data.error) {
        addError(data.error);
        snackbar(translate('Failed to check article') + '!', 'error', data.error);
        return;
      }

      snackbar(translate('Article OK') + '!', 'success');
    })();
  };

  /**
   *
   * @param article
   */
  const copyArticle = (values: Article, formik: any) => {
    (async () => {
      const newArticle = Object.assign({}, values);
      newArticle.id = null;
      if (!newArticle.id) delete (newArticle as any).id;
      newArticle.number = '' + (parseInt(newArticle.number) + 1);

      // Name
      if (newArticle.name) {
        const start = newArticle.name.indexOf('(');
        const end = newArticle.name.indexOf(')');
        if (start >= 0 && end >= 0 && end === newArticle.name.length - 1) {
          const nrString = newArticle.name.slice(start);
          const nr = parseInt(nrString.substr(1, nrString.length - 2)) + 1;
          newArticle.name = newArticle.name.replace(nrString, '(' + nr + ')');
        }
      }

      // Text
      if (newArticle.textSeller) {
        const start = newArticle.textSeller.indexOf('(');
        const end = newArticle.textSeller.indexOf(')');
        if (start >= 0 && end >= 0 && end === newArticle.textSeller.length - 1) {
          const nrString = newArticle.textSeller.slice(start);
          const nr = parseInt(nrString.substr(1, nrString.length - 2)) + 1;
          newArticle.textSeller = newArticle.textSeller.replace(nrString, '(' + nr + ')');
        }
      }

      if (newArticle.imageCoords) {
        newArticle.imageCoords.forEach((_, index) => {
          if (newArticle.imageCoords) {
            newArticle.imageCoords[index][0] += 30;
          }
        });
      }

      const data = await gqlArticleService.save(null, newArticle, ArticleOutput(), false);
      if (data.error) {
        addError(data.error);
        snackbar(translate('Failed to copy article') + '!', 'error', data.error);
        return;
      }

      history('/article/' + data.data.id);
      dispatch(updateItem({ article: emptyArticle() }, null));
      dispatch(fetchItem({ articleId: data.data.id }));

      await queryClient.invalidateQueries('articles');
    })();
  };

  // Extra buttons
  const buttons: {
    name: string;
    fn: any;
    icon?: any;
    disabled?: boolean;
    neverDisable?: boolean;
  }[] = [];
  if (id !== '0' && rights?.articleWrite) {
    buttons.push({
      name: translate('Copy article'),
      fn: copyArticle,
      icon: <Icon>file_copy</Icon>,
      disabled: false,
      neverDisable: true,
    });
    buttons.push({
      name: translate('Check article'),
      fn: checkArticle,
      icon: <Icon>check</Icon>,
      disabled: false,
      neverDisable: true,
    });
  }

  if (!setting) return <LoadingComponent />;

  // Render
  return (
    <Formik
      initialValues={{ ...item }}
      enableReinitialize={true}
      onSubmit={async (v, f) => {
        //dispatch(updateItems({ articles: undefined }, null));
        //dispatch(updateItem({ article: undefined }, null));
        await saveItem(v, f);
        //await queryClient.invalidateQueries('articles');
      }}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({
        isSubmitting,
        handleReset,
        handleChange,
        values,
        setSubmitting,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form>
          <BaseContainerFormik
            submitButton={rights?.articleWrite}
            submitText={translate('Save article')}
            deleteText={translate('Delete article')}
            onDelete={
              rights?.articleWrite
                ? async () => {
                    dispatch(updateItems({ articles: undefined }, null));
                    removeItem(values, setSubmitting);
                    await queryClient.invalidateQueries('articles');
                  }
                : undefined
            }
            onReset={handleReset}
            dirty={dirty}
            buttons={buttons}
            header={(item ? item.name : '') + ' - ' + values.number}
            isSubmitting={isSubmitting}
            subheader={id !== '0' ? translate('Update article') : translate('New article')}
            linkHeaders={
              id !== '0' && rights.reportAll
                ? [
                    {
                      header: `${translate('To article report')}`,
                      fn: () => {
                        history('/report/article_details/' + id);
                      },
                    },
                  ]
                : undefined
            }
          >
            <Grid item sm={12}>
              <Tags
                item={values}
                type="Article"
                onUpdate={(tags: string[] | undefined | null) => {
                  setFieldValue('tags', tags);
                }}
              />
            </Grid>
            {true && (
              <>
                <Grid item sm={6}>
                  <HelperTextField
                    className={classes.textField}
                    type="text"
                    placeholder={translate('Name')}
                    label={translate('Name') + ' *'}
                    name="name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <HelperTextField
                    className={classes.textField}
                    type="text"
                    placeholder={translate('Category')}
                    label={translate('Category') + ' *'}
                    name="category"
                  />
                </Grid>
                <Grid item sm={3}>
                  <HelperTextField
                    className={classes.textField}
                    type="currency"
                    label={translate('Price')}
                    name="priceInCents"
                  />
                </Grid>
                <Grid item sm={3}>
                  <HelperTextField
                    className={classes.textField}
                    type="currency"
                    label={translate('Buy price')}
                    name="buyPriceInCents"
                  />
                </Grid>
                <Grid item sm={3}>
                  <HelperTextField
                    className={classes.textField}
                    type="number"
                    label={translate('In stock')}
                    name="inStock"
                    hint={translate('How many are available per year? Use -1 for unlimited.')}
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    className={classes.textField}
                    variant="standard"
                    type="number"
                    disabled={true}
                    label={translate('Sold', true)}
                    name="sold"
                    value={getSoldForDates(values).sold}
                  />
                </Grid>
                <Grid item sm={3}>
                  <HelperTextField
                    className={classes.textField}
                    type="number"
                    label={translate('Cost account')}
                    name="account"
                  />
                </Grid>
                <Grid item sm={3}>
                  <HelperTextField
                    className={classes.textField}
                    type="number"
                    label={translate('Cost center')}
                    name="costCenter"
                  />
                </Grid>
              </>
            )}
            <Grid item sm={3}>
              <HelperTextField
                className={classes.textField}
                type="text"
                label={translate('Article Number')}
                name="number"
              />
            </Grid>
            <Grid item sm={3}>
              <Typography
                variant={'body2'}
                style={{
                  marginBottom: '-6px',
                  transform: 'translate(0, 1.5px) scale(0.90)',
                  transformOrigin: 'top left',
                  color: theme.theme.palette.text.secondary,
                }}
              >
                {translate('Company')}
              </Typography>
              <Select
                name={`company`}
                fullWidth
                variant="standard"
                value={
                  values.company === undefined || values.company === null ? '' : values.company
                }
                onChange={(e: any) => {
                  setFieldValue('company', e.target.value);
                }}
              >
                <MenuItem value={''}>&nbsp;</MenuItem>
                {setting.companyName && <MenuItem value={'1'}>{setting.companyName}</MenuItem>}
                {setting.secondaryCompanyName && (
                  <MenuItem value={'2'}>{setting.secondaryCompanyName}</MenuItem>
                )}
              </Select>
            </Grid>
            <Grid item sm={3}>
              <HelperTextField
                className={classes.textField}
                type="number"
                label={translate('VAT Code')}
                name="vatCode"
              />
            </Grid>
            <Grid item sm={6}>
              <HelperTextField
                className={classes.textField}
                type="text"
                placeholder={translate('Measurement')}
                label={translate('Measurement')}
                name="measurement"
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded={lsExpText === 'true' ? true : false}
                onChange={(e) =>
                  lsExpText === 'true'
                    ? localStorage.setItem('lsexptext', 'false')
                    : localStorage.setItem('lsexptext', 'true')
                }
              >
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls={`panel-content`}
                  id={`panel-header`}
                >
                  <Typography>{translate('Text', true)}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ color: theme.theme.palette.text.primary }}>
                  <Grid container>
                    <Grid item sm={12} style={{ paddingBottom: '10px' }}>
                      <HelperTextField
                        className={classes.textField}
                        type="textarea"
                        label={translate('Seller text')}
                        name="textSeller"
                        hint={translate('The text appearing in the sellers section on the quote.')}
                      />
                    </Grid>
                    <Grid item sm={12} style={{ paddingBottom: '10px' }}>
                      <HelperTextField
                        className={classes.textField}
                        type="textarea"
                        label={translate('Buyer text')}
                        name="textBuyer"
                        hint={translate('The text appearing in the buyer section on the quote.')}
                      />
                    </Grid>
                    <Grid item sm={12} style={{ paddingBottom: '10px' }}>
                      <HelperTextField
                        className={classes.textField}
                        type="textarea"
                        label={translate('Other text')}
                        name="textOther"
                        hint={translate('The text appearing in the other section on the quote.')}
                      />
                    </Grid>
                    <Grid item sm={12} style={{ paddingBottom: '10px' }}>
                      <HelperTextField
                        className={classes.textField}
                        type="textarea"
                        label={translate('Invoice text')}
                        name="textInvoice"
                        hint={translate(
                          'The text visible on the invoice. If no text is specified the seller text is used.'
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded={lsExpChoice === 'true' ? true : false}
                onChange={(e) =>
                  lsExpChoice === 'true'
                    ? localStorage.setItem('lsexpchoice', 'false')
                    : localStorage.setItem('lsexpchoice', 'true')
                }
              >
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls={`panel-content`}
                  id={`panel-header`}
                >
                  <Typography>{translate('Choice', true)}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ color: theme.theme.palette.text.primary }}>
                  <Grid container>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isAutoIncluded"
                              color="primary"
                              checked={values.isAutoIncluded}
                              onChange={(e) => setFieldValue('isAutoIncluded', e.target.checked)}
                            />
                          }
                          label={translate('Auto included?')}
                        />
                        <FormHelperText>
                          {translate('Include this article in all new opportunities?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isPercentage"
                              color="primary"
                              checked={values.isPercentage}
                              onChange={(e) => setFieldValue('isPercentage', e.target.checked)}
                            />
                          }
                          label={translate('Price in percentage?')}
                        />
                        <FormHelperText>
                          {translate(
                            'Should the article be priced with a percentage of the total opportunity price?'
                          )}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isFixedPrice"
                              color="primary"
                              checked={values.isFixedPrice}
                              onChange={(e) => setFieldValue('isFixedPrice', e.target.checked)}
                            />
                          }
                          label={translate('Fixed price') + '?'}
                        />
                        <FormHelperText>
                          {translate('The article has a fixed unchangeable price?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isExposure"
                              color="primary"
                              checked={values.isExposure}
                              onChange={(e) => setFieldValue('isExposure', e.target.checked)}
                            />
                          }
                          label={translate('Exposure?')}
                        />
                        <FormHelperText>{translate('Is this an exposure article?')}</FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isConnectedToMatch"
                              color="primary"
                              checked={values.isConnectedToMatch}
                              onChange={(e) =>
                                setFieldValue('isConnectedToMatch', e.target.checked)
                              }
                            />
                          }
                          label={translate('Connected to match?')}
                        />
                        <FormHelperText>
                          {translate('Is the article connected to matches played?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>

                    {values.isConnectedToMatch && (
                      <Grid item sm={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="split"
                                color="primary"
                                checked={values.split}
                                onChange={(e) => setFieldValue('split', e.target.checked)}
                              />
                            }
                            label={translate('Split to single amount?')}
                          />
                          <FormHelperText>
                            {translate('Split each one of this article to 1 per row?')}
                          </FormHelperText>
                        </FormGroup>
                      </Grid>
                    )}
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isConnectedToPlayer"
                              color="primary"
                              checked={values.isConnectedToPlayer}
                              onChange={(e) =>
                                setFieldValue('isConnectedToPlayer', e.target.checked)
                              }
                            />
                          }
                          label={translate('Connected to players?')}
                        />
                        <FormHelperText>
                          {translate('Is the article connected to players?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isInvoicedAfterEvent"
                              color="primary"
                              checked={values.isInvoicedAfterEvent}
                              onChange={(e) =>
                                setFieldValue('isInvoicedAfterEvent', e.target.checked)
                              }
                            />
                          }
                          label={translate('Invoiced after event?')}
                        />
                        <FormHelperText>
                          {translate('Invoiced separately after specific event?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isBarter"
                              color="primary"
                              checked={values.isBarter}
                              onChange={(e) => setFieldValue('isBarter', e.target.checked)}
                            />
                          }
                          label={translate('Barter article?')}
                        />
                        <FormHelperText>{translate('Is this a barter article?')}</FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isExchangeable"
                              color="primary"
                              checked={values.isExchangeable}
                              onChange={(e) => setFieldValue('isExchangeable', e.target.checked)}
                            />
                          }
                          label={translate('Exchangeable?')}
                        />
                        <FormHelperText>
                          {translate('Exchangeable to other articles?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="excludeOnQuote"
                              color="primary"
                              checked={values.excludeOnQuote}
                              onChange={(e) => setFieldValue('excludeOnQuote', e.target.checked)}
                            />
                          }
                          label={translate('Exclude on Quote?')}
                        />
                        <FormHelperText>
                          {translate('Exclude the article in quotes?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isSendMessage"
                              color="primary"
                              checked={values.isSendMessage}
                              onChange={(e) => setFieldValue('isSendMessage', e.target.checked)}
                            />
                          }
                          label={translate('Send message when sold?')}
                        />
                        <FormHelperText>
                          {translate('Send message when this article is sold?')}
                        </FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}></Grid>
                    {values.isSendMessage && (
                      <Grid item sm={6}>
                        <HelperAutoCompleteField
                          className={classes.autoCompleteField}
                          name="sendMessageTo"
                          label={translate('Send message to')}
                          placeholder={translate('Send message to')}
                          onKeyUp={(e: any) => {
                            dispatch(
                              fetchUsers({
                                limit: 20,
                                order: 'firstname',
                                search: e.target.value,
                                fields: ['firstname', 'lastname'],
                              })
                            );
                          }}
                          options={userOptions}
                          renderAs={Autocomplete}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {values.image ? (
              <Grid item xs={12}>
                <Accordion
                  defaultExpanded={lsExpImage === 'true' ? true : false}
                  onChange={(e) =>
                    lsExpImage === 'true'
                      ? localStorage.setItem('lsexpimage', 'false')
                      : localStorage.setItem('lsexpimage', 'true')
                  }
                >
                  <AccordionSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    aria-controls={`panel-content`}
                    id={`panel-header`}
                  >
                    <Typography>{translate('Image') + ' - ' + values.image}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item sm={4}>
                        <HelperTextField
                          className={classes.textField}
                          type="text"
                          label={translate('Image')}
                          name="image"
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <HelperTextField
                          className={classes.textField}
                          type="text"
                          label={translate('Name')}
                          name="imageName"
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Typography
                          variant="body2"
                          style={{
                            marginBottom: '-6px',
                            transform: 'translate(0, 1.5px) scale(0.90)',
                            transformOrigin: 'top left',
                            color: theme.theme.palette.text.secondary,
                          }}
                        >
                          {translate('Type')} (*)
                        </Typography>
                        <Select
                          name="imageCoordsType"
                          fullWidth
                          variant="standard"
                          placeholder={translate('Type') + ''}
                          value={values.imageCoordsType}
                          onChange={(e: SelectChangeEvent<any>) => {
                            if (e.target.value === '') setFieldValue('imageCoords', null);
                            setFieldValue('imageCoordsType', e.target.value);
                          }}
                        >
                          <MenuItem value={'rect'}>Square</MenuItem>
                          <MenuItem value={'circle'}>Circle</MenuItem>
                          <MenuItem value={''}>None</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item sm={4}>
                        <Typography
                          variant="body2"
                          style={{
                            marginBottom: '-6px',
                            transform: 'translate(0, 1.5px) scale(0.90)',
                            transformOrigin: 'top left',
                            color: theme.theme.palette.text.secondary,
                          }}
                        >
                          {translate('Displayed')} (*)
                        </Typography>
                        <Select
                          name="imageDisplayed"
                          fullWidth
                          variant="standard"
                          placeholder={translate('Displayed') + ''}
                          value={values.imageDisplayed ? 1 : 0}
                          onChange={(e: SelectChangeEvent<any>) => {
                            if (e.target.value === '') setFieldValue('imageDisplayed', false);
                            setFieldValue('imageDisplayed', e.target.value);
                          }}
                        >
                          <MenuItem value={0}>{translate('No')}</MenuItem>
                          <MenuItem value={1}>{translate('Yes')}</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item sm={4}>
                        <HelperTextField
                          hint={translate(
                            'When pressing previous in the grid view of articles, which article should we open?'
                          )}
                          className={classes.textField}
                          type="text"
                          label={translate('Previous Article ID')}
                          name="imagePrevious"
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <HelperTextField
                          hint={translate(
                            'When pressing next in the grid view of articles, which article should we open?'
                          )}
                          className={classes.textField}
                          type="text"
                          label={translate('Next Article ID')}
                          name="imageNext"
                        />
                      </Grid>

                      <Grid item sm={12}>
                        <Typography variant="body2" color="textPrimary">
                          {translate(
                            'Select the image area for the article on the image below. You can continue to drag and resize until you are happy with the result.'
                          )}
                          <br />
                          {translate(
                            'Corners will automatically snap to other close corners, hold ctrl if you want to avoid snapping.'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} style={{ textAlign: 'center' }}>
                        {isVideo(values.image) ? (
                          <video style={{ maxWidth: '720px' }} controls>
                            <source src={BACKEND + 'articles/' + values.image} type="video/mp4" />
                          </video>
                        ) : (
                          <>
                            {true && (
                              <ImageComponent
                                draggable={true}
                                type={values.imageCoordsType}
                                imageCoords={
                                  values.imageCoords === undefined
                                    ? null
                                    : (values.imageCoords as any as Coordinate[] | null)
                                }
                                otherImageCoords={
                                  values.imageArticles ? (values.imageArticles as any) : null
                                }
                                src={BACKEND + 'articles/' + values.image}
                                onChange={(positions) => {
                                  setFieldValue('imageCoords', positions, false);
                                }}
                                onClick={(item) => {
                                  if (item) {
                                    dialogHelper.setOpen(true);
                                    dialogHelper.setMessage({
                                      title: translate('Open article') + ' ' + item.name,
                                      message: translate(
                                        'Are you sure you want to open another article? All unsaved changes on this article will be lost.'
                                      ),
                                    });
                                    dialogHelper.setData(('/article/' + (item as any).id) as any);
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                      {values.imageExtra !== '' &&
                        values.imageExtra &&
                        values.imageExtra.split(';').map((src, index) => (
                          <Grid item sm={12} key={index}>
                            <div
                              style={{
                                width: '99%',
                                textAlign: 'center',
                                justifyContent: 'center',
                                left: '3px',
                                top: '3px',
                                position: 'relative',
                              }}
                            >
                              <img
                                alt=""
                                crossOrigin={'anonymous'}
                                src={'/files/articles/' + src}
                                style={{ width: '100%', border: '1px solid #fff' }}
                              />
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : null}
            {id !== '0' && rights?.articleWrite && (
              <Grid item xs={12}>
                <FileUploadContainer
                  articleId={id}
                  accept="image/*, video/*"
                  singleFile={true}
                  listExisting={true}
                  onSelectedFile={(image: Image) => {
                    if (!values.image) {
                      setFieldValue('image', image.path.replace('articles/', ''));
                      setFieldValue(
                        'imageName',
                        image.displayName ? image.displayName : image.name
                      );
                    } else {
                      setFieldValue(
                        'imageExtra',
                        values.imageExtra ? values.imageExtra + ';' + image.name : image.name
                      );
                    }
                  }}
                />
              </Grid>
            )}
            <ConfirmDialogComponent
              dialogHelper={dialogHelper}
              onAccept={() => history(dialogHelper.getData() as any)}
            />
          </BaseContainerFormik>
        </Form>
      )}
    </Formik>
  );
};

export default ArticleInfo;
