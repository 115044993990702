import { Grid, IconButton, Icon } from '@mui/material';
import React, { useContext } from 'react';
import { Account, Agreement, Opportunity, Reminder, User } from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';
import ReminderAddDialogComponent from 'routes/reminder/reminder.add.dialog';
import { useDialogHelper } from 'components/confirm.dialog.component';

/**
 *
 */
interface Props {
  id?: string | number | null;
  type?: string;
  reminders?: Reminder[] | null;
  reminderAccount?: Account | null;
  reminderUser?: User | null;
  reminderOpportunity?: Opportunity | null;
  reminderAgreement?: Agreement | null;
}

/**
 *
 * @param props
 */
export const BaseContainerReminder: React.FC<Props> = ({
  reminders,
  reminderAccount,
  reminderUser,
  reminderOpportunity,
  reminderAgreement,
  type,
  id,
  ...props
}) => {
  const { translate } = useContext(LanguageContext);
  const dialogHelper = useDialogHelper();

  const onAccept = (reminder: Reminder) => {
    console.log({ reminder });
  };

  const onCancel = () => {
    console.log({ close: true });
  };

  const showAddReminder = () => {
    //dialogHelper.setData({});
    dialogHelper.setOpen(true);
  };

  // No reminders, just display the add reminders button
  if (!reminders || reminders.length === 0) {
    return (
      <>
        <Grid item style={{ paddingRight: '10px', margin: 'auto' }}>
          <IconButton size="large" onClick={showAddReminder}>
            <Icon>alarm_add</Icon>
          </IconButton>
        </Grid>
        <ReminderAddDialogComponent
          dialogHelper={dialogHelper}
          account={reminderAccount}
          user={reminderUser}
          opportunity={reminderOpportunity}
          agreement={reminderAgreement}
          onAccept={onAccept}
          onCancel={onCancel}
        />
      </>
    );
  }

  // Has the date passed for any of the reminders?
  const dateHasPassed =
    reminders?.filter((reminder) => {
      const reminderDate = new Date(reminder.date as any);
      const nowDate = new Date();
      return reminderDate.getTime() - nowDate.getTime() <= 0;
    })?.length !== 0;

  // Display with reminders
  return (
    <>
      <Grid
        item
        style={{ paddingRight: '10px', margin: 'auto' }}
        title={translate('Reminders has been set')}
      >
        <IconButton size="large" onClick={showAddReminder}>
          {/* <AlarmIcon style={{ color: dateHasPassed ? 'red' : 'yellow' }} /> */}
          <Icon style={{ color: dateHasPassed ? 'red' : 'yellow' }}>alarm</Icon>
        </IconButton>
      </Grid>
      <ReminderAddDialogComponent
        dialogHelper={dialogHelper}
        account={reminderAccount}
        user={reminderUser}
        opportunity={reminderOpportunity}
        agreement={reminderAgreement}
        onAccept={onAccept}
        onCancel={onCancel}
      />
    </>
  );
};
