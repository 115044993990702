import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Icon,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useReduxState from 'components/hooks/useReduxState';
import setCart from 'redux/actions/cart.actions';
import { LanguageContext } from 'translation/languageContext';
import { renderAsCurrency } from 'routes/opportunity/opportunity.article.table';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 23,
      top: 8,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

export const ArticleCartComponent: React.FC = () => {
  const { cart } = useReduxState({ fetched: ['cart'] });
  const [open, setOpen] = useState<boolean>(false);
  const { translate } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [, setCarts] = useState(cart);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmpty = () => {
    localStorage.removeItem('cart');
    dispatch(setCart([]));
    setCarts([]);
    setOpen(false);
  };

  return (
    <>
      {cart && cart.length > 0 && (
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          size="large"
        >
          <StyledBadge badgeContent={cart ? cart.length : 0} color="secondary">
            <Icon>shopping_cart</Icon>
          </StyledBadge>
          <span>&nbsp;{translate('Cart')}</span>
        </IconButton>
      )}
      <Dialog open={open} maxWidth={'md'} fullWidth onClose={handleClose}>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('Category')}</TableCell>
                <TableCell>{translate('Article')}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{translate('Price')}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{translate('Amount')}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart &&
                cart.map((article, index) => (
                  <TableRow key={index}>
                    <TableCell>{article.article.category}</TableCell>
                    <TableCell>{article.article.name}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {renderAsCurrency((article.pricePerItemInCents * article.amount) / 100)}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{article.amount}</TableCell>
                    <TableCell style={{ width: '30px', maxWidth: '30px' }}>
                      <IconButton
                        style={{ padding: '0px' }}
                        onClick={() => {
                          if (cart) {
                            cart[index].amount++;
                            cart[index].priceInCents = cart[index].sumInCents =
                              cart[index].pricePerItemInCents * cart[index].amount;
                            localStorage.setItem('cart', JSON.stringify(cart));
                            dispatch(setCart(cart));
                            setCarts(Object.assign([], cart));
                          }
                        }}
                        size="large"
                      >
                        <Icon>add_box</Icon>
                      </IconButton>
                    </TableCell>
                    <TableCell
                      style={{
                        width: '30px',
                        maxWidth: '30px',
                        paddingRight: '30px',
                        paddingLeft: '5px',
                      }}
                    >
                      <IconButton
                        style={{ padding: '0px' }}
                        onClick={() => {
                          if (cart) {
                            cart[index].amount--;
                            if (cart[index].amount === 0) {
                              cart.splice(index, 1);
                            } else {
                              cart[index].priceInCents = cart[index].sumInCents =
                                cart[index].pricePerItemInCents * cart[index].amount;
                            }
                            localStorage.setItem('cart', JSON.stringify(cart));
                            dispatch(setCart(cart));
                            setCarts(Object.assign([], cart));
                            if (cart.length === 0) handleClose();
                          }
                        }}
                        size="large"
                      >
                        <Icon>indeterminate_check_box</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="secondary"
            variant="contained"
          >
            {translate('Cancel')}
          </Button>
          <Button
            onClick={() => {
              handleEmpty();
            }}
            color="primary"
            variant="contained"
          >
            {translate('Empty cart')}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              history('/opportunity/0');
            }}
            color="primary"
            variant="contained"
          >
            {translate('New opportunity')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArticleCartComponent;
