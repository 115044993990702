import React, { createRef, useContext, useState } from 'react';
import { Input, TextField, TextFieldProps, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { useHints } from 'components/hooks/useQueryHooks';
import globalStyles from 'styling/global.styles';
import { ThemeContext } from 'App';
import { LanguageContext } from 'translation/languageContext';
import { FieldProps } from 'fields/field.props';
import { renderAsCurrency } from 'routes/opportunity/opportunity.article.table';

export const NumberFormatCustomImproved = React.forwardRef(function NumberFormatCustomImproved(
  props,
  ref
) {
  const { ...other } = props;
  return <NumberFormat {...other} getInputRef={ref} />;
});

export const NumberFormatCustom = (props: FieldAttributes<any>) => {
  const [floatValue, setFloatValue] = useState(props.value);
  const { inputRef, onChange, onBlur, withLabel, name, type, id, ...other } = props;

  if (!inputRef) return <></>;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: withLabel ? 'left' : 'right' }}
      onBlur={() => {
        onBlur({
          target: {
            value: type === 'number' ? floatValue : floatValue * 100,
          },
        });
      }}
      onValueChange={(values: any) => {
        if (
          values.value !== undefined &&
          values.floatValue !== undefined &&
          values.value !== null &&
          values.floatValue !== null
        ) {
          setFloatValue(values.floatValue as any);
          onChange({
            target: {
              value: type === 'number' ? values.floatValue : values.floatValue * 100,
            },
          });
        }
      }}
      decimalScale={2}
      fixedDecimalScale={type === 'curreny' ? true : false}
      thousandSeparator={' '}
      decimalSeparator={','}
    />
  );
};

export const HelperCurrencyFormat = ({
  className,
  onValueChange,
  style,
  hint,
  placeholder,
  label,
  type,
  readOnly,
  renderAs,
  ...props
}: FieldAttributes<FieldProps>) => {
  return (
    <NumberFormat
      variant="standard"
      disabled={readOnly}
      style={{ textAlign: 'right' }}
      className={className}
      value={renderAsCurrency((props.value as number) / 100, false)}
      fullWidth
      suffix={type === 'currency_short' ? '' : ' kr'}
      thousandSeparator={' '}
      decimalSeparator={','}
      customInput={TextField}
      inputProps={{ style: { textAlign: 'right' } }}
    />
  );
};

/**
 *
 * @param param0
 */
export const HelperTextFieldUnmapped = ({
  className,
  onValueChange,
  style,
  hint,
  placeholder,
  label,
  type,
  readOnly,
  renderAs,
  ...props
}: FieldAttributes<FieldProps>) => {
  const [fields, meta] = useField<FieldProps>(props);
  const formik = useFormikContext<any>();

  const errorText = meta.touched && meta.error ? meta.error : '';
  const hasError = meta.touched && meta.error ? true : false;
  const displayLabel = label ? label : placeholder;

  const multiline = type === 'textarea' ? true : false;
  const classes = globalStyles();
  const inputRef = createRef<any>();
  const [, setError] = useState<string>('');
  //const hints = useSelector((s: ReduxState) => (s.settings && s.settings.hints ? s.settings.hints : false));
  const { hints } = useHints();
  const theme = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  //console.log(`Rendering ${fields.name}, hints: ${hints}`);

  if (type === 'number') {
    if (fields.value === undefined || fields.value === null) {
      return <></>;
    }

    return (
      <>
        {label ? (
          <Typography
            variant={'body2'}
            style={{
              marginBottom: '-4px',
              transform: 'translate(0, 1.5px) scale(0.90)',
              transformOrigin: 'top left',
            }}
          >
            {label}
          </Typography>
        ) : null}
        <NumberFormat
          className={
            'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
              ? className
              : classes.textField
          }
          variant="standard"
          disabled={readOnly}
          style={{ textAlign: 'right', ...style }}
          value={parseFloat(fields.value.toString())}
          fullWidth
          placeholder={placeholder}
          thousandSeparator={' '}
          decimalSeparator={','}
          customInput={TextField}
          inputProps={{ style: { textAlign: 'right', ...style } }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const value = parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.'));
            //console.log({ value: e.target.value, parsedValued: value });
            formik.setFieldValue(fields.name, value, false);
          }}
          onBlur={(e: React.FocusEvent<any>) => {
            if (onValueChange) {
              if (e.target.value === null) {
                e.target.value = '0';
              } else {
                e.target.value = parseFloat(
                  e.target.value.replaceAll(' ', '').replaceAll(',', '.')
                );
              }
              onValueChange(e);
            }
          }}
        />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'currency') {
    if (fields.value === undefined || fields.value === null) {
      return <></>;
    }

    return (
      <>
        {label ? (
          <Typography
            variant={'body2'}
            style={{
              marginBottom: '-4px',
              transform: 'translate(0, 1.5px) scale(0.90)',
              transformOrigin: 'top left',
            }}
          >
            {label}
          </Typography>
        ) : null}
        <NumberFormat
          className={
            'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
              ? className
              : classes.textField
          }
          variant="standard"
          disabled={readOnly}
          value={renderAsCurrency(parseFloat(fields.value as any) / 100, false)}
          fullWidth
          placeholder={placeholder}
          thousandSeparator={' '}
          decimalSeparator={','}
          suffix={' kr'}
          style={{ textAlign: 'right', ...style }}
          customInput={TextField}
          inputProps={{ style: { textAlign: 'right', ...style } }}
          onChange={(e: any) => {
            const value = parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.')) * 100;
            //console.log({ value: e.target.value, parsedValued: value });
            formik.setFieldValue(fields.name, value, false);
          }}
          onBlur={(e: any) => {
            if (onValueChange) {
              if (e.target.value === null) {
                e.target.value = '0';
              } else {
                e.target.value =
                  parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.')) * 100;
              }
              onValueChange(e);
            }
          }}
        />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'currency_short') {
    if (fields.value === undefined || fields.value === null) {
      return <></>;
    }

    return (
      <>
        {label ? (
          <Typography
            variant={'body2'}
            style={{
              marginBottom: '-4px',
              transform: 'translate(0, 1.5px) scale(0.90)',
              transformOrigin: 'top left',
            }}
          >
            {label}
          </Typography>
        ) : null}
        <NumberFormat
          className={
            'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
              ? className
              : classes.textField
          }
          variant="standard"
          disabled={readOnly}
          style={{ textAlign: 'right', ...style }}
          value={renderAsCurrency(parseFloat(fields.value.toString()) / 100, false)}
          fullWidth
          placeholder={placeholder}
          thousandSeparator={' '}
          decimalSeparator={','}
          customInput={TextField}
          inputProps={{ style: { textAlign: 'right', ...style } }}
          onBlur={async (e: any) => {
            if (onValueChange) {
              if (e.target.value === null) {
                e.target.value = '0';
              } else {
                e.target.value =
                  parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.')) * 100;
              }
              const value = parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.'));
              formik.setFieldValue(fields.name, value, true);
              onValueChange(e);
            }
          }}
        />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'currency_old') {
    return (
      <>
        {label ? (
          <Typography
            variant={'body2'}
            style={{
              marginBottom: '-4px',
              transform: 'translate(0, 1.5px) scale(0.90)',
              transformOrigin: 'top left',
            }}
          >
            {label}
          </Typography>
        ) : null}
        <Input
          ref={inputRef}
          style={style}
          disabled={readOnly}
          className={
            'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
              ? className
              : classes.textField
          }
          value={parseFloat(fields.value as any) / 100}
          fullWidth
          onChange={(e) => {
            formik.setFieldValue(fields.name, e.target.value, false);
          }}
          onBlur={(e) => {
            if (onValueChange) {
              if (e.target.value === null) {
                e.target.value = '0';
              }
              onValueChange(e);
            }
          }}
          inputComponent={NumberFormatCustom}
          inputProps={{
            inputRef,
            name: fields.name,
            type,
            value: parseFloat(fields.value as any) / 100,
            withLabel: label ? true : false,
          }}
        />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'number_old') {
    return (
      <>
        {label ? (
          <Typography
            variant={'body2'}
            style={{
              marginBottom: '-4px',
              transform: 'translate(0, 1.5px) scale(0.90)',
              transformOrigin: 'top left',
            }}
          >
            {label}
          </Typography>
        ) : null}
        <Input
          ref={inputRef}
          style={style}
          disabled={readOnly}
          placeholder={placeholder}
          className={
            'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
              ? className
              : classes.textField
          }
          value={fields.value}
          onChange={(e) => {
            formik.setFieldValue(fields.name, e.target.value, false);
          }}
          onBlur={(e) => {
            if (onValueChange && e.target.value) {
              onValueChange(e);
            }
          }}
          inputComponent={NumberFormatCustom}
          inputProps={{
            inputRef,
            name: fields.name,
            type,
            value: parseFloat(fields.value as any),
            withLabel: label ? true : false,
          }}
        />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'imageCoords') {
    return (
      <>
        {label ? (
          <Typography variant={'body2'} color="textPrimary">
            {label}
          </Typography>
        ) : null}
        <Input disabled={true} fullWidth value={JSON.stringify(fields.value)} />
        {hints ? (
          <Typography
            variant={'caption'}
            style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
          >
            {hint}
          </Typography>
        ) : null}
      </>
    );
  }

  if (type === 'date') {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label}
            value={fields.value}
            mask="____-__-__"
            onChange={(date: any) => {
              if (date === null) {
                setError('');
                formik.setFieldValue(fields.name, null, true);
              } else {
                try {
                  setError('');
                  date?.setHours(12, 0, 0);
                  formik.setFieldValue(fields.name, date?.toISOString(), true);
                } catch {
                  setError(translate('Not a valid date'));
                  console.log({ warning: 'Not a valid date' });
                }
              }
            }}
            inputFormat="yyyy-MM-dd"
            readOnly={readOnly}
            renderInput={(props: TextFieldProps) => (
              <TextField
                fullWidth
                style={{ color: readOnly ? 'red' : 'blue' }}
                variant={'standard'}
                {...props}
              />
            )}
          />{' '}
          {hints ? (
            <Typography
              variant={'caption'}
              style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
            >
              {hint}
            </Typography>
          ) : null}
        </LocalizationProvider>
      </>
    );
  }

  if (type === 'datetext') {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {label ? (
            <Typography
              variant={'body2'}
              style={{
                marginBottom: '-4px',
                transform: 'translate(0, 1.5px) scale(1.0)',
                transformOrigin: 'top left',
              }}
            >
              {label}
            </Typography>
          ) : null}
          <Input
            ref={inputRef}
            style={style}
            disabled={readOnly}
            placeholder={placeholder}
            className={
              'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
                ? className
                : classes.textField
            }
            value={fields.value}
            onChange={(e) => {
              if (e.target.value.length > 10)
                formik.setFieldValue(fields.name, e.target.value.substring(0, 10), false);
              else formik.setFieldValue(fields.name, e.target.value, false);
            }}
            onBlur={(e) => {
              if (onValueChange && e.target.value) {
                onValueChange(e);
              }
            }}
          />
          {hints ? (
            <Typography
              variant={'caption'}
              style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
            >
              {hint}
            </Typography>
          ) : null}
        </LocalizationProvider>
      </>
    );
  }

  return (
    <>
      {label ? (
        <Typography
          variant={'body2'}
          style={{
            marginBottom: '-4px',
            transform: 'translate(0, 1.5px) scale(0.90)',
            transformOrigin: 'top left',
            color:
              theme && theme.theme
                ? hasError
                  ? theme.theme.palette.error.main
                  : theme.theme.palette.text.primary
                : 'primary',
          }}
        >
          {displayLabel}
        </Typography>
      ) : null}
      <Input
        fullWidth
        multiline={multiline}
        error={hasError}
        ref={inputRef}
        style={style}
        disabled={readOnly}
        placeholder={placeholder}
        type={type}
        className={
          'MuiInputBase-input MuiInput-input MuiInputBase-root' + className
            ? className
            : classes.textField
        }
        {...fields}
        value={fields.value}
        onChange={(e) => {
          formik.setFieldValue(fields.name, e.target.value, false);
        }}
        onBlur={(e) => {
          if (onValueChange && e.target.value) {
            onValueChange(e);
          }
        }}
      />
      {hasError ? (
        <Typography
          variant={'caption'}
          style={{ color: theme && theme.theme ? theme.theme.palette.error.main : 'red' }}
        >
          {errorText}
        </Typography>
      ) : null}
      {hints ? (
        <Typography
          variant={'caption'}
          style={{ color: theme && theme.theme ? theme.theme.hint : 'primary' }}
        >
          {hint}
        </Typography>
      ) : null}
    </>
  );

  /*
  return (
    <>
      <TextField
        style={style}
        label={displayLabel}
        disabled={readOnly}
        className={'MuiInputBase-input MuiInput-input MuiInputBase-root' + className ? className : classes.textField}
        type={type}
        placeholder={placeholder}
        {...fields}
        error={hasError}
        helperText={errorText}
      />
      {hints ? (
        <Typography variant={'caption'} style={{ color: theme ? theme.theme.palette.text.hint : 'primary' }}>
          {hint}
        </Typography>
      ) : null}
    </>
  );
  */
};

export const HelperTextField = HelperTextFieldUnmapped;
export default HelperTextField;
