//import DateFnsUtils from '@date-io/date-fns';
import { Container, Grid, Link, Paper, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { BaseContainerAlerts } from 'fields/basecontainer_alerts';
import { BaseContainerButtons } from 'fields/basecontainer_buttons';
import { BaseContainerStatus } from 'fields/basecontainer_status';
import { BaseContainerReminder } from 'fields/basecontainer_reminder';
import { Account, Agreement, Opportunity, Reminder, User } from '@earnenterprise/asc-models';
import { useFormikContext } from 'formik';
import { BaseContainerFunctions } from './basecontainer_functions';

interface Props {
  id?: string | number | null;
  type?: string;
  showReminders?: boolean;
  reminders?: Reminder[] | null;
  reminderUser?: User | null;
  reminderAccount?: Account | null;
  reminderOpportunity?: Opportunity | null;
  reminderAgreement?: Agreement | null;
  header: string;
  subheader?: string;
  submitButton?: boolean;
  submitText?: string;
  deleteText?: string;
  resetText?: string;
  readonly?: boolean;
  error?: string;
  warning?: string;
  info?: string;
  onReset?: any;
  onDelete?: any;
  isSubmitting?: boolean;
  dirty?: boolean;
  status?: { opportunity: boolean; quote: boolean; order: boolean; agreement: boolean };
  buttons?: { name: string; fn: any; icon?: any; disabled?: boolean; neverDisable?: boolean }[];
  functions?: { name: string; fn: any; icon?: any }[];
  linkHeaders?: { header: string; fn: React.MouseEventHandler }[];
  children?: React.ReactNode;
}

export const BaseContainerFormik = ({ header, readonly, subheader, children, ...props }: Props) => {
  const formik = useFormikContext();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px' }}>
            <Grid container direction="row">
              {props.showReminders === true && (
                <BaseContainerReminder
                  id={props.id}
                  type={props.type}
                  reminders={props.reminders}
                  reminderAccount={props.reminderAccount}
                  reminderOpportunity={props.reminderOpportunity}
                  reminderAgreement={props.reminderAgreement}
                  reminderUser={props.reminderUser}
                />
              )}
              <Grid item>
                <Typography variant="h6">{header}</Typography>
                {subheader && <Typography variant="body2">{subheader}</Typography>}
              </Grid>
              {!props.status && !props.functions && (
                <Grid item sm style={{ justifyContent: 'end' }}></Grid>
              )}
              <BaseContainerStatus status={props.status} />
              <Grid item xs style={{ textAlign: 'end' }}>
                {props.linkHeaders?.map((linkHeader, indexHeader) => (
                  <Fragment key={indexHeader}>
                    {indexHeader > 0 && ' / '}
                    <Link onClick={linkHeader.fn} underline="none" style={{ cursor: 'pointer' }}>
                      {linkHeader.header}
                    </Link>
                  </Fragment>
                ))}
                {!readonly && (
                  <BaseContainerFunctions formikContext={formik} functions={props.functions} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: '16px' }}>
            {!readonly && (
              <BaseContainerAlerts error={props.error} warning={props.warning} info={props.info} />
            )}
            <Grid container spacing={3}>
              {children}
            </Grid>
            {!readonly && <BaseContainerButtons {...props} />}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BaseContainerFormik;
