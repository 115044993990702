function baseSanitize(value, outputType) {
    // Strings
    switch (outputType) {
        case "time":
            try {
                //console.log({ type: typeof value, outputType })
                if (value === null || value === undefined)
                    return new Date();
                else if (typeof value !== 'object') {
                    return new Date(Date.parse(value.toString())).toISOString();
                }
                return value.toISOString();
            }
            catch (_a) {
                console.error({ 'Invalid date for ${outputType}': value });
                return undefined;
            }
        case "?time":
            try {
                //console.log({ type: typeof value, outputType })
                if (value === null || value === undefined)
                    return undefined;
                else if (typeof value !== 'object') {
                    return new Date(Date.parse(value.toString())).toISOString();
                }
                return value.toISOString();
            }
            catch (_b) {
                console.log('Invalid date for ${outputType}', value);
                return undefined;
            }
        case "string":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return '';
            else if (typeof value !== 'string') {
                return value.toString();
            }
            return value;
        case "?string":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return undefined;
            else if (typeof value !== 'string') {
                return value.toString();
            }
            return value;
        case "int":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return 0;
            else if (typeof value !== 'number') {
                try {
                    return parseInt(value, 10);
                }
                catch (_c) {
                    console.error('Error parsing int', { value, outputType });
                }
            }
            return Math.round(value);
        case "?int":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return undefined;
            else if (typeof value !== 'number') {
                try {
                    return parseInt(value, 10);
                }
                catch (_d) {
                    console.error('Error parsing int', { value, outputType });
                }
            }
            return value ? Math.round(value) : undefined;
        case "!int":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return undefined;
            else if (typeof value !== 'number') {
                try {
                    return parseInt(value, 10);
                }
                catch (_e) {
                    console.error('Error parsing int', { value, outputType });
                }
            }
            return value ? Math.round(value) : undefined;
        case "float":
        case "double":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return 0;
            else if (typeof value !== 'number') {
                try {
                    return parseFloat(value.toString());
                }
                catch (_f) {
                    console.error('Error parsing float', { value, outputType });
                }
            }
            return value;
        case "?float":
        case "?double":
            //console.log({ type: typeof value, key })
            if (value === null || value === undefined)
                return undefined;
            else if (typeof value !== 'number') {
                try {
                    return parseFloat(value.toString());
                }
                catch (_g) {
                    console.error('Error parsing float', { value, outputType });
                }
            }
            return value;
        case "bool":
        case "boolean":
            if (value === null || value === undefined)
                return false;
            else if (typeof value !== 'boolean') {
                try {
                    switch (value.toString().toUpperCase()) {
                        case "TRUE":
                        case "1":
                            return true;
                        case "FALSE":
                        case "0":
                            return false;
                        default:
                            console.error('Error parsing bool', { value, outputType });
                            break;
                    }
                }
                catch (_h) {
                    console.error('Error parsing bool', { value, outputType });
                }
            }
            return value;
        case "?bool":
        case "?boolean":
            if (value === null || value === undefined)
                return undefined;
            else if (typeof value !== 'boolean') {
                try {
                    switch (value.toString().toUpperCase()) {
                        case "TRUE":
                        case "1":
                            return true;
                        case "FALSE":
                        case "0":
                            return false;
                        default:
                            console.error('Error parsing bool', { value, outputType });
                            break;
                    }
                }
                catch (_j) {
                    console.error('Error parsing bool', { value, outputType });
                }
            }
            return value;
    }
    throw ({ 'Error parsing type': { value, outputType } });
}
function sanitize(input, outputType, name) {
    //console.groupCollapsed('Sanitize');
    // This is not an object? Basic type?
    if (!isObject(input)) {
        console.log({ name: name ? name : 'ROOT', input });
        return input;
    }
    // Check if this is an array
    if (Array.isArray(input)) {
        const output = Object.assign([], input);
        for (let i = 0; i < input.length; i++) {
            output[i] = sanitize(input[i], outputType, `${name}[${i}]`);
        }
        console.log({ name: name ? name : 'ROOT', output });
        return output;
    }
    // Create new output object from input
    const output = Object.assign({}, input);
    // Loop through all object values if this input is an object
    if (isObject(output)) {
        // Empty object?
        if (Object.keys(output).length === 0) {
            console.log('empty input');
            return output;
        }
        // Check if key exists in entries
        Object.entries(outputType).forEach(([outputKey, outputValue]) => {
            const index = Object.entries(output).findIndex(([key]) => key === outputKey);
            // Missing key in index, set default values.
            if (index === -1) {
                switch (outputValue) {
                    case "string":
                        output[outputKey] = '';
                        break;
                    case "int":
                    case "float":
                    case "double":
                        output[outputKey] = 0;
                        break;
                }
            }
        });
        Object.entries(output).forEach(([key, value]) => {
            // Check for keys that should be removed
            const index = Object.entries(outputType).findIndex(([outputKey]) => key === outputKey);
            if (index === -1) {
                console.log({ name: name ? name : 'ROOT', delete: key });
                delete output[key];
                return;
            }
            // Loop through all outputType keys and values
            Object.entries(outputType).forEach(([outputKey, outputValue]) => {
                if (key === outputKey) {
                    switch (outputValue) {
                        case "string":
                        case "?string":
                        case "int":
                        case "?int":
                        case "float":
                        case "?float":
                        case "double":
                        case "?double":
                        case "bool":
                        case "?bool":
                        case "boolean":
                        case "?boolean":
                            output[key] = baseSanitize(value, outputValue);
                            break;
                        case "time":
                        case "?time":
                        case "!int":
                            output[key] = baseSanitize(value, outputValue);
                            if (output[key] === undefined)
                                delete output[key];
                            break;
                        default:
                            // Array of objects
                            if (isObject(value) && Array.isArray(value)) {
                                if (Array.isArray(outputValue)) {
                                    // Output value is an array of objects, ie ['int']
                                    for (let i = 0; i < value.length; i++) {
                                        //console.log({ name: name ? name : 'ROOT', baseSanitize: outputValue[0] })
                                        if (Array.isArray(value[i])) {
                                            for (let j = 0; j < value[i].length; j++) {
                                                output[key][i][j] = baseSanitize(value[i][j], outputValue[0][0]);
                                            }
                                        }
                                        else {
                                            output[key][i] = baseSanitize(value[i], outputValue[0]);
                                        }
                                    }
                                }
                                else {
                                    // Output value is an object, ie { int: 'int' }
                                    for (let i = 0; i < value.length; i++) {
                                        //console.log({ name: name ? name : 'ROOT', valueI: value[i] })
                                        // Do we have an empty object?
                                        const useUndefined = !value[i] || Object.keys(value[i]).length === 0;
                                        if (isObject(value[i]) && isObject(outputValue) && useUndefined) {
                                            value.splice(i, 1);
                                            output[key].splice(i, 1);
                                            i--;
                                        }
                                        else if (output[key][i]) {
                                            // Delete unwanted subkeys
                                            Object.entries(value[i]).forEach(([valueKey]) => {
                                                const index = Object.entries(outputValue).findIndex(([outputKey]) => valueKey === outputKey);
                                                if (index === -1)
                                                    delete value[i][valueKey];
                                            });
                                            Object.entries(outputValue).forEach(([tmpKey, tmpValue]) => {
                                                // TODO: Check for object?
                                                if (!isObject(tmpValue)) {
                                                    //console.log({ key, tmpKey, name, tmpValue })
                                                    output[key][i][tmpKey] = baseSanitize(useUndefined ? undefined : value[i][tmpKey], tmpValue);
                                                    if (output[key][i][tmpKey] === undefined) {
                                                        if (tmpValue === "!int") {
                                                            console.log({ name: name ? name : 'ROOT', delete: tmpKey });
                                                            delete output[key][i][tmpKey];
                                                        }
                                                    }
                                                }
                                                else {
                                                    //console.log({ key, tmpKey, name })
                                                    if (value[i][tmpKey]) {
                                                        output[key][i][tmpKey] = sanitize(useUndefined ? undefined : value[i][tmpKey], tmpValue, tmpKey);
                                                    }
                                                    else {
                                                        // We should delete empty objects
                                                        console.log({ name: name ? name : 'ROOT', delete: tmpKey });
                                                        delete output[key][i][tmpKey];
                                                    }
                                                }
                                            });
                                        }
                                        else {
                                            console.log({ name: name ? name : 'ROOT', notValue: output[key][i] });
                                        }
                                    }
                                }
                            }
                            else if (isObject(value)) {
                                output[key] = sanitize(value, outputValue, key);
                            }
                            else {
                                if (isObject(outputValue)) {
                                    // We should delete subobjects without a key
                                    delete output[key];
                                }
                                else {
                                    console.error("Unknown type", { type: typeof value, key });
                                }
                            }
                            break;
                    }
                }
            });
        });
    }
    console.log({ name: name ? name : 'ROOT', output });
    return output;
}
function isObject(input) {
    return typeof input === 'object' && input !== null;
}

export { sanitize };
