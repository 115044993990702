import { sanitize } from '@earnenterprise/asc-sanitize';
import { handleAxiosGraphQLResponse, unboxAxios } from '@earnenterprise/react-network';
import authQLService from 'services/authQL.service';
import {
  GQLQueryParameters,
  GQLReturnType,
  httpDelete,
  httpGet,
  httpList,
  httpPost,
  httpRequest,
  httpSave,
} from 'services/gql.service';

type ServiceType<D extends object> = {
  get: (id: number | string | null, useRefreshToken?: boolean) => Promise<GQLReturnType<D>>;
  list: (
    queryParameters: GQLQueryParameters,
    useRefreshToken?: boolean
  ) => Promise<GQLReturnType<D[]>>;
  save: (
    id: number | string | null,
    data: any,
    sanitizeObject: any,
    useRefreshToken?: boolean
  ) => Promise<GQLReturnType>;
  remove: (id: number | string | null, useRefreshToken?: boolean) => Promise<GQLReturnType>;
  request: (
    query?: string,
    useRefreshToken?: boolean,
    pathNameOverride?: string
  ) => Promise<GQLReturnType>;
  post: (
    query?: string,
    useRefreshToken?: boolean,
    pathNameOverride?: string
  ) => Promise<GQLReturnType>;
};

export const createService = <D extends object>(
  pathName: string,
  respFields: string,
  extra?: object
) => {
  const list = async (
    queryParameters: GQLQueryParameters,
    useRefreshToken?: boolean
  ): Promise<GQLReturnType<D[]>> => {
    const { response, error } = await unboxAxios(
      httpList(pathName, respFields, queryParameters, useRefreshToken)
    );

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      return list(queryParameters, true);
    }

    return handleAxiosGraphQLResponse({ response, error, type: pathName + 's' });
  };

  const request = async (
    query?: string,
    useRefreshToken?: boolean,
    pathNameOverride?: string
  ): Promise<GQLReturnType> => {
    const { response, error } = await unboxAxios(
      httpRequest(
        pathNameOverride ? pathNameOverride : pathName,
        respFields,
        query,
        useRefreshToken
      )
    );

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      return request(query, true, pathNameOverride);
    } else if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      useRefreshToken
    ) {
      authQLService.logout();
    }

    return handleAxiosGraphQLResponse({
      response,
      error,
      type: pathNameOverride ? pathNameOverride : pathName,
    });
  };

  const post = async (
    query?: string,
    useRefreshToken?: boolean,
    pathNameOverride?: string
  ): Promise<GQLReturnType> => {
    const { response, error } = await unboxAxios(
      httpPost(pathNameOverride ? pathNameOverride : pathName, respFields, query, useRefreshToken)
    );

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      return post(query, true, pathNameOverride);
    } else if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      useRefreshToken
    ) {
      authQLService.logout();
    }

    return handleAxiosGraphQLResponse({
      response,
      error,
      type: pathNameOverride ? pathNameOverride : pathName,
    });
  };

  const get = async (
    id: number | string | null,
    useRefreshToken?: boolean
  ): Promise<GQLReturnType<D>> => {
    if (id === null) throw Error('id cannot be null');
    const { response, error } = await unboxAxios(
      httpGet(id, pathName, respFields, useRefreshToken)
    );

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      return get(id, true);
    } else if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      useRefreshToken
    ) {
      authQLService.logout();
    }

    return handleAxiosGraphQLResponse({ response, error, type: pathName });
  };

  const save = async (
    id: number | string | null,
    data: any,
    sanitizeObject: any,
    useRefreshToken?: boolean
  ): Promise<GQLReturnType> => {
    const sanitizedData = sanitize(data, sanitizeObject);

    // Send query
    const { response, error } = await unboxAxios(
      httpSave(id, pathName, respFields, sanitizedData, useRefreshToken)
    );

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      console.log('Need to repeat request, token expired.');
      return save(id, data, sanitizeObject, true);
    }

    // Which response should we use?
    if (id && id !== '0' && id !== 0) {
      return handleAxiosGraphQLResponse({
        response,
        error,
        type: `${pathName}Save`,
      });
    } else {
      return handleAxiosGraphQLResponse({
        response,
        error,
        type: `${pathName}Create`,
      });
    }
  };

  const remove = async (
    id: number | string | null,
    useRefreshToken?: boolean
  ): Promise<GQLReturnType> => {
    if (id === null) throw Error('id cannot be null');
    const { response, error } = await unboxAxios(httpDelete(id, pathName, useRefreshToken));

    // Retry if unauthorized and without token
    if (
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0].message === 'Unauthorized' &&
      !useRefreshToken
    ) {
      return remove(id, true);
    }
    return handleAxiosGraphQLResponse({
      response,
      error,
      type: `${pathName}Delete`,
    });
  };

  return { get, list, save, remove, request, post, ...extra } as ServiceType<D> & typeof extra;
};
