/* External lib imports. */
import { Button, CssBaseline } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch } from 'react-redux';
import { ErrorProvider } from 'error/error.context';

/* My Imports */
import { refreshToken } from 'redux/actions/auth.actions';
import { CCRouter } from 'router';
import { authQLService } from 'services/authQL.service';
import { SplashASC } from 'styling/splashASC';
import { ASCThemeProvider } from 'styling/themeContext';
import { LanguageProvider } from 'translation/languageContext';
import { Cron } from 'services/cron.service';
import { WebSocketService } from 'services/websocket.service';
import { ASCThemeContext } from '@earnenterprise/asc-theme';

//import * as Sentry from '@sentry/react';
//import { Integrations } from '@sentry/tracing';

export const BACKEND = process.env.FILES_PATH ? process.env.FILES_PATH : '/files/';
export const VERSION = 10900;

// ! Global VAT for exposure is 6.9% as of 2020-01-01
// ! Global VAT for exposure is 0.0% as of 2022-01-01
export const getGlobalVAT = () => {
  return 0.0;
};

// Create a client
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 30 * 60 * 1000, refetchOnWindowFocus: false } },
});

/*
Sentry.init({
  dsn: 'https://4ead6cb21c524dcb89bf068bb7a58ed0@o512608.ingest.sentry.io/5613141',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
*/

export const ThemeContext = React.createContext<ASCThemeContext>({} as ASCThemeContext);

/**
 *
 */
export const AppFC: React.FC = () => {
  let themeName = localStorage.getItem('theme');
  if (!themeName) themeName = 'Default';
  let language = localStorage.getItem('language');
  if (!language) language = 'Swedish';
  const [splash, setSplash] = useState(false);
  const [online, setOnline] = useState(false);

  const dispatch = useDispatch();
  const notistackRef = React.createRef<SnackbarProvider>();

  // Check once and then every 20 minutes if the refresh token needs updating.
  if (authQLService.expireInMinutes() < 60 * 24) {
    dispatch(refreshToken());
  }
  setInterval(() => {
    if (authQLService.expireInMinutes() < 60 * 24) {
      dispatch(refreshToken());
    }
  }, 20 * 60 * 1000);

  const onClickDismiss = (key?: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WebSocketService>
          <Cron online={online} setOnline={setOnline} />
          <ASCThemeProvider context={ThemeContext} defaultTheme={themeName}>
            {splash && <SplashASC onSplashComplete={() => setSplash(false)} />}
            <LanguageProvider defaultLanguage={language}>
              <SnackbarProvider
                ref={notistackRef}
                action={(key: SnackbarKey) => <Button onClick={onClickDismiss(key)}>{'OK'}</Button>}
                maxSnack={10}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ErrorProvider>
                  <>
                    <CssBaseline />
                    {splash ? null : <CCRouter online={online} />}
                  </>
                </ErrorProvider>
              </SnackbarProvider>
            </LanguageProvider>
          </ASCThemeProvider>
          <ReactQueryDevtools initialIsOpen={true} />
        </WebSocketService>
      </QueryClientProvider>
    </>
  );
};

/*
<ErrorProvider>
<>
  <CssBaseline />
  {splash ? null : <CCRouter online={online} />}
</>
</ErrorProvider>
*/
