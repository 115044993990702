import { Button, Grid, Chip, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useContext, Fragment, useState, useEffect } from 'react';
import { LanguageContext } from 'translation/languageContext';
import useReduxState from 'components/hooks/useReduxState';
import { Tag } from '@earnenterprise/asc-models';
import globalStyles from 'styling/global.styles';
import { useDispatch } from 'react-redux';
import { fetchTags } from 'redux/actions/items.actions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface Props {
  linkType: string;
  from: Date | null;
  to: Date | null;
  tags?:
    | 'Global'
    | 'Article'
    | 'Agreement'
    | 'Opportunity'
    | 'Quote'
    | 'Account'
    | 'Account,Article'
    | 'Message';
  defaultTags?: string[];
  setFrom: (date: Date | null) => void;
  setTo: (date: Date | null) => void;
  setCategories?: (category: any) => void;
  setSelectedTags?: (tags: string[]) => void;
  hideDates?: boolean;
}

/**
 * Validation
 */
// const validationSchema = yup.object({});

export const getFromDate = (offset: number) => {
  const date = new Date();
  if (date.getMonth() < 4)
    return new Date((date.getFullYear() - 1 + offset).toString() + '-05-01T00:00:00Z');
  return new Date((date.getFullYear() + offset).toString() + '-05-01T00:00:00Z');
};

export const getToDate = (offset: number) => {
  const date = new Date();
  if (date.getMonth() < 4)
    return new Date((date.getFullYear() + offset).toString() + '-04-30T23:59:59Z');
  return new Date((date.getFullYear() + 1 + offset).toString() + '-04-30T23:59:59Z');
};

/**
 * Userinfo
 * @param props
 */
export const ReportFilterComponent: React.FC<Props> = (props: Props) => {
  const { tags, workStrings } = useReduxState({ fetched: ['tags'] });
  const { translate } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const classes = globalStyles();
  const [selectedTags, setSelectedTags] = useState<string[]>(
    props.defaultTags ? props.defaultTags : []
  );

  /*
  if (tags === undefined) {
    dispatch(fetchTags({}));
  }
  */

  useEffect(() => {
    //if (onChangeTags) onChangeTags(selectedTags, match);
    if (tags === undefined) dispatch(fetchTags({ workStrings }));

    return function cleanup() {
      //console.log({ cleanup: '' });
    };
    //}, [onChangeTags, selectedTags, match, tags, workStrings, dispatch]);
  }, [selectedTags, tags, workStrings, dispatch]);

  /**
   *
   * @param offset
   */
  const checkSeason = (offset: number) => {
    return (
      props.from?.toISOString().substring(0, 10) ===
        getFromDate(offset).toISOString().substring(0, 10) &&
      props.to?.toISOString().substring(0, 10) === getToDate(offset).toISOString().substring(0, 10)
    );
  };

  /**
   *
   * @param offset
   */
  const setSeason = (offset: number) => {
    const from: Date = getFromDate(offset);
    const to: Date = getToDate(offset);
    if (props.setCategories) props.setCategories([]);
    props.setFrom(from);
    props.setTo(to);
  };

  /**
   *
   * @param tag
   */
  const toggleSelectedTag = (tag: string) => {
    const index = selectedTags.findIndex((a) => a === tag);
    if (index >= 0) {
      selectedTags.splice(index, 1);
    } else {
      selectedTags.push(tag);
    }
    setSelectedTags(Object.assign([], selectedTags));
    if (props.setSelectedTags) props.setSelectedTags(Object.assign([], selectedTags));
  };

  // Render
  return (
    <>
      <Grid item xs={12}>
        <Button color={checkSeason(-1) ? 'primary' : 'secondary'} onClick={() => setSeason(-1)}>
          {translate('Previous season')}
        </Button>
        <Button color={checkSeason(0) ? 'primary' : 'secondary'} onClick={() => setSeason(0)}>
          {translate('This season')}
        </Button>
        <Button color={checkSeason(1) ? 'primary' : 'secondary'} onClick={() => setSeason(1)}>
          {translate('Next season')}
        </Button>
      </Grid>
      {tags && props.tags && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {tags &&
              tags.map((tag: Tag, index: number) => {
                return (
                  <Fragment key={index}>
                    {(props.tags === tag.type ||
                      tag.type === 'Global' ||
                      (props.tags
                        ? props.tags.split(',').findIndex((name) => name === tag.type) >= 0
                        : false)) && (
                      <Chip
                        className={classes.chip}
                        style={{ cursor: 'pointer', marginBottom: '5px' }}
                        onClick={() => {
                          toggleSelectedTag(tag.name);
                        }}
                        label={tag.name}
                        color="primary"
                        variant={
                          selectedTags.findIndex((selTag) => selTag === tag.name) === -1
                            ? 'outlined'
                            : 'filled'
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
          </Grid>
        </Grid>
      )}
      {!props.hideDates && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                value={props.from}
                label={translate('From')}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(date: any) => {
                  if (props.setCategories) props.setCategories([]);
                  props.setFrom(date);
                }}
                renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                value={props.to}
                label={translate('To')}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(date: any) => {
                  if (props.setCategories) props.setCategories([]);
                  props.setTo(date);
                }}
                renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
              />
            </Grid>
          </LocalizationProvider>
        </>
      )}
    </>
  );
};

export default ReportFilterComponent;
