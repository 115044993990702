import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  Typography,
  Icon,
  Container,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionProps } from '@mui/material/transitions';
import React, { useContext } from 'react';
import {} from 'react-router-dom';

import { Article } from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';
import ArticleAreaInfo from 'routes/article/article.area.info';
import { ArticleListTable } from 'routes/article/article.list';
import { Opportunity } from '@earnenterprise/asc-models';

interface Props {
  open: boolean;
  opportunity: Opportunity;
  onClose: () => void;
  onAddArticle: (article: Article) => void;
  onDisplayArticleChanged: (article: Article | undefined) => void;
  displayArticle?: Article;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'fixed',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref as any} {...(props as any)} />;
});

export const OpportunityArticleAddComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <Icon>close</Icon>
            </IconButton>
            <Typography variant="h6" className={classes.title} color="primary">
              {translate('Add article')}
            </Typography>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              {translate('Done')}
            </Button>
          </Toolbar>
        </AppBar>
        <Grid item xs={12} style={{ paddingTop: '90px' }}>
          {props.displayArticle && (
            <ArticleAreaInfo
              isInline={true}
              onClose={() => {
                props.onDisplayArticleChanged(undefined);
              }}
              from={props.opportunity.from}
              to={props.opportunity.to}
              onAddArticle={props.onAddArticle}
              id={props.displayArticle.id ? props.displayArticle.id : 0}
            />
          )}
          {!props.displayArticle && (
            <Container>
              <ArticleListTable
                from={props.opportunity.from}
                to={props.opportunity.to}
                isInline={true}
                onAddArticle={props.onAddArticle}
                onDisplayArticleChanged={props.onDisplayArticleChanged}
                displayType={'grid'}
                containerProps={{ style: { paddingBottom: '24px' } }}
              />
            </Container>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

export default OpportunityArticleAddComponent;
