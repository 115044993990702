export const msalConfig = {
  auth: {
    clientId: '9ad303a3-ce18-4223-9e35-7f4deea54f03', //This is your client ID
    authority: 'https://login.microsoftonline.com/fbc33648-70de-450d-8c7b-5d0ad852cec0', //This is your tenant info
    redirectUri: window.origin, //This is your redirect URI
  },
  cache: {
    cacheLocation: 'localStorage' as 'localStorage' | 'sessionStorage' | undefined,
    storeAuthStateInCookie: true,
  },
};

export const requestObj = {
  scopes: ['user.read', 'email', 'openid', 'profile', 'mail.send', 'mail.read'],
};
