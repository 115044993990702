import { Card, CardContent, CardHeader, Container, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing ? theme.spacing(2) : 2,
    },
    debugContainer: {
      marginTop: theme.spacing ? theme.spacing(2) : 2,
    },
    card: {
      backgroundColor: '#b33',
    },
    debugCard: {
      backgroundColor: '#eee',
      color: '#000',
      fontSize: '10px',
    },
  })
);

// Error Information Props
interface ErrorProps {
  error: string | null;
  description?: any;
}

// - Debug Information Props
interface DebugProps {
  value?: any;
}

/**
 *
 * @param props
 */
export const ErrorAlert: React.FC<ErrorProps> = (props) => {
  const classes = useStyles();

  if (!props.error) {
    return null;
  }

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs>
          <Card className={classes.card}>
            <CardHeader title={props.error}></CardHeader>
            <CardContent>{props.description && <span>{props.description}</span>}</CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

/**
 *
 * @param props
 */
export const Debug: React.FC<DebugProps> = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.debugContainer}>
      <Grid container>
        <Grid item xs>
          <Card className={classes.debugCard}>
            <CardContent>
              <pre>{JSON.stringify(props.value, null, 2)}</pre>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorAlert;
