import { Badge } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  iconStyle?: string;
  //labelIcon: React.ElementType<SvgIconProps> | React.Component;
  labelIcon: any;
  labelInfo?: string;
  badgeCount?: number;
  labelText: string;

  href?: string;
};

export const StyledTreeItem: React.FC<StyledTreeItemProps> = (props: StyledTreeItemProps) => {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, iconStyle, labelInfo, badgeCount, ...other } = props;
  const history = useNavigate();

  return (
    <TreeItem
      onMouseDown={(event: any) => {
        if (event.button === 1) {
          event.preventDefault();
          if (props.href) {
            const newWindow = window.open(props.href, '_blank');
            newWindow?.blur();
            window.focus();
          }
        }
      }}
      onClick={(event: any) => {
        event.preventDefault();
        if (props.href) history(props.href);
      }}
      label={
        <div className={classes.labelRoot} title={labelText}>
          {/* <LabelIcon color="primary" className={iconStyle} /> */}
          <span color="primary" className={iconStyle} style={{ fontSize: '8px' }}>
            {LabelIcon}
          </span>
          <Typography variant="body2">&nbsp;&nbsp;{labelText}</Typography>
          <Typography variant="subtitle2" color="primary">
            {labelInfo}
          </Typography>
          {badgeCount !== undefined && badgeCount > 0 && (
            <Badge
              badgeContent={badgeCount}
              style={{ marginLeft: '1rem', display: badgeCount === 0 ? 'hidden' : 'inline' }}
              color={'primary'}
            />
          )}
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      /*
      color: 'var(--primary)',
      '&:focus > $content': {
        backgroundColor: `var(--bg-primary)`,
        color: 'var(--focused)'
      }
      */
    },
    content: {
      paddingLeft: theme.spacing ? theme.spacing(0) : 0,
      /*
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      */
      '$expanded > &': {
        paddingLeft: theme.spacing ? theme.spacing(2) : 2,
      },
    },
    group: {
      marginLeft: 0,
      /*
      '& $content': {
        paddingLeft: theme.spacing(2)
      }
      */
    },
    expanded: {},
    label: {
      /*
      fontWeight: 'inherit',
      color: 'inherit'
      */
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing ? theme.spacing(0.5, 0) : 0,
    },
    labelIcon: {
      marginRight: theme.spacing ? theme.spacing(2) : 2,
    },
    labelText: {
      fontWeight: 'normal',
      flexGrow: 1,
    },
  })
);
