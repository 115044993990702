import React from 'react';
import { Link } from '@mui/material';
import { Agreement } from '@earnenterprise/asc-models';

export const renderAsAgreement = (agreement?: Agreement | null, history?: any) => {
  if (!agreement) return null;
  return (
    <Link
      underline="none"
      href={`/agreement/${agreement.id}`}
      onClick={(e: any) => {
        if (history) {
          e.preventDefault();
          history(`/agreement/${agreement.id}`);
        }
      }}
    >
      {agreement.name}
    </Link>
  );
};
