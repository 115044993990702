import React, { useEffect, useState } from 'react';
import {
  ASCThemeProviderProps,
  createTheme,
  Theme as MyTheme,
  MuiTheme as Theme,
} from '@earnenterprise/asc-theme';
import { gqlThemeService } from 'services/gql.services';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ThemeContext } from 'App';

export let themeOptions: MyTheme[] = [];

export const ASCThemeProvider: React.FC<ASCThemeProviderProps> = ({ defaultTheme, children }) => {
  const [theme, setTheme] = useState<string | number | undefined>();
  const [muiTheme, setMuiTheme] = useState<Theme>();

  // Run on load, to fetch default theme.
  useEffect(() => {
    const theme = defaultTheme ? (defaultTheme === 'Default' ? 1 : defaultTheme) : 1;

    const t: MyTheme | undefined = themeOptions.find(
      (option) =>
        option.theme.name === theme || option.id === theme || option.id === parseInt(theme + '')
    );
    if (!t) {
      gqlThemeService.list({}).then((data: any) => {
        const themes = data.data as MyTheme[];
        themeOptions = themes;
        changeTheme(theme, true);
      });
    }
  }, [defaultTheme]);

  const changeTheme = async (name: number | string, force?: boolean) => {
    //if (name !== 'Default') {
    if (name === 'Default') name = '1';

    const data = await (gqlThemeService as any).getTheme(name, false);
    if (data.error) {
      console.error({ error: 'could not find theme', data });
      name = 'Default';
    }

    if (data.data?.theme) {
      const index = themeOptions.findIndex(
        (t) => t.theme.name === (data.data as MyTheme).theme.name
      );
      if (index === -1) themeOptions.push(data.data);
    } else if (data.theme) {
      const index = themeOptions.findIndex((t) => t.theme.name === (data as MyTheme).theme.name);
      if (index === -1) themeOptions.push(data);
    } else {
      console.error('no theme supplied?');
      console.error(data);
    }
    //}
    if (name !== localStorage.getItem('theme') || force) {
      setMuiTheme(createTheme(themeOptions, name) as Theme);
    }
    localStorage.setItem('theme', name.toString());
    setTheme(name);
  };

  //console.log({ theme, muiTheme });

  // Dont display if we dont have a theme
  if (!theme) return <></>;
  if (!muiTheme) return <></>;

  return (
    <ThemeContext.Provider value={{ name: theme.toString(), theme: muiTheme, changeTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
