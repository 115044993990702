/* External lib imports. */

import React from 'react';
import { useQuery } from 'react-query';
import { Theme } from '@earnenterprise/asc-theme';
/* My Imports */

import { authQLService } from 'services/authQL.service';
import {
  gqlGroupService,
  gqlMessageService,
  gqlSettingService,
  gqlThemeService,
} from 'services/gql.services';

interface CronProps {
  online: boolean;
  setOnline: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Cron: React.FC<CronProps> = (props) => {
  // Query for user
  const userQuery = useQuery(
    'user',
    async () => {
      //console.log({ App: 'refreshing user' });
      const user = await authQLService.getUser();
      if (user && !props.online) props.setOnline(true);
      if (!user && props.online) props.setOnline(false);

      return user;
    },
    {
      staleTime: 30 * 60 * 1000,
      refetchInterval: 30 * 60 * 1000, // ! Refresh every 30 minutes? Change 30 * 60 * 1000.
      refetchIntervalInBackground: true,
    }
  );

  // Query for rights
  const { data: rights } = useQuery(
    ['rights'],
    async () => {
      if (await authQLService.isOnline()) {
        const dataRights = await gqlGroupService.getRights();
        return dataRights;
      }
      return null;
    },
    {
      enabled: !!userQuery.data,
    }
  );

  useQuery(
    'themes',
    async () => {
      //console.log({ App: 'refreshing themes' });
      const data = await gqlThemeService.list({});
      const themes = data.data as Theme[];
      return themes;
    },
    {
      staleTime: 30 * 60 * 1000,
      refetchInterval: 30 * 60 * 1000, // ! Refresh every 30 minutes? Change 30 * 60 * 1000.
      refetchIntervalInBackground: true,
    }
  );

  // Messages
  useQuery(
    ['messages-user'],
    async () => {
      //console.log({ App: 'refreshing messages for user' });
      if (rights && rights.group && (rights.group.messageRead || rights.group.messageAll))
        return await gqlMessageService.list({ userId: userQuery.data.id, search: 'unread' }, false);
      return null;
    },
    {
      enabled: !!userQuery.data && !!rights,
      staleTime: 30 * 1000,
      refetchInterval: 30 * 1000, // Refresh messages every 30 seconds.
      refetchIntervalInBackground: true,
    }
  );

  // Settings
  useQuery(
    ['settings'],
    async () => {
      //console.log({ App: 'refreshing settings' });
      return await gqlSettingService.request();
    },
    {
      staleTime: 30 * 60 * 1000,
      refetchInterval: 30 * 60 * 1000, // ! Refresh every 30 minutes? Change 30 * 60 * 1000.
      refetchIntervalInBackground: true,
    }
  );

  return <></>;
};
