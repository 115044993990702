import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  accountSelector,
  accountsSelector,
  agreementSelector,
  agreementsSelector,
  articleSelector,
  articlesSelector,
  opportunitiesSelector,
  opportunitySelector,
  reportOpportunitiesSelector,
  quoteSelector,
  quotesSelector,
  themesSelector,
  userSelector,
  usersSelector,
  groupSelector,
  groupsSelector,
  imageSelector,
  imagesSelector,
  workCountSelector,
  workingSelector,
  cartSelector,
  reportAccountsSelector,
  reportArticlesSelector,
  messageSelector,
  messagesSelector,
  matchSelector,
  matchesSelector,
  presentationSelector,
  presentationsSelector,
  tagSelector,
  tagsSelector,
  externalMatchesSelector,
  reportInvoicesSelector,
} from 'redux/selectors/item.selectors';
import { ReduxState } from 'redux/state';
//import { ThemeContext } from 'styling/themeContext';
import { ThemeContext } from 'App';
import { ErrorContext } from 'error/error.context';
import { clearErrors } from 'redux/actions/items.actions';

type fetchedType =
  | 'cart'
  | 'working'
  | 'workCount'
  | 'account'
  | 'accounts'
  | 'article'
  | 'articles'
  | 'agreement'
  | 'agreements'
  | 'user'
  | 'users'
  | 'group'
  | 'groups'
  | 'quote'
  | 'quotes'
  | 'message'
  | 'messages'
  | 'match'
  | 'matches'
  | 'externalMatches'
  | 'image'
  | 'images'
  | 'themes'
  | 'presentation'
  | 'presentations'
  | 'opportunity'
  | 'opportunities'
  | 'reportOpportunities'
  | 'reportAccounts'
  | 'reportArticles'
  | 'reportInvoices'
  | 'reportInvoiceAfterwards'
  | 'reportInvoice'
  | 'tag'
  | 'tags'
  | 'theme';

/**
 *
 */
export interface Props {
  fetched: fetchedType[];
}

/**
 * Use Options hook
 * @param param0 { skipFetch }.
 */
export const useReduxState = ({ fetched: f }: Props) => {
  const cart = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'cart') !== -1 ? cartSelector(s) : undefined
  );
  const working = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'working') !== -1 ? workingSelector(s) : undefined
  );
  const workCount = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'workCount') !== -1 ? workCountSelector(s) : undefined
  );
  const account = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'account') !== -1 ? accountSelector(s) : undefined
  );
  const accounts = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'accounts') !== -1 ? accountsSelector(s) : undefined
  );
  const article = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'article') !== -1 ? articleSelector(s) : undefined
  );
  const articles = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'articles') !== -1 ? articlesSelector(s) : undefined
  );
  const agreement = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'agreement') !== -1 ? agreementSelector(s) : undefined
  );
  const agreements = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'agreements') !== -1 ? agreementsSelector(s) : undefined
  );
  const user = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'user') !== -1 ? userSelector(s) : undefined
  );
  const users = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'users') !== -1 ? usersSelector(s) : undefined
  );
  const group = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'group') !== -1 ? groupSelector(s) : undefined
  );
  const groups = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'groups') !== -1 ? groupsSelector(s) : undefined
  );
  const opportunity = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'opportunity') !== -1 ? opportunitySelector(s) : undefined
  );
  const opportunities = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'opportunities') !== -1 ? opportunitiesSelector(s) : undefined
  );
  const quote = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'quote') !== -1 ? quoteSelector(s) : undefined
  );
  const quotes = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'quotes') !== -1 ? quotesSelector(s) : undefined
  );
  const message = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'message') !== -1 ? messageSelector(s) : undefined
  );
  const messages = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'messages') !== -1 ? messagesSelector(s) : undefined
  );
  const match = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'match') !== -1 ? matchSelector(s) : undefined
  );
  const matches = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'matches') !== -1 ? matchesSelector(s) : undefined
  );
  const externalMatches = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'externalMatches') !== -1 ? externalMatchesSelector(s) : undefined
  );
  const image = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'image') !== -1 ? imageSelector(s) : undefined
  );
  const images = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'images') !== -1 ? imagesSelector(s) : undefined
  );

  const presentation = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'presentation') !== -1 ? presentationSelector(s) : undefined
  );
  const presentations = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'presentations') !== -1 ? presentationsSelector(s) : undefined
  );
  const reportOpportunities = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportOpportunities') !== -1
      ? reportOpportunitiesSelector(s)
      : undefined
  );
  const reportAccounts = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportAccounts') !== -1 ? reportAccountsSelector(s) : undefined
  );
  const reportArticles = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportArticles') !== -1 ? reportArticlesSelector(s) : undefined
  );
  const reportInvoices = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportInvoices') !== -1 ? reportInvoicesSelector(s) : undefined
  );
  const reportInvoiceAfterwards = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportInvoiceAfterwards') !== -1
      ? reportArticlesSelector(s)
      : undefined
  );
  const reportInvoice = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'reportInvoice') !== -1 ? reportArticlesSelector(s) : undefined
  );
  const themes = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'themes') !== -1 ? themesSelector(s) : undefined
  );
  const tag = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'tag') !== -1 ? tagSelector(s) : undefined
  );
  const tags = useSelector((s: ReduxState) =>
    f.findIndex((n) => n === 'tags') !== -1 ? tagsSelector(s) : undefined
  );

  const workStrings = useSelector((s: ReduxState) => (s.items ? s.items.workStrings : undefined));
  const error = useSelector((s: ReduxState) => (s.items ? s.items.error : undefined));
  const theme = useContext(ThemeContext);
  const { addError } = useContext(ErrorContext);
  const dispatch = useDispatch();

  if (error) {
    if (addError) addError(error);
    dispatch(clearErrors());
  }

  return {
    working,
    workCount,
    account,
    accounts,
    article,
    articles,
    agreement,
    agreements,
    user,
    users,
    group,
    groups,
    opportunity,
    opportunities,
    quote,
    quotes,
    message,
    messages,
    match,
    matches,
    externalMatches,
    themes,
    theme,
    tags,
    tag,
    image,
    images,
    presentation,
    presentations,
    reportOpportunities,
    reportAccounts,
    reportArticles,
    reportInvoices,
    reportInvoiceAfterwards,
    reportInvoice,
    workStrings,
    cart,
    error,
  };
};

export default useReduxState;
