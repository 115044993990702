import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorContext } from 'error/error.context';
import { remove } from 'models/helper.useItem';
import { GQLReturnType } from 'services/gql.service';
import { LanguageContext } from 'translation/languageContext';
import { Models } from '@earnenterprise/asc-models';

export interface Props {
  func: (id: number | string | null, useRefreshToken?: boolean) => Promise<GQLReturnType>;
  type:
    | 'account'
    | 'article'
    | 'agreement'
    | 'opportunity'
    | 'user'
    | 'quote'
    | 'message'
    | 'match'
    | 'presentation'
    | 'group'
    | 'package'
    | 'player'
    | 'reminder';
  skipRedirect?: boolean;
}

/**
 * Use Save hook
 * @param param0
 */
export const useDelete = ({ func, type, skipRedirect }: Props) => {
  const history = useNavigate();
  const { translate } = useContext(LanguageContext);
  const { snackbar } = useContext(ErrorContext);

  /**
   *
   * @param user
   * @param setSubmitting
   */
  async function removeItem(
    item: Models | undefined,
    setSubmitting?: (isSubmitting: boolean) => void
  ) {
    // Do we want to remove an item that does not exist?
    if (!item) return;

    const { error } = await remove(item, func, setSubmitting);
    if (error) {
      snackbar(translate('Failed to remove') + '!', 'error', error);
      return;
    }

    snackbar(translate('Removed successfully') + '!', 'success');
    if (!error && !skipRedirect) history(`/${type}/`);
  }

  return { removeItem };

  //return component.render();
};

export default useDelete;
