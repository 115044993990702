import { Link, Icon } from '@mui/material';
import { Column } from '@material-table/core';
import React from 'react';

/**
 *
 * @param title
 * @param path
 * @param history
 * @param userChanged
 * @param field
 */
export const ownerColumn = (
  title: string,
  path: string,
  history: any,
  userChanged: ((userId?: string | number | null) => void) | undefined,
  field = 'owner',
  hidden?: boolean,
  disableLink?: boolean
) => {
  return {
    title,
    field: field + '.firstname',
    hidden: hidden ? hidden : false,
    sorting: false,
    disableClick: true,
    render: (rowData: any) => {
      if (rowData[field])
        if (disableLink)
          return (
            <>
              {rowData[field].firstname}&nbsp;{rowData[field].lastname}
            </>
          );
      return (
        <>
          <Link
            href={`/user/${rowData[field].id}/${path}`}
            underline="none"
            onClick={(e: any) => {
              e.preventDefault();
              if (userChanged) userChanged(rowData[field]?.id);
              else history(`/user/${rowData[field]?.id}/${path}`);
            }}
          >
            {rowData[field].firstname}&nbsp;{rowData[field].lastname}
          </Link>
        </>
      );
    },
  };
};

/**
 *
 * @param title
 * @param path
 * @param history
 * @param userChanged
 * @param field
 */
export const accountColumn = (
  title: string,
  path: string,
  history: any,
  accountChanged: ((accountId?: string | number | null) => void) | undefined,
  field = 'account',
  disableLink?: boolean
) => {
  return {
    title,
    field: field + '.name',
    hidden: false,
    sorting: true,
    disableClick: true,
    width: 'auto',
    render: (rowData: any) => {
      if (rowData[field]) if (disableLink) return <>{rowData[field].name}</>;
      if (!rowData[field]) return <></>;
      return (
        <>
          <Link
            underline="none"
            href={`/account/${rowData[field].id}/${path}`}
            onClick={(e: any) => {
              e.preventDefault();
              if (accountChanged) accountChanged(rowData[field]?.id);
              else history(`/account/${rowData[field]?.id}/${path}`);
            }}
          >
            {rowData[field].name}
          </Link>
        </>
      );
    },
  };
};

/**
 *
 * @param title
 * @param path
 * @param history
 * @param userChanged
 * @param field
 */
export const readColumn = (
  title: string,
  path: string,
  history: any,
  accountChanged: ((accountId?: string | number | null) => void) | undefined,
  field = 'read'
): Column<any> => {
  return {
    title: '',
    field,
    hidden: false,
    sorting: false,
    disableClick: false,
    width: 1,
    headerStyle: { padding: '0px' },
    cellStyle: { padding: '0px' },
    render: (rowData: any) => {
      return (
        <>
          {rowData[field] ? (
            ''
          ) : (
            <span title={title}>
              <Icon style={{ marginLeft: '10px' }}>announcement</Icon>
            </span>
          )}
        </>
      );
    },
  };
};

/**
 *
 * @param title
 * @param path
 * @param history
 * @param userChanged
 * @param field
 */
export const opportunityColumn = (
  title: string,
  history: any,
  opportunityChanged: ((opportunityId?: string | number | null) => void) | undefined,
  field = 'opportunity',
  disableLink?: boolean
) => {
  return {
    title,
    field: field + '.number',
    hidden: false,
    sorting: false,
    disableClick: true,
    render: (rowData: any) => {
      if (rowData[field]) {
        if (disableLink) return <>{rowData[field].number}</>;
        return (
          <>
            <Link
              href={`/opportunity/${rowData[field].id}`}
              onClick={(e: any) => {
                e.preventDefault();
                if (opportunityChanged) opportunityChanged(rowData[field]?.id);
                else history(`/opportunity/${rowData[field]?.id}`);
              }}
            >
              {rowData[field].number}
            </Link>
          </>
        );
      }
    },
  };
};
