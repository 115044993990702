import { Grid, Hidden, IconButton, Icon } from '@mui/material';
import React, { useContext } from 'react';
import { globalStyles } from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';

/**
 *
 */
interface Props {
  status?: { opportunity: boolean; quote: boolean; order: boolean; agreement: boolean };
}

/**
 *
 * @param props
 */
export const BaseContainerStatus: React.FC<Props> = ({ status, ...props }) => {
  const { translate } = useContext(LanguageContext);
  const classes = globalStyles();

  return (
    <>
      {status ? (
        <Hidden xlDown>
          <Grid item sm style={{ textAlign: 'end' }}>
            <IconButton size="medium" title={translate('Opportunity')}>
              {/* <WorkOutlineIcon className={status.opportunity ? classes.iconOpportunity : ''} /> */}
              <Icon className={status.opportunity ? classes.iconOpportunity : ''}>
                work_outline
              </Icon>
            </IconButton>
            {/* <ArrowRightIcon style={{ position: 'relative', top: '8px' }} /> */}
            <Icon style={{ position: 'relative', top: '8px' }}>arrow_right</Icon>
            <IconButton size="medium" title={translate('Quote')}>
              {/* <WorkIcon className={status.quote ? classes.iconQuote : ''} /> */}
              <Icon className={status.quote ? classes.iconQuote : ''}>work</Icon>
            </IconButton>
            {/* <ArrowRightIcon style={{ position: 'relative', top: '8px' }} /> */}
            <Icon style={{ position: 'relative', top: '8px' }}>arrow_right</Icon>
            <IconButton size="medium" title={translate('Agreement')}>
              {/* <DescriptionIcon className={status.agreement ? classes.iconAgreement : ''} /> */}
              <Icon className={status.agreement ? classes.iconAgreement : ''}>description</Icon>
            </IconButton>
            {/* <ArrowRightIcon style={{ position: 'relative', top: '8px' }} /> */}
            <Icon style={{ position: 'relative', top: '8px' }}>arrow_right</Icon>
            <IconButton size="medium" title={translate('Order')}>
              {/* <CreditCardIcon className={status.order ? classes.iconOrder : ''} /> */}
              <Icon className={status.order ? classes.iconOrder : ''}>credit_card</Icon>
            </IconButton>
          </Grid>
        </Hidden>
      ) : null}
    </>
  );
};
