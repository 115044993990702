import { Account } from '@earnenterprise/asc-models';
import { Link } from '@mui/material';
import React from 'react';

export const renderAsAccount = (account?: Account | null, history?: any) => {
  if (!account) return null;
  return (
    <Link
      underline="none"
      href={`/account/${account.id}`}
      onClick={(e: any) => {
        if (history) {
          e.preventDefault();
          e.stopPropagation();
          history(`/account/${account.id}`);
        }
      }}
    >
      {account.name}
    </Link>
  );
};
