import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Icon,
} from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BACKEND } from 'App';
import { useRights } from 'components/hooks/useQueryHooks';
import useReduxState from 'components/hooks/useReduxState';
import ImageComponent from 'components/image.component';
import BaseContainer from 'fields/basecontainer';
import { Article, emptyArticle, getSoldForDates, setupArticle } from '@earnenterprise/asc-models';
import { renderAsArticle } from 'components/renderers';
import { fetchItem, updateItem } from 'redux/actions/items.actions';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { LoadingComponent } from 'routes/loading';
import { renderAsCurrency } from 'routes/opportunity/opportunity.article.table';
import { isVideo } from 'routes/article/article.info';

interface Props {
  id: number | string;
  isInline?: boolean;
  from?: Date | string;
  to?: Date | string | null;
  onAddArticle?: (article: Article) => void;
  onClose?: () => void;
}

/**
 * Userinfo
 * @param props
 */
export const ArticleAreaInfo = (props: Props) => {
  const { translate } = useContext(LanguageContext);
  const [id, setId] = useState<string | number | undefined>(props.id);
  const [, setLoaded] = useState(false);
  const classes = globalStyles();

  const dispatch = useDispatch();
  const history = useNavigate();
  const { article: item, workStrings } = useReduxState({ fetched: ['article'] });
  const { rights } = useRights();

  useEffect(() => {
    if (id === '0') dispatch(updateItem({ article: emptyArticle() }, null));
    if (id !== '0') {
      dispatch(updateItem({ article: emptyArticle() }, null));
      dispatch(fetchItem({ articleId: id, workStrings }));
    }
    return function cleanup() {
      console.log({ cleanup: '' });
    };
  }, [id, dispatch, workStrings]);

  // Dont render without and item
  if (item === null || item === undefined) {
    return <LoadingComponent />;
  }
  setupArticle(item);

  const title = item.imageName ? item.imageName : item.image ? item.image : '';

  /*
  const buttons: { name: string; icon?: any; fn: any }[] = [];
  buttons.push({
    name: translate('Add article'),
    icon: <SaveIcon />,
    fn: () => {
      if (props.onAddArticle && item) props.onAddArticle(item);
    }
  });
  */

  let imageWidth = '100%';
  if (item && item.imageExtra) {
    const nrofimages = item.imageExtra.split(';').length;
    imageWidth =
      parseInt((100 / (nrofimages + (item.imageCoordsType ? 0 : 1)) - 1).toString()) + '%';
    console.log(imageWidth);
  }

  const openArea = (area?: string) => {
    if (area) {
      setId(area);
      if (!props.isInline) {
        history('/article/area/' + area);
        setLoaded(false);
      }
    }
  };

  // Render
  return (
    <BaseContainer
      header={title}
      linkHeaders={
        id !== '0' && rights.reportAll
          ? [
              {
                header: `${translate('To article report')}`,
                fn: () => {
                  history('/report/article_details/' + id);
                },
              },
            ]
          : undefined
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6">
            <IconButton
              style={{ position: 'relative', display: 'float', left: '0px' }}
              onClick={() => {
                setId(undefined);
                if (!props.isInline) history('/article');
                else if (props.onClose) props.onClose();
              }}
              size="large"
            >
              <Icon style={{ width: '1.5em', height: '1.5em' }}>apps</Icon>
            </IconButton>
            {item.name}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ justifyContent: 'end', textAlign: 'right', marginTop: '20px' }}>
          {rights?.opportunityWrite && (
            <Button
              style={{ marginTop: '5px', marginRight: '5px' }}
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.onAddArticle && item) props.onAddArticle(item);
                setId(undefined);
                if (!props.isInline) history('/article');
                else if (props.onClose) props.onClose();
              }}
            >
              <Icon>save</Icon> &nbsp;
              {translate('Add article')}
            </Button>
          )}
        </Grid>
        {item.image && item.id && item.imageCoordsType && (
          <>
            <Grid
              item
              xs={1}
              style={{ display: 'inline-grid', placeItems: 'center', padding: '0px' }}
            >
              <IconButton
                disabled={item.imagePrevious ? false : true}
                onClick={() => openArea(item?.imagePrevious)}
                size="large"
              >
                <Icon style={{ fontSize: '3rem' }}>navigate_before</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              {isVideo(item.image) ? (
                <>
                  <video style={{ maxWidth: '720px' }} controls>
                    <source src={BACKEND + 'articles/' + item.image} type="video/mp4" />
                  </video>
                </>
              ) : (
                <ImageComponent
                  id={item.id}
                  isSale={true}
                  from={props.from}
                  to={props.to}
                  name={item.name}
                  sales={item.sales}
                  bookings={item.bookings}
                  type={item.imageCoordsType}
                  imageCoords={item.imageCoords ? item.imageCoords : null}
                  otherImageCoords={item.imageArticles ? (item.imageArticles as any) : null}
                  src={BACKEND + 'articles/' + item.image + '?' + Date.now()}
                  draggable={false}
                  onClick={(item) => {
                    if (item) {
                      setId((item as any).id);
                      if (!props.isInline) {
                        setLoaded(false);
                        history('/article/area/' + (item as any).id);
                      }
                    }
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ display: 'inline-grid', placeItems: 'center', padding: '0px' }}
            >
              <IconButton
                disabled={item.imageNext ? false : true}
                onClick={() => openArea(item?.imageNext)}
                size="large"
              >
                <Icon style={{ fontSize: '3rem' }}>navigate_next</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {!item.imageExtra ? null : (
                <div
                  style={{
                    width: '99%',
                    textAlign: 'center',
                    justifyContent: 'center',
                    left: '3px',
                    top: '3px',
                    position: 'relative',
                  }}
                >
                  {item.imageExtra.split(';').map((src, index) => (
                    <img
                      alt=""
                      key={index}
                      crossOrigin={'anonymous'}
                      src={'/files/articles/' + src}
                      style={{
                        width: imageWidth,
                        border: '1px solid #fff',
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    />
                  ))}
                </div>
              )}
            </Grid>
          </>
        )}
        {item.image && item.id && !item.imageCoordsType && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {isVideo(item.image) ? (
              <>
                <video style={{ maxWidth: '100%' }} controls>
                  <source src={BACKEND + 'articles/' + item.image} type="video/mp4" />
                </video>
              </>
            ) : (
              <>
                {!item.imageExtra ? (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                      left: '3px',
                      top: '3px',
                      position: 'relative',
                    }}
                  >
                    <img
                      alt=""
                      crossOrigin={'anonymous'}
                      src={'/files/articles/' + item.image}
                      style={{ width: imageWidth, border: '1px solid #fff' }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: '99%',
                      textAlign: 'center',
                      justifyContent: 'center',
                      left: '3px',
                      top: '3px',
                      position: 'relative',
                    }}
                  >
                    <img
                      alt=""
                      crossOrigin={'anonymous'}
                      src={'/files/articles/' + item.image}
                      style={{
                        width: imageWidth,
                        border: '1px solid #fff',
                        marginLeft: '10px',
                        marginRight: '5px',
                      }}
                    />
                    {item.imageExtra.split(';').map((src, index) => (
                      <img
                        alt=""
                        key={index}
                        crossOrigin={'anonymous'}
                        src={'/files/articles/' + src}
                        style={{
                          width: imageWidth,
                          border: '1px solid #fff',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </Grid>
        )}
        <Grid item xs={12} style={{ marginLeft: '25px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{translate('Name')}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{translate('Number')}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {translate('Booked')}/{translate('Sold', true)}/{translate('In stock')}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>{translate('Price/Item')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.imageArticles?.map((article: Article, index) => {
                const sold = getSoldForDates(article, props.from, props.to);
                let style = {};
                if (article.inStock > 0) {
                  if (sold.sold >= article.inStock)
                    style = { backgroundColor: 'rgba(200, 100, 100, 0.6)' };
                  else if (sold.booked + sold.sold >= article.inStock)
                    style = { backgroundColor: 'rgba(200, 200, 0, 0.6)' };
                }
                return (
                  <Fragment key={index}>
                    <TableRow style={style}>
                      <TableCell style={{ whiteSpace: 'nowrap', maxWidth: '40px', width: '40px' }}>
                        {rights?.opportunityWrite && (
                          <IconButton
                            className={classes.iconButtonNoPadding}
                            onClick={() => {
                              if (props.onAddArticle && article) props.onAddArticle(article);
                              setId(undefined);
                              if (!props.isInline) history('/article');
                              else if (props.onClose) props.onClose();
                            }}
                            size="large"
                          >
                            <Icon>add_circle</Icon>
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>{renderAsArticle({ article, history })}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{article.number}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        {sold.booked} / {sold.sold}
                        {article.inStock > 0 && ' / ' + article.inStock}
                        {article.inStock <= 0 && ' / -'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        {renderAsCurrency(article.priceInCents / 100)}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </BaseContainer>
  );
};

export default ArticleAreaInfo;
