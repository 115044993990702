import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { Fragment, useContext } from 'react';
import { Player, User } from '@earnenterprise/asc-models';
import { Opportunity } from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';
import { useQuery } from 'react-query';
import { GQLReturnType } from 'services/gql.service';
import { gqlPlayerService } from 'services/gql.services';
import { useRights, useTable } from 'components/hooks/useQueryHooks';
import { setDefaultTableValues } from 'components/table.component';
import { DialogHelper } from 'components/confirm.dialog.component';

interface Props {
  dialogHelper: DialogHelper;
  opportunity: Opportunity;
  onAccept: (player: Player) => void;
  onCancel: () => void;
}

export const PlayerSelectDialogComponent = (props: Props) => {
  const lsName = 'playerList';
  const { translate } = useContext(LanguageContext);
  const { data: qUser } = useQuery<User>(['user']);
  const { setSearchSize } = useTable(lsName, 'category, name');
  const { rights } = useRights();

  // Players
  const playersQuery = useQuery<GQLReturnType | null>(
    ['players'],
    async () => {
      return await gqlPlayerService.list({
        order: 'number',
        limit: 500,
        offset: 0,
        tags: undefined,
        tagsMatching: undefined,
      });
    },
    { enabled: !!qUser && !!rights, refetchOnMount: true }
  );

  // Default values
  if (setDefaultTableValues(lsName, setSearchSize)) {
    playersQuery.refetch();
  }

  const players = playersQuery.data?.data as any as Player[];

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      scroll={'paper'}
      keepMounted
      fullWidth
      maxWidth={'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{translate('Select player')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px', height: 'auto' }}>
        <Grid container spacing={3}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('Number')}</TableCell>
                <TableCell>{translate('Name')}</TableCell>
                <TableCell>
                  {translate('Booked')}/{translate('Sold', true)}/{translate('In stock')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players?.map((player, index) => (
                <Fragment key={index}>
                  {player.articles?.map((article, key) => {
                    let style = { backgroundColor: '' };
                    article.inStock = 1;
                    if (article.inStock > 0) {
                      if (article.sales && article.sales?.length >= article.inStock)
                        style = { backgroundColor: 'rgba(200, 100, 100, 0.6)' };
                      else if (
                        article.bookings &&
                        article.bookings?.length + (article.sales ? article.sales?.length : 0) >=
                          article.inStock
                      )
                        style = { backgroundColor: 'rgba(200, 200, 0, 0.6)' };
                    }

                    return (
                      <Fragment key={key}>
                        {props.dialogHelper.getData() &&
                          article.id === props.dialogHelper.getData()['article'].article.id && (
                            <>
                              <TableRow>
                                <TableCell style={style}>{player.number}</TableCell>
                                <TableCell style={style}>{player.name}</TableCell>
                                <TableCell style={style}>
                                  {article.bookings?.length} / {article.sales?.length} /{' '}
                                  {article.inStock}
                                </TableCell>
                                <TableCell>
                                  {style.backgroundColor !== 'rgba(200, 100, 100, 0.6)' && (
                                    <Button
                                      style={{ padding: '0px' }}
                                      color="primary"
                                      onClick={() => {
                                        props.onAccept(player);
                                      }}
                                    >
                                      {translate('Select')}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          color="primary"
        >
          {translate('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerSelectDialogComponent;
