import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useRights, useUser } from 'components/hooks/useQueryHooks';
import { Article } from '@earnenterprise/asc-models';
import { Opportunity } from '@earnenterprise/asc-models';
import { GQLReturnType } from 'services/gql.service';
import { gqlArticleService } from 'services/gql.services';
import { LanguageContext } from 'translation/languageContext';
import { renderAsCurrency } from 'routes/opportunity/opportunity.article.table';

/**
 *
 */
interface Props {
  dialogHelper: any;
  opportunity: Opportunity;
  onAccept: (newArticle: Article) => void;
  onCancel: () => void;
}

/**
 *
 * @param props
 */
export const ArticleExchangeDialogComponent: React.FC<Props> = ({
  opportunity,
  ...props
}: Props) => {
  const { translate } = useContext(LanguageContext);
  const { rights } = useRights();
  const { user: qUser } = useUser();

  // Opportunities
  const articlesQuery = useQuery<GQLReturnType | null>(
    ['articles_exchangeable', { article: props.dialogHelper.getData() }],
    async () => {
      if (props.dialogHelper.getData()) {
        console.log('Fetching data');
        return await gqlArticleService.list({}, false);
      }
      return null;
    },
    { enabled: !!qUser && !!rights, refetchOnMount: 'always' }
  );

  if (
    !articlesQuery.data &&
    props.dialogHelper.getOpen() &&
    props.dialogHelper.getData() &&
    !articlesQuery.isFetching
  ) {
    console.log('REFetching data');
    articlesQuery.refetch();
  }

  /**
   *
   */
  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      keepMounted
      fullWidth
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{translate('Exchange article')}</DialogTitle>
      <DialogContent style={{ height: 'auto', overflow: 'hidden' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{translate('Article')}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{translate('Number')}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{translate('Price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articlesQuery.data?.data
                  .filter((article: Article) => article.isExchangeable)
                  .map((article: Article, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          props.dialogHelper.setData(null);
                          props.dialogHelper.setOpen(false);
                          articlesQuery.remove();
                          props.onAccept(article);
                        }}
                      >
                        <TableCell>{article.name}</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>{article.number}</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                          {renderAsCurrency(article.priceInCents / 100)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.dialogHelper.setData(null);
            props.dialogHelper.setOpen(false);
            articlesQuery.remove();
            props.onCancel();
          }}
        >
          {translate('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleExchangeDialogComponent;
