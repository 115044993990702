import { IServiceError } from '@earnenterprise/react-network';
import axios from 'axios';
import { authQLService } from 'services/authQL.service';
import { sanitize as ASCSanitize } from '@earnenterprise/asc-sanitize';

export interface GQLReturnType<T = any> {
  error: IServiceError | IServiceError[] | null;
  items: number;
  data?: T;
}

export interface GQLQueryParameters {
  offset?: number;
  limit?: number;
  order?: string;
  search?: string;
  fields?: string[];
  userId?: string | number;
  accountId?: string | number;
  opportunityId?: string | number;
  from?: Date;
  to?: Date;
  categories?: boolean;
  tags?: string[];
  tagsMatching?: 'All' | 'Any';
}

const ccEnvironment = {
  backend: process.env.REACT_APP_GRAPHQL_API_URL,
};

export const httpUpload = async (data: FormData, useRefreshToken?: boolean) => {
  return axios.post(ccEnvironment.backend + 'upload', data, {
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data', ...authQLService.header(useRefreshToken) },
  });
};

/**
 *
 * @param id
 * @param type
 */
export const httpGet = async (
  id: number | string,
  type: string,
  columns: string,
  useRefreshToken?: boolean
) => {
  const data = {
    operationName: null,
    query: `{ ${type} (id: ${id}) { ${columns} } }`,
    variables: {},
  };
  return await axios.post(ccEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 *
 * @param id
 * @param type
 */
export const httpRequest = async (
  type: string,
  columns: string,
  query?: string,
  useRefreshToken?: boolean
) => {
  const data = {
    operationName: null,
    query: query
      ? columns === ''
        ? `{ ${type} (${query}) }`
        : `{ ${type} (${query}) { ${columns} } }`
      : columns === ''
      ? `{ ${type} }`
      : `{ ${type} { ${columns} } }`,
    variables: {},
  };
  return await axios.post(ccEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 *
 * @param id
 * @param type
 */
export const httpPost = async (
  type: string,
  columns: string,
  query?: string,
  useRefreshToken?: boolean
) => {
  const data = {
    operationName: null,
    query: query
      ? columns === ''
        ? `{ ${type} (${query}) }`
        : `{ ${type} (${query}) { ${columns} } }`
      : columns === ''
      ? `{ ${type} }`
      : `{ ${type} { ${columns} } }`,
    variables: {},
  };
  data.query = 'mutation ' + data.query;
  return await axios.post(ccEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 *
 * @param obj
 */
export const stringify = (obj: any): string => {
  if (Array.isArray(obj)) {
    Object.keys(obj).forEach((key: any) => {
      obj[key] = stringify(obj[key]);
    });
    return '[' + obj.toString() + ']';
  } else if (typeof obj !== 'object') {
    // not an object, stringify using native function
    return JSON.stringify(obj);
  }
  // Implements recursive object serialization according to JSON spec
  // but without quotes around the keys.
  const props = Object.keys(obj)
    .map((key) =>
      obj[key] !== null && obj[key] !== undefined ? `${key}:${stringify(obj[key])}` : `${key}:null`
    )
    .join(',');

  return `{${props}}`;
};

/**
 *
 * @param id
 * @param type
 * @param data
 */
export const httpSave = async (
  id: string | number | null,
  type: string,
  columns: string,
  object: any,
  useRefreshToken?: boolean
) => {
  // Delete id from object, we already have it in parameters
  // delete object.id;

  // Get JSON object string without quotes before parameter names
  const objectString = stringify(object);

  // Generate operation name, either save or create
  const opName = type + (id && id !== '0' && id !== 0 ? 'Save' : 'Create');

  // Generate arguments
  const idOpt = `(${type}: ${objectString}) { ${columns} }`;

  //console.log(`mutation { ${opName} ${idOpt} }`);

  /*
  if (id && id !== "0" && id !== 0)
    idOpt = `(id: "${id}", ${type}: ${objectString}) { ${columns} }`;
  */

  // Query GraphQL
  const query = {
    operationName: null,
    query: `mutation { ${opName} ${idOpt} }`,
    variables: {},
  };
  return await axios.post(ccEnvironment.backend + 'graphql', query, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 *
 * @param id
 * @param type
 */
export const httpDelete = async (id: number | string, type: string, useRefreshToken?: boolean) => {
  const opName = type + 'Delete';
  const data = {
    operationName: null,
    query: `mutation { ${opName} (id: ${id}) }`,
    variables: {},
  };
  return await axios.post(ccEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 *
 * @param type
 */
export const httpList = async (
  type: string,
  columns: string,
  queryParameters: GQLQueryParameters,
  useRefreshToken?: boolean
) => {
  const queryExtra = getQuery(queryParameters);
  const data = {
    operationName: null,
    query: `{ ${type}s ${queryExtra} { ${columns} } }`,
    variables: {},
  };
  return await axios.post(ccEnvironment.backend + 'graphql', data, {
    withCredentials: true,
    headers: authQLService.header(useRefreshToken),
  });
};

/**
 * Builds a query string from parameters.
 *
 * @param param0 query parameters
 * @returns the query string
 */
const getQuery = ({
  offset,
  limit,
  order,
  search,
  fields,
  userId,
  accountId,
  opportunityId,
  from,
  to,
  categories,
  tags,
  tagsMatching,
}: GQLQueryParameters): string => {
  let query = '';

  // Add limit, offset, search and order to query
  query += limit ? (query ? ', ' : '') + `limit: ${limit}` : '';
  query += offset ? (query ? ', ' : '') + `offset: ${offset}` : '';
  query += search ? (query ? ', ' : '') + `search: "${search}"` : '';
  query += order ? (query ? ', ' : '') + `order: "${order}"` : '';
  query += userId ? (query ? ', ' : '') + `userId: ${userId}` : '';
  query += accountId ? (query ? ', ' : '') + `accountId: ${accountId}` : '';
  query += opportunityId ? (query ? ', ' : '') + `opportunityId: ${opportunityId}` : '';
  query += from ? (query ? ', ' : '') + `from: "${from.toISOString()}"` : '';
  query += to ? (query ? ', ' : '') + `to: "${to.toISOString()}"` : '';
  query += categories ? (query ? ', ' : '') + `categories: true` : '';
  query += tagsMatching ? (query ? ', ' : '') + `tagsMatching: "${tagsMatching}"` : '';

  // Add Field array to query.
  let fieldArray = '';
  if (fields) {
    fields.forEach((field) => {
      fieldArray += field ? (fieldArray ? ', ' : '') + `"${field}"` : '';
    });
  }
  query += fieldArray ? (query ? ', ' : '') + `fields: [${fieldArray}]` : '';

  let tagsArray = '';
  if (tags) {
    tags.forEach((tag) => {
      tagsArray += tag ? (tag ? ',' : '') + `"${tag}"` : '';
    });
  }
  query += tagsArray ? (query ? ', ' : '') + `tags: [${tagsArray}]` : '';

  return query ? '(' + query + ')' : '';
};

export const sanitize = async (item: any, type: any) => {
  const output = ASCSanitize(item, type);
  console.log({ output });
};
