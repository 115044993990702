import {
  Account,
  Article,
  Group,
  Image,
  Match,
  Message,
  Presentation,
  Reminder,
  Setting,
  Tag,
  User,
} from '@earnenterprise/asc-models';
import { Opportunity, Payment, PaymentOutput } from '@earnenterprise/asc-models';
import { Person } from '@earnenterprise/asc-models';
import { Quote, emptyQuote, QuoteOutput } from '@earnenterprise/asc-models';
import { createService } from 'services/gql.base.service';
import { stringify } from 'services/gql.service';
import { sanitize } from '@earnenterprise/asc-sanitize';
import { Theme } from '@earnenterprise/asc-theme';

const always = 'id, created';
const owner = 'owner { id, firstname, lastname }';
const addresses = 'addresses { description, street, suite, zipcode, city, email }';

const customFields = 'customFields { name, value }';
const contacts = 'contacts { id, title, firstname, lastname, phone, email }';
const account = `account { id, name, ${contacts}, organizationNo }`;
const accountSmall = `account { id, name, organizationNo }`;
const accountWithOwner = `account { id, name, ${contacts}, ${owner}, organizationNo }`;
const opportunity = `opportunity { id, number }`;
const files = 'files { id, name, path, size }';
//const agreement = 'agreement { id, name, tags, from, to, reminderAt, reminderStatus, files { id, name, path, size } }';
const agreementSmall = 'agreement { id, name, tags, from, to, reminderAt, reminderStatus }';
const contact = 'contact { id, title, firstname, lastname, phone, email }';
const sales = `sales { amount, from, to, account { id, name }, opportunity { id, number, ${contact} } }`;
//const sales = `sales { amount, from, to, account { id, name }, opportunity { id, number }`;
const accountLogs = 'logs { id, created, date, info }';
const bookings =
  'bookings { status, amount, from, to, account { id, name }, opportunity { id, number } }';
const imageArticles = `imageArticles { id, name, imageCoords, imageCoordsType, ${sales}, ${bookings}, inStock, priceInCents, category, number }`;
const opportunityArticles =
  'articles { id, order, article { id, split, company, category, name, number, priceInCents, textSeller, costCenter, vatCode, textBuyer, textOther, textInvoice, isConnectedToMatch, isConnectedToPlayer, isExchangeable, isFixedPrice, isPercentage, isBarter, isInvoicedAfterEvent, isExposure, excludeOnQuote, inactive }, amount, pricePerItemInCents, priceInCents, rebateInCents, sumInCents, text, match { id, date, homeTeam, awayTeam }, player { id, name, number } }';
const paymentArticles =
  'articles { id, article { id, split, company, category, name, number, priceInCents, costCenter, vatCode, isFixedPrice, isPercentage, isInvoicedAfterEvent, isExposure, isBarter, textInvoice, excludeOnQuote }, amount, vat, costCenter, pricePerItemInCents, priceInCents, rebateInCents, sumInCents, text }';
const payments = `payments { ${always}, ${paymentArticles}, priceInCents, date, text, status, label, hideArticles, hideArticlesText, calculated, paymentTerms, season, useFortnoxSecond, isInvoicedAfterEvent, invoiceNumber, orderNumber }`;

const rights = `opportunityRead, opportunityWrite, opportunityAll, messageRead, messageWrite, messageAll, quoteRead, quoteWrite, quoteAll, accountRead, accountWrite, accountAll, agreementRead, agreementWrite, agreementAll, reportAll, adminAll, articleRead, articleWrite, reportAll`;

const reminders =
  'reminders { id, text, status, date, opportunity { id }, account { id }, owner { id }, agreement { id } }';

const respFieldsAccount = `${always}, tags, inactive, name, status, organizationNo, phone, email, warning, priceInCents, ${owner}, ${addresses}, ${customFields}, ${contacts}, ${accountLogs}`;
const respFieldsAgreement = `${always}, name, tags, from, to, reminderAt, reminderStatus, ${owner}, ${account}, ${files}`;
const respFieldsUser = `${always}, username, firstname, lastname, email, avatar, isOffice365`;
const respFieldsArticle = `${always}, inactive, company, split, category, name, number, account, inStock, image, imageExtra, imageName, imageNext, imagePrevious, imageDisplayed, tags, measurement, imageCoordsType, imageCoords, ${imageArticles}, costCenter, textSeller, textBuyer, textOther, textInvoice, priceInCents, buyPriceInCents, vatCode, account, isAutoIncluded, isFixedPrice, isExposure, isInvoicedAfterEvent, isConnectedToMatch, isConnectedToPlayer, isSendMessage, isExchangeable, isPercentage, isBarter, excludeOnQuote, ${sales}, ${bookings}, sendMessageTo { id, firstname, lastname }`;
const respFieldsOpportunity = `${always}, number, text, readonly, status, ignoreSeason, ${owner}, ${account}, ${opportunityArticles}, ${payments}, priceInCents, from, to, completedAt, ${contact}, ${reminders}`;
const respFieldsReport = `${always}, number, status, ignoreSeason, ${owner}, ${accountWithOwner}, ${opportunityArticles} ${payments}, priceInCents, from, to, completedAt, ${contact}`;
const respFieldsOrder = `${always}, number, status, ${owner}, ${account}, ${opportunityArticles}, ${payments}, priceInCents, from, to, ${contact}`;
const respFieldsQuote = `${always}, ${owner}, ${account}, ${contact}, ${opportunity}, filename, comment, status, template, getAcceptDocumentId, getAcceptDocumentStatus, getAcceptMessage, getAcceptSubject, oneflowMessage, forSigning, signers, textSeller { text, type }, textBuyer { text, type }, textOther { text, type }`;
const respFieldsTheme = `${always}, theme { id, name, type, primaryLight, primaryDark, primaryMain, textPrimary, textSecondary, textHint, textDisabled, backgroundPaper, backgroundDefault, backgroundDrawerLeft, backgroundNavbar, backgroundImage, navbarTextColor, navbarTextFontSize, expansionpanelHeaderTextExpanded }`;
const respFieldsMessage = `${always}, subject, text, read, from {id, firstname, lastname }, to { id, firstname, lastname }, account { id, name }, opportunity { id, number }`;
const respFieldsMatch = `${always}, date, homeTeam, awayTeam, time, articles { id, name, inactive, category, inStock, ${sales}, ${bookings} }`;
const respFieldsImage = `${always}, name, displayName, path, size, type, next, previous, displayed`;
const respFieldsFile = `name, type, size, path, modified, opportunityValue, autoTag`;
const respFieldsPresentation = `${always}, name, text, image`;
const respFieldsPayment = `${always}, ${paymentArticles}, priceInCents, date, text, status, label, hideArticles, hideArticlesText, calculated, paymentTerms, season, useFortnoxSecond, isInvoicedAfterEvent, invoiceNumber, orderNumber`;
const respFieldsSetting = `${always}, paymentTerms, testEnvironment, defaultVAT, companyName, secondaryCompanyName, hasSecondaryCompany, getAcceptEnabled, oneflowEnabled, fortnoxEnabled, briljantEnabled, blEnabled, signrEnabled, fortnoxCreateOrder, oneflowSignOrderEnabled, alwaysShowPaymentsOnQuote, invoiceTextAppend, mandatoryOrgNo, blHiddenArticleNumber, statusLevels, menuString, exportAgreementFiles`;
const respFieldsTag = `${always}, name, type`;
const respFieldsGroup = `${always}, name, ${rights}, users { id, firstname, lastname }`;
const respFieldsReminder = `${always}, text, status, date, ${opportunity}, ${accountSmall}, ${agreementSmall}`;
const respFieldsSearch = `accounts { id, name, organizationNo }, opportunities { id, number, status, text, account { id, name } }, persons { id, firstname, lastname, email, phone, account { id, name } }`;
const respFieldsPackage = `${always}, name`;
const respFieldsPlayer = `${always}, name, number, season, articles { id, name, category, inStock, ${sales}, ${bookings} }`;

export const gqlAccountService = {
  ...createService<Account>('account', respFieldsAccount),
  tagAllMyCustomers: async (tag: string) => {
    const query = `tag: "${tag}"`;
    return gqlAccountService.request(query, false, 'accountTagAll');
  },
};

export const gqlAgreementService = createService('agreement', respFieldsAgreement);

export const gqlUserService = {
  ...createService<User>('user', respFieldsUser),
  updateVersion: async () => {
    const data = await gqlUserService.request(undefined, false, 'updateUserVersion');
    return { user: data.data, error: data.error };
  },
};

export const gqlSearchService = {
  ...createService('search', respFieldsSearch),
  search: async (searchString: string) => {
    const data = await gqlSearchService.request(`search: "${searchString}"`, false, 'search');
    return { search: data.data, error: data.error };
  },
};

export const gqlGroupService = {
  ...createService<Group>('group', respFieldsGroup),
  getRights: async () => {
    const data = await gqlGroupService.request(undefined, false, 'getRights');
    if (data.data?.id) delete data.data.id;
    if (data.data?.created) delete data.data.created;
    if (data.data?.name) delete data.data.name;
    if (data.data?.users) delete data.data.users;
    return { group: data.data, error: data.error };
  },
};

export const gqlImageService = createService<Image>('image', respFieldsImage);

export const gqlArticleService = {
  ...createService<Article>('article', respFieldsArticle),
  checkArticle: async (article: Article) => {
    let query = '';
    if (article.id) query += (query ? ', ' : '') + `articleId: ${article.id}`;
    const data = await gqlArticleService.request(query, false, 'articleCheck');
    return { article: data.data, error: data.error };
  },
};

export const gqlPackageService = createService('package', respFieldsPackage);

export const gqlPlayerService = createService('player', respFieldsPlayer);

export const gqlOpportunityService = {
  ...createService<Opportunity>('opportunity', respFieldsOpportunity),
  sendInvoice: async (opportunity: Opportunity, payment: Payment) => {
    let query = '';
    if (payment.id) query += (query ? ', ' : '') + `paymentId: ${payment.id}`;
    const data = await gqlOpportunityService.request(query, false, 'paymentSend');
    return { opportunity: data.data, error: data.error };
  },
  ignoreSeason: async (opportunity: Opportunity, season: string) => {
    let query = '';
    if (opportunity.id) query += (query ? ', ' : '') + `opportunityId: ${opportunity.id}`;
    if (season) query += (query ? ', ' : '') + `season: "${season}"`;
    const data = await gqlOpportunityService.request(query, false, 'opportunityIgnoreSeason');
    return { opportunity: data.data, error: data.error };
  },
  setOpportunityArticleText: async (opportunity: Opportunity, article: Article, text: string) => {
    let query = '';
    if (opportunity.id) query += (query ? ', ' : '') + `opportunityId: ${opportunity.id}`;
    if (article.id) query += (query ? ', ' : '') + `articleId: ${article.id}`;
    if (text) query += (query ? ', ' : '') + `text: "${text}"`;
    const data = await gqlOpportunityService.request(query, false, 'setOpportunityArticleText');
    return { opportunity: data.data, error: data.error };
  },
  getInvoiceData: async (opportunity: Opportunity, payment: Payment) => {
    let query = '';
    if (payment.id) query += (query ? ', ' : '') + `paymentId: ${payment.id}`;
    const data = await gqlOpportunityService.request(query, false, 'getInvoiceData');
    return { opportunity: data.data, error: data.error };
  },
};

export const gqlPaymentService = {
  ...createService('payment', respFieldsPayment),
  savePayment: async (opportunity: Opportunity, payment: Payment) => {
    //await sanitize(payment, PaymentOutput());
    const sanitizedPayment = sanitize(payment, PaymentOutput());
    const paymentData = stringify(sanitizedPayment);

    let query = '';
    query += `opportunityId: ${opportunity.id}`;
    query += `, payment: ${paymentData}`;
    const data = await gqlPaymentService.post(query, false, 'paymentSave');
    return { payment: data.data, error: data.error };
  },
};

export const gqlOrderService = createService('order', respFieldsOrder);

export const gqlReminderService = createService<Reminder>('reminder', respFieldsReminder);

export const gqlMessageService = createService<Message>('message', respFieldsMessage);

export const gqlMatchService = createService<Match>('match', respFieldsMatch, {
  externalList: async ({ order, from, to }: { order: string; from: Date; to: Date }) => {
    if (!from) from = new Date();
    if (!to) to = new Date('2099-01-01');
    return gqlMatchService.request(
      `order: "${order}", from: "${from.toISOString()}", to: "${to.toISOString()}"`,
      false,
      'externalMatches'
    );
  },
});

export const gqlPresentationService = createService<Presentation>(
  'presentation',
  respFieldsPresentation
);

export const gqlSettingService = createService<Setting>('setting', respFieldsSetting);

export const gqlTestService = {
  ...createService('test', 'status, message'),
  getAccept: async () => {
    return gqlTestService.request(`test: "getAccept"`);
  },
  oneflow: async () => {
    return gqlTestService.request(`test: "oneflow"`);
  },
  fortnox: async () => {
    return gqlTestService.request(`test: "fortnox"`);
  },
  bl: async () => {
    return gqlTestService.request(`test: "bl"`);
  },
};

export const gqlTagService = createService<Tag>('tag', respFieldsTag);

export const gqlQuoteService = {
  ...createService<Quote>('quote', respFieldsQuote),
  fromOpportunity: async (
    opportunity: Opportunity,
    template: string | null,
    textSeller: string | null,
    textBuyer: string | null,
    textOther: string | null,
    contact: string | null,
    contacts: Person[] | null
  ) => {
    const quote = emptyQuote();
    quote.owner = opportunity.owner;
    quote.opportunity = opportunity;
    quote.account = opportunity.account;

    quote.contact = contacts?.find((obj) => (obj as Person).id === contact);
    //quote.contact = contact;
    quote.template = template ? template : 'Default';

    quote.textSeller = textSeller
      ? textSeller.split('\n').map((item) => ({ text: item, type: 'Seller' }))
      : null;
    quote.textBuyer = textBuyer
      ? textBuyer.split('\n').map((item) => ({ text: item, type: 'Buyer' }))
      : null;
    quote.textOther = textOther
      ? textOther.split('\n').map((item) => ({ text: item, type: 'Other' }))
      : null;

    quote.id = null;
    const data = await gqlQuoteService.save(null, quote, QuoteOutput());
    return { quote: data.data, error: data.error };
  },
  getQuoteData: async (quote: Quote) => {
    let query = '';
    if (quote.id) query += (query ? ', ' : '') + `quoteId: ${quote.id}`;
    const data = await gqlOpportunityService.request(query, false, 'getQuoteData');
    return { quote: data.data, error: data.error };
  },
};

export const gqlReportService = createService('report', respFieldsReport, {
  getOpportunities: async ({
    userId,
    won,
    invoiceAfterwards,
    invoice,
    lost,
    from,
    to,
    tags,
  }: {
    userId?: string | number | null;
    won?: boolean;
    invoiceAfterwards?: boolean;
    invoice?: boolean;
    lost?: boolean;
    from?: Date | null;
    to?: Date | null;
    tags?: string[] | null;
  }) => {
    let query = '';
    if (userId) query += (query ? ', ' : '') + `userId: ${userId}`;
    if (won) query += (query ? ', ' : '') + `won: ${won}`;
    if (invoiceAfterwards) query += (query ? ', ' : '') + `billedAfterwards: ${invoiceAfterwards}`;
    if (invoice) query += (query ? ', ' : '') + `invoiced: ${invoice}`;
    if (lost) query += (query ? ', ' : '') + `lost: ${lost}`;

    try {
      if (from) query += (query ? ', ' : '') + `from: "${from.toISOString()}"`;
    } catch {
      // TODO: Handle errors when from.toISOString() fails
    }
    try {
      if (to) query += (query ? ', ' : '') + `to: "${to.toISOString()}"`;
    } catch {
      // TODO: Handle errors when to.toISOString() fails
    }

    let tagsArray = '';
    if (tags) {
      tags.forEach((tag) => {
        tagsArray += tag ? (tag ? ',' : '') + `"${tag}"` : '';
      });
      query += tagsArray ? (query ? ', ' : '') + `tags: [${tagsArray}]` : '';
    }

    return gqlReportService.request(query);
  },
});

export const gqlBackupService = {
  ...createService('backup', ''),
  backup: async () => {
    return gqlBackupService.request();
  },
  restore: async (filename: string) => {
    return gqlBackupService.request(`filename:"${filename}"`, false, 'backupRestore');
  },
  reset: async () => {
    return gqlBackupService.request(undefined, false, 'resetDatabase');
  },
  remoteRestore: async (site: string) => {
    return gqlBackupService.request(`site:"${site}"`, false, 'remoteRestore');
  },
};

export const gqlThemeService = {
  ...createService<Theme>('theme', respFieldsTheme),
  getTheme: async (id: string, useRefreshToken?: boolean) => {
    let query = '';
    if (id) query += (query ? ', ' : '') + `id: ${id}`;
    const data = await gqlThemeService.request(query, useRefreshToken);
    return { data: data.data, error: data.error };
  },
};

export const gqlFileService = createService<{
  name: string;
  type: string;
  opportunityValue: number;
}>('file', respFieldsFile, {
  import: async (filename: string) => {
    return gqlFileService.request(`filename:"${filename}"`, false, 'importExcel');
  },
  resize: async (filename: string) => {
    return gqlFileService.request(`filename:"${filename}"`, false, 'resizeArticleImage');
  },
  export: async (
    type: string,
    tags: string[],
    matchAll: string,
    userid?: number,
    from?: Date,
    to?: Date,
    splitAccount?: boolean
  ) => {
    if (!tags) tags = [];
    if (!userid) userid = 0;
    if (!from) from = new Date(Date.parse('1901-01-01T00:00:00Z'));
    if (!to) to = new Date(Date.parse('1901-01-01T00:00:00Z'));
    if (splitAccount === undefined) splitAccount = true;

    const fromParsed = new Date(from).toISOString();
    const toParsed = new Date(to).toISOString();

    if (matchAll === 'All' && tags.length > 0) {
      return gqlFileService.request(
        `type: "${type}", tags: ${JSON.stringify(
          tags
        )}, matchAll: true, userId: ${userid}, from: "${fromParsed}", to: "${toParsed}", splitAccount: ${splitAccount}`,
        false,
        'exportExcel'
      );
    } else if (tags.length > 0) {
      return gqlFileService.request(
        `type: "${type}", tags: ${JSON.stringify(
          tags
        )}, matchAll: false, userId: ${userid}, from: "${fromParsed}", to: "${toParsed}", splitAccount: ${splitAccount}`,
        false,
        'exportExcel'
      );
    }
    return gqlFileService.request(
      `type: "${type}", tags: [], matchAll: false, userId: ${userid}, from: "${fromParsed}", to: "${toParsed}", splitAccount: ${splitAccount}`,
      false,
      'exportExcel'
    );
  },
});

export const gqlTemplateService = createService('template', '', {
  updateTemplate: async (filename: string, opportunityValue: number, autoTag: string) => {
    return gqlTemplateService.request(
      `filename:"${filename}", opportunityValue: ${opportunityValue}, autoTag: "${autoTag}"`,
      false,
      'updateTemplate'
    );
  },
});
