import { updateThemeForStyle } from 'styling/global.styles';
import { Action } from 'styling/redux/actions';

interface ThemeState {
  theme: string;
}

export const themeReducer = (
  state: ThemeState = {
    theme: localStorage.getItem('theme') ? (localStorage.getItem('theme') as string) : 'Default',
  },
  action: Action
): ThemeState => {
  switch (action.type) {
    case 'THEME_STATUS':
      localStorage.setItem('theme', action.theme);
      updateThemeForStyle(action.theme);
      return { ...state, theme: action.theme };

    default:
      return state;
  }
};

export default themeReducer;
