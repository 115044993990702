import React, { useState, useContext } from 'react';
import ErrorDisplayComponent from 'error/error.component';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { LanguageContext } from 'translation/languageContext';
import ConfirmDialogComponent, { useDialogHelper } from 'components/confirm.dialog.component';

type IErrorContext = {
  errors?: Array<any>;
  addError: (error: any) => void;
  clearErrors: () => void;
  snackbar: (text: string, type: 'success' | 'error' | 'info' | 'warning', extra?: any) => void;
};

/**
 *
 */
export const ErrorContext = React.createContext<IErrorContext>({} as IErrorContext);

export const ErrorProvider: React.FC = ({ children }) => {
  const [errors, setErrors] = useState<Array<any>>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { translate } = useContext(LanguageContext);
  const dialogHelper = useDialogHelper();

  /**
   *
   * @param key
   * @param info
   */
  const onMore = (key: any, info: any) => {
    try {
      const infoobject = JSON.parse(info);
      if (Array.isArray(infoobject) && infoobject.length === 1) {
        if (infoobject[0].response) {
          if (
            infoobject[0].response.data &&
            infoobject[0].response.data.errors &&
            Array.isArray(infoobject[0].response.data.errors)
          ) {
            delete infoobject[0].response.data.data;
            delete infoobject[0].response.headers;
            delete infoobject[0].response.config;
            delete infoobject[0].response.request;
            for (let i = 0; i < infoobject[0].response.data.errors.length; i++) {
              delete infoobject[0].response.data.errors[i].path;
              delete infoobject[0].response.data.errors[i].locations;
              infoobject[0].response.data.errors[i].body = (
                infoobject[0].response.data.errors[i].message as string
              ).slice(
                (infoobject[0].response.data.errors[i].message as string).indexOf('body:') + 6
              );
              infoobject[0].response.data.errors[i].message = (
                infoobject[0].response.data.errors[i].message as string
              ).replace('body: ' + infoobject[0].response.data.errors[i].body, '');
              infoobject[0].response.data.errors[i].body = JSON.parse(
                infoobject[0].response.data.errors[i].body
              );
            }
            dialogHelper.setMessage({
              title: 'Info',
              message: JSON.stringify(infoobject[0].response, null, 2),
            });
          } else {
            dialogHelper.setMessage({
              title: 'Info',
              message: JSON.stringify(infoobject[0].response, null, 2),
            });
          }
        } else {
          dialogHelper.setMessage({
            title: 'Info',
            message: JSON.stringify(infoobject[0], null, 2),
          });
        }
      } else {
        dialogHelper.setMessage({ title: 'Info', message: JSON.stringify(infoobject, null, 2) });
      }
    } catch (ex) {
      dialogHelper.setMessage({ title: 'Info', message: info });
    }
    dialogHelper.setOpen(true);
  };

  /**
   *
   * @param key
   * @param object
   */
  const onClickDismiss = (key: any) => () => {
    closeSnackbar(key);
  };

  /**
   *
   * @param text
   * @param type
   * @param extra
   */
  const snackbar = (text: string, type: 'success' | 'error' | 'info' | 'warning', extra?: any) => {
    if (!extra) {
      enqueueSnackbar(text, { variant: type });
    } else {
      const data = JSON.stringify(extra, null, 2);
      enqueueSnackbar(text, {
        variant: type,
        action: (key: any) => (
          <>
            <Button onClick={(key) => onMore(key, data)}>{translate('INFO')}</Button>
            <Button onClick={onClickDismiss(key)}>{'OK'}</Button>
          </>
        ),
      });
    }
  };

  /**
   *
   * @param error
   */
  const addError = (error: any) => {
    if (Array.isArray(error)) {
      error.forEach((e: any) => {
        if (errors?.findIndex((el) => e.message === el.message) === -1 || errors.length === 0) {
          setErrors([...errors, e]);
        }
      });
    } else {
      if (errors?.findIndex((el) => error.message === el.message) === -1 || errors.length === 0) {
        setErrors([...errors, error]);
      }
    }
  };

  /**
   *
   */
  const clearErrors = () => {
    setErrors([]);
  };

  /**
   *
   */
  return (
    <ErrorContext.Provider value={{ errors, addError, clearErrors, snackbar }}>
      {children}
      <ConfirmDialogComponent
        hideCancelButton={true}
        rawText={true}
        dialogHelper={dialogHelper}
        onAccept={() => {
          // TODO: What should we do when we close the error dialog?
        }}
      />
      <ErrorDisplayComponent errors={errors}></ErrorDisplayComponent>
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
