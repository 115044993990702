import { Models } from '@earnenterprise/asc-models';
import { Query, QueryResult } from '@material-table/core';
import { GQLQueryParameters, GQLReturnType } from 'services/gql.service';

function emptyResponse() {
  return Object.assign({}, { data: [], page: 0, totalCount: 0 });
}

export const getSeason = (offset: number) => {
  const date = new Date();
  if (date.getMonth() < 4)
    return (
      (date.getFullYear() - 1 + offset).toString() + '/' + (date.getFullYear() + offset).toString()
    );
  return (
    (date.getFullYear() + offset).toString() + '/' + (date.getFullYear() + offset + 1).toString()
  );
};

export const renderDate = (rowData: Models, item?: string) => {
  if (item && item !== 'row') {
    const value = !(rowData as any)[item]
      ? ''
      : fancyDate(new Date(Date.parse((rowData as any)[item].toString())));
    return value === '1-01-01' ? '' : value;
  }
  return !(rowData as any).created
    ? ''
    : fancyDate(new Date(Date.parse((rowData as any).created.toString())));
};

export const fancyDate = (date: Date | null) => {
  if (!date) return '';
  return date.toLocaleDateString();
};

export const fancyTime = (date: Date) => {
  let diff = Date.now() - date.getTime();
  let diffSeconds = Math.floor(diff / 1000);

  if (diffSeconds < 0) {
    diffSeconds = Math.abs(diffSeconds);
    diff = Math.abs(diff);
    if (diffSeconds < 60) return `In ${diffSeconds} seconds`;
    const diffMinutes = Math.floor(diff / (1000 * 60));
    if (diffMinutes === 1) return `In ${diffMinutes} minute, ${diffSeconds - 60} seconds`;
    if (diffMinutes < 60) return `In ${diffMinutes} minutes`;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    if (diffHours === 1) return `In ${diffHours} hour, ${diffMinutes - 60} minutes`;
    if (diffHours < 24) return `In ${diffHours} hours`;
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (diffDays === 1) return `In ${diffDays} day, ${diffHours - 24} hours`;
    if (diffDays < 365) return `In ${diffDays} days`;
  } else {
    if (diffSeconds < 60) return `${diffSeconds} seconds ago`;
    const diffMinutes = Math.floor(diff / (1000 * 60));
    if (diffMinutes === 1) return `${diffMinutes} minute, ${diffSeconds - 60} seconds ago`;
    if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    if (diffHours === 1) return `${diffHours} hour, ${diffMinutes - 60} minutes ago`;
    if (diffHours < 24) return `${diffHours} hours ago`;
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (diffDays === 1) return `${diffDays} day, ${diffHours - 24} hours ago`;
    if (diffDays < 365) return `${diffDays} days ago`;
  }

  return date.toLocaleString();
};

export const translateDate = (
  translate: (text: string, multiple?: boolean | undefined) => string,
  date: string
) => {
  date = date.replace('seconds ago', translate('seconds ago'));
  date = date.replace('minute,', translate('minute,'));
  date = date.replace('minutes ago', translate('minutes ago'));
  date = date.replace('hour,', translate('hour,'));
  date = date.replace('hours ago', translate('hours ago'));
  date = date.replace('day,', translate('day,'));
  date = date.replace('days ago', translate('days ago'));

  return date;
};

export const renderDateAndTime = (rowData: Models, item?: string) => {
  if (item && item !== 'row')
    return !(rowData as any)[item]
      ? ''
      : fancyTime(new Date(Date.parse((rowData as any)[item].toString())));
  return !(rowData as any).created
    ? ''
    : fancyTime(new Date(Date.parse((rowData as any).created.toString())));
};

/**
 *
 */
//export async function deleteTableData<T extends Models>(oldData: T, dispatch: Dispatch<any>, fn: any): Promise<any> {
export async function deleteTableData<T extends Models>(oldData: T, fn: any): Promise<any> {
  /*
  // Are we still online?
  if (!(await authQLService.isOnline())) {
    dispatch(checkStatus());
    return null;
  }
  */

  // Delete the data
  const { data, error } = await fn((oldData as any).id);
  if (error || !data) {
    return null;
  }

  return null;
}

/**
 *
 * @param query
 */
export async function getTableData<T extends Models>(
  query: Query<T> | null,
  //dispatch: Dispatch<any>,
  fields: string[],
  fn: (queryParameters: GQLQueryParameters) => Promise<GQLReturnType>,
  onError: (error: any) => void,
  options?: {
    accountId?: string | number | null;
    userId?: string | number | null;
    from?: Date | null;
    to?: Date | null;
    categories?: boolean;
    searchText?: string | null;
    searchTags?: string[] | null;
    searchMatching?: 'All' | 'Any';
  }
): Promise<QueryResult<T>> {
  /*
  // Are we still online?
  if (!(await authQLService.isOnline())) {
    dispatch(checkStatus());
    return emptyResponse();
  }
  */

  const def = fields.findIndex((f) => f === 'inactive') >= 0 ? ['inactive'] : undefined;

  // Fetch user data
  const { data, error, items } = await fn({
    offset: query ? query.page * query.pageSize : 0,
    limit: query ? query.pageSize : 0,
    order: query
      ? query.orderBy
        ? (query.orderDirection === 'desc' ? '-' : '') + (query.orderBy as any).field
        : undefined
      : undefined,
    search:
      options && options.searchText
        ? options.searchText
        : query
        ? query.search
          ? query.search
          : undefined
        : undefined,
    userId: options && options.userId ? options.userId : undefined,
    accountId: options && options.accountId ? options.accountId : undefined,
    from: options && options.from ? options.from : undefined,
    to: options && options.to ? options.to : undefined,
    fields: query ? (query.search || options?.searchText ? fields : def) : def,
    categories: options && options.categories ? options.categories : undefined,
    tags: options && options.searchTags ? options.searchTags : undefined,
    tagsMatching: options && options.searchMatching ? options.searchMatching : undefined,
  });
  if (error || !data) {
    error && console.error(error);
    if (error && !Array.isArray(error) && error.message === 'invalid signature') {
      //dispatch(refreshToken());
      // FIXME: This is a hack to get the token refreshed
    } else {
      onError(error);
    }
    return emptyResponse();
  }

  // If we're past the last page when changing search.
  if (query) {
    if (items < query.page * query.pageSize && query.page > 0) {
      query.page = 0;
      //return await getTableData(query, dispatch, fields, fn, onError, options);
      return await getTableData(query, fields, fn, onError, options);
    }
  }

  // Return data
  return {
    data: data.map((data: any) => {
      if (data && data.tableData) data.tableData.showDetailPanel = true;
      return data;
    }),
    page: query ? query.page : 0,
    totalCount: items,
  };
}
