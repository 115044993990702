import { OpportunityArticle } from '@earnenterprise/asc-models';

export type Cart = { cart: OpportunityArticle[] };

export interface CartAction extends Cart {
  type: 'CART';
}

export type Action = CartAction;

export const setCart = (cart: OpportunityArticle[]): CartAction => {
  return { type: 'CART', cart };
};

export default setCart;
