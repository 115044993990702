import { Article, OpportunityArticle, emptyArticle } from '@earnenterprise/asc-models';
import { Dispatch } from 'redux';
import setCart from 'redux/actions/cart.actions';

/** Returns a copy of the expanded array with the category added or removed */
export const toggleCategory = (category: string, expanded: string[]) => {
  const result = expanded.includes(category)
    ? expanded.filter((i) => i !== category)
    : [...expanded, category];
  localStorage.setItem('articleDefaultExpanded', JSON.stringify(result));
  return result;
};

/**
 * If all categories are expanded, return empty array. Otherwise return array with all categories.
 *
 * @param categories all existing categories
 * @param expanded all expanded categories
 * @returns array with all categories or none
 */
export const toggleCategoryAll = (categories: string[], expanded: string[]) => {
  const result = expanded.length === categories.length ? [] : [...categories];
  localStorage.setItem('articleDefaultExpanded', JSON.stringify(result));
  return result;
};

/** Returns the first article with the image name, or an empty article if none was found */
export const getFirstArticleWithImage = (articles: Article[], name: string) => {
  if (articles) {
    const index = articles.findIndex(
      (a) => 'articles/' + a.image?.toLowerCase() === name.toLowerCase()
    );
    if (index !== -1) return articles[index];
  }

  return emptyArticle();
};

/** Adds the article to the cart and dispatches the updates cart to the redux state */
export const addArticleToCart = (article: Article, dispatch: Dispatch) => {
  const cartStr = localStorage.getItem('cart');
  const cart: OpportunityArticle[] = cartStr ? JSON.parse(cartStr) : [];
  const oa = cart.find((a) => a.article.id === article.id);
  if (!oa) {
    cart.push({
      id: null,
      article,
      amount: 1,
      order: cart.length,
      priceInCents: article.priceInCents,
      pricePerItemInCents: article.priceInCents,
      rebateInCents: 0,
      sumInCents: article.priceInCents,
    });
  } else {
    oa.amount += 1;
    oa.priceInCents = oa.sumInCents = oa.pricePerItemInCents * oa.amount;
  }
  localStorage.setItem('cart', JSON.stringify(cart));
  dispatch(setCart(cart));
};
