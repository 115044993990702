import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  Typography,
  Icon,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Agreement } from '@earnenterprise/asc-models';
import { Article } from '@earnenterprise/asc-models';
import { Account } from '@earnenterprise/asc-models';
import { Opportunity } from '@earnenterprise/asc-models';
import { Tag, TagOutput } from '@earnenterprise/asc-models';
import { gqlTagService } from 'services/gql.services';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { useDialogHelper } from 'components/confirm.dialog.component';

interface Props {
  item: Agreement | Article | Opportunity | Account;
  type?: 'Global' | 'Article' | 'Agreement' | 'Opportunity' | 'Account';
  onUpdate: (tags: string[] | null | undefined) => void;
}

export const Tags = (props: Props) => {
  const { translate } = useContext(LanguageContext);
  const classes = globalStyles();
  const [tags, setTags] = useState(props.item.tags);
  const [item, setItem] = useState<Tag[]>([]);
  const newDialogHelper = useDialogHelper();

  //const tags = props.item ? (props.item.tags ? props.item.tags : []) : [];

  useEffect(() => {
    if (!tags && props.item.tags) {
      setTags(props.item.tags);
    } else if (tags && props.item.tags && props.item.tags.length !== tags.length) {
      setTags(props.item.tags);
    } else if (tags && props.item.tags && tags.length > 0 && tags[0] !== props.item.tags[0]) {
      setTags(props.item.tags);
    }
  }, [tags, props.item.tags]);

  const onDelete = (index: number) => {
    tags?.splice(index, 1);
    setTags(tags);
    props.onUpdate(tags);
  };

  const onAddTag = (name: string) => {
    // No previous tags?
    if (!tags) {
      setTags([name]);
      props.onUpdate([name]);
      newDialogHelper.setOpen(false);
    } else {
      // Only add tag if its not already added.
      if (tags.findIndex((tag) => tag === name) === -1) {
        tags?.push(name);
        setTags(tags);
        props.onUpdate(tags);
        newDialogHelper.setOpen(false);
      }
    }
  };

  const onClick = () => {
    (async () => {
      const data = await gqlTagService.list({});
      newDialogHelper.setOpen(true);
      if (data.data) setItem(data.data as Tag[]);
    })();
  };

  return (
    <>
      {tags &&
        tags.map((value: string, index: number) => (
          <Chip
            className={classes.chip}
            label={value}
            key={index}
            color="primary"
            style={{ marginBottom: '5px' }}
            variant="outlined"
            onDelete={(event: any) => onDelete(index)}
            deleteIcon={<Icon>delete</Icon>}
          />
        ))}
      <Chip
        className={classes.chipAdd}
        label={translate('New tag')}
        color="default"
        onClick={(event: any) => onClick()}
      ></Chip>
      <Dialog
        open={newDialogHelper.getOpen()}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ height: 'auto', overflow: 'hidden' }}>
          <Typography>{translate('Select a tag or enter a new one below')}</Typography>
          <br />
          {item &&
            item.map(
              (tag: Tag, index: number) =>
                (props.type === tag.type || tag.type === 'Global') && (
                  <Chip
                    className={classes.chipAdd}
                    label={tag.name}
                    key={index}
                    color="primary"
                    variant="outlined"
                    onClick={() => onAddTag(tag.name)}
                  />
                )
            )}
          <br />
          <br />
          <Input
            fullWidth
            onChange={(e) => newDialogHelper.setData(e.target.value)}
            placeholder={translate('Name')}
          ></Input>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => newDialogHelper.setOpen(false)} color="primary">
            {translate('Cancel')}
          </Button>
          <Button
            onClick={() => {
              let name = newDialogHelper.getData();
              if (name) {
                name = name.charAt(0).toUpperCase() + name.slice(1);
                if (!tags || tags.findIndex((tag) => tag === name) === -1) {
                  (async () => {
                    await gqlTagService.save(
                      null,
                      { name, type: props.type ? props.type : 'Global' },
                      TagOutput()
                    );
                    onAddTag(name);
                  })();
                }
              }
            }}
          >
            {translate('Add tag')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tags;
