import { IconButton, Icon } from '@mui/material';
import React, { useState } from 'react';
import { useHints, useUser } from 'components/hooks/useQueryHooks';
import ArticleCartComponent from 'routes/article/article.cart';
import NavSideList from 'nav/nav.sidelist';

interface Props {
  hidden: boolean;
  online: boolean;
  onLogout?: any;
}
/**
 * Navigation class for logged in users.
 */
export const NavOnline = (props: Props) => {
  const { user: me } = useUser();
  const { hints, setHints } = useHints();
  const online = props.online;

  const [openUser, setOpenUser] = useState<boolean | null>(null);

  const handleMenu = (event: any) => {
    setOpenUser(event.currentTarget);
  };

  // Empty menu when user is null
  if (me === null || me === undefined || online === false) {
    return <></>;
  }

  return (
    <>
      <NavSideList
        open={Boolean(openUser)}
        onClose={() => {
          setOpenUser(false);
        }}
      />
      <ArticleCartComponent />
      <IconButton aria-controls="menu-appbar" onClick={handleMenu} size="large">
        <Icon>person</Icon>
        &nbsp;{me.firstname}
      </IconButton>
      <IconButton aria-controls="menu-appbar" onClick={() => setHints(!hints)} size="large">
        {/* <HelpOutlineIcon color={hints ? 'secondary' : 'primary'} /> */}
        <Icon color={hints ? 'secondary' : 'primary'}>help_outline</Icon>
      </IconButton>
    </>
  );
};

export default NavOnline;
