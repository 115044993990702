import { combineReducers } from 'redux';
import { onlineReducer } from 'redux/reducers/online.reducer';
import { userReducer } from 'redux/reducers/user.reducer';
import { rightsReducer } from 'redux/reducers/rights.reducer';

/** Combined Reducers */
export const authReducer = combineReducers({
  user: userReducer,
  online: onlineReducer,
  rights: rightsReducer,
});

/** Info */
export default authReducer;
