import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { globalStyles } from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { useReduxState } from 'components/hooks/useReduxState';

interface Props {
  dialogHelper: DialogHelper;
  hideCancelButton?: boolean;
  rawText?: boolean;
  onAccept: () => void;
  onCancel?: () => void;
}

interface InputProps {
  dialogHelper: DialogHelper;
  hideCancelButton?: boolean;
  rawText?: boolean;
  onAccept: (data: unknown) => void;
  onCancel?: () => void;
}

/*
const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
*/

export const useDialogHelper = () => {
  const [message, setMessage] = useState<{ message: string; title: string }>({
    message: '',
    title: '',
  });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any | undefined | null>();

  const getOpen = () => {
    return open;
  };

  const getData = () => {
    return data;
  };
  const getMessage = () => {
    return message;
  };

  return { setOpen, getOpen, setMessage, setData, getMessage, getData };
};

export type DialogHelper = ReturnType<typeof useDialogHelper>;

export const InputDialogComponent = (props: InputProps) => {
  const { translate } = useContext(LanguageContext);
  useReduxState({ fetched: ['theme'] });
  const inputData = props.dialogHelper.getData();
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const classes = globalStyles();

  useEffect(() => {
    if (inputData) {
      setInputValue(inputData.inputValue);
    }
  }, [inputData, props.dialogHelper]);

  if (!inputData) return <></>;

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      keepMounted
      maxWidth={props.rawText ? 'lg' : 'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.dialogHelper.getMessage().title}
      </DialogTitle>
      <DialogContent>
        {!props.rawText && (
          <DialogContentText>{props.dialogHelper.getMessage().message}</DialogContentText>
        )}
        {props.rawText && (
          <div>
            <pre>{props.dialogHelper.getMessage().message}</pre>
          </div>
        )}
        <Grid item sm={12}>
          <FormGroup>
            <Input
              className={'MuiInputBase-input MuiInput-input MuiInputBase-root' + classes.textField}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <br />
            {inputData.inputHelp && (
              <FormHelperText>{translate(inputData.inputHelp)}</FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!props.hideCancelButton && (
          <Button
            onClick={() => {
              if (props.onCancel) props.onCancel();
              props.dialogHelper.setOpen(false);
            }}
            color="secondary"
            variant="contained"
          >
            {translate('Cancel')}
          </Button>
        )}
        <Button
          onClick={() => {
            props.onAccept(inputValue);
            props.dialogHelper.setOpen(false);
          }}
          color="primary"
          variant="contained"
        >
          {translate('Agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmDialogComponent: React.FC<Props> = (props) => {
  const { translate } = useContext(LanguageContext);

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      keepMounted
      maxWidth={props.rawText ? 'lg' : 'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.dialogHelper.getMessage().title}
      </DialogTitle>
      <DialogContent>
        {!props.rawText && (
          <DialogContentText>{props.dialogHelper.getMessage().message}</DialogContentText>
        )}
        {props.rawText && (
          <div>
            <pre>{props.dialogHelper.getMessage().message}</pre>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!props.hideCancelButton && (
          <Button
            onClick={() => {
              if (props.onCancel) props.onCancel();
              props.dialogHelper.setOpen(false);
            }}
            color="secondary"
            variant="contained"
          >
            {translate('Cancel')}
          </Button>
        )}
        <Button
          onClick={() => {
            props.onAccept();
            props.dialogHelper.setOpen(false);
          }}
          color="primary"
          variant="contained"
        >
          {translate('Agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogComponent;
