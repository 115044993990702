import { Card, CardContent, Container, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {
  inline?: boolean;
}

export const LoadingComponent: React.FC<Props> = (props) => {
  const [failed, setFailed] = useState<boolean>(false);
  const [tm, setTm] = useState<any>(null);

  // Cleanup
  useEffect(() => {
    if (tm === null) {
      setTm(
        setTimeout(() => {
          setFailed(true);
        }, 5000)
      );
    }

    // Remove cleaning timeout
    return function cleanup() {
      if (tm) {
        //console.log({ cleanup: '', timeout: tm });
        clearTimeout(tm);
      }
    };
  }, [tm]);

  if (props.inline) {
    return (
      <>
        {!failed && (
          <>
            <Typography variant="h6">Loading...</Typography>
            <LinearProgress />
          </>
        )}
        {failed && (
          <>
            <Typography variant="h6" color="error">
              The server did not respond in time.
            </Typography>
          </>
        )}
      </>
    );
  }
  return (
    <>
      <Container>
        <Card>
          {!failed && (
            <CardContent>
              <Typography variant="h6">Loading...</Typography>
              <LinearProgress />
            </CardContent>
          )}
          {failed && (
            <CardContent>
              <Typography variant="h6" color="error">
                The server did not respond in time.
              </Typography>
            </CardContent>
          )}
        </Card>
      </Container>
    </>
  );
};

export default LoadingComponent;
