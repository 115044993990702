import { Action } from 'redux/actions/auth.actions';

export const onlineReducer = (state: boolean | null = null, action: Action): any => {
  switch (action.type) {
    case 'LOGIN':
      return true;
    case 'LOGOUT':
      return false;
    case 'STATUS':
      return action.online;
    case 'STATUS_WORKING':
      return state;
    default:
      return state;
  }
};

export default onlineReducer;
