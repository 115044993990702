import { useReactMSAL } from '@earnenterprise/react-msal';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Icon } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useReduxState from 'components/hooks/useReduxState';
import { useMessages, useRights, useSetting, useUser } from 'components/hooks/useQueryHooks';
import { logoutAction } from 'redux/actions/auth.actions';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { StyledTreeItem } from 'nav/styled.tree.item';
import { msalConfig, requestObj } from 'auth/msalConfig';
import { getMenuFromSetting } from 'routes/settings/economy/settings_menu';
import { emptySetting } from '@earnenterprise/asc-models';

interface Props {
  visible: boolean;
  always: boolean;
  online: boolean;
  onMouseOver?: any;
  onMouseOut?: any;
}

export const NavTreeView: React.FC<Props> = (props) => {
  const classes = globalStyles();
  const { account, theme, workStrings } = useReduxState({
    fetched: ['account', 'themes', 'theme'],
  });
  const { user: me } = useUser();
  const { translate } = useContext(LanguageContext);
  const [hideTimer, setHideTimer] = useState<NodeJS.Timeout>();
  const [showTimer, setShowTimer] = useState<NodeJS.Timeout>();
  const [menuItems, setMenuItems] = useState<{ order: number; text: string; original: string }[]>(
    []
  );
  const reactMSAL = useReactMSAL({ config: msalConfig, scopes: requestObj.scopes });
  const dispatch = useDispatch();
  const online = props.online;

  // React-query imports
  const { setting } = useSetting();
  const { messages } = useMessages();
  const { rights } = useRights();

  useEffect(() => {
    if (setting && setting.id) setMenuItems(getMenuFromSetting(setting));
  }, [setting, workStrings, dispatch]);

  if (!online) return <></>;
  if (!setting) return <></>;
  if (setting.menuString === '') setting.menuString = emptySetting().menuString;

  let nodeId = 50;

  const color = theme.theme?.components?.MuiAccordion?.styleOverrides
    ? (theme.theme?.components?.MuiAccordion?.styleOverrides?.root as any).color
    : '#acfacf';

  return (
    <Drawer
      open={props.visible || props.always}
      onMouseOver={() => {
        if (!props.always) {
          if (hideTimer) clearTimeout(hideTimer);
          const timer = setTimeout(() => {
            props.onMouseOver();
          }, 350);
          setShowTimer(timer);
        }
      }}
      onMouseOut={() => {
        if (!props.always) {
          if (showTimer) clearTimeout(showTimer);
          const timer = setTimeout(() => {
            props.onMouseOut();
          }, 500);
          setHideTimer(timer);
        }
      }}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.visible || props.always,
        [classes.drawerClose]: !props.visible && !props.always,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.visible || props.always,
          [classes.drawerClose]: !props.visible && !props.always,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <TreeView
        defaultExpanded={['1', '2']}
        defaultCollapseIcon={null}
        defaultExpandIcon={<Icon>arrow_right</Icon>}
        defaultEndIcon={null}
        style={{
          paddingTop: '20px',
          paddingLeft: '0px',
          height: 'calc(100vh - 130px)',
          overflow: 'auto',
        }}
      >
        <Divider style={{ margin: '10px' }} />
        <StyledTreeItem
          nodeId={(nodeId++).toString()}
          iconStyle={classes.iconAccount}
          labelText={translate('Home')}
          href={'/'}
          labelIcon={<Icon>home</Icon>}
          style={{
            color: theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
          }}
        />
        {rights?.articleRead && (
          <>
            <StyledTreeItem
              nodeId={(nodeId++).toString()}
              iconStyle={classes.iconAccount}
              labelText={translate('Article', true)}
              href={'/article'}
              labelIcon={<Icon>shopping_cart</Icon>}
              style={{
                color:
                  theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
              }}
            />
            {false && (
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconAccount}
                labelText={translate('Package', true)}
                href={'/package'}
                labelIcon={<Icon>shopping_cart</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
            )}
          </>
        )}
        <StyledTreeItem
          nodeId={(nodeId++).toString()}
          iconStyle={classes.iconAccount}
          labelText={translate('Match', true)}
          href={'/match'}
          labelIcon={<Icon>schedule_rounded</Icon>}
          style={{
            color: theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
          }}
        />
        <StyledTreeItem
          nodeId={(nodeId++).toString()}
          iconStyle={classes.iconAccount}
          labelText={translate('Player', true)}
          href={'/player'}
          labelIcon={<Icon>person</Icon>}
          style={{
            color: theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
          }}
        />
        <StyledTreeItem
          nodeId={(nodeId++).toString()}
          iconStyle={classes.iconAccount}
          labelText={translate('Presentation', true)}
          href={'/presentation'}
          labelIcon={<Icon>slideshow</Icon>}
          style={{
            color: theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
          }}
        />
        {(rights?.agreementRead ||
          rights?.messageRead ||
          rights?.accountRead ||
          rights?.opportunityRead ||
          rights?.quoteRead) && (
          <>
            <Divider style={{ margin: '10px' }} />
            <StyledTreeItem
              nodeId={'1'}
              labelText={me.firstname + ' ' + me.lastname}
              labelIcon={<Icon>person</Icon>}
              style={{ color, fontWeight: 'bold' }}
            >
              {menuItems.map((item, keyIndex: number) => {
                switch (item.original) {
                  case 'Message':
                    return (
                      rights?.messageRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconInfo}
                          labelText={item.text ? item.text : translate('Message', true)}
                          href={'/user/' + me.id + '/messages'}
                          labelIcon={<Icon>mail</Icon>}
                          badgeCount={
                            messages
                              ? messages.filter((message) => !message.read).length
                              : undefined
                          }
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Customer':
                    return (
                      rights?.accountRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconAccount}
                          labelText={item.text ? item.text : translate('Customer', true)}
                          href={'/user/' + me.id + '/accounts'}
                          labelIcon={<Icon>business</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Opportunity':
                    return (
                      rights?.opportunityRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconOpportunity}
                          labelText={item.text ? item.text : translate('Opportunity', true)}
                          href={'/user/' + me.id + '/opportunities'}
                          labelIcon={<Icon>work_outline</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Quote':
                    return (
                      rights?.quoteRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconQuote}
                          labelText={item.text ? item.text : translate('Quote', true)}
                          href={'/user/' + me?.id + '/quotes'}
                          labelIcon={<Icon>work</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Agreement':
                    return (
                      rights?.agreementRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconAgreement}
                          labelText={item.text ? item.text : translate('Agreement', true)}
                          href={'/user/' + me.id + '/agreements'}
                          labelIcon={<Icon>description</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Order':
                    return (
                      rights?.opportunityRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconOrder}
                          labelText={item.text ? item.text : translate('Order', true)}
                          href={'/user/' + me.id + '/orders'}
                          labelIcon={<Icon>credit_card</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                }
                return null;
              })}
            </StyledTreeItem>
          </>
        )}
        {account && account.created && rights?.accountRead ? (
          <>
            <Divider style={{ margin: '10px' }} />
            <StyledTreeItem
              nodeId={'2'}
              title={account.name}
              labelText={
                account.name.length >= 23 ? account.name.substr(0, 20) + '...' : account.name
              }
              labelIcon={<Icon>business</Icon>}
              style={{ color, fontWeight: 'bold' }}
            >
              {menuItems.map((item, keyIndex: number) => {
                switch (item.original) {
                  case 'Message':
                    return (
                      rights?.messageRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconInfo}
                          labelText={item.text ? item.text : translate('Message', true)}
                          href={'/account/' + account.id + '/messages'}
                          labelIcon={<Icon>mail</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Info':
                    return (
                      rights?.accountRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconInfo}
                          labelText={
                            item.text
                              ? item.text
                              : translate('Info') + ' / ' + translate('Contact', true)
                          }
                          href={'/account/' + account.id}
                          labelIcon={<Icon>info</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Order':
                    return (
                      rights?.opportunityRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconOrder}
                          labelText={item.text ? item.text : translate('Order', true)}
                          href={'/account/' + account.id + '/orders'}
                          labelIcon={<Icon>credit_card</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Agreement':
                    return (
                      rights?.agreementRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconInfo}
                          labelText={item.text ? item.text : translate('Agreement', true)}
                          href={'/account/' + account.id + '/agreements'}
                          labelIcon={<Icon>description</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Quote':
                    return (
                      rights?.quoteRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconQuote}
                          labelText={item.text ? item.text : translate('Quote', true)}
                          href={'/account/' + account.id + '/quotes'}
                          labelIcon={<Icon>work</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                  case 'Opportunity':
                    return (
                      rights?.opportunityRead && (
                        <StyledTreeItem
                          key={keyIndex}
                          nodeId={(nodeId++).toString()}
                          iconStyle={classes.iconOpportunity}
                          labelText={item.text ? item.text : translate('Opportunity', true)}
                          href={'/account/' + account.id + '/opportunities'}
                          labelIcon={<Icon>work_outline</Icon>}
                          style={{
                            color:
                              theme.theme && theme.theme.palette
                                ? theme.theme.palette.text.primary
                                : '#fff',
                          }}
                        />
                      )
                    );
                }
                return null;
              })}
            </StyledTreeItem>
          </>
        ) : (
          <></>
        )}
        {((rights?.opportunityRead && rights?.accountRead) || rights?.reportAll) && (
          <>
            <Divider style={{ margin: '10px' }} />
            {rights?.reportAll && (
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                labelText={translate('Report', true)}
                labelIcon={<Icon>description</Icon>}
                style={{ color, fontWeight: 'bold' }}
              >
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconPerson}
                  labelText={translate('User', true)}
                  href={'/report/user'}
                  labelIcon={<Icon>people</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconInfo}
                  labelText={translate('Article', true)}
                  href={'/report/article'}
                  labelIcon={<Icon>business</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconAccount}
                  labelText={translate('Customer', true)}
                  href={'/report/account'}
                  labelIcon={<Icon>business</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconInfo}
                  labelText={translate('Invoicing', false)}
                  href={'/report/invoice'}
                  labelIcon={<Icon>business</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconInfo}
                  labelText={translate('Invoice Afterwards', true)}
                  href={'/report/invoice_afterwards'}
                  labelIcon={<Icon>business</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
              </StyledTreeItem>
            )}
            {!rights?.reportAll && rights?.opportunityRead && rights?.accountRead && (
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                labelText={translate('Report', true)}
                labelIcon={<Icon>description</Icon>}
                style={{ color, fontWeight: 'bold' }}
              >
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconPerson}
                  labelText={translate('User', true)}
                  href={'/report/user/' + me.id}
                  labelIcon={<Icon>people</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
              </StyledTreeItem>
            )}
          </>
        )}
        {rights?.adminAll && (
          <>
            <Divider style={{ margin: '10px' }} />
            <StyledTreeItem
              nodeId={(nodeId++).toString()}
              labelText={translate('Setting', true)}
              labelIcon={<Icon>settings</Icon>}
              style={{ color, fontWeight: 'bold' }}
            >
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconPerson}
                labelText={translate('Theme', true)}
                href={'/settings/theme'}
                labelIcon={<Icon>palette</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconAccount}
                labelText={translate('Economy', true)}
                href={'/settings/economy'}
                labelIcon={<Icon>monetization_on</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconAccount}
                labelText={translate('Status level', true)}
                href={'/settings/statuslevel'}
                labelIcon={<Icon>list</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconAccount}
                labelText={translate('Menu', true)}
                href={'/settings/menu'}
                labelIcon={<Icon>menu</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
              <StyledTreeItem
                nodeId={(nodeId++).toString()}
                iconStyle={classes.iconAccount}
                labelText={translate('Tag', true)}
                href={'/settings/tags'}
                labelIcon={<Icon>style</Icon>}
                style={{
                  color:
                    theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
                }}
              />
            </StyledTreeItem>
          </>
        )}
        <>
          <Divider style={{ margin: '10px' }} />
          <StyledTreeItem
            nodeId={(nodeId++).toString()}
            labelText={translate('Administration', true)}
            labelIcon={<Icon>settings</Icon>}
            style={{ color, fontWeight: 'bold' }}
          >
            {rights?.adminAll && (
              <>
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconPerson}
                  labelText={translate('User', true)}
                  href={'/user'}
                  labelIcon={<Icon>person</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconPerson}
                  labelText={translate('Group', true)}
                  href={'/group'}
                  labelIcon={<Icon>group</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconAccount}
                  labelText={translate('File', true)}
                  href={'/file'}
                  labelIcon={<Icon>insert_drive_file</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
                <StyledTreeItem
                  nodeId={(nodeId++).toString()}
                  iconStyle={classes.iconAccount}
                  labelText={translate('System')}
                  href={'/settings/system'}
                  labelIcon={<Icon>settings</Icon>}
                  style={{
                    color:
                      theme.theme && theme.theme.palette
                        ? theme.theme.palette.text.primary
                        : '#fff',
                  }}
                />
              </>
            )}
            <StyledTreeItem
              nodeId={(nodeId++).toString()}
              iconStyle={classes.iconAccount}
              labelText={translate('Changelog')}
              href={'/settings/changelog'}
              labelIcon={<Icon>dynamic_feed</Icon>}
              style={{
                color:
                  theme.theme && theme.theme.palette ? theme.theme.palette.text.primary : '#fff',
              }}
            />
          </StyledTreeItem>
        </>
      </TreeView>
      <List style={{ bottom: '0px', position: 'absolute', width: '100%' }}>
        <ListItem button>
          <ListItemIcon style={{ maxWidth: '25px', width: '25px', minWidth: '25px' }}>
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText
            style={{ textAlign: 'left' }}
            primary={translate('Logout')}
            onClick={async () => {
              await reactMSAL.logout();
              dispatch(logoutAction());
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default NavTreeView;
