import { Person } from '@earnenterprise/asc-models';
import React from 'react';

/**
 *
 * @param user
 * @param history
 */
export const renderAsContact = (contact?: Person | null, history?: any) => {
  if (!contact) return null;
  let phoneandemail = '';
  if (contact.phone) phoneandemail += '\nTelefon: ' + contact.phone;
  if (contact.email) phoneandemail += '\nE-mail: ' + contact.email;

  return (
    <span title={phoneandemail}>
      {contact.firstname} {contact.lastname}
    </span>
  );
};
