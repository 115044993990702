import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  CardHeader,
  Card,
  CardMedia,
  Theme,
  Icon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionProps } from '@mui/material/transitions';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { fetchItem, fetchImages } from 'redux/actions/items.actions';
import { httpUpload } from 'services/gql.service';
import globalStyles from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import useReduxState from 'components/hooks/useReduxState';
import { Image } from '@earnenterprise/asc-models';

interface Props {
  agreementId?: string | null;
  articleId?: string | null;
  opportunityId?: string | null;
  accept?: string;
  singleFile?: boolean | null;
  type?: string;
  listExisting?: boolean | null;
  onDone?: (data: any) => void;
  onSelectedFile?: (image: Image) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref as any} {...(props as any)} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 345,
    cursor: 'pointer',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export const FileUploadContainer: React.FC<Props> = ({
  agreementId,
  articleId,
  opportunityId,
  accept,
  singleFile,
  type,
  onDone,
  onSelectedFile,
  listExisting,
  children,
}) => {
  const classes = globalStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { translate } = useContext(LanguageContext);
  const { images, workStrings } = useReduxState({ fetched: ['images'] });
  const classPopup = useStyles();

  // Fetch images...
  useEffect(() => {
    if (open && !images) {
      dispatch(fetchImages({ workStrings }));
    }

    return function cleanup() {
      // console.log({ cleanup: '' });
    };
  }, [dispatch, open, images, workStrings]);

  if (!accept) accept = 'image/*, video/*, .pdf, .docx';

  const onDrop = useCallback(
    (acceptedFiles) => {
      const length = acceptedFiles.length - 1;
      acceptedFiles.forEach(async (file: File, index: number) => {
        // Create a formdata request and upload it
        const formData = new FormData();
        if (agreementId) formData.append('agreementId', agreementId);
        if (articleId) formData.append('articleId', articleId);
        if (opportunityId) formData.append('opportunityId', opportunityId);
        if (type) formData.append('type', type);
        formData.append(file.name, file);
        httpUpload(formData, false).then((data) => {
          // Wait for all files to be uploaded before refreshing.
          if (index === length) {
            acceptedFiles.length = 0;
            acceptedFiles = [];
            if (agreementId) dispatch(fetchItem({ agreementId }));
            if (articleId) dispatch(fetchItem({ articleId }));
            if (onDone) onDone(data);
          }
        });
      });
    },
    [agreementId, articleId, opportunityId, type, dispatch, onDone]
  );

  /**
   *
   * @param image
   */
  const selectedImage = (image: Image) => {
    setOpen(false);
    if (onSelectedFile) onSelectedFile(image);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    multiple: singleFile ? false : true,
  });

  return (
    <>
      <Grid
        container
        style={{ marginLeft: '0px', paddingRight: '16px', marginBottom: '16px' }}
        spacing={2}
      >
        {listExisting && (
          <Grid item xs={6} sm={4} md={2} lg={1} className={classes.existingFile}>
            <IconButton
              onClick={() => setOpen(true)}
              style={{ fontSize: '3.5rem', margin: '0 auto', padding: '12px' }}
              title={translate('Select already uploaded file')}
              size="large"
            >
              {/* <InsertDriveFileIcon style={{ fontSize: '3.5rem', margin: '0 auto' }} /> */}
              <Icon style={{ fontSize: '3.5rem', margin: '0 auto' }}>insert_drive_file</Icon>
            </IconButton>
          </Grid>
        )}
        <Grid
          item
          xs={6 + (!listExisting ? 6 : 0)}
          sm={8 + (!listExisting ? 4 : 0)}
          md={10 + (!listExisting ? 2 : 0)}
          lg={11 + (!listExisting ? 1 : 0)}
          {...getRootProps({ className: classes.dropzone })}
        >
          <input title={''} {...getInputProps()} />
          <p style={{ width: '100%' }}>
            {singleFile
              ? translate("Drag 'n' drop a file here, or click to select file")
              : translate("Drag 'n' drop some files here, or click to select files")}
            <br />
            {singleFile && listExisting
              ? translate('You can select existing files to the left')
              : null}
          </p>
        </Grid>
        {acceptedFiles.length > 0 ? (
          <div>
            <ul>
              {acceptedFiles.map((file) => (
                <li key={file.name}>
                  Uploading file {file.name} - {file.size / 1024} kbytes
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </Grid>
      <Dialog
        open={open}
        scroll={'paper'}
        keepMounted
        fullWidth
        maxWidth={'lg'}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-slide-title">{translate('Select image')}</DialogTitle>
        <DialogContent style={{ height: 'auto' }}>
          <Grid container spacing={2}>
            {images &&
              images.map((image, index) => (
                <Grid key={index} item xs={12} sm={4} md={3} lg={2}>
                  <Card className={classPopup.root} onClick={() => selectedImage(image)}>
                    <CardHeader
                      subheader={image.displayName ? image.displayName : image.name}
                    ></CardHeader>
                    <CardMedia
                      className={classPopup.media}
                      image={'/files/' + image.path}
                      title={image.name}
                    />
                  </Card>
                  {image.name}
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {translate('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadContainer;
