import { Typography } from '@mui/material';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Icon,
} from '@mui/material';
import MaterialTable, { Column, Query } from '@material-table/core';
import React, { Fragment, RefObject, useContext } from 'react';
import {
  QueryClient,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
  UseQueryResult,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ErrorContext } from 'error/error.context';
import { getTableData } from 'models/helper.tableData';
import { GQLQueryParameters, GQLReturnType } from 'services/gql.service';
import { LanguageContext } from 'translation/languageContext';
import { Models } from '@earnenterprise/asc-models';

interface Props {
  columns: Array<Column<any>>;
  path: string;
  actions?: any;
  search: string[];

  pageSize?: number;
  fn: (queryParameters: GQLQueryParameters, useRefreshToken?: boolean) => Promise<GQLReturnType>;
  tableRef: RefObject<MaterialTable<any>>;

  onRowDelete?: (item: any) => Promise<void>;
  onPageChange?: (page: number) => void;
  detailPanel?: any[];

  userId?: string | number | null;
  accountId?: string | number | null;
  from?: Date | null;
  to?: Date | null;
  searchPage?: number;
  searchText?: string | null;
  searchTags?: string[] | null;
  searchMatching?: 'Any' | 'All';
  onClick?: any;
}

interface QueryProps {
  columns: Array<Column<any>>;
  path: string;
  actions?: any;
  search: string[];
  size: any | undefined;
  pageSize?: number;

  onRowDelete?: (item: any) => Promise<void>;
  onPageChange?: (page: number) => void;
  onSortingChange?: (order: string) => void;
  onSizeChange?: (size: any) => void;
  detailPanel?: any[];
  bgColor?: (rowData: any) => string;

  userId?: string | number | null;
  accountId?: string | number | null;
  from?: Date | null;
  to?: Date | null;
  query?: UseQueryResult<GQLReturnType | null, unknown>;
  searchOrder?: string;
  searchPage?: number;
  searchText?: string | null;
  searchTags?: string[] | null;
  searchMatching?: 'Any' | 'All';
  onClick?: any;
}

export const MTable = (props: Props) => {
  const history = useNavigate();
  const errorContext = useContext(ErrorContext);
  const { materialTableLocalization } = useContext(LanguageContext);

  if (!props.columns) return <></>;
  if (!props.fn) return <></>;

  return (
    <MaterialTable
      tableRef={props.tableRef}
      title=""
      localization={materialTableLocalization()}
      actions={props.actions}
      detailPanel={props.detailPanel}
      options={{
        toolbar: false,
        actionsColumnIndex: -1,
        sorting: true,
        //exportButton: true,
        exportAllData: true,
        search: true,
        draggable: false,
        pageSizeOptions: props.pageSize ? [20, 50, 100, props.pageSize] : [20, 50, 100],
        pageSize: props.pageSize ? props.pageSize : 20,
        paging: true,
        paginationType: 'normal',
        emptyRowsWhenPaging: false,
      }}
      columns={props.columns}
      data={(query: Query<Models>) => {
        const data = getTableData(
          { ...query, page: props.searchPage ? props.searchPage : query ? query.page : 0 },
          props.search,
          props.fn,
          (error) => {
            errorContext.addError(error);
          },
          {
            accountId: props.accountId,
            userId: props.userId,
            from: props.from,
            to: props.to,
            searchText: props.searchText,
            searchTags: props.searchTags,
            searchMatching: props.searchMatching,
          }
        );
        return data;
      }}
      onRowClick={(event: any, item: any | undefined) => {
        if (item) {
          if (!props.onClick) history(props.path + item.id);
          else props.onClick(item);
        }
      }}
      editable={{
        /* onRowUpdate: (newData: User, oldData: User) => updateTableData(newData, oldData, dispatch), */
        /* onRowDelete: (oldData: Opportunity) => deleteTableData<Opportunity>(oldData, dispatch, gqlOpportunityService.remove) */
        /* onRowAdd: (newData: User) => updateTableData(newData) */
        onRowDelete: props.onRowDelete,
      }}
    />
  );
};

/**
 *
 */
export const MTableQuery = (props: QueryProps) => {
  const history = useNavigate();

  const { translate } = useContext(LanguageContext);
  const page = props.searchPage ? (props.searchPage >= 0 ? props.searchPage : 0) : 0;
  const order = props.searchOrder ? props.searchOrder : '';
  const pageSize = props.pageSize ? props.pageSize : 20;
  const items = props.query?.data?.items ? props.query?.data?.items : 0;

  if (!props.columns) return <></>;
  if (props.query?.isLoading) return <></>;

  const start = page * pageSize + 1;
  const end = (page + 1) * pageSize > items ? items : (page + 1) * pageSize;
  if (start > items && page !== 0 && props.onPageChange) props.onPageChange(0);

  /**
   *
   * @param column
   */
  const sortByColumn = (column: Column<any>) => {
    if (!props.onSortingChange || !column.field) return;
    const name = column.field.toString();

    if (order === '') props.onSortingChange(name);
    else if (order === name) props.onSortingChange('-' + name);
    else if (order === '-' + name) props.onSortingChange(name);
    else props.onSortingChange(name);
  };

  return (
    <>
      <Typography>{props.from ? props.from.toISOString() : ''}</Typography>
      <Typography>{props.to ? props.to.toISOString() : ''}</Typography>
      <Table size={props.size}>
        <TableHead>
          <TableRow>
            {props.columns.map((column, index) => (
              <Fragment key={index}>
                {!column.hidden && column.sorting && (
                  <TableCell
                    style={{ cursor: 'pointer', padding: '0px 16px 0px 16px' }}
                    onClick={() => sortByColumn(column)}
                  >
                    {column.title}
                    {order === column.field?.toString() && (
                      <IconButton size="large">
                        <Icon style={{ fontSize: '18px' }}>arrow_downward_sharp</Icon>
                      </IconButton>
                    )}
                    {order === '-' + column.field?.toString() && (
                      <IconButton size="large">
                        {/* <ArrowUpwardSharp style={{ fontSize: '18px' }} /> */}
                        <Icon style={{ fontSize: '18px' }}>arrow_upward_sharp</Icon>
                      </IconButton>
                    )}
                  </TableCell>
                )}
                {!column.hidden && !column.sorting && (
                  <TableCell style={{ cursor: 'default', padding: '0px 16px 0px 16px' }}>
                    {column.title}
                  </TableCell>
                )}
              </Fragment>
            ))}
            {props.actions && <TableCell>{translate('Actions')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.query?.data?.data?.map((rowData: any, rowIndex: number) => (
            <Fragment key={rowIndex}>
              <TableRow
                style={{ backgroundColor: props.bgColor ? props.bgColor(rowData) : undefined }}
              >
                {props.columns.map((column, index) => (
                  <Fragment key={index}>
                    {!column.hidden && (
                      <TableCell
                        onClick={(e) => {
                          if (column.disableClick) return;
                          if (!props.onClick) history(props.path + rowData.id);
                          else props.onClick(rowData);
                        }}
                      >
                        {column.render
                          ? column.render(rowData, 'row')
                          : column.field
                          ? rowData[column.field.toString()]
                          : ''}
                      </TableCell>
                    )}
                  </Fragment>
                ))}
                {props.actions?.map((action: any, index: number) => (
                  <Fragment key={index}>
                    <TableCell style={{ padding: '0px', textAlign: 'center' }}>
                      <IconButton
                        style={
                          props.size && props.size === 'small' ? { padding: '0px' } : undefined
                        }
                        onClick={(e) => action.onClick(e, rowData)}
                        size="large"
                      >
                        {action.icon}
                      </IconButton>
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={{ textAlign: 'left', padding: '5px' }}>
              <IconButton
                title={translate('Change table size')}
                onClick={() => {
                  if (props.onSizeChange)
                    props.onSizeChange(props.size && props.size === 'small' ? 'medium' : 'small');
                }}
                size="large"
              >
                {/* <FormatSize style={{ fontSize: '20px' }} /> */}
                <Icon style={{ fontSize: '20px' }}>format_size</Icon>
              </IconButton>
            </TableCell>
            <TableCell
              style={{ textAlign: 'right', padding: '5px' }}
              colSpan={props.columns.filter((c) => !c.hidden).length - 1 + (props.actions ? 1 : 0)}
            >
              <IconButton
                style={{}}
                disabled={page === 0}
                onClick={() => {
                  if (props.onPageChange) props.onPageChange(0);
                }}
                size="large"
              >
                {/* <FirstPage style={{ fontSize: '20px' }} /> */}
                <Icon style={{ fontSize: '20px' }}>first_page</Icon>
              </IconButton>
              <IconButton
                style={{}}
                disabled={page === 0}
                onClick={() => {
                  if (props.onPageChange) props.onPageChange(page - 1);
                }}
                size="large"
              >
                {/* <ChevronLeft style={{ fontSize: '20px' }} /> */}
                <Icon style={{ fontSize: '20px' }}>chevron_left</Icon>
              </IconButton>
              <span style={{ position: 'relative', top: '2px' }}>
                {start}-{end} {translate('of')} {items}
              </span>
              <IconButton
                style={{}}
                disabled={(page + 1) * pageSize + 1 > items}
                onClick={() => {
                  if (props.onPageChange) props.onPageChange(page + 1);
                }}
                size="large"
              >
                {/* <ChevronRight style={{ fontSize: '20px' }} /> */}
                <Icon style={{ fontSize: '20px' }}>chevron_right</Icon>
              </IconButton>
              <IconButton
                style={{}}
                disabled={(page + 1) * pageSize + 1 > items}
                onClick={() => {
                  if (props.onPageChange) props.onPageChange(Math.floor(items / pageSize));
                }}
                size="large"
              >
                {/* <LastPage style={{ fontSize: '20px' }} /> */}
                <Icon style={{ fontSize: '20px' }}>last_page</Icon>
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export const generateRequestData = (
  lsName: string,
  fields: string[],
  orderBy: string,
  userId?: string | number | null,
  from?: any,
  to?: any,
  accountId?: string | number | null,
  tags?: string[]
) => {
  const lsItem = localStorage.getItem(lsName);
  const lsData = lsItem ? JSON.parse(lsItem) : null;

  const requestData = {
    offset: (lsData ? lsData.searchSize : 100) * (lsData ? lsData.searchPage : 0),
    limit: lsData ? lsData.searchSize : 100,
    userId: userId ? userId : undefined,
    accountId: accountId ? accountId : undefined,
    order: lsData ? lsData.searchOrder : orderBy,
    fields,
    search: lsData ? lsData.searchText : undefined,
    tags: lsData ? (lsData.searchTags ? lsData.searchTags : tags) : tags,
    from,
    to,
    tagsMatching: lsData ? lsData.searchMatching : 'All',
  };
  return requestData;
};

export const setDefaultTableValues = (
  lsName: string,
  setSearchSize: React.Dispatch<React.SetStateAction<number>>
): boolean => {
  const lsItem = localStorage.getItem(lsName);
  const lsData = lsItem ? JSON.parse(lsItem) : null;
  if (!lsItem || !lsData) return false;

  if (!lsData.searchSize) {
    setSearchSize(50);
    localStorage.setItem(lsName, JSON.stringify({ ...lsData, searchSize: 50 }));
    return true;
  }

  return false;
};

export const searchTextUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>,
  queryClient?: QueryClient
) => {
  return async (search: string) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, searchText: search }));
    setSearchText(search);

    // Invalidate queryClient previous queries so we're not showing old data
    let invalidateFilter: string | undefined;
    switch (lsName) {
      case 'customerList':
        invalidateFilter = 'accounts';
        break;
      case 'opportunityList':
        invalidateFilter = 'opportunities';
        break;
      case 'orderList':
        invalidateFilter = 'orders';
        break;
      case 'agreementList':
        invalidateFilter = 'agreements';
        break;
      case 'quoteList':
        invalidateFilter = 'quotes';
        break;
    }

    if (invalidateFilter)
      queryClient
        ?.getQueryCache()
        .findAll(invalidateFilter)
        .forEach((q) => {
          q.invalidate();
        });

    // Refetch data
    await query.refetch();
  };
};

export const searchTagsUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  searchTags: string[] | undefined,
  searchMatching: 'Any' | 'All',
  setSearchTags: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  setSearchMatching: React.Dispatch<React.SetStateAction<'Any' | 'All'>>,
  tagsChanged?: (tags?: string[], matching?: 'Any' | 'All') => void
) => {
  return async (tags: string[] | undefined, matching: 'Any' | 'All') => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    const myTags: string[] | undefined = tags ? (tags.length === 0 ? undefined : tags) : undefined;

    if (tagsChanged) tagsChanged(tags, matching);

    if (myTags !== searchTags) {
      localStorage.setItem(
        lsName,
        JSON.stringify({ ...value, searchTags: tags, searchMatching: matching })
      );
      setSearchTags(myTags);
      setSearchMatching(matching);
      await query.refetch();
    } else if (searchMatching !== matching) {
      localStorage.setItem(lsName, JSON.stringify({ ...value, searchMatching: matching }));
      setSearchMatching(matching);
      await query.refetch();
    }
  };
};

export const searchPageUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  setSearchPage: React.Dispatch<React.SetStateAction<number>>
) => {
  return async (number: number) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, searchPage: number }));
    setSearchPage(number);
    await query.refetch();
  };
};

export const searchSortingUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  setSearchOrder: React.Dispatch<React.SetStateAction<string>>
) => {
  return async (order: string) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, searchOrder: order }));
    setSearchOrder(order);
    await query.refetch();
  };
};

export const onTableSizeUpdated = (
  lsName: string,
  setTableSize: React.Dispatch<React.SetStateAction<any | undefined>>
) => {
  return async (size: any | undefined) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, tableSize: size }));
    setTableSize(size);
  };
};

export const reportTagsUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  searchTags: string[] | undefined,
  setTags: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  tagsChanged?: (tags?: string[], matching?: 'Any' | 'All') => void
) => {
  return async (tags: string[] | undefined) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    const myTags: string[] | undefined = tags ? (tags.length === 0 ? undefined : tags) : undefined;

    if (tagsChanged) tagsChanged(tags);

    if (myTags !== searchTags) {
      localStorage.setItem(lsName, JSON.stringify({ ...value, tags: tags }));
      setTags(myTags);
      await query.refetch();
    }
  };
};

export const reportFromUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  setFrom: React.Dispatch<React.SetStateAction<Date | null>>
) => {
  return async (date: Date | null) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, from: date }));
    setFrom(date);
    await query.refetch();
  };
};

export const reportToUpdated = (
  lsName: string,
  query:
    | QueryObserverRefetchErrorResult<GQLReturnType | null, unknown>
    | QueryObserverSuccessResult<GQLReturnType | null, unknown>,
  setTo: React.Dispatch<React.SetStateAction<Date | null>>
) => {
  return async (date: Date | null) => {
    const lsValue = localStorage.getItem(lsName);
    const value = JSON.parse(lsValue ? lsValue : '{}');
    localStorage.setItem(lsName, JSON.stringify({ ...value, to: date }));
    setTo(date);
    await query.refetch();
  };
};

export default MTable;
