import { IServiceError } from '@earnenterprise/react-network';
import { Action, ModelReports } from 'redux/actions/report.actions';

interface ReportState extends ModelReports {
  error?: IServiceError | IServiceError[] | null;
}

export const reportReducer = (state: ReportState = {}, action: Action): ReportState => {
  if (action.type === 'REPORTS_STATUS') {
    if (action.error && state.error) {
      if (Array.isArray(state.error) && !Array.isArray(action.error)) {
        action.error = [...state.error, action.error];
      } else if (Array.isArray(state.error) && Array.isArray(action.error)) {
        action.error = [...state.error, ...action.error];
      } else if (!Array.isArray(state.error) && !Array.isArray(action.error)) {
        action.error = [state.error, action.error];
      } else if (!Array.isArray(state.error) && Array.isArray(action.error)) {
        action.error = [state.error, ...action.error];
      }
    }
  }

  switch (action.type) {
    case 'REPORTS_STATUS':
      if (action.error) {
        if (action.opportunities)
          return { ...state, opportunities: action.opportunities, error: action.error };
        if (action.accounts) return { ...state, accounts: action.accounts, error: action.error };
        if (action.articles) return { ...state, articles: action.articles, error: action.error };
        if (action.invoices) return { ...state, invoices: action.invoices, error: action.error };
        return { ...state, error: action.error };
      } else {
        if (action.opportunities) return { ...state, opportunities: action.opportunities };
        if (action.accounts) return { ...state, accounts: action.accounts };
        if (action.articles) return { ...state, articles: action.articles };
        if (action.invoices) return { ...state, invoices: action.invoices };
      }
      return state;

    default:
      return state;
  }
};

export default reportReducer;
