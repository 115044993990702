import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Group } from '@earnenterprise/asc-models';
import { authQLService } from 'services/authQL.service';

export interface StatusAction {
  type: 'STATUS';
  online: boolean;
}

export interface LoginAction {
  type: 'LOGIN' | 'LOGOUT' | 'RIGHTS';
  rights?: Group;
}

export interface StatusWorking {
  type: 'STATUS_WORKING';
  isWorking: boolean;
}

export type Action = StatusAction | StatusWorking | LoginAction;

export const loginAction = (): LoginAction => {
  return { type: 'LOGIN' };
};
export const logoutAction = (): LoginAction => {
  return { type: 'LOGOUT' };
};
export const rightsAction = (rights: Group): LoginAction => {
  return { type: 'RIGHTS', rights };
};

/**
 *
 * @param status
 */
export const statusAction = (status: boolean): StatusAction => {
  return { type: 'STATUS', online: status };
};

/**
 *
 * @param isWorking
 */
const statusWorking = (isWorking: boolean): StatusWorking => {
  return { type: 'STATUS_WORKING', isWorking };
};

/**
 *
 */
export const refreshToken = (): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve: any) => {
      dispatch(statusWorking(true));
      authQLService.refreshToken().then(({ error }) => {
        if (error) {
          dispatch(logoutAction());
        }
        dispatch(statusWorking(false));
        resolve();
      });
    });
  };
};

/**
 *
 */
export const checkStatus = (): ThunkAction<Promise<void>, unknown, unknown, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve: any) => {
      dispatch(statusWorking(true));
      authQLService.isOnline().then((online) => {
        dispatch(statusAction(online));
        online ? dispatch(loginAction()) : dispatch(logoutAction());
        dispatch(statusWorking(false));
        resolve();
      });
    });
  };
};

export default checkStatus;
