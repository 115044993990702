import { Box, Drawer, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useContext, useState } from 'react';
import { useUser } from 'components/hooks/useQueryHooks';
import useReduxState from 'components/hooks/useReduxState';
//import { ThemeContext } from 'styling/themeContext';
import { ThemeContext } from 'App';
import { LanguageContext } from 'translation/languageContext';

interface Props {
  open: boolean;
  onClose: any;
}

export const NavSideList: React.FC<Props> = (props) => {
  const { name, changeTheme } = useContext(ThemeContext);
  const { themes } = useReduxState({ fetched: ['themes'] });
  const { user: me } = useUser();
  const { languageName, translate, changeLanguage } = useContext(LanguageContext);
  const [debugMode, setDebugMode] = useState<string>('no');

  if (!me) return <></>;
  //if (themes === undefined) return <></>;

  return (
    <>
      <Drawer anchor="right" open={props.open} onClose={() => props.onClose()}>
        <div
          style={{ width: '250px', height: '100%' }}
          role="presentation"
          onKeyDown={() => props.onClose()}
        >
          <Box
            style={{ width: '100%', textAlign: 'center', marginTop: '25px', marginBottom: '25px' }}
          >
            {false && (
              <img
                alt="User Avatar"
                src={me?.avatar}
                style={{ width: '200px', borderRadius: '100px', border: '1px solid #acf' }}
              />
            )}
          </Box>

          {themes !== undefined && (
            <>
              <Divider />
              <List>
                <ListItem button>
                  <FormControl fullWidth>
                    <InputLabel id="theme-select">{translate('Theme')}</InputLabel>
                    <Select
                      fullWidth
                      displayEmpty
                      value={name}
                      onChange={(e) => {
                        if (e.target) changeTheme(e.target.value as any);
                      }}
                    >
                      {false && <MenuItem value={'Default'}>Default</MenuItem>}
                      {themes?.map((theme, index) => (
                        <MenuItem key={index} value={theme.id ? theme.id : theme.theme.name}>
                          {theme?.theme?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </>
          )}

          <Divider />
          <List>
            <ListItem button>
              <FormControl fullWidth>
                <InputLabel id="language-select">{translate('Language')}</InputLabel>
                <Select
                  fullWidth
                  displayEmpty
                  value={languageName}
                  onChange={(e) => {
                    if (e.target) changeLanguage(e.target.value as any);
                  }}
                >
                  <MenuItem value="English">
                    <em>Default - English</em>
                  </MenuItem>
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Swedish">Swedish</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>

          <Divider />
          <List>
            <ListItem button>
              <FormControl fullWidth>
                <InputLabel id="debug-select">{translate('Debug Mode')}</InputLabel>
                <Select
                  fullWidth
                  displayEmpty
                  value={debugMode}
                  onChange={(e) => {
                    if (e.target) setDebugMode(e.target.value as any);
                  }}
                >
                  <MenuItem value="no">
                    <em>No</em>
                  </MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>
          {debugMode === 'yes' && (
            <>
              <Divider />
              <span style={{ paddingLeft: '10px', marginLeft: '10px' }}>
                <pre style={{ paddingLeft: '10px', marginLeft: '10px' }}>
                  {JSON.stringify(me, undefined, 2)}
                </pre>
              </span>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default NavSideList;
