import { Group } from '@earnenterprise/asc-models';
import { Action } from 'redux/actions/auth.actions';

export const rightsReducer = (state: Group | null = null, action: Action): Group | null => {
  switch (action.type) {
    case 'RIGHTS':
      return action.rights ? action.rights : null;
    default:
      return state;
  }
};

export default rightsReducer;
