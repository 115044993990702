import { Article, OpportunityArticle } from '@earnenterprise/asc-models';
import { renderDate } from 'models/helper.tableData';
import { IconButton, Link, Icon } from '@mui/material';

import React from 'react';

/**
 *
 * @param article
 * @param history
 */
export const renderAsArticle = (article: {
  article?: Article | null;
  history?: any;
  oarticle?: OpportunityArticle | null;
  translate?: (text: string, multiple?: boolean | undefined) => string;
  skipNewWindow?: boolean;
  skipExchangeable?: boolean;
  maxSize?: number;
  onExchange?: (article?: Article | null) => void;
}) => {
  if (!article) return null;
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {article.skipNewWindow && (
        <Link
          underline="none"
          href={`/article/${article.article?.id}`}
          title={article.article?.name}
          onClick={(e: any) => {
            e.preventDefault();
            article.history(`/article/${article.article?.id}`);
          }}
        >
          {article.maxSize && article.article && article.article.name.length > article.maxSize
            ? article.article?.name.substring(0, article.maxSize - 3).trimEnd() + '...'
            : article.article?.name}
        </Link>
      )}
      {!article.skipNewWindow && (
        <>
          {/* <OpenInNewIcon style={{ paddingRight: '3px', fontSize: '16px', position: 'relative', top: '3px' }} /> */}
          <Icon
            style={{ paddingRight: '3px', fontSize: '1.0rem', position: 'relative', top: '0px' }}
          >
            open_in_new
          </Icon>{' '}
          <Link
            target="_blank"
            underline="none"
            href={`/article/${article.article?.id}`}
            title={article.article?.name}
          >
            {article.maxSize && article.article && article.article.name.length > article.maxSize
              ? article.article?.name.substring(0, article.maxSize - 3).trimEnd() + '...'
              : article.article?.name}
          </Link>
        </>
      )}

      {article.article?.isExchangeable && !article.skipExchangeable && (
        <IconButton
          style={{ padding: '0px', marginLeft: '5px' }}
          title={article.translate ? article.translate('Exchange article') : ''}
          onClick={() => {
            if (article.onExchange) article.onExchange(article.article);
          }}
          size="large"
        >
          {/* <CodeIcon style={{ paddingLeft: '3px', paddingRight: '3px', fontSize: '20px', position: 'relative' }} /> */}
          <Icon
            style={{
              paddingLeft: '3px',
              paddingRight: '3px',
              fontSize: '1.0rem',
              position: 'relative',
            }}
          >
            code
          </Icon>{' '}
        </IconButton>
      )}
      {article.oarticle && article.article?.isConnectedToMatch && article.oarticle.match && (
        <>
          <br />
          {article.oarticle.match.awayTeam + ' - '}
          {renderDate(article.oarticle.match, 'date')}
        </>
      )}
      {article.oarticle && article.article?.isConnectedToPlayer && article.oarticle.player && (
        <>
          <br />
          {article.oarticle.player.number ? '#' + article.oarticle.player.number + ' - ' : ''}
          {article.oarticle.player.name}
        </>
      )}
    </div>
  );
};
