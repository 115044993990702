import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  Input,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { globalStyles } from 'styling/global.styles';
import { LanguageContext } from 'translation/languageContext';
import { useReduxState } from 'components/hooks/useReduxState';

interface EditMenuProps {
  dialogHelper: any;
  hideCancelButton?: boolean;
  rawText?: boolean;
  onAccept: (data: any, index: number) => void;
  onCancel?: () => void;
}

export const EditMenuDialogComponent: React.FC<EditMenuProps> = (props) => {
  const { translate } = useContext(LanguageContext);
  useReduxState({ fetched: ['theme'] });
  const inputData = props.dialogHelper.getData();
  const [inputMenuString, setInputMenuString] = useState<{
    order: number;
    text: string;
    original: string;
  }>({ order: 0, text: '', original: '' });
  const [inputIndex, setInputIndex] = useState<number>(-1);
  const classes = globalStyles();
  //const inputRef = createRef<any>();

  /**
   *
   */
  useEffect(() => {
    if (inputData) {
      setInputMenuString({ ...inputData.menu, order: inputData.menu.order });
      setInputIndex(inputData.index);
    }

    return function cleanup() {
      console.log({ cleanup: '' });
    };
  }, [inputData]);

  if (!inputData) return <></>;

  return (
    <Dialog
      open={props.dialogHelper.getOpen()}
      keepMounted
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.dialogHelper.getMessage().title}
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText>{props.dialogHelper.getMessage().message}</DialogContentText>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <FormGroup>
              {translate('Ordering')}
              <Input
                className={
                  'MuiInputBase-input MuiInput-input MuiInputBase-root' + classes.textField
                }
                value={inputMenuString.order}
                onChange={(e) => {
                  setInputMenuString({
                    ...inputMenuString,
                    order: parseInt(e.target.value.replace(/[^0-9]/g, '')),
                  });
                }}
              />
            </FormGroup>
            <br />
          </Grid>
          <Grid item sm={8}>
            <FormGroup>
              {translate('Text')}
              <Input
                className={
                  'MuiInputBase-input MuiInput-input MuiInputBase-root' + classes.textField
                }
                value={inputMenuString.text}
                onChange={(e) => {
                  setInputMenuString({ ...inputMenuString, text: e.target.value });
                }}
              />
            </FormGroup>
            <br />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!props.hideCancelButton && (
          <Button
            onClick={() => {
              if (props.onCancel) props.onCancel();
              props.dialogHelper.setOpen(false);
            }}
            color="primary"
          >
            {translate('Cancel')}
          </Button>
        )}
        <Button
          onClick={() => {
            //inputMenuString.order += '%';
            props.onAccept(inputMenuString, inputIndex);
            props.dialogHelper.setOpen(false);
          }}
          color="primary"
        >
          {translate('Agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
