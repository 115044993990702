import { useDispatch } from 'react-redux';
import { Person } from '@earnenterprise/asc-models';
import { setupSetting } from '@earnenterprise/asc-models';
import { fetchAccounts, fetchUsers, updateItems } from 'redux/actions/items.actions';
import { useSetting, useUser } from 'components/hooks/useQueryHooks';
import { useReduxState } from 'components/hooks/useReduxState';

/**
 *
 */
export interface Props {
  // Do not run fetchUsers, fetchAccounts, etc.
  // * Should probably not be set except in specific circumstances.
  skipFetch?: boolean;
  skipFetchUsers?: boolean;
  skipFetchAccounts?: boolean;
}

/**
 * Use Options hook
 * @param param0 { skipFetch }.
 */
export const useOptions = ({ skipFetch, skipFetchUsers, skipFetchAccounts }: Props) => {
  const dispatch = useDispatch();
  const { users, accounts } = useReduxState({ fetched: ['users', 'accounts'] });
  const { setting } = useSetting();
  const { user: me } = useUser();

  // Fetch users when not cached in redux
  if ((users === null || users === undefined) && !skipFetch && !skipFetchUsers) {
    dispatch(updateItems({ users: [] }, null));
    dispatch(fetchUsers({}));
  }

  // Fetch accounts when not cached in redux
  if ((accounts === null || accounts === undefined) && !skipFetch && !skipFetchAccounts) {
    dispatch(updateItems({ accounts: [] }, null));
    dispatch(fetchAccounts({}));
  }

  /**
   * add value and label to all user options
   * @param item
   */
  const createUserOptions = (item: any, field = 'owner') => {
    if (item[field]) {
      (item[field] as any).label =
        item[field].firstname + (item[field].lastname ? ' ' + item[field].lastname : '');
      (item[field] as any).value = item[field].id;
    }

    if (!users) return [];
    if (!me) return [];
    const userOptions: any[] = [...users];
    userOptions.forEach((opt, i) => {
      if (userOptions.findIndex((o) => o.value === opt.id) === -1) {
        userOptions[i].value = opt.id;
        userOptions[i].label = opt.firstname + (opt.lastname ? ' ' + opt.lastname : '');
      }
    });

    return userOptions;
  };

  /**
   * add value and label to all user options
   * @param item
   */
  const createContactOptions = (
    item: any,
    contacts: Person[] | undefined | null,
    field = 'contact'
  ) => {
    if (item[field]) {
      (item[field] as any).label =
        item[field].firstname + (item[field].lastname ? ' ' + item[field].lastname : '');
      (item[field] as any).value = item[field].id;
    }

    if (!contacts) return [];

    const contactOptions: any[] = [...contacts];
    contactOptions.forEach((opt, i) => {
      if (contactOptions.findIndex((o) => o.value === opt.id) === -1) {
        contactOptions[i].value = opt.id;
        contactOptions[i].label = opt.firstname + (opt.lastname ? ' ' + opt.lastname : '');
      }
    });

    return contactOptions;
  };

  /**
   * add value and label to all account options
   * @param item
   */
  const createAccountOptions = (item: any) => {
    if (item.account) {
      (item.account as any).label = item.account.name;
      (item.account as any).value = item.account.id;
    }
    if (!accounts) return [];
    const accountOptions: any[] = [...accounts];
    accountOptions.forEach((opt, i) => {
      if (accountOptions.findIndex((o) => o.value === opt.id) === -1) {
        accountOptions[i].value = opt.id;
        accountOptions[i].label = opt.name;
      }
    });

    return accountOptions;
  };

  /**
   * add value and label to all account options
   * @param item
   */
  const createStatusOptions = () => {
    const statusOptions: { value: string; label: string }[] = [];

    if (setting !== undefined) {
      setupSetting(setting);
      setting.statusLevels.split('|').forEach((statusLevel) => {
        const level = statusLevel.split(';');
        statusOptions.push({
          value: level[0],
          label: level.length === 2 ? level[0] + ' - ' + level[1] : level[0],
        });
      });
    }

    return statusOptions;
  };

  return { createUserOptions, createAccountOptions, createStatusOptions, createContactOptions };
};

export default useOptions;
