import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import useSave from 'components/hooks/useSave';
import BaseContainerFormik from 'fields/basecontainer_formik';

import { emptySetting, Setting, SettingOutput } from '@earnenterprise/asc-models';

import { gqlSettingService } from 'services/gql.services';
import globalStyles from 'styling/global.styles';
import { EditMenuDialogComponent } from 'routes/settings/economy/settings_menu.dialog';
import { LanguageContext } from 'translation/languageContext';
import { useDialogHelper } from 'components/confirm.dialog.component';

import { useRights, useSetting } from 'components/hooks/useQueryHooks';

export function getMenuFromSettingState(
  setting: Setting
): React.SetStateAction<{ order: number; text: string; original: string }[]> {
  const menuItems: { order: number; text: string; original: string }[] = [];

  setting.menuString.split('|').forEach((menuItem) => {
    const item = menuItem.split(';');
    menuItems.push({ order: parseInt(item[0]), original: item[1], text: item[2] });
  });

  return menuItems;
}

export function getMenuNameFromSetting(
  setting: Setting,
  name: string,
  translate: any,
  multiple?: boolean
) {
  const filter = setting.menuString
    .split('|')
    .find((menuItem) => {
      const item = menuItem.split(';');
      if (item[1] === name) return true;
      return null;
    })
    ?.split(';');
  if (filter && filter.length > 1) return filter[2] ? filter[2] : translate(name, multiple);
  return translate(name, multiple);
}

export function getMenuFromSetting(
  setting: Setting
): { order: number; text: string; original: string }[] {
  const menuItems: { order: number; text: string; original: string }[] = [];

  setting.menuString.split('|').forEach((menuItem) => {
    const item = menuItem.split(';');
    menuItems.push({ order: parseInt(item[0]), original: item[1], text: item[2] });
  });

  return menuItems;
}

function getMenuStringFromLevels(
  menuItems: { order: number; text: string; original: string }[]
): string {
  let status = '';

  menuItems.forEach((item) => {
    if (status !== '') status += '|';
    if (item.text) status += item.order + ';' + item.original + ';' + item.text;
    else status += item.order + ';' + item.original + ';';
  });
  return status;
}

export const SettingsMenuComponent: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  const classes = globalStyles();
  const { saveItem } = useSave({
    func: gqlSettingService.save,
    sanitizeObject: SettingOutput(),
    type: 'setting',
  });
  const { querySetting, setting } = useSetting();
  const [menuItems, setMenuItems] = useState(getMenuFromSetting(setting));
  const inputDialogHelper = useDialogHelper();
  const { rights } = useRights();

  if (!rights?.adminAll) return <></>;
  if (!setting.id) return <></>;

  const buttons = [
    {
      name: translate('Reset to default'),
      fn: (e: any, formik: any) => {
        const tmp = emptySetting();
        setMenuItems(getMenuFromSetting(tmp));
        formik.setFieldValue('menuString', tmp.menuString);
      },
    },
  ];

  return (
    <>
      <Formik
        initialValues={setting}
        enableReinitialize={true}
        onSubmit={async (t, formikHelpers) => {
          //dispatch(setSettings(undefined));
          await saveItem(t, formikHelpers);
          //await querySetting.refetch();
          //saveMutation.mutate({ t, formikHelpers });
          //await queryClient.refetchQueries(['settings']);
          querySetting.refetch();
        }}
        validateOnChange={true}
      >
        {({ setFieldValue }) => (
          <Form>
            <BaseContainerFormik
              header={translate('Menu', true)}
              subheader={translate('Setting', true)}
              submitButton={true}
              buttons={buttons}
            >
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('Ordering')}</TableCell>
                      <TableCell>{translate('Original')}</TableCell>
                      <TableCell>{translate('Text')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {menuItems.map((menu, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{menu.order}</TableCell>
                          <TableCell>{translate(menu.original, true)}</TableCell>
                          <TableCell>{menu.text}</TableCell>
                          <TableCell
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '70px',
                              paddingLeft: '5px',
                              paddingRight: '0px',
                              width: '70px',
                              textAlign: 'right',
                            }}
                          >
                            <IconButton
                              className={classes.iconButtonNoPadding}
                              title={translate('Edit menu')}
                              onClick={(e) => {
                                inputDialogHelper.setData({ menu, index } as any);
                                inputDialogHelper.setOpen(true);
                                inputDialogHelper.setMessage({
                                  title: translate('Edit menu'),
                                  message: '',
                                });
                              }}
                              size="large"
                            >
                              <Icon>edit_outlined</Icon>
                            </IconButton>
                            <IconButton
                              className={classes.iconButtonNoPadding}
                              title={translate('Delete menu')}
                              onClick={(e) => {
                                menuItems.splice(index, 1);
                                setting.statusLevels = getMenuStringFromLevels(menuItems);
                                setMenuItems(Object.assign([], menuItems));
                                setFieldValue('menuString', getMenuStringFromLevels(menuItems));
                              }}
                              size="large"
                            >
                              <Icon>delete_outline</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <EditMenuDialogComponent
                  dialogHelper={inputDialogHelper}
                  onAccept={async (
                    menuItem: { order: number; text: string; original: string },
                    index: number
                  ) => {
                    const tmp = Object.assign([], menuItems);
                    if (index === -1) tmp.push(menuItem);
                    else (tmp as any)[index] = menuItem;

                    (tmp as { order: number; text: string; original: string }[]).sort((a, b) => {
                      const aNum = a.order;
                      const bNum = b.order;
                      return aNum < bNum ? -1 : 0;
                    });
                    setMenuItems(tmp);
                    setFieldValue('menuString', getMenuStringFromLevels(tmp));
                  }}
                />
              </Grid>
              <Grid item sm={12}></Grid>
            </BaseContainerFormik>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SettingsMenuComponent;
