import { FormikHelpers } from 'formik';
//import authQLService from 'services/authQL.service';
import { Models } from '@earnenterprise/asc-models';

/**
 *
 * @param item
 * @param setSubmitting
 * @param fn
 */
export async function remove<T extends Models>(item: T, fn: any, setSubmitting?: (isSubmitting: boolean) => void): Promise<{ error: any | undefined }> {
  // !No item, how did you get here?
  if (item === null) return { error: new Error('no item') };

  // Are we still online?
  if (setSubmitting) setSubmitting(true);
  /*
  if (!(await authQLService.isOnline())) {
    if (setSubmitting) setSubmitting(false);
    return { error: new Error('not online') };
  }
  */

  // Post to backend, wait and check for errors
  const { error } = await fn((item as any).id);
  if (error) {
    if (setSubmitting) setSubmitting(false);
    return { error };
  }

  // Update state and toast!
  if (setSubmitting) setSubmitting(false);
  return { error: undefined };
}

/**
 * SaveUser inside main
 */
export async function save(item: Models, fn: any, sanitizeObject: any, helpers?: FormikHelpers<any>): Promise<{ data?: any; isNew: boolean; error?: any | undefined }> {
  // !No item, how did you get here?
  if (item === null) return { isNew: false, error: new Error('no item') };

  // NewItem?
  const isNew = (item as any).id === null || (item as any).id === '0' || (item as any).id === 0 || (item as any).id === undefined;

  // Are we still online?
  if (helpers) helpers.setSubmitting(true);
  /*
  if (!(await authQLService.isOnline())) {
    if (helpers) helpers.setSubmitting(false);
    return { isNew, error: new Error('not online') };
  }
  */

  // Post to backend, wait and check for errors
  const { data, error } = await fn((item as any).id, item, sanitizeObject);
  if (error) {
    //M.toast({ html: 'User update failed ' + error, classes: 'toast-error' });
    if (helpers) helpers.setSubmitting(false);
    return { data, isNew, error };
  }

  

  //M.toast({ html: 'User updated' });
  if (helpers) helpers.setSubmitting(false);
  (item as any).id = data.id;

  return { data, isNew };
}
