import { Localization } from '@material-table/core';
import React, { useState } from 'react';
import English from 'translation/english';
import Swedish from 'translation/swedish';

type ILanguageContext = {
  languageName: string;
  changeLanguage: (name: string) => void;
  translate: (text: string, multiple?: boolean) => string;
  materialTableLocalization: () => Localization;
};

interface LanguageProps {
  defaultLanguage?: string;
}

/**
 *
 */
export const LanguageContext = React.createContext<ILanguageContext>({} as ILanguageContext);

export const LanguageProvider: React.FC<LanguageProps> = ({ defaultLanguage, children }) => {
  const [language, setLanguage] = useState<string>(defaultLanguage ? defaultLanguage : 'English');

  /**
   *
   * @param name
   */
  const changeLanguage = async (name: string) => {
    localStorage.setItem('language', name);
    setLanguage(name);
  };

  /**
   *
   * @param text
   */
  const translate = (text: string, multiple?: boolean) => {
    switch (language) {
      case 'English': {
        const index = English.findIndex((item) => item.text === text);
        if (index === -1) return text;
        const item = English[index];
        if (multiple && item.translation.multiple) {
          return item.translation.multiple;
        }
        if (English[index].translation.default) return English[index].translation.default;
        break;
      }
      case 'Swedish': {
        const index = Swedish.findIndex((item) => item.text === text);
        if (index === -1) {
          console.log(`{ text: '${text}', translation: { default: '' } },`);
          return text;
        }
        const item = Swedish[index];
        if (multiple && item && item.translation.multiple) return item.translation.multiple;
        if (Swedish[index].translation.default) return Swedish[index].translation.default;
        break;
      }
    }
    return text;
  };

  const materialTableLocalization = () => {
    switch (language) {
      // Return default
      case 'English':
        break;

      case 'Swedish':
        return {
          pagination: {
            labelDisplayedRows: '{from}-{to} av {count}',
            labelRowsSelect: 'rader',
            labelRowsPerPage: 'Rader per sida',
            firstAriaLabel: 'Första sidan',
            firstTooltip: 'Första sidan',
            previousAriaLabel: 'Föregående sida',
            previousTooltip: 'Föregående sida',
            nextAriaLabel: 'Nästa sida',
            nextTooltip: 'Nästa sida',
            lastAriaLabel: 'Sista sidan',
            lastTooltip: 'Sista sidan',
          },
          grouping: {
            placeholder: 'Dra kolumner här för att gruppera...',
            label: 'Grupperad ',
          },
          toolbar: {
            nRowsSelected: '{0} rader(s) valda',
            searchTooltip: 'Sök',
            searchPlaceholder: 'Sök',
            exportTitle: 'Exportera',
            exportAriaLabel: 'Exportera',
          },
          header: {
            actions: 'Åtgärder',
          },
          body: {
            emptyDataSourceMessage: 'Inga poster att visa',
            filterRow: {
              filterTooltip: 'Filtrera',
            },
            editRow: {
              deleteText: 'Är du säker på att du vill ta bort denna rad?',
              cancelTooltip: 'Avbryt',
              saveTooltip: 'Spara',
            },
            addTooltip: 'Lägg till',
            deleteTooltip: 'Ta bort',
            editTooltip: 'Ändra',
          },
        };
    }

    return {
      pagination: {},
    };
  };

  return (
    <LanguageContext.Provider
      value={{ languageName: language, changeLanguage, translate, materialTableLocalization }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
