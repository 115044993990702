/* External lib imports. */
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  To,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import ErrorAlert from 'error/error.alert';
/* My Imports */
import Nav from 'nav/nav';
import globalStyles from 'styling/global.styles';

export interface RouterProps {
  online: boolean;
  rights?: any;
  history?: any;
  computedMatch?: any;
  location?: any;
  match?: any;
  path?: string;
  staticContext?: any;
}

/**
 * CCRouter
 */
export const CCRouter: React.FC<RouterProps> = (props) => {
  const classes = globalStyles();
  const online = props.online;
  const path = window.location.pathname;

  const AuthLogin = lazy(() => import('auth/login'));

  // For logged out users.
  const HomeComponent = lazy(() => import('routes/home'));
  const ExternalMatchesComponent = lazy(() => import('routes/external/matches'));
  const SettingsChangelog = lazy(() => import('routes/settings/changelog/settings_changelog'));

  const MatchComponent = lazy(() => import('routes/match/match'));
  const PlayerComponent = lazy(() => import('routes/player/player'));
  const UserComponent = lazy(() => import('routes/user/user'));
  const GroupComponent = lazy(() => import('routes/group/group'));
  const PresentationComponent = lazy(() => import('routes/presentation/presentation'));
  const FileComponent = lazy(() => import('routes/file/file.list'));

  const AgreementComponent = lazy(() => import('routes/agreement/agreement'));
  const ArticleComponent = lazy(() => import('routes/article/article'));
  const ArticleAreaComponent = lazy(() => import('routes/article/article.area'));
  const PackageComponent = lazy(() => import('routes/package/package'));
  const OpportunityComponent = lazy(() => import('routes/opportunity/opportunity'));
  const OrderComponent = lazy(() => import('routes/order/order'));
  const AccountComponent = lazy(() => import('routes/account/account'));
  const MessageComponent = lazy(() => import('routes/message/message'));
  const ReportComponent = lazy(() => import('routes/report/report'));
  const QuoteComponent = lazy(() => import('routes/quote/quote'));

  // Admin loads
  //if (online && rights?.adminAll) {
  const SettingsEconomy = lazy(() => import('routes/settings/economy/settings_economy'));
  const SettingsStatusLevel = lazy(() => import('routes/settings/economy/settings_status_levels'));
  const SettingsMenu = lazy(() => import('routes/settings/economy/settings_menu'));
  const SettingsTags = lazy(() => import('routes/settings/economy/settings_tags'));
  const SettingsSystem = lazy(() => import('routes/settings/system/settings_system'));
  const SettingsTheme = lazy(() => import('routes/settings/theme/settings_theme'));
  //}

  const getHistory = (): To => {
    const path = localStorage.getItem('history');
    if (path && path !== '/login') {
      return path;
    }
    return '/';
  };

  const setHistory = (): To => {
    localStorage.setItem('history', path);
    return '/login';
  };

  return (
    <>
      <Router>
        <div className={classes.root}>
          <Nav online={online} />
          <Suspense
            fallback={
              <div className="progress">
                <div className="indeterminate"></div>
              </div>
            }
          >
            <div className={classes.content}>
              <Routes>
                {online && (
                  <>
                    <Route path="/home/:id" element={<HomeComponent online={online} />} />
                    <Route path="/home" element={<HomeComponent online={online} />} />

                    <Route path="/user/:id/:section" element={<UserComponent />} />
                    <Route path="/user/:id" element={<UserComponent />} />
                    <Route path="/user" element={<UserComponent />} />

                    <Route path="/group/:id" element={<GroupComponent />} />
                    <Route path="/group" element={<GroupComponent />} />

                    <Route path="/order/:id" element={<OrderComponent />} />
                    <Route path="/order" element={<OrderComponent />} />

                    <Route path="/opportunity/:id" element={<OpportunityComponent />} />
                    <Route path="/opportunity" element={<OpportunityComponent />} />

                    <Route path="/article/area/:id" element={<ArticleAreaComponent />} />
                    <Route path="/article/:id" element={<ArticleComponent online={online} />} />
                    <Route path="/article" element={<ArticleComponent online={online} />} />

                    <Route path="/package/:id" element={<PackageComponent online={online} />} />
                    <Route path="/package" element={<PackageComponent online={online} />} />

                    <Route path="/match/:id" element={<MatchComponent />} />
                    <Route path="/match" element={<MatchComponent />} />

                    <Route path="/player/:id" element={<PlayerComponent />} />
                    <Route path="/player" element={<PlayerComponent />} />

                    <Route path="/presentation/:id/:section" element={<PresentationComponent />} />
                    <Route path="/presentation/:id" element={<PresentationComponent />} />
                    <Route path="/presentation" element={<PresentationComponent />} />

                    <Route path="/agreement/:id" element={<AgreementComponent />} />
                    <Route path="/agreement" element={<AgreementComponent />} />

                    <Route path="/quote/:id" element={<QuoteComponent />} />
                    <Route path="/quote" element={<QuoteComponent />} />

                    <Route path="/message/:id" element={<MessageComponent />} />
                    <Route path="/message" element={<MessageComponent />} />

                    <Route path="/account/:id/:section" element={<AccountComponent />} />
                    <Route path="/account/:id" element={<AccountComponent />} />
                    <Route path="/account" element={<AccountComponent />} />

                    <Route path="/report/:section/:id" element={<ReportComponent />} />
                    <Route path="/report/:section" element={<ReportComponent />} />
                    <Route path="/report" element={<ReportComponent />} />

                    <Route path="/theme/:id" element={<SettingsTheme />} />
                    <Route path="/settings/theme" element={<SettingsTheme />} />
                    <Route path="/settings/economy" element={<SettingsEconomy />} />
                    <Route path="/settings/statuslevel" element={<SettingsStatusLevel />} />
                    <Route path="/settings/menu" element={<SettingsMenu />} />
                    <Route path="/settings/tags" element={<SettingsTags />} />
                    <Route path="/settings/system" element={<SettingsSystem />} />
                    <Route path="/settings/changelog" element={<SettingsChangelog />} />

                    <Route path="/file" element={<FileComponent />} />

                    <Route path="/login" element={<Navigate to={getHistory()} />} />

                    <Route path="/" element={<HomeComponent online={online} />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {!online && (
                  <>
                    <Route path="/login" element={<AuthLogin online={online} />} />
                    <Route
                      path="*"
                      element={
                        <Navigate to={setHistory()} state={{ pathname: '/login', from: path }} />
                      }
                    />
                  </>
                )}

                {ExternalMatchesComponent && (
                  <Route path="/external/matches" element={<ExternalMatchesComponent />} />
                )}
              </Routes>
            </div>
          </Suspense>
        </div>
      </Router>
    </>
  );
};

/**
 * No Match, display 404 error.
 */
export function NoMatch() {
  const location = useLocation();

  return (
    <ErrorAlert error="404: Not Found" description={'No path found for: ' + location.pathname} />
  );
}

export default CCRouter;
